import styled from 'styled-components';
import { Input } from 'antd';

const { TextArea } = Input;

const TextFieldStyleOne = styled(Input)`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  margin-top: ${(props) => props.marginTop};
  border: 1px solid ${(props) => props.borderColor};
  width: ${(props) => props.width};
  
  &:focus,
  &:hover {
    border-color: ${(props) => props.hoverBorderColor} !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); /* Optional for focus outline */
  }

  @media (max-width: 600px) {
    font-size: ${(props) => props.mobileFontSize};
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: ${(props) => props.tabletFontSize};
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: ${(props) => props.laptopFontSize};
  }

  @media (min-width: 1201px) {
    font-size: ${(props) => props.macbook13FontSize};
  }
`;


const TextAreaStyleOne = styled(TextArea)`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  margin-top: ${(props) => props.marginTop};
  border: 1px solid ${(props) => props.borderColor};
  width: ${(props) => props.width};
  resize: ${(props) => (props.resizeable ? 'both' : 'none')}; /* Optional for controlling resize */

  &:focus,
  &:hover {
    border-color: ${(props) => props.hoverBorderColor} !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); /* Optional for focus outline */
  }

  @media (max-width: 600px) {
    font-size: ${(props) => props.mobileFontSize};
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: ${(props) => props.tabletFontSize};
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: ${(props) => props.laptopFontSize};
  }

  @media (min-width: 1201px) {
    font-size: ${(props) => props.macbook13FontSize};
  }
`;


export { TextFieldStyleOne,TextAreaStyleOne };
