import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import styles from '../../styles.module.scss';

import { fetchListOfAllCustomers } from '../../../logic/customers/actions';
import { getListOfCustomers, getIsLoadingListOfCustomers } from '../../../logic/customers/selectors';
import moment from 'moment';

import { capitalizeFirstLetter } from '../../../../../shared/utils/utils';
import BankDetailsModal from './BankDetailsModal';

const CustomersOverview = ({
    listOfCustomers,
    isLoadingListOfCustomers,
    fetchListOfAllCustomers,
    setIsDetailsModalOpen,
    setDetailsModalTitle,
    setCurrentDetailsFormToRender,
    setDetailsModalFooter,
    setModalWidth,
    setRefresh,
    refresh,
    ...props
}) => {
    useEffect(() => {
        fetchListOfAllCustomers({});
    }, [fetchListOfAllCustomers]);

    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile_url',
            key: 'profile_url',
            render: (profile_url, record) => {
                const renderProfileImage = (url, name) => {
                    if (url) {
                        return (
                            <img
                                src={url + ""}
                                alt={name}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ''; // Reset the image source if it fails
                                }}
                                style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                            />
                        );
                    } else {
                        const initials = name.split(' ').map(n => n[0]).join('');
                        return (
                            <div style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fff',
                            }}>
                                {initials}
                            </div>
                        );
                    }
                };
                return renderProfileImage(profile_url, record.display_name);
            },
        },

        {
            title: 'Name',
            dataIndex: 'display_name',
            key: 'display_name',
        },
        {
            title: 'Company',
            dataIndex: 'company_name',
            key: 'company_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Channels',
            dataIndex: 'total_active_channels_count',
            key: 'total_active_channels_count',
            render: (text) => text ?? 0,
        },
        {
            title: 'Vendors',
            dataIndex: 'total_active_vendors_count',
            key: 'total_active_vendors_count',
            render: (text) => text ?? 0,
        },
        {
            title: 'Products',
            dataIndex: 'total_product_count_in_use',
            key: 'total_product_count_in_use',
            render: (text) => text ?? 0,
        },
        {
            title: 'Matches',
            dataIndex: 'total_matches_got_by_active_channels',
            key: 'total_matches_got_by_active_channels',
            render: (text) => text ?? 0,
        },
        {
            title: 'Join Date',
            dataIndex: 'last_access',
            key: 'last_access',
            render: (text) => moment(text).fromNow(),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <button
                    onClick={() => handleBankDetailsClick(record)}
                    className={styles.bank_details_button}
                >
                    Bank Details
                </button>
            ),
        }
    ];

    const handleBankDetailsClick = (record) => {
        // Implement the logic to handle the bank details click
        console.log('Bank details clicked for:', record);
        setRefresh(!refresh)
        setModalWidth("30%")
        setCurrentDetailsFormToRender(<BankDetailsModal setIsDetailsModalOpen={setIsDetailsModalOpen} record={record} setRefresh={setRefresh} refresh={refresh} />);
        setDetailsModalTitle(`Bank details of  ${capitalizeFirstLetter(record.display_name)}`);
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true);
    };
    return (
        <div className={styles.customers_overview_container}>
            <Table
                bordered
                columns={columns}
                dataSource={listOfCustomers}
                loading={isLoadingListOfCustomers}
                rowKey="id"
                pagination={false}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    listOfCustomers: getListOfCustomers(state),
    isLoadingListOfCustomers: getIsLoadingListOfCustomers(state),
});

const mapDispatchToProps = {
    fetchListOfAllCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersOverview);
