import styles from './styles.module.scss'
import { ButtonStyleOne } from './styled'
import { useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};

const CustomButton = ({
    width = '100%',
    title = 'Button',
    background_color,
    hoverBackgroundColor,
    hoverBorderColor,
    hoverTextColor,
    text_color,
    border_color,
    font_size = '16px',
    unit = 'px',
    border_radius = '4px',
    padding = '0px',
    font_weight = 'normal',
    colorScheme = 'primary', // New prop for color schemes
    onClick = () => { },
    icon = null,
    pick_passed_font_size = false,
    mobileFontSize,
    tabletFontSize,
    laptopFontSize,
    is_loading = false,
    macbook13FontSize,
    disabled = null, // New prop for disabled state
    ...props
}) => {

    // Color schemes
    const colorSchemes = {
        primary: {
            background_color: '#08338A',
            hoverBackgroundColor: '#0056b3',
            text_color: 'white',
            border_color: '#007bff',
            hoverTextColor: 'white',
        },
        secondary: {
            background_color: '#28a745',
            hoverBackgroundColor: '#218838',
            text_color: 'white',
            border_color: '#28a745',
            hoverTextColor: 'white',
        },
        danger: {
            background_color: '#dc3545',
            hoverBackgroundColor: '#c82333',
            text_color: 'white',
            border_color: '#dc3545',
            hoverTextColor: 'white',
        },
        warning: {
            background_color: '#ffc107',
            hoverBackgroundColor: '#e0a800',
            text_color: 'black',
            border_color: '#ffc107',
            hoverTextColor: 'black',
        },
        info: {
            background_color: '#17a2b8',
            hoverBackgroundColor: '#138496',
            text_color: 'white',
            border_color: '#17a2b8',
            hoverTextColor: 'white',
        },
        custom: {
            background_color,
            hoverBackgroundColor,
            text_color,
            border_color,
            hoverTextColor,
        }
    };

    // Apply the selected color scheme
    const selectedColors = colorSchemes[colorScheme] || colorSchemes.custom;

    // Responsive font sizes
    const responsiveFontSizes = getResponsiveFontSizes(font_size, unit);
    const finalMobileFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveFontSizes.mobileFontSize;
    const finalTabletFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveFontSizes.tabletFontSize;
    const finalLaptopFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveFontSizes.laptopFontSize;
    const finalMacbook13FontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveFontSizes.macbook13FontSize;

    return (
        <div className={styles.container}>
            <ButtonStyleOne
                width={width}
                backgroundColor={disabled ? '#d3d3d3' : selectedColors.background_color} // Gray background when disabled
                hoverBackgroundColor={disabled ? '#d3d3d3' : selectedColors.hoverBackgroundColor}
                hoverBorderColor={disabled ? '#d3d3d3' : selectedColors.hoverBorderColor}
                hoverTextColor={disabled ? '#a9a9a9' : selectedColors.hoverTextColor} // Dimmed text color when disabled
                textColor={disabled ? '#a9a9a9' : selectedColors.text_color}
                fontSize={font_size}
                fontWeight={font_weight}
                borderRadius={border_radius}
                padding={padding}
                borderColor={disabled ? '#a9a9a9' : selectedColors.border_color}
                mobileFontSize={finalMobileFontSize}
                tabletFontSize={finalTabletFontSize}
                laptopFontSize={finalLaptopFontSize}
                macbook13FontSize={finalMacbook13FontSize}
                onClick={!disabled ? onClick : undefined} // Prevent click when disabled
                disabled={disabled} // Pass disabled prop to the styled component
                {...props}
            >
                <div style={{ display: 'flex', justifyContent: "center", gap: "5px",alignItems:"center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {icon}
                    </div>
                    <div>
                        {title}
                    </div>
                    {
                        is_loading && <div >
                            <Spin indicator={<LoadingOutlined spin />} />
                        </div>
                    }

                </div>

            </ButtonStyleOne>
        </div>
    );
};

export { CustomButton };
