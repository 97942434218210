import { configureStore } from '@reduxjs/toolkit';
import AppReducer from '../modules/app/logic/slice'
import DashboardReducer from '../modules/dashboard/logic/dashboard/slice'
import ChannelReducer from '../modules/dashboard/logic/channels/slice'
import VendorReducer from '../modules/dashboard/logic/vendor/slice'
import ProductReducer from '../modules/dashboard/logic/product/slice'
import PeopleReducer from '../modules/dashboard/logic/people-manager/slice'
import DealsReducer from '../modules/dashboard/logic/deals/slice'
import CategoryReducer from '../modules/dashboard/logic/category/slice'
import PermissionReducer from '../modules/dashboard/logic/permissions/slice'
import UsersReducer from '../modules/dashboard/logic/users/slice';
import CustomerReducer from '../modules/dashboard/logic/customers/slice';

const store = configureStore({
    reducer: {
        app: AppReducer,
        dashboard: DashboardReducer,
        channel: ChannelReducer,
        vendor: VendorReducer,
        product: ProductReducer,
        people_manager: PeopleReducer,
        deals:DealsReducer,
        category:CategoryReducer,
        permissions:PermissionReducer,
        users: UsersReducer,
        customers: CustomerReducer
    }
});

// Types
const { getState } = store;
const RootState = getState();
const AppDispatch = store.dispatch;

export {
    store,
    RootState,
    AppDispatch,
};
