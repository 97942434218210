import { createSlice } from '@reduxjs/toolkit';

// Remove the deals-related slice
// const appSlice = createSlice({
//     name: 'deals',
//     initialState: {
//         listOfDeals: [],
//         isLoadingListOfDeals: false,
//     },
//     reducers: {
//         setListOfDeals: (state, action) => {
//             state.listOfDeals = action.payload;
//         },
//         setIsLoadingListOfDeals: (state, action) => {
//             state.isLoadingListOfDeals = action.payload;
//         },
//     },
// });

// export const {
//     setIsLoadingListOfDeals,
//     setListOfDeals
// } = appSlice.actions;

// export default appSlice.reducer;

const customerSlice = createSlice({
    name: 'customers',
    initialState: {
        listOfCustomers: [],
        isLoadingListOfCustomers: false,
    },
    reducers: {
        setListOfCustomers: (state, action) => {
            state.listOfCustomers = action.payload;
        },
        setIsLoadingListOfCustomers: (state, action) => {
            state.isLoadingListOfCustomers = action.payload;
        },
    },
});

export const {
    setIsLoadingListOfCustomers,
    setListOfCustomers
} = customerSlice.actions;

export default customerSlice.reducer;
