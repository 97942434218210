import styled from 'styled-components';
import { Card } from 'antd';

// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};

const CustomCard = styled(Card)`
  .ant-card-head {
    background-color: ${(props) => props.headingBgColor || '#f0f0f0'};
    border-bottom: 1px solid ${(props) => props.borderColor || '#d9d9d9'};
  }

  .ant-card-head-title {
    color: ${(props) => props.headingColor || '#000'};
    font-size: ${(props) => props.headingFontSize || '18px'};
    
    @media (max-width: 600px) {
      font-size: ${(props) => props.mobileHeadingFontSize || getResponsiveFontSizes(props.headingFontSize || '18px', 'px').mobileFontSize};
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: ${(props) => props.tabletHeadingFontSize || getResponsiveFontSizes(props.headingFontSize || '18px', 'px').tabletFontSize};
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: ${(props) => props.laptopHeadingFontSize || getResponsiveFontSizes(props.headingFontSize || '18px', 'px').laptopFontSize};
    }

    @media (min-width: 1201px) {
      font-size: ${(props) => props.macbook13HeadingFontSize || getResponsiveFontSizes(props.headingFontSize || '18px', 'px').macbook13FontSize};
    }
  }

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:${(props) => props.height || '4rem'};
    width:${(props) => props.width || '200px'};
  }

  .icon-container {
  width:40%;
    margin-right: 16px;
    color: ${(props) => props.iconColor || '#000'};
  }

  .value-container {
   width:60%;
    color: ${(props) => props.valueColor || '#000'};
    font-size: ${(props) => props.valueFontSize || '16px'};
    font-weight: ${(props) => props.valueFontWeight || 'normal'};
    
    @media (max-width: 600px) {
      font-size: ${(props) => props.mobileValueFontSize || getResponsiveFontSizes(props.valueFontSize || '16px', 'px').mobileFontSize};
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: ${(props) => props.tabletValueFontSize || getResponsiveFontSizes(props.valueFontSize || '16px', 'px').tabletFontSize};
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: ${(props) => props.laptopValueFontSize || getResponsiveFontSizes(props.valueFontSize || '16px', 'px').laptopFontSize};
    }

    @media (min-width: 1201px) {
      font-size: ${(props) => props.macbook13ValueFontSize || getResponsiveFontSizes(props.valueFontSize || '16px', 'px').macbook13FontSize};
    }
  }
`;

export { CustomCard };
