import { createSlice, current } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'people_manager',
    initialState: {
        listOfPeople: [],
        isLoadingListOfPeople: false,
        listOfTrainingImages: [],
        isLoadingListOfTrainingImages: false,
        current_selected_people: null,
        refresh_list_of_people: false,
    },
    reducers: {
        setListOfPeople: (state, action) => {
            state.listOfPeople = action.payload;
        },
        setCurrentSelectPeople: (state, action) => {
            state.current_selected_people = action.payload;
        },
        refreshListOfPeople: (state, action) => {
            state.refresh_list_of_people = !state.refresh_list_of_people;
        },
        setIsLoadingListOfPeople: (state, action) => {
            state.isLoadingListOfPeople = action.payload;
        },
        setListOfTrainingImages: (state, action) => {
            state.listOfTrainingImages = action.payload;
        },
        setIsLoadingListOfTrainingImages: (state, action) => {
            state.isLoadingListOfTrainingImages = action.payload;
        },
    },
});

export const {
    setListOfPeople,
    setIsLoadingListOfPeople,
    setIsLoadingListOfTrainingImages,
    setListOfTrainingImages,
    setCurrentSelectPeople,
    refreshListOfPeople
} = appSlice.actions;

export default appSlice.reducer;
