import React, { useEffect, useState } from "react";
import { Table, Checkbox, Button, Row, Col, Input, notification } from "antd";
import * as XLSX from "xlsx";
import { fetchListOfChannelsToExport } from "../../../logic/channels/actions";
import { getIsLoadingListOfChannelsToExport, getListOfChannelsToExport } from "../../../logic/channels/selectors";
import { connect } from "react-redux";
import { CustomButton } from "../../../../../shared/ui/button";

const Export = ({
    listOfChannelsToExport,
    isLoadingListOfChannelsToExport,
    fetchListOfChannelsToExport,
}) => {
    const [columns, setColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [editableColumns, setEditableColumns] = useState({});
    const [fileName, setFileName] = useState("exported_data.xlsx");
    const [isExporting, setIsExporting] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    
    useEffect(() => {
        // Example sample data to simulate columns

        const sampleData = [
            { channel_name: "Sample Channel 1", total_products: 100, total_matches: 50, is_shoppable: true },
            { channel_name: "Sample Channel 2", total_products: 200, total_matches: 0, is_shoppable: false },
            { channel_name: "Sample Channel 3", total_products: 150, total_matches: 25, is_shoppable: true },
        ];

        // Generate column options from sample data
        const initialColumns = Object.keys(sampleData[0]).map((key) => ({
            key,
            label: formatColumnName(key.replace(/_/g, " ")),
        }));

        setColumns(initialColumns);
        setSelectedColumns(initialColumns.map((col) => col.key)); // Select all columns by default

        const editable = {};
        initialColumns.forEach((col) => {
            editable[col.key] = col.label; // Initialize editable labels
        });

        setEditableColumns(editable);

    }, []);

    useEffect(() => {
        if (triggerExport&&listOfChannelsToExport?.length > 0) {
            // Filter data based on selected columns and apply custom column names
            const dataToExport = listOfChannelsToExport.map((row) => {
                const filteredRow = {};
                selectedColumns.forEach((col) => {
                    filteredRow[editableColumns[col] || col] = row[col];
                });
                return filteredRow;
            });

            // Create Excel file
            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Exported Data");

            // Trigger download with the provided file name
            XLSX.writeFile(workbook, fileName);

            // Set isExporting to false after export is complete
            setIsExporting(false);
            setTriggerExport(false); // Reset the export trigger
        }
    }, [triggerExport,listOfChannelsToExport]);

    const handleExport = () => {

        if (selectedColumns.length === 0) {
            alert("Please select at least one column to export.");
            return;
        }

        fetchListOfChannelsToExport();
        setIsExporting(true);
        setTriggerExport(true);
    };

    const handleColumnNameChange = (key, value) => {
        setEditableColumns((prev) => ({ ...prev, [key]: value }));
    };

    const formatColumnName = (str) => {
        return str
            .replace(/_/g, " ")  // Replace underscores with spaces
            .replace(/\b\w/g, (match) => match.toUpperCase());  // Capitalize the first letter of each word
    };
    
    return (
        <div>
        <Row gutter={[16, 16]} align="middle" style={{ marginBottom: "16px" }}>
            <Col flex="auto">
                <Input
                    placeholder="Enter file name (default: exported_data.xlsx)"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value || "exported_data.xlsx")}
                />
            </Col>
            <Col>
                {/* <Button type="primary" onClick={handleExport} loading={isExporting}>
                    Export to Excel
                </Button> */}
                <CustomButton
                        title="Export to Excel"
                        width="100%"
                        font_size="0.7"
                        unit="rem"
                        padding="5px 5px 5px 5px"
                        colorScheme="primary"
                        border_color="#FAFAFA"
                        background_color="#FAFAFA"
                        is_loading={isExporting}
                        onClick={(e) => handleExport(e)}
                    />
            </Col>
        </Row>
    
        <div style={{ marginBottom: "16px" }}>
            {/* Column header */}
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                <div style={{ textAlign: "left", flex: 1 }}>Select the columns to include in your export file from the list below.</div>
            </div>
    
            {/* Split the columns into two sections */}
            <Row gutter={[16, 16]}>
                {/* First Partition of Columns */}
                <Col span={12}>
                    <div>
                        {columns.slice(0, Math.ceil(columns.length / 2)).map((col) => (
                            <div
                                key={col.key}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ textAlign: "left", flex: 1 }}>
                                    <div style={{ marginBottom: "5px", fontWeight: "bold" }}>
                                        {formatColumnName(col.key)}
                                    </div>
                                    <Checkbox
                                        checked={selectedColumns.includes(col.key)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setSelectedColumns((prev) =>
                                                isChecked
                                                    ? [...prev, col.key]
                                                    : prev.filter((key) => key !== col.key)
                                            );
                                        }}
                                    />
                                    <Input
                                        value={editableColumns[col.key]}
                                        onChange={(e) => handleColumnNameChange(col.key, e.target.value)}
                                        placeholder="Column Name"
                                        style={{ width: "150px", marginLeft: "10px" }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Col>
    
                {/* Second Partition of Columns */}
                <Col span={12}>
                    <div>
                        {columns.slice(Math.ceil(columns.length / 2)).map((col) => (
                            <div
                                key={col.key}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ textAlign: "left", flex: 1 }}>
                                    <div style={{ marginBottom: "5px", fontWeight: "bold" }}>
                                        {formatColumnName(col.key)}
                                    </div>
                                    <Checkbox
                                        checked={selectedColumns.includes(col.key)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setSelectedColumns((prev) =>
                                                isChecked
                                                    ? [...prev, col.key]
                                                    : prev.filter((key) => key !== col.key)
                                            );
                                        }}
                                    />
                                    <Input
                                        value={editableColumns[col.key]}
                                        onChange={(e) => handleColumnNameChange(col.key, e.target.value)}
                                        placeholder="Column Name"
                                        style={{ width: "150px", marginLeft: "10px" }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
    </div>
    
    );
};

const mapState = (state) => ({
    listOfChannelsToExport: getListOfChannelsToExport(state),
    isLoadingListOfChannelsToExport: getIsLoadingListOfChannelsToExport(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfChannelsToExport: (data) => dispatch(fetchListOfChannelsToExport(data)),
});

export default connect(mapState, mapDispatchToProps)(Export);
