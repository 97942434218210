import { apiGet, apiPost, apiPostWithFile, apiPut } from "../../shared/api/api";

const getAllChannels = (data, signal) => apiPost('channel/get_all_channels', data, signal);
const getAllChannelsToExport = (data, signal) => apiPost('channel/export_channels', data, signal);

const getAllChannelsWithOnlyCoreDetails = (data, signal) => apiPost('channel/get_all_channel_core_details', data, signal);
const getAllVendorsBtChannelId = (data, signal) => apiPost('channel/get_vendors_by_channel_id', data, signal);
const getAllVendorsBtChannelIdWithoutPagination = (data, signal) => apiPost('channel/get_vendors_by_channel_id_without_pagination', data, signal);
const getReviewDetails = (data, signal) => apiPost('channel/get_review_details', data, signal);

const getListOfFacialRecognitionsLinkedToChannel = (data, signal) => apiPost('channel/get_facial_recognition_by_channel_id', data, signal);
const getListOfBlacklistViatorTagLinkedToChannel = (data, signal) => apiPost('channel/get_blacklist_viator_tags_by_channel_id', data, signal);
const getListOfBlacklistDestinationsLinkedToChannel = (data, signal) => apiPost('channel/get_blacklist_viator_destinations_by_channel_id', data, signal);
const getMatchCountDetailsForChannel = (data, signal) => apiPost('channel/fetch_matches_count_details_for_channel', data, signal);

const updateVendorStatus = (data) => apiPut('channel/update_vendor_status', data);
const updateVendorStatusInBulk = (data) => apiPost('channel/update_vendor_status_in_bulk', data);
const updateBlacklistKeywordsList = (data) => apiPost('channel/update_black_listed_keywords', data);
const updateViatorStatus = (data) => apiPost('channel/update_viator_status', data);


const updateChannelCategories = (data) => apiPost('channel/update_channel_categories', data);
const getChannelCategories = (data) => apiPost('channel/get_channel_categories', data);


const updateFacialRecognitionStatus = (data) => apiPut('channel/update_facial_recognition_status', data);
const updateBlacklistViatorTagStatus = (data) => apiPut('channel/update_blacklist_viator_tags_list', data);
const updateBlacklistDestinationStatus = (data) => apiPut('channel/update_blacklist_viator_destination_list', data);

const updateChannelServiceStatus = (data) => apiPut('channel/update_channel_services_status', data);
const addNewChannel = (data) => apiPostWithFile('channel/add_new_channel', data);
const updateChannelDetails = (data) => apiPostWithFile('channel/update_channel_details', data);

export {
    getAllChannels,
    updateVendorStatus,
    updateChannelServiceStatus,
    addNewChannel,
    updateChannelDetails,
    getListOfFacialRecognitionsLinkedToChannel,
    updateFacialRecognitionStatus,
    getAllVendorsBtChannelId,
    getAllVendorsBtChannelIdWithoutPagination,
    getListOfBlacklistViatorTagLinkedToChannel,
    updateBlacklistViatorTagStatus,
    getListOfBlacklistDestinationsLinkedToChannel,
    updateBlacklistDestinationStatus,
    getMatchCountDetailsForChannel,
    getAllChannelsWithOnlyCoreDetails,
    getReviewDetails,
    getAllChannelsToExport,
    updateVendorStatusInBulk,
    updateChannelCategories,
    getChannelCategories,
    updateViatorStatus,
    updateBlacklistKeywordsList
}