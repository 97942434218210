import { connect } from "react-redux";
import styles from '../../styles.module.scss'

const ChannelVisualizationTab = ({ title = 'default title', ...props }) => {
    return <div className={styles.channel_overview_tab_container}>
        On this we will have graphs and charts to draw meaning full conclusions.
    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapState, mapDispatchToProps)(ChannelVisualizationTab)