import React, { useEffect, useState } from "react";
import { Table, Checkbox, Button, Row, Col, Input, notification, Switch } from "antd";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { fetchListOfAllVendorsForExport } from "../../../logic/vendor/actions";
import { getIsLoadingListOfVendorsForExport, getListOfVendorsForExport } from "../../../logic/vendor/selectors";
import { CustomButton } from "../../../../../shared/ui/button";
import { setListOfVendorsForExport } from "../../../logic/vendor/slice";

const Export = ({
    listOfVendorsForExport,
    isLoadingListOfVendorsToExport,
    fetchListOfAllVendorsForExport,
    listSearchParams = null,
    setListOfVendorsForExport
}) => {
    const [columns, setColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [editableColumns, setEditableColumns] = useState({});
    const [fileName, setFileName] = useState("exported_data.xlsx");
    const [isExporting, setIsExporting] = useState(false);
    const [applyFilters, setApplyFilters] = useState(true);
    const [triggerExport, setTriggerExport] = useState(false); // New state

    const [includeVendorsWithZeroProducts, setIncludeVendorsWithZeroProducts] = useState(false);
    const [includeVendorsWithZeroMatches, setIncludeVendorsWithZeroMatches] = useState(false);

  

    useEffect(() => {
        // Example sample data to simulate columns
        const sampleData = [
            {
                platform: "Platform A",
                name: "Vendor 1",
                logo_url: "https://example.com/logo1.png",
                domain_url: "https://vendor1.com",
                status: "active",
                regions: "UK,US,CA",
                enabled_channels: 10,
                disabled_channels: 5,
                matches_count: 100,
                total_products: 500
            },
            {
                platform: "Platform B",
                name: "Vendor 2",
                logo_url: "https://example.com/logo2.png",
                domain_url: "https://vendor2.com",
                status: "inactive",
                regions: "US,DE",
                enabled_channels: 8,
                disabled_channels: 2,
                matches_count: 0,
                total_products: 200
            },
            {
                platform: "Platform C",
                name: "Vendor 3",
                logo_url: "https://example.com/logo3.png",
                domain_url: "https://vendor3.com",
                status: "suspended",
                regions: "FR,IT",
                enabled_channels: 5,
                disabled_channels: 10,
                matches_count: 25,
                total_products: 300
            }
        ];

        // Generate column options from sample data
        const initialColumns = Object.keys(sampleData[0]).map((key) => ({
            key,
            label: formatColumnName(key.replace(/_/g, " ")),
        }));

        setColumns(initialColumns);
        setSelectedColumns(initialColumns.map((col) => col.key)); // Select all columns by default

        const editable = {};
        initialColumns.forEach((col) => {
            editable[col.key] = col.label; // Initialize editable labels
        });

        setEditableColumns(editable);

        return () => {
            setTriggerExport(false)

        }
    }, []);


    useEffect(() => {
        if (triggerExport && listOfVendorsForExport?.length > 0) {
            const dataToExport = listOfVendorsForExport.map((row) => {
                const filteredRow = {};
                selectedColumns.forEach((col) => {
                    filteredRow[editableColumns[col] || col] = row[col];
                });
                return filteredRow;
            });

            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Exported Data");

            XLSX.writeFile(workbook, fileName);

            setIsExporting(false);
            setTriggerExport(false); // Reset the export trigger
            setListOfVendorsForExport([]); // Reset the list of vendors to export
        }
    }, [triggerExport, listOfVendorsForExport]);

    const handleExport = (event) => {

        event.preventDefault();

        if (selectedColumns.length === 0) {
            alert("Please select at least one column to export.");
            return;
        }

        setIsExporting(true);
      
        fetchListOfAllVendorsForExport({
            keyword: applyFilters ? listSearchParams.keyword : "",
            country_code: applyFilters ? listSearchParams.country_code : [],
            platform: applyFilters ? listSearchParams.platform : [],
            status: applyFilters ? listSearchParams.status : [],
            do_not_fetch_zero_prod_vendors: includeVendorsWithZeroProducts,
            include_zero_match_vendors: includeVendorsWithZeroMatches,
            apply_platform_filter: applyFilters,
            apply_country_filter: applyFilters,
            apply_keyword_filter: applyFilters,
            categories: applyFilters ? listSearchParams.iab_category_id : [],
        }).then((response) => {
            setIsExporting(false);
            setTriggerExport(true)
            
        }).catch((error) => {
            console.error(error)
            setIsExporting(false);
         })
    };

    const handleColumnNameChange = (key, value) => {
        setEditableColumns((prev) => ({ ...prev, [key]: value }));
    };

    const formatColumnName = (str) => {
        return str
            .replace(/_/g, " ")  // Replace underscores with spaces
            .replace(/\b\w/g, (match) => match.toUpperCase());  // Capitalize the first letter of each word
    };

    useEffect(() => {
        const getFileName = () => {
            if (!listSearchParams) return "exported_data.xlsx";

            const { keyword, platform } = listSearchParams;
            let fileName = "exported_data";

            // If there are platforms, add them to the filename
            if (platform && platform.length > 0) {
                fileName += `_${platform.join("_")}`;
            }

            // If there is a keyword, replace spaces with underscores and add it to the filename
            if (keyword) {
                const formattedKeyword = keyword.replace(/\s+/g, '_'); // Replace spaces with underscores
                fileName += `_${formattedKeyword}`;
            }

            return `${fileName}.xlsx`;
        };

        setFileName(getFileName());
    }, [listSearchParams]);


    return (
        <div>
            <Row gutter={[16, 16]} align="middle" style={{ marginBottom: "16px" }}>
                <Col flex="auto">
                    <Input
                        placeholder="Enter file name (default: exported_data.xlsx)"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value || "exported_data.xlsx")}
                    />
                </Col>
                <Col>
                    {/* <Button type="primary" onClick={handleExport} loading={isExporting}>
                        Export to Excel
                    </Button> */}
                    <CustomButton
                        title="Export to Excel"
                        width="100%"
                        font_size="0.7"
                        unit="rem"
                        padding="5px 5px 5px 5px"
                        colorScheme="primary"
                        border_color="#FAFAFA"
                        background_color="#FAFAFA"
                        is_loading={isExporting}
                        onClick={(e) => handleExport(e)}
                    />
                </Col>
            </Row>

            {/* Apply Filters Switches */}
            <div style={{ marginBottom: "16px" }}>
                <Row gutter={[16, 16]}>
                    {/* First Section: Apply Previous Filters */}
                    {/* <Col span={6}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <label style={{ marginRight: "10px" }}>Apply previous filters:</label>
                            <Switch
                                checked={applyFilters}
                                onChange={(checked) => setApplyFilters(checked)}
                            />
                        </div>
                    </Col> */}


                    {/* Third Section: Include Vendors with 0 Matches */}
                    {/* <Col span={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <label style={{ marginRight: "10px" }}>Include vendors with 0 matches and 0 products:</label>
                            <Switch
                                checked={includeVendorsWithZeroMatches}
                                onChange={(checked) => {
                                    setIncludeVendorsWithZeroProducts(checked)
                                    setIncludeVendorsWithZeroMatches(checked)
                                }}
                            />
                        </div>
                    </Col> */}
                </Row>
            </div>



            <div style={{ marginBottom: "16px" }}>
                {/* Column header */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div style={{ textAlign: "left", flex: 1 }}>Select the columns to include in your export file from the list below.</div>

                </div>

                {/* Three Column Layout (Flexbox) */}
                <Row gutter={[16, 16]}>
                    {/* Dynamically Render Columns */}
                    {columns.map((col, index) => (
                        <Col key={col.key} span={6}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ textAlign: "left", flex: 1 }}>
                                    {/* Display original column label alongside editable name */}
                                    <div style={{ marginBottom: "5px", fontWeight: "bold" }}>
                                        {formatColumnName(col.key)}
                                    </div>
                                    <Checkbox
                                        checked={selectedColumns.includes(col.key)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setSelectedColumns((prev) =>
                                                isChecked
                                                    ? [...prev, col.key]
                                                    : prev.filter((key) => key !== col.key)
                                            );
                                        }}
                                    />
                                    <Input
                                        value={editableColumns[col.key]}
                                        onChange={(e) => handleColumnNameChange(col.key, e.target.value)}
                                        placeholder="Column Name"
                                        style={{ width: "150px", marginLeft: "10px" }}
                                    />
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>


    );
};

const mapState = (state) => ({
    listOfVendorsForExport: getListOfVendorsForExport(state),
    isLoadingListOfVendorsToExport: getIsLoadingListOfVendorsForExport(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfAllVendorsForExport: (data) => dispatch(fetchListOfAllVendorsForExport(data)),
    setListOfVendorsForExport: (data) => dispatch(setListOfVendorsForExport(data)),

});

export default connect(mapState, mapDispatchToProps)(Export);
