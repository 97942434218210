import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { Table, Switch } from "antd";
import styles from './modal.module.scss';
import { fetchMatchCountDivisionsForVendor } from '../../../logic/vendor/actions';
import { CustomTextfield } from '../../../../../shared/ui/text-field';
import { FaSearch } from 'react-icons/fa';
import debounce from 'lodash.debounce'; // Import lodash debounce function
import { getCurrentSelectedVendor, getIsLoadingMatchCountDivisionForVendor, getMatchCountDivisionForVendor } from '../../../logic/vendor/selectors';
import { setCurrentSelectVendor, setIsLoadingMatchCountDivisionForVendor, setMatchCountDivisionForVendor } from '../../../logic/vendor/slice';

const MatchCountDetails = ({
    listOfAllChannels,
    record = null,
    setDetailsModalFooter,
    setIsDetailsModalOpen,
    setIsLoadingMatchCountDivisionForVendor,
    fetchMatchCountDivisionsForVendor,
    isLoadingMatchCountDivision,
    is_pagination_enabled = true,
    listSearchParams = null,
    setMatchCountDivisionForVendor,
    setCurrentSelectVendor,
    ...props
}) => {
    const [channelsData, setChannelsData] = useState([]);
    const [enabledChannels, setEnabledChannels] = useState([]);
    const [channelType, setChannelType] = useState([]);
    const [newlyEnabledChannels, setNewlyEnabledChannels] = useState([]);
    const [alreadyAllowedChannels, setAlreadyAllowedChannels] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const prevSearchTerm = useRef('');
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });
    const initialLoadRef = useRef(false);
    // Create refs to track the latest values
    const alreadyAllowedChannelsRef = useRef(alreadyAllowedChannels);
    const newlyEnabledChannelsRef = useRef(newlyEnabledChannels);

    useEffect(() => {
        setIsLoadingMatchCountDivisionForVendor(true)
        return () => {

            setChannelsData([])
            setCurrentSelectVendor(null)
            setMatchCountDivisionForVendor({
                formatted_data: [],
                totalRecords: 0,
                totalPages: 0,
                currentPage: 0,
            })
        }
    }, [])

    // useEffect(() => {
    //     if (record?.vendor_id) {
    //         fetchMatchCountDivisionsForVendor({ keyword: "", page: 1, limit: 10, is_pagination_enabled: true, vendor_id: record?.vendor_id });
    //     }
    // }, [record?.vendor_id]);

    // Debounce the search function to avoid excessive requests
    const debouncedSearch = useCallback(
        debounce((searchTerm, page, pageSize, is_pagination_enabled, record) => {
            fetchMatchCountDivisionsForVendor({ keyword: searchTerm, page, limit: pageSize, is_pagination_enabled, vendor_id: record?.vendor_id });
        }, 1000), // 1-second debounce delay
        []
    );

    useEffect(() => {
        // if (!initialLoadRef.current) {
        // initialLoadRef.current = true
        debouncedSearch(searchTerm, pagination.current, 10, is_pagination_enabled, record);
        // }
    }, [searchTerm, pagination.current, debouncedSearch, record?.vendor_id]);



    useEffect(() => {
        // Initialize channels data from listOfAllChannels
        const allChannels = listOfAllChannels?.formatted_data || [];
        setChannelsData(allChannels);
        setPagination(prev => ({
            ...prev,
            total: listOfAllChannels.totalRecords,
            pageSize: 10,
            page: listOfAllChannels.currentPage
        }));

    }, [listOfAllChannels, record?.vendor_id]);

    const handleSwitchChange = (channelId, checked) => {

        setChannelsData((prev) => {
            let update_list = prev.map((c) => {
                if (c.channel_id === channelId) {
                    return {
                        ...c,
                        enabled: checked
                    }
                } else {
                    return c
                }
            })
            return update_list
        })


    };


    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);

    const columns = [
        {
            title: 'Logo',
            dataIndex: 'logo_url',
            key: 'logo_url',
            render: (logo_url, record) => {

                const renderProfileImage = (url, channel_name) => {
                    if (url) {
                        return (
                            <img
                                src={url}
                                alt={channel_name}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ''; // Reset the image source if it fails
                                }}
                                style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                            />
                        );
                    } else {
                        const initials = channel_name.split(' ').map(n => n[0]).join('');
                        return (
                            <div style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fff',
                            }}>
                                {initials}
                            </div>
                        );
                    }
                };

                return renderProfileImage(logo_url, record?.channel_name);
            },
        },
        {
            title: 'Channel Name',
            dataIndex: 'channel_name',
            key: 'channel_name',
        },
        {
            title: 'Total Matches',
            dataIndex: 'matches_count',
            key: 'matches_count',
            align: 'center',
            render: (value, record) => (
                <div >
                    {Number(value).toLocaleString()}
                </div>
            ),
        },
        // {
        //     title: 'Enabled',
        //     dataIndex: 'enabled',
        //     key: 'enabled',
        //     render: (text, record) => (
        //         <Switch
        //             checked={record?.enabled}
        //             onChange={(checked) => handleSwitchChange(record?.channel_id, checked)}
        //         />
        //     ),
        // },
    ];

    const data = channelsData.map((channel, index) => ({
        key: index + 1,
        serialNumber: index + 1,
        channel_name: channel.channel_name,
        matches_count: channel.matches_count,
        enabled: channel.enabled,
        channel_id: channel.channel_id,
        logo_url: channel.logo_url
    }));

    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    return (
        <div className={styles.list_of_allowed_channels_container}>

            <div className={styles.table_container}>
                <Table
                    columns={columns}
                    dataSource={data} // Use the filtered data for the table
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: pagination.total, // Total number of records from API
                        showSizeChanger: false
                    }}
                    onChange={handleTableChange}
                    bordered
                    loading={isLoadingMatchCountDivision}
                    title={() => (<div style={{ display: 'flex', alignContent: 'flex-end' }}>
                        {/* <div>
                            <CustomDropDown
                                defaultValue='default'
                                options={[
                                    { value: 'default', label: 'All Channels' },
                                    { value: 'enabled', label: 'Enabled' },
                                    { value: 'disabled', label: 'Disabled' },
                                    // { value: 'no_selected', label: 'Non selected' },
                                ]}
                                onChange={(value) => setChannelType(value)}
                            />
                        </div> */}
                        {/* <div style={{ width: "20%" }}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search i.e  vendor name "
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div> */}
                    </div>)}
                />
            </div>
        </div>
    );
};

const mapState = (state) => ({
    listOfAllChannels: getMatchCountDivisionForVendor(state),
    isLoadingMatchCountDivision: getIsLoadingMatchCountDivisionForVendor(state),
    record: getCurrentSelectedVendor(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchMatchCountDivisionsForVendor: (data) => dispatch(fetchMatchCountDivisionsForVendor(data)),
    setMatchCountDivisionForVendor: (data) => dispatch(setMatchCountDivisionForVendor(data)),
    setCurrentSelectVendor: (data) => dispatch(setCurrentSelectVendor(data)),
    setIsLoadingMatchCountDivisionForVendor: (data) => dispatch(setIsLoadingMatchCountDivisionForVendor(data)),
});

export default connect(mapState, mapDispatchToProps)(MatchCountDetails);
