

import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Table, Checkbox, Row, Col } from "antd";
import styles from './modal.module.scss';
import debounce from 'lodash.debounce';
import { fetchListOfListOfBlacklistViatorTagLinkedToChannel, updateBlacklistViatorTagStatus } from "../../../logic/channels/actions";
import { getIsLoadingListOfBlacklistViatorTagLinkedToChannel, getListOfBlacklistViatorTagLinkedToChannel } from "../../../logic/channels/selectors";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { FaSearch } from "react-icons/fa";
import { CustomButton } from "../../../../../shared/ui/button";

const ListOfAllBlacklistViatorTags = ({
    updateBlacklistViatorTagStatus,
    fetchListOfListOfBlacklistViatorTagLinkedToChannel,
    listOfBlackListViatorTag,
    is_pagination_enabled = true,
    isLoadingBlackListViatorTags,
    record,
    refresh,
    ...props
}) => {
    const [blackListViatorTags, setBlackListViatorTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [pagination, setPagination] = useState({ current: 1, pageSize: 60 });
    const prevSearchTerm = useRef('');

    const debouncedSearch = useCallback(
        debounce((searchTerm, page, record, is_pagination_enabled) => {
            fetchListOfListOfBlacklistViatorTagLinkedToChannel({ keyword: searchTerm, page: page, limit: 60, channel_id: record?.channel_id, is_pagination_enabled });
        }, 1000), []
    );

    useEffect(() => {
        if (record) {
            fetchListOfListOfBlacklistViatorTagLinkedToChannel({ keyword: "", page: 1, limit: 60, channel_id: record?.channel_id, is_pagination_enabled });
            setPagination((prev) => ({ ...prev, current: 1 }));
        }
    }, [record?.channel_id, is_pagination_enabled, refresh]);

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, record, is_pagination_enabled);
    }, [searchTerm, pagination.current, debouncedSearch, record?.vendor_id, is_pagination_enabled]);

    useEffect(() => {
        if (listOfBlackListViatorTag) {
       
            const uniqueBlackListViatorTag = (tags) => {
                const seen = new Set();
                return tags?.filter(tag => {
                    const duplicate = seen.has(tag.tag_id);
                    seen.add(tag.tag_id);
                    return !duplicate;
                });
            };

            const allTags = listOfBlackListViatorTag?.formatted_data
            
            setBlackListViatorTags(allTags);

            setPagination(prev => ({
                ...prev,
                total: listOfBlackListViatorTag.totalRecords,
                page: listOfBlackListViatorTag.currentPage,
                pageSize: 60,
            }));
        }
    }, [listOfBlackListViatorTag, searchTerm, record?.channel_id]);

    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);

    const handleToggle = (selectedTag) => {

        setBlackListViatorTags(prevTags =>
            prevTags.map(tag =>
                tag.tag_id === selectedTag.tag_id
                    ? { ...tag, enabled: !tag.enabled }
                    : tag
            )
        );

        updateBlacklistViatorTagStatus({
            channel_id: record?.channel_id,
            tag_id: [selectedTag.tag_id],
            checked: !selectedTag.enabled
        });
    };

    const handleSelectAllToggle = () => {
        const unselectedTags = blackListViatorTags.filter(tag => !tag.enabled).map(tag => tag.tag_id);
        const allSelected = unselectedTags.length === 0;

        setBlackListViatorTags(prevTags =>
            prevTags.map(tag => ({
                ...tag,
                enabled: !allSelected // Toggle all based on current selection state
            }))
        );

        updateBlacklistViatorTagStatus({
            channel_id: record?.channel_id,
            tag_id: allSelected
                ? blackListViatorTags.map(tag => tag.tag_id) // Deselect all
                : unselectedTags, // Select all unselected
            checked: !allSelected
        });
    };
 
    const renderBlacklistTagsInColumns = () => {
        const columns = [[], [], []]; // Initialize 3 columns
     
        blackListViatorTags?.forEach((tag, index) => {
            const colIndex = index % 3;
            columns[colIndex].push(tag);
        });

        return (
            <Row gutter={[16, 16]}>
                {columns.map((column, colIndex) => (
                    <Col key={colIndex} span={8}>
                        {column.map((tag) => (
                            <div key={tag.tag_id} style={{ display: "flex", alignItems: 'center', width: "100%", marginBottom: "0px" }}>
                                <Checkbox
                                    disabled={!record.show_viator_tickets}
                                    checked={tag.enabled}
                                    onChange={() => handleToggle(tag)}
                                    style={{ marginRight: '10px' }}
                                />
                                <div style={{ color: "black" }}>{tag.name}</div>
                            </div>
                        ))}
                    </Col>
                ))}
            </Row>
        );
    };

    return (
        <div className={styles.list_of_black_list_tags_container}>
            <Table
                columns={[{ title: "", dataIndex: "name" }]}
                key={pagination.current}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: false
                }}
                onChange={(pagination) => setPagination(prev => ({
                    ...prev,
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                }))}
                bordered
                loading={isLoadingBlackListViatorTags}
                rowClassName={() => styles.custom_row}
                locale={{ emptyText: renderBlacklistTagsInColumns() }}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.search_bar}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search i.e  Fashion Tours"
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {searchTerm?.length > 0 && blackListViatorTags?.length > 0 && (
                            <div className={styles.select_all_button}>
                                <CustomButton
                                    title={blackListViatorTags.every(tag => tag.enabled) ? "Unselect All" : "Select All"}
                                    width="100%"
                                    font_size="0.8"
                                    unit="rem"
                                    padding="5px 10px 0px 10px"
                                    colorScheme="info"
                                    onClick={handleSelectAllToggle}
                                />
                            </div>
                        )}
                    </div>
                )}
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfBlackListViatorTag: getListOfBlacklistViatorTagLinkedToChannel(state),
    isLoadingBlackListViatorTags: getIsLoadingListOfBlacklistViatorTagLinkedToChannel(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateBlacklistViatorTagStatus: (data) => dispatch(updateBlacklistViatorTagStatus(data)),
    fetchListOfListOfBlacklistViatorTagLinkedToChannel: (data) => dispatch(fetchListOfListOfBlacklistViatorTagLinkedToChannel(data)),
});

export default connect(mapState, mapDispatchToProps)(ListOfAllBlacklistViatorTags);
