
import toast from "react-hot-toast";
import { Dea, DealsAPI, DealsAPIlsAPI } from "../../../api";
import { setIsLoadingListOfDeals, setListOfDeals } from "./slice";

let abortController_fetchListOfAllDeals = null;  // Declare globally within the file

const fetchListOfAllDeals = (data) => (dispatch) => {
    if (abortController_fetchListOfAllDeals) {
        abortController_fetchListOfAllDeals.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllDeals = new AbortController();
    const { signal } = abortController_fetchListOfAllDeals;
    dispatch(setIsLoadingListOfDeals(true))
    DealsAPI.getAllDeals(data, signal)
        .then((response) => {
         
            let formatted_data = response?.data?.map((record) => {
                return {
                   ...record
                }
            })

            dispatch(setListOfDeals({
                formatted_data: formatted_data,
                totalRecords: response.totalRecords,
                totalPages: response.totalPages,
                currentPage: response.currentPage
            }))

            dispatch(setIsLoadingListOfDeals(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfDeals(true))

        })
};


export {
    // Note user
    fetchListOfAllDeals
}