import { apiGet, apiPost, apiPostWithFile, apiPut } from "../../shared/api/api";

const getAllProducts = (data,signal) => apiPost('product/get_all_products', data,signal);
const getAllProductsToExport = (data,signal) => apiPost('product/export_all_products', data,signal);

const getAllProductsForPeople = (data,signal) => apiPost('product/get_all_products_for_people', data,signal);
const addFaceProductRecord = (data) => apiPost('product/add_face_prod_rec', data);
const addProductRecord = (data) => apiPostWithFile('product/add_new_product', data);
const updateProduct = (data) => apiPostWithFile('product/update_product_details', data);

const getAllFaceProdRecords = (data) => apiPost('product/get_all_face_prod_records', data);
const removeFaceProductRecord = (data) => apiPost('product/remove_face_prod_rec', data);

export {
    getAllProducts,
    getAllProductsForPeople,
    addFaceProductRecord,
    getAllFaceProdRecords,
    removeFaceProductRecord,
    addProductRecord,
    updateProduct,
    getAllProductsToExport
}