import toast from "react-hot-toast";
import { CustomersAPI } from "../../../api";
import { setIsLoadingListOfCustomers, setListOfCustomers } from "./slice";

let abortController_fetchListOfAllCustomers = null;  // Declare globally within the file

const fetchListOfAllCustomers = (data) => (dispatch) => {
    if (abortController_fetchListOfAllCustomers) {
        abortController_fetchListOfAllCustomers.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllCustomers = new AbortController();
    const { signal } = abortController_fetchListOfAllCustomers;
    dispatch(setIsLoadingListOfCustomers(true));
    CustomersAPI.getAllCustomers(data, signal)
        .then((response) => {
           
            dispatch(setListOfCustomers(response?.data));

            dispatch(setIsLoadingListOfCustomers(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingListOfCustomers(false));
        });
};

export {
    fetchListOfAllCustomers
};