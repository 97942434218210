import React from 'react';
import ic_not_allowed from '../../../assets/icons/restrictions.png';

const PageNotAllowed = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh", width: "100%", alignItems: "center", justifyContent: "center", textAlign: "center", padding: "20px" }}>
            <div>
                <img src={ic_not_allowed} alt="Not Allowed" width={200} />
            </div>
            <div style={{ fontSize: "24px", fontWeight: "bold", marginTop: "20px" }}>
                Access Denied
            </div>
            <div style={{ fontSize: "16px", color: "#555", marginTop: "10px", maxWidth: "400px" }}>
                We're sorry, but your email domain is not authorized to access this page. 
                If you believe this is an error, please contact support for assistance.
            </div>
        </div>
    );
};

export default PageNotAllowed;
