import { apiDelete, apiGet, apiPost, apiPostWithFile, apiPostWithoutSignal, apiPut } from "../../shared/api/api";

const getAllVendors = (data, signal) => apiPost('vendor/get_all_vendors', data, signal);
const exportAllVendors = (data, signal) => apiPost('vendor/export_all_vendors', data, signal);

const getMatchCountDetailsForVendor = (data, signal) => apiPost('vendor/fetch_matches_count_details_for_vendor', data, signal);
const getVendorCountDetailsByChannel = (data, signal) => apiPost('vendor/get_vendor_count_details_by_channel', data, signal);

const disableVendor = (data) => apiPostWithoutSignal('vendor/disable_vendor', data);
const getAllVendorsList = (data) => apiGet('vendor/get_list_of_platforms', data);
const getAllChannelsForVendors = (data,signal) => apiPost('vendor/get_channels_for_vendor', data,signal);
const getAllCatagoriesForVendor = (data,signal) => apiPost('vendor/get_categories_for_vendor', data,signal);
const updateVendorRegions = (data) => apiPut('vendor/update_regions', data);
const updateVendorChannels = (data) => apiPut('vendor/update_channels', data);
const updateVendorChannelsInBulk = (data) => apiPost('vendor/update_channels_in_bulk', data);

const updateCatagoriesForChannel = (data) => apiPut('vendor/update_catagories', data);
const addNewVendor = (data) => apiPostWithFile('vendor/add_new_vendor', data);
const updateVendorDetails = (data) => apiPostWithFile('vendor/update_vendor_details', data);

export {
    getAllVendors,
    updateVendorRegions,
    updateVendorChannels,
    addNewVendor,
    updateVendorDetails,
    getAllVendorsList,
    getAllChannelsForVendors,
    disableVendor,
    getAllCatagoriesForVendor,
    updateCatagoriesForChannel,
    getMatchCountDetailsForVendor,
    getVendorCountDetailsByChannel,
    exportAllVendors,
    updateVendorChannelsInBulk
}