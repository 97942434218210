import { connect } from "react-redux";
import styles from '../../styles.module.scss'
import { CustomHeading } from "../../../../../shared/ui/heading";
import { FaRegWindowClose } from "react-icons/fa";
import { CustomButton } from "../../../../../shared/ui/button";
import React from "react";

const ConfigurationSidebar = ({
    handleClose = () => { console.error('no method provided') },
    handleOkay = () => { console.error('no method provided') },
    handleCancel = () => { console.error('no method provided') },

    okay_button_title = "Create",
    cancel_button_title = "Cancel",

    title = 'default title',

    body = null,
    footer = null,
    setDetailsModalTitle = () => { console.error('no method provided') },
    setDetailsModalFooter = () => { console.error('no method provided') },
    setIsDetailsModalOpen=()=>{console.error('no method provided')},
    setCurrentDetailsFormToRender = () => { console.error('no method provided') },
    ...props }) => {
    return <div className={styles.general_configuration_side_container}>
        <div className={styles.header_container}>
            <CustomHeading headingText={title} font_size="1" unit="rem" />
            <FaRegWindowClose style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <div className={styles.body_container}>
            {React.cloneElement(body, { setCurrentDetailsFormToRender, setIsDetailsModalOpen, setDetailsModalFooter,setDetailsModalTitle })}
        </div>
        {
            footer == null ? <div className={styles.footer_container}>
                <CustomButton
                    title={okay_button_title}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleOkay}
                />
                <CustomButton
                    title={cancel_button_title}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    colorScheme="danger"
                    onClick={handleCancel}
                />
            </div> : footer
        }

    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapState, mapDispatchToProps)(ConfigurationSidebar)