import { connect } from "react-redux";
import styles from '../../styles.module.scss'
import { Tabs } from "antd";

const TabsRender = ({
    list_of_tabs = [],
    show_tab = false,
    handleOnChange = (e) => { console.error('no handler provided') },
    ...props }) => {
    return <div className={styles.general_tabs_render_container} style={{ width: "100%" }}>

        {show_tab && <Tabs
            onChange={handleOnChange}
            type="card"
            items={list_of_tabs.map((tab, i) => {
                const id = String(i + 1);
                return {
                    label: tab?.label,
                    key: id,
                    children: tab?.screen,
                };
            })}
        />}
        {show_tab == false && list_of_tabs[0]?.screen}

    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapState, mapDispatchToProps)(TabsRender)