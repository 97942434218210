import { apiGet, apiPost, apiPostWithFile, apiPostWithoutSignal, apiPut } from "../../shared/api/api";

// update_basic_details,update_bank_details,fetch_bank_profile_details,fetch_basic_profile_details
const update_basic_details = (data) => apiPostWithoutSignal('customers/update_basic_details', data);
const update_bank_details = (data) => apiPostWithoutSignal('customers/update_bank_details', data);
const fetch_bank_profile_details = (data) => apiPostWithoutSignal('customers/fetch_bank_profile_details',data);
const fetch_basic_profile_details = (data) => apiPostWithoutSignal('customers/fetch_basic_profile_details',data);

export {
    update_basic_details,
    update_bank_details,
    fetch_bank_profile_details,
    fetch_basic_profile_details
}