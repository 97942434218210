import React from "react";
import { Row, Col, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import styles from './model.module.scss';

const BankDetailsModal = ({ record }) => {
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div className={styles.bank_details}>
            <Row>
                <Col span={11}><strong>Bank Name:</strong></Col>
                <Col span={11}>{record.bank_name || "N/A"}</Col>
                <Col span={2}>
                    <Tooltip title="Copy">
                        <CopyOutlined onClick={() => handleCopy(record.bank_name || "N/A")} />
                    </Tooltip>
                </Col>
            </Row>
            <Row>
                <Col span={11}><strong>Account Number:</strong></Col>
                <Col span={11}>{record.account_number || "N/A"}</Col>
                <Col span={2}>
                    <Tooltip title="Copy">
                        <CopyOutlined onClick={() => handleCopy(record.account_number || "N/A")} />
                    </Tooltip>
                </Col>
            </Row>
            <Row>
                <Col span={11}><strong>Account Type:</strong></Col>
                <Col span={11}>{record.account_type || "N/A"}</Col>
                <Col span={2}>
                    <Tooltip title="Copy">
                        <CopyOutlined onClick={() => handleCopy(record.account_type || "N/A")} />
                    </Tooltip>
                </Col>
            </Row>
            <Row>
                <Col span={11}><strong>IFSC Code:</strong></Col>
                <Col span={11}>{record.ifsc_code || "N/A"}</Col>
                <Col span={2}>
                    <Tooltip title="Copy">
                        <CopyOutlined onClick={() => handleCopy(record.ifsc_code || "N/A")} />
                    </Tooltip>
                </Col>
            </Row>
            <Row>
                <Col span={11}><strong>Branch Name:</strong></Col>
                <Col span={11}>{record.branch_name || "N/A"}</Col>
                <Col span={2}>
                    <Tooltip title="Copy">
                        <CopyOutlined onClick={() => handleCopy(record.branch_name || "N/A")} />
                    </Tooltip>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsModal);
