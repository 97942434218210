import React, { useEffect, useState } from "react";
import { Table, Upload, message, Modal } from "antd";
import styles from './model.module.scss';
import { CustomButton } from "../../../../../shared/ui/button";
import { FaUpload, FaXbox } from "react-icons/fa";
import { connect } from "react-redux";
import { configureFacialTrainingImage, deleteFacialTrainingImage, fetchListOfAllTrainingImages } from "../../../logic/people-manager/actions";
import { getIsLoadingListOfTrainingImages, getListOfTrainingImages } from "../../../logic/people-manager/selectors";
import { toast } from "react-toastify";
import ic_no_data from '../../../../../assets/icons/box.png'
import ic_delete from '../../../../../assets/icons/delete_2.png'
// Generate dummy data
const generateDummyData = (numRecords) => {
    const dummyData = [];
    for (let i = 1; i <= numRecords; i++) {
        dummyData.push({
            key: i, // Unique key for each row
            image_url: `https://face.ovraven.com/people/1/image/image${i}.jpg`, // Dummy image URL
            title: `Image Title ${i}`, // Dummy title
        });
    }
    return dummyData;
};

const ListOfAllTrainingImages = ({
    record,
    isLoadingListOfTrainingImages,
    fetchListOfAllTrainingImages,
    deleteFacialTrainingImage,
    listOfAllTrainingImages,
    configureFacialTrainingImage,
    ...props
}) => {
    const [data, setData] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (record?.id) {
            // Fetch training images when record changes
            fetchListOfAllTrainingImages({ id: record.id });
        }
    }, [record, fetchListOfAllTrainingImages]);

    useEffect(() => {
        // Set dummy data on component mount
        setData(listOfAllTrainingImages);
    }, [listOfAllTrainingImages]);

    const handleRemove = (select_record) => {
        deleteFacialTrainingImage({ id: record?.id, imageName: select_record?.title });
    };

    const columns = [
        {
            title: "Image",
            dataIndex: "image_url",
            key: "image_url",
            render: (text) => <img src={text} alt="facial training" style={{ width: 50, height: 50 }} />,
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Action",
            key: "action",
            align: 'center',
            render: (text, record) => (
                <div style={{display:"flex",justifyContent:"right"}}>
                    <CustomButton
                        title="Remove"
                        width="100%"
                        colorScheme="danger"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        onClick={() => handleRemove(record)}
                        icon={<img src={ic_delete} width={15} />}
                    />
                </div>

            ),
        },
    ];

    const handleUploadImages = async () => {
        if (!fileList.length) {
            return message.error("Please select images to upload.");
        }

        // Prepare form data with the selected files
        const formData = new FormData();
        // formData.append('id', record?.id); // Add the record ID to the form data

        fileList.forEach((file) => {
            formData.append('files', file.originFileObj); // Append each image to form data
        });

        try {
            toast.info("Uploading images .. ")
            const apiUrl = `https://face.ovraven.com/people/${record?.id}/images`; // API URL

            // Make a POST request to upload images
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: formData,
                headers: {
                    // No need to set 'Content-Type' header for FormData; it will be set automatically
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to upload images: ${response.statusText}`);
            }

            const result = await response.json();

            message.success('Images uploaded successfully!');

            // Refresh the list of images
            fetchListOfAllTrainingImages({ id: record?.id });
            setIsModalVisible(false); // Close the modal after successful upload
        } catch (error) {
            console.error('Error uploading images:', error);
            message.error('Failed to upload images.');
        }
    };

    const handleFileChange = (info) => {
        let newFileList = [...info.fileList];
        setFileList(newFileList);
    };

    const showModal = () => {
        setIsModalVisible(true);
        setFileList([])
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    return (
        <div className={styles.list_of_all_training_images}>
            <div className={styles.upload_button_container}>
                <CustomButton
                    title="Select and Upload Images"
                    width="100%"
                    colorScheme="primary"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={showModal}
                    icon={<FaUpload size={15} />}
                />
            </div>

            {/* Modal for image upload */}
            <Modal
                title="Select Images to Upload"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={600}
            >
                <div style={{}}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                        <div style={{ display: "flex", justifyContent: "left" }}>
                            <Upload
                                multiple
                                beforeUpload={() => false} // Prevent auto upload, handle it manually
                                fileList={fileList}
                                onChange={handleFileChange}
                                accept="image/*"
                            >
                                <div style={{ display: "flex", justifyContent: "right", backgroundColor: "red", width: "" }}>
                                    <CustomButton
                                        title="Select Images"
                                        width="100%"
                                        colorScheme="primary"
                                        font_size="0.8"
                                        unit="rem"
                                        padding="5px 10px 0px 10px"
                                        icon={<FaUpload size={15} />}
                                    />
                                </div>

                            </Upload>
                        </div>

                        {fileList?.length === 0 ? (
                            <div style={{ textAlign: "center" }}>
                                <img src={ic_no_data} width={50} alt="no data" />
                            </div>
                        ) : null}


                    </div>


                    <div style={{ display: "flex", justifyContent: "right", marginTop: "10px" }}>
                        <CustomButton
                            title="Upload"
                            width="100%"
                            colorScheme="danger"
                            font_size="0.8"
                            unit="rem"
                            padding="5px 10px 0px 10px"
                            onClick={handleUploadImages}
                            icon={<FaUpload size={15} />}
                        />
                    </div>
                </div>
            </Modal>
            {/* <div> */}
            <Table
                columns={columns}
                dataSource={data}
                bordered
                loading={isLoadingListOfTrainingImages}
            />
            {/* </div> */}

        </div>
    );
};

const mapState = (state) => ({
    isLoadingListOfTrainingImages: getIsLoadingListOfTrainingImages(state),
    listOfAllTrainingImages: getListOfTrainingImages(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfAllTrainingImages: (formData) => dispatch(fetchListOfAllTrainingImages(formData)),
    deleteFacialTrainingImage: (formData) => dispatch(deleteFacialTrainingImage(formData)),
    configureFacialTrainingImage: (formData) => dispatch(configureFacialTrainingImage(formData)),
});

export default connect(mapState, mapDispatchToProps)(ListOfAllTrainingImages);
