import toast from "react-hot-toast";
import { getCookie } from "../utils/utils";

// utils/api.js
const BASE_URL = process.env.REACT_APP_BACKEND_URL
// const BASE_URL = 'https://studio-api.edgevideo.com/'
// Helper function to retrieve the auth token

// Usage

// Generic function to set up headers
const getHeaders = () => {
    const authToken = getCookie('authToken');
    return {
        'Content-Type': 'application/json',
        ...(authToken && { 'Authorization': `Bearer ${authToken}` }),
    };
};

// GET request
export const apiGet = async (url, data) => {
    try {
        const response = await fetch(BASE_URL + url, {
            method: 'GET',
            headers: getHeaders(),
            credentials: 'include', // Ensure cookies are included in the request
        });

        if (!response.ok) {
            const error = await response.json();
            toast.error(error.message || 'Something went wrong');
            return { error: error.message || 'Something went wrong' };
        }

        return await response.json();
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Fetch aborted');
            return { error: 'Fetch aborted' };
        }
        console.error('API GET Error:', error.message);
        toast.error(error.message || 'Something went wrong');
        return { error: error.message || 'Something went wrong' };
    }
};
 
// POST request
export const apiPost = async (url, data, signal) => {
    try {
        const response = await fetch(BASE_URL + url, {
            method: 'POST',
            headers: getHeaders(),
            credentials: 'include',
            body: JSON.stringify(data),
            signal: signal
        });

        if (!response.ok) {
            const error = await response.json();
            toast.error(error.message || 'Something went wrong');
            return { error: error.message || 'Something went wrong' };
        }

        return await response.json();
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Fetch aborted');
            return { error: 'Fetch aborted' };
        }
        console.log('API POST Error:', error.message);
        toast.error(error.message || 'Something went wrong');
        return { error: error.message || 'Something went wrong' };
    }
};

export const apiPostWithoutSignal = async (url, data) => {
    try {

        const response = await fetch(BASE_URL + url, {
            method: 'POST',
            headers: getHeaders(),
            credentials: 'include',
            body: JSON.stringify(data),
         
        });

        if (!response.ok) {
            const error = await response.json();
            toast.error(error.message || 'Something went wrong');
            return { error: error.message || 'Something went wrong' };
        }

        return await response.json();
    } catch (error) {
        console.error('API POST Error:', error.message);
        toast.error(error.message || 'Something went wrong');
        return { error: error.message || 'Something went wrong' };
    }
};

// POST request with file upload
export const apiPostWithFile = async (url, formData) => {
    try {
        const authToken = getCookie('authToken');
        const response = await fetch(BASE_URL + url, {
            method: 'POST',
            headers: {
                // No need to set `Content-Type` because the browser sets it automatically for `FormData`
                'Accept': 'application/json',
                ...(authToken && { 'Authorization': `Bearer ${authToken}` }),
            },
            credentials: 'include',
            body: formData,  // Send `FormData` directly
        });

        if (!response.ok) {
            const error = await response.json();
            toast.error(error.message || 'Something went wrong');
            return { error: error.message || 'Something went wrong' };
        }

        return await response.json();
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Fetch aborted');
            return { error: 'Fetch aborted' };
        }
        console.error('API POST Error:', error.message);
        toast.error(error.message || 'Something went wrong');
        return { error: error.message || 'Something went wrong' };
    }
};

// PUT request
export const apiPut = async (url, data) => {
    try {
        const response = await fetch(BASE_URL + url, {
            method: 'PUT',
            headers: getHeaders(),
            credentials: 'include',
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const error = await response.json();
            toast.error(error.message || 'Something went wrong');
            return { error: error.message || 'Something went wrong' };
        }

        return await response.json();
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Fetch aborted');
            return { error: 'Fetch aborted' };
        }
        console.error('API PUT Error:', error.message);
        toast.error(error.message || 'Something went wrong');
        return { error: error.message || 'Something went wrong' };
    }
};

// DELETE request
export const apiDelete = async (url) => {
    try {
        const response = await fetch(BASE_URL + url, {
            method: 'DELETE',
            headers: getHeaders(),
            credentials: 'include',
        });

        if (!response.ok) {
            const error = await response.json();
            toast.error(error.message || 'Something went wrong');
            return { error: error.message || 'Something went wrong' };
        }

        return await response.json();
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Fetch aborted');
            return { error: 'Fetch aborted' };
        }
        console.error('API DELETE Error:', error.message);
        toast.error(error.message || 'Something went wrong');
        return { error: error.message || 'Something went wrong' };
    }
};

export { BASE_URL }
