import React from 'react';
import styles from './styles.module.scss';
import { SelectStyleOne } from './styled'; // Import the styled component for the Select
import { Option } from 'antd/es/mentions';
import { Select } from 'antd';
const { Option: SelectOption } = Select; // Destructuring Option from Select

// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};

const CustomDropDown = ({
    options = [], // Array of options for the dropdown
    width = '100%', // Width of the select dropdown
    font_size = '16px', // Base font size for the select dropdown
    unit = 'px', // Unit for font size
    borderRadius = '5px', // Border radius of the select dropdown
    padding = '10px', // Padding inside the select dropdown
    colorScheme = 'primary', // New prop for color schemes
    defaultValue,
    pick_passed_font_size = false, // Flag to use passed font sizes
    mobileFontSize,
    tabletFontSize,
    laptopFontSize,
    macbook13FontSize,
    enable_search=false,
    onChange = (e) => { console.error("no on change method is provided") },
    ...props // Additional props
}) => {

    // Color schemes
    const colorSchemes = {
        primary: {
            backgroundColor: '#08338A',
            borderColor: '#e8dcdc',
            color: 'black',
            hoverBorderColor: '#0056b3',
        },
        secondary: {
            backgroundColor: 'black',
            borderColor: '#28a745',
            color: 'black',
            hoverBorderColor: '#218838',
        },
        danger: {
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            color: 'white',
            hoverBorderColor: '#c82333',
        },
        warning: {
            backgroundColor: '#ffc107',
            borderColor: '#ffc107',
            color: 'black',
            hoverBorderColor: '#e0a800',
        },
        info: {
            backgroundColor: '#17a2b8',
            borderColor: '#17a2b8',
            color: 'white',
            hoverBorderColor: '#138496',
        },
        custom: {
            backgroundColor: props.backgroundColor || '#f5f5f5',
            borderColor: props.borderColor || '#d9d9d9',
            color: props.color || '#333',
            hoverBorderColor: props.hoverBorderColor || '#1890ff',
        },
    };

    // Apply the selected color scheme
    const selectedColors = colorSchemes[colorScheme] || colorSchemes.custom;

    const responsiveFontSizes = getResponsiveFontSizes(font_size, unit);

    const finalMobileFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveFontSizes.mobileFontSize;
    const finalTabletFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveFontSizes.tabletFontSize;
    const finalLaptopFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveFontSizes.laptopFontSize;
    const finalMacbook13FontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveFontSizes.macbook13FontSize;

    return (
        <div className={styles.container}>
            <SelectStyleOne
                onChange={onChange}
                width={width}
                backgroundColor={selectedColors.backgroundColor}
                color={selectedColors.color}
                borderColor={selectedColors.borderColor}
                hoverBorderColor={selectedColors.hoverBorderColor}
                defaultValue={defaultValue}
                padding={padding}
                borderRadius={borderRadius}
                fontSize={font_size}
                mobileFontSize={finalMobileFontSize}
                tabletFontSize={finalTabletFontSize}
                laptopFontSize={finalLaptopFontSize}
                macbook13FontSize={finalMacbook13FontSize}
                showSearch={enable_search} // Enables search functionality
                filterOption={(input, option) =>
                    (option?.children?.toLowerCase().includes(input.toLowerCase()))
                } // Custom filter logic
                {...props} // Pass additional props to SelectStyleOne
            >
                {options.map((option, index) => (
                    <Option key={index} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </SelectStyleOne>
        </div>
    );
};

export { CustomDropDown };
