import styled from 'styled-components';
import { Button } from 'antd';

const ButtonStyleOne = styled(Button)`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  margin-top: ${(props) => props.marginTop};
  border: 1px solid ${(props) => props.borderColor};
  cursor: pointer;
  width: ${(props) => props.width};
  display:flex;
  gap:10px;
  justify-content:center;
  align-item:center;
 &:hover {
    background-color: ${(props) => props.hoverBackgroundColor} !important;
    border-color: ${(props) => props.hoverBorderColor} !important;
    color: ${(props) => props.hoverTextColor} !important;
  }

  @media (max-width: 600px) {
    font-size: ${(props) => props.mobileFontSize};
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: ${(props) => props.tabletFontSize};
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: ${(props) => props.laptopFontSize};
  }

  @media (min-width: 1201px) {
    font-size: ${(props) => props.macbook13FontSize};
  }
`;

export  { ButtonStyleOne }