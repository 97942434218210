import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import styles from './modal.module.scss';
import { CustomDropDown } from '../../../../../shared/ui/dropdown';
import { getIsLoadingListOfChannelsWithOnlyCoreDetails, getIsLoadingVe, getListOfChannelsWithOnlyCoreDetails } from '../../../logic/channels/selectors';
import { Card, Col, Divider, Progress, Row, Spin, Typography } from 'antd';
import ic_average_score from '../../../../../assets/icons/average.png'
import { LoadingOutlined } from '@ant-design/icons';
import { fetchVendorCountDetailsByChannel } from '../../../logic/vendor/actions';
import { getIsLoadingVendorCountDetailsByChannel } from '../../../logic/vendor/selectors';
import ic_vendors from '../../../../../assets/icons/supplier.png'
import ic_active from '../../../../../assets/icons/accept.png'
import ic_in_active from '../../../../../assets/icons/remove.png'

const { Title } = Typography;

const VendorCountDetails = ({
    //  record = null,
    listOfChannelsBasicDetails,
    isLoadingListOfChannels,
    fetchVendorCountDetailsByChannel,
    average_results = null,
    isLoadingVendorCountDetails,
    ...props
}) => {
    const [vendorCountDetailsToRender, setVendorCountDetailsToRender] = useState({
        total: 0,
    })
    const [currentSelectedDropdownValue, setCurrentSelectedDropdownValue] = useState(null)
    const [listOfChannels, setListOfChannels] = useState([
        {
            label: 'Collective count',
            value: 'default'
        }
    ])

    const [selectedChannel, setSelectedChannel] = useState(null)

    useEffect(() => {
        return () => {
            setCurrentSelectedDropdownValue('default')
        }
    }, [average_results])

    useEffect(() => {
        if (average_results) {
            setVendorCountDetailsToRender({
                total: average_results.total,
            })
        }
    }, [average_results])

    useEffect(() => {
        if (selectedChannel != null) {
            fetchVendorCountDetailsByChannel({ channel_id: selectedChannel?.value })
                .then((response) => {
                    setVendorCountDetailsToRender({
                        total: response?.counts.enabled || 0,
                        enabled: response?.counts.enabled || 0,
                        disabled: response?.counts.disabled || 0,
                    });
                })
                .catch((error) => {
                    console.error('Error fetching review details:', error);

                    setVendorCountDetailsToRender({
                        total: 0,
                    });
                });
        } else {
            setVendorCountDetailsToRender({
                total: average_results.total,
            })
        }
    }, [selectedChannel])
    useEffect(() => {
        if (listOfChannelsBasicDetails) {
            let formatted_data_for_drop_down = listOfChannelsBasicDetails.formatted_data.map(rec => ({
                label: rec.channel_name,
                value: rec.channel_id
            }));
        
            // Add 'Collective count' as the first entry
            formatted_data_for_drop_down.unshift({
                label: 'Collective count',
                value: 'default'
            });
        
            setListOfChannels(formatted_data_for_drop_down);
        }
        
    }, [listOfChannelsBasicDetails])
    return <div className={styles.review_count_details_modal_container}>
        <div>
            <Card bordered={false} className="criclebox">
                <div className={styles.header_container}>
                    <CustomDropDown
                        defaultValue='default'
                        options={listOfChannels}
                        onChange={(value, record) => {
                            if (value != 'default') {
                                setSelectedChannel(record)
                            } else {
                                setSelectedChannel(null)
                            }
                            setCurrentSelectedDropdownValue(value)
                        }}
                    />
                </div>
                <Divider />
                <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                        <Col xs={18}>
                            <span>{'Vendors'}</span>
                            <Title level={3}>
                                {isLoadingVendorCountDetails ? <Spin indicator={<LoadingOutlined spin />} size="medium" /> : Number(vendorCountDetailsToRender.total)?.toLocaleString()}
                            </Title>
                        </Col>
                        <Col xs={6}>
                            {/* <div >{c.icon}</div> */}
                            <img src={ic_vendors} />
                        </Col>
                    </Row>

                </div>
                {/* <div style={{ marginTop: "5rem" }}>
                    <div>
                        <Progress percent={30} showInfo={false} />
                    </div>
                </div> */}
            </Card>
        </div>
    </div>
};

const mapState = (state) => ({
    listOfChannelsBasicDetails: getListOfChannelsWithOnlyCoreDetails(state),
    isLoadingListOfChannels: getIsLoadingListOfChannelsWithOnlyCoreDetails(state),
    isLoadingVendorCountDetails: getIsLoadingVendorCountDetailsByChannel(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchVendorCountDetailsByChannel: (data) => dispatch(fetchVendorCountDetailsByChannel(data)),
});

export default connect(mapState, mapDispatchToProps)(VendorCountDetails);
