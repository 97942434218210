import { connect } from "react-redux";
import styles from '../../styles.module.scss';
import { Table, Button, Tag, Menu, Checkbox, Tooltip } from "antd";
import { CustomButton } from "../../../../../shared/ui/button";
import { FaAd, FaCarBattery, FaEdit, FaFileExcel, FaFilter, FaPlus, FaSearch } from "react-icons/fa";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { useCallback, useEffect, useRef, useState } from "react";
import VendorDetailsForm from "./VendorDetailsForm";

import { CustomDropDown } from "../../../../../shared/ui/dropdown";
// import { getAllVendors } from "../../../logic/vendors/selectors";
import ListOfAllowedRegions from "./ListOfAllowedRegions";
import ListOfAllowedChannels from "./ListOfAllowedChannels";
import { getAllVendors, getAllVendorsNames, getIsLoadingListOfVendors } from "../../../logic/vendor/selectors";
import { sortedCountriesGroupedBySubRegionForFilter } from "../../../../../shared/utils/regions_with_countries";
import NewVendorDataForm from "./NewVendorDataForm";
import { fetchListOfAllVendors } from "../../../logic/vendor/actions";
import debounce from 'lodash.debounce';
import ic_catagory from '../../../../../assets/icons/market-segment.png'
import ic_delete from '../../../../../assets/icons/delete_2.png'
import WarningBeforeDeletingAnyVendor from "./WarningBeforeDeletingAnyVendor";
import ListOfCatagories from "./ListOfCatagories";
import { setCurrentSelectVendor, setIsLoadingListOfVendors, setListOfVendors } from "../../../logic/vendor/slice";
import ic_decline from '../../../../../assets/icons/decline.png'
import ic_link from '../../../../../assets/icons/link.png'
import ic_pending from '../../../../../assets/icons/clock.png'
import ic_unknown from '../../../../../assets/icons/unknown.png'
import ic_suspended from '../../../../../assets/icons/decline.png'
import ic_not_joined from '../../../../../assets/icons/unlink.png'
import ic_filter_cat from '../../../../../assets/icons/filter_cat.png'

import ic_view_match_count_details from '../../../../../assets/icons/split.png'

import { setCurrentChannelRecord, setIsLoadingListOfChannels, setListOfChannels } from "../../../logic/channels/slice";
import MatchCountDetails from "./MatchCountDetails";
import Export from "./Export";
import ListOfCategoriesForFilter from "./ListOfCategoriesForFilter";
import { getPermissions } from "../../../logic/permissions/selectors";

const filters = [
    { value: 'pending', text: 'Pending', color: 'orange' },
    { value: 'suspended', text: 'Suspended', color: 'yellow' },
    { value: 'rejected', text: 'Rejected', color: 'red' },
    { value: 'unknown', text: 'Unknown', color: 'blue' },
    { value: 'joined', text: 'Joined', color: 'green' },
    { value: 'notjoined', text: 'Not Joined', color: 'gray' }
];

const VendorOverviewTab = ({
    setCurrentDetailsFormToRender,
    setIsDetailsModalOpen,
    setDetailsModalTitle,
    setDetailsModalFooter,
    isLoadingListOfVendors,
    setCurrentSidebarFooterToRender,
    setIsVendorConfigurationSidebarOpen,
    setCurrentSidebarFormToRender,
    fetchListOfAllVendors,
    listOfVendorPlatforms,
    setSideBarTitle,
    setCurrentSelectVendor,
    setIsLoadingListOfVendors,
    setListOfVendors,
    setIsLoadingListOfChannels,
    setCurrentChannelRecord,
    setListOfChannels,
    // redux selectors
    listOfVendors,
    permissions,
    ...props }) => {
    const [showSelectedFilters, setShowSelectedFilters] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([])
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [selectedCountry, setSelectedCountry] = useState(['all']);
    const [selectedStatus, setSelectedStatus] = useState(['all'])
    const [currentSelectedVendorIndex, setCurrentSelectedVendorIndex] = useState(-1)
    const [listOfPlatforms, setListOfPlatforms] = useState([{ value: "default", label: "All platforms" }])
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });
    const [listOfSelectedCategories, setListOfSelectedCategories] = useState([])
    const [selectedCategoriesNames, setSelectedCategoriesNames] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [fetchOnlyAdvertizingVendors, setFetchOnlyAdvertizingVendors] = useState(null)
    // Initial states for current page and filters
    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    const [currentFilters, setCurrentFilters] = useState({});
    // State to store the dynamically set filters
    const [columnFilters, setColumnFilters] = useState([]);
    const [listSearchParams, setListSearchParams] = useState({})
    const prevSearchTerm = useRef(''); // Store the previous search term
    const [isAllowedToUpdateVendor, setIsAllowedToUpdateVendor] = useState(false);
    const [isAllowedToManageRegions, setIsAllowedToManageRegions] = useState(false);
    const [isAllowedToManageVendorMatches, setIsAllowedToManageVendorMatches] = useState(false);
    const [isAllowedToManageVendorCategories, setIsAllowedToManageVendorCategories] = useState(false);
    const [isAllowedToManageChannels, setIsAllowedToManageChannels] = useState(false);
    const [isAllowedToDeleteVendors, setIsAllowedToDeleteVendors] = useState(false);
    const [isAllowedToExportVendors, setIsAllowedToExportVendors] = useState(false);

    useEffect(() => {
        setIsLoadingListOfVendors(true)
        return () => {
            setListOfVendors({
                formatted_data: [],
                totalRecords: 0,
                totalPages: 0,
                currentPage: 1,
            })
        }
    }, [])
    // Debounce the search function to avoid excessive requests
    const debouncedSearch = useCallback(
        debounce((searchTerm, page, pageSize, selectedPlatform, selectedCountry, selectedStatus, fetchOnlyAdvertizingVendors, listOfSelectedCategories) => {
            setListSearchParams({ keyword: searchTerm, page, limit: pageSize, platform: selectedPlatform, country_code: selectedCountry, status: selectedStatus, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories })
            fetchListOfAllVendors({ keyword: searchTerm, page, limit: pageSize, platform: selectedPlatform, country_code: selectedCountry, status: selectedStatus, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories });
        }, 1000), // 1-second debounce delay
        []
    );

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, pagination.pageSize, selectedPlatform, selectedCountry, selectedStatus, fetchOnlyAdvertizingVendors, listOfSelectedCategories);
    }, [searchTerm, pagination.current, debouncedSearch, listOfSelectedCategories]);

    // useEffect(() => {
    //     handleApplyFilter()
    // }, [selectedCountry, selectedPlatform, selectedRegion, selectedStatus])


    useEffect(() => {
        if (listOfVendors) {
            setData(listOfVendors.formatted_data);
            if (currentSelectedVendorIndex != -1) {
                setCurrentSelectVendor(listOfVendors.formatted_data[currentSelectedVendorIndex])
            }
            setPagination(prev => ({
                ...prev,
                total: listOfVendors.totalRecords,
                pageSize: 10,
            }));
        }

    }, [listOfVendors]);

    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);

    useEffect(() => {
        if (listOfVendorPlatforms) {
            let formatted_platforms = listOfVendorPlatforms.map((v) => {

                return {
                    value: v.platform,
                    label: v.platform
                }
            })

            setListOfPlatforms([{ value: "default", label: "All platforms" }, ...formatted_platforms])
            let ar = listOfVendorPlatforms.map(p => p.platform); // No need for return statement

            setSelectedPlatform(ar);

        }
    }, [listOfVendorPlatforms])


    // Update column filters when selectedStatus changes
    useEffect(() => {

        if (selectedStatus?.length > 0) {
            const updatedFilters = filters?.map((filter) => ({
                ...filter,
                selected: filter.value === selectedStatus, // Check if the filter is selected
            }));


            setColumnFilters(updatedFilters); // Update the column filters
        }
    }, [selectedStatus]); // Run when selectedStatus changes

    useEffect(() => {
        setPagination(prev => ({
            ...prev,
            current: 1
        }));

    }, [selectedStatus])

    // Load selected statuses from localStorage, or select all by default
    useEffect(() => {
        const savedStatus = JSON.parse(localStorage.getItem('selectedStatus'));
        if (savedStatus?.length > 0) {
            setSelectedStatus(savedStatus);
            setCurrentFilters(savedStatus)
        } else {
            // If no saved status is found, select all by default
            let f_l = filters?.map(filter => filter.value)
            if (f_l?.length > 0)
                setSelectedStatus(f_l);
        }
    }, []); // Empty dependency array to run only once on component mount

    // Save selected statuses to localStorage whenever it changes
    useEffect(() => {
        if (selectedStatus?.length > 0)
            localStorage.setItem('selectedStatus', JSON.stringify(selectedStatus));
    }, [selectedStatus]);

    // Function to handle the change in selected filter (checkbox change)
    const handleCheckboxChange = (checkedValues) => {
        if (checkedValues?.length > 0) {
            setSelectedStatus(checkedValues); // Update the selected filters
            setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: selectedCountry, status: checkedValues, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories })
            fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: selectedCountry, status: checkedValues, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories });
        }
    };

    useEffect(() => {
        if (permissions) {
            setIsAllowedToUpdateVendor(permissions?.UPDATE_VENDORS?.enabled);
            setIsAllowedToManageRegions(permissions?.MANAGE_REGIONS?.enabled);
            setIsAllowedToManageVendorMatches(permissions?.MANAGE_VENDOR_MATCHES?.enabled);
            setIsAllowedToManageVendorCategories(permissions?.MANAGE_VENDOR_CATEGORIES?.enabled);
            setIsAllowedToManageChannels(permissions?.MANAGE_CHANNELS?.enabled);
            setIsAllowedToDeleteVendors(permissions?.DELETE_VENDORS?.enabled);
            setIsAllowedToExportVendors(permissions?.EXPORT_VENDORS?.enabled);
        }
    }, [permissions]);

    const filterMenu = (
        <Menu>
            Select status
            <Menu.Item>
                <Checkbox.Group
                    options={columnFilters?.map((filter) => ({
                        label: <span style={{}}>{filter?.text}</span>,
                        value: filter?.value,
                    }))}
                    value={selectedStatus} // Set checked values based on selectedStatus
                    onChange={handleCheckboxChange} // Handle checkbox changes
                />
            </Menu.Item>
            {/* Display 'All checked' option if no filter is selected */}
            {selectedStatus?.length === 0 && (
                <Menu.Item disabled>
                    <span>Applied all by default</span>
                </Menu.Item>
            )}
        </Menu>
    );

    // Define the columns structure
    const columns = [
        {
            title: 'Logo',
            dataIndex: 'logo_url',
            key: 'logo_url',
            render: (logo_url, record) => {
                const renderProfileImage = (url, name) => {
                    if (url) {
                        return (
                            <img
                                src={url}
                                alt={name}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ''; // Reset the image source if it fails
                                }}
                                style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                            />
                        );
                    } else {
                        const initials = name.split(' ').map(n => n[0]).join('');
                        return (
                            <div style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fff',
                            }}>
                                {initials}
                            </div>
                        );
                    }
                };

                return renderProfileImage(logo_url, record.name);
            },
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',

        },
        {
            title: 'Vendor Name',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => {
                let domainUrl = record?.domain_url;

                // Add 'www.' if it is missing from the domain URL
                if (domainUrl && !domainUrl.startsWith('www.')) {
                    domainUrl = `www.${domainUrl}`;
                }

                return domainUrl ? (
                    <a
                        href={`https://${domainUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {value}
                    </a>
                ) : (
                    <span>{value}</span> // Render plain text if the URL is missing
                );
            },
        }

        ,
        {
            title: 'Allowed Regions',
            dataIndex: 'allowedRegions',
            key: 'allowedRegions',
            align: 'center',
            render: (value, record) => (
                <div style={{}}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: isAllowedToManageRegions ? "pointer" : "not-allowed",
                    }}>
                        {value?.length}
                        <FaEdit
                            size={15}
                            onClick={() => { if (isAllowedToManageRegions) handleEditRegions(record); }}
                            style={{
                                color: isAllowedToManageRegions ? "inherit" : "gray",
                                pointerEvents: isAllowedToManageRegions ? "auto" : "none",
                            }}
                        />
                    </div>
                </div>
            )

        },
        {
            title: 'Number of Channels',
            dataIndex: 'allowedChannels',
            key: 'allowedChannels',
            align: 'center',
            render: (value, record) => (
                <div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: isAllowedToManageChannels ? "pointer" : "not-allowed"
                    }}>
                        {Array.isArray(value) ? value.length : value || 'N/A'}
                        <FaEdit
                            size={15}
                            onClick={() => {
                                if (isAllowedToManageChannels) handleEditChannels(record);
                            }}
                            style={{
                                color: isAllowedToManageChannels ? "inherit" : "gray",
                                pointerEvents: isAllowedToManageChannels ? "auto" : "none",
                            }}
                        />
                    </div>
                </div>
            )

        },
        {
            title: 'Total Products',
            dataIndex: 'total_products',
            key: 'total_products',
            align: 'center',
            render: (value, record) => (
                <div >
                    {Number(value).toLocaleString()}

                </div>
            ),
        },
        {
            title: 'Total Matches',
            dataIndex: 'matches_count',
            key: 'matches_count',
            align: 'center',
            render: (value, record) => (
                <Tooltip
                    title={isAllowedToManageVendorMatches ? `Click to view division of match count` : `Permission denied`}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                            cursor: isAllowedToManageVendorMatches ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                            if (isAllowedToManageVendorMatches) handleViewMatchCountDetails(record);
                        }}
                    >
                        <img
                            src={ic_view_match_count_details}
                            width={15}
                            onClick={(e) => {
                                if (isAllowedToManageVendorMatches) {
                                    e.stopPropagation(); // Prevent duplicate event firing
                                    handleViewMatchCountDetails(record);
                                }
                            }}
                            style={{
                                filter: isAllowedToManageVendorMatches ? "none" : "grayscale(100%)",
                                pointerEvents: isAllowedToManageVendorMatches ? "auto" : "none",
                            }}
                            alt="View Match Count"
                        />
                        {Number(value).toLocaleString()}
                    </div>
                </Tooltip>
            )
            
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (value) => {
                // Capitalize the first letter and add a space for specific cases
                const formatStatus = (str) => {
                    if (!str) return ''; // Handle undefined or null values by returning an empty string or a default value
                    const capitalized = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                    return capitalized.replace('Notjoined', 'Not Joined');
                };


                // Define an icon map for each status
                const statusIcons = {
                    Suspended: ic_suspended,
                    Rejected: ic_decline,
                    Unknown: ic_unknown,
                    Pending: ic_pending,
                    Joined: ic_link,
                    'Not Joined': ic_not_joined
                };

                // Get the formatted status and corresponding icon
                const displayValue = formatStatus(value);
                const icon = statusIcons[displayValue];

                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {icon && <img src={icon} alt={displayValue} style={{ width: '20px', height: '20px' }} />}
                        <span>{displayValue}</span>
                    </div>
                );
            },

            filterDropdown: () => filterMenu,

            filterMultiple: true, // Allow multiple selections
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record, index) => (
                <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                    <CustomButton
                        title="Configure Categories"
                        width="100%"
                        font_size="0.7"
                        unit="rem"
                        padding="5px 5px 0px 5px"
                        colorScheme="secondary"
                        icon={<img src={ic_catagory} width={20} />}
                        onClick={() => handleConfigureCatagories(record, index)}
                        disabled={!isAllowedToManageVendorCategories}
                    />
                    <CustomButton
                        title="Edit Details"
                        width="100%"
                        font_size="0.7"
                        unit="rem"
                        padding="5px 5px 0px 5px"
                        onClick={() => handleEditRecord(record)}
                        icon={<FaEdit size={15} />}
                        disabled={!isAllowedToUpdateVendor}
                    />

                    <CustomButton
                        title="Details"
                        width="100%"
                        font_size="0.7"
                        unit="rem"
                        padding="5px 5px 0px 5px"
                        colorScheme="secondary"
                        icon={<FaSearch size={15} />}
                        onClick={() => handleViewDetails(record)}
                    />

                    <CustomButton
                        title="Delete"
                        width="100%"
                        font_size="0.7"
                        unit="rem"
                        padding="5px 5px 0px 5px"
                        colorScheme="danger"
                        icon={<img src={ic_delete} width={15} />}
                        onClick={() => handleDisable(record)}
                        disabled={!isAllowedToDeleteVendors}
                    />

                </div>
            ),
        },
    ];


    const handleViewDetails = (record) => {
        // // Implement view details logic here, such as navigation or modal display
        setCurrentDetailsFormToRender(<VendorDetailsForm is_pagination_enabled={false} setIsDetailsModalOpen={setIsDetailsModalOpen} setDetailsModalFooter={setDetailsModalFooter} record={record} />)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Vendor details")
    };

    const handleConfigureCatagories = (record, index) => {
        setCurrentSelectedVendorIndex(index)
        setCurrentSelectVendor(record)
        setRefresh(!refresh)
        setCurrentDetailsFormToRender(
            <ListOfCatagories
                setIsDetailsModalOpen={setIsDetailsModalOpen}
                setDetailsModalFooter={setDetailsModalFooter}
                refresh={refresh}
                selectedCategoriesNames={selectedCategoriesNames}
                setSelectedCategoriesNames={setSelectedCategoriesNames}
            />)
        setIsDetailsModalOpen(true)
        setDetailsModalFooter(false)
        setDetailsModalTitle("Configure Categories for " + record.name)
    };

    const handleFilterByCategory = () => {
        setCurrentDetailsFormToRender(
            <ListOfCategoriesForFilter
                setIsDetailsModalOpen={setIsDetailsModalOpen}
                setDetailsModalFooter={setDetailsModalFooter}
                listOfSelectedCategories={listOfSelectedCategories}
                setListOfSelectedCategories={setListOfSelectedCategories}
                selectedCategoriesNames={selectedCategoriesNames}
                setSelectedCategoriesNames={setSelectedCategoriesNames}
            />)
        setIsDetailsModalOpen(true)
        setDetailsModalFooter(false)
        setDetailsModalTitle("Select categories to filter list of vendors")
    };

    const handleEditRegions = (record) => {
        setCurrentDetailsFormToRender(<ListOfAllowedRegions listSearchParams={listSearchParams} setIsDetailsModalOpen={setIsDetailsModalOpen} record={record} setDetailsModalFooter={setDetailsModalFooter} />)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Configure regions for " + record.name)
    };

    const handleEditChannels = (record) => {
        setCurrentSelectVendor(record)
        setCurrentChannelRecord(record)
        setCurrentDetailsFormToRender(<ListOfAllowedChannels
            listSearchParams={listSearchParams}
            is_pagination_enabled={true}
            setDetailsModalFooter={setDetailsModalFooter}
            setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
            setIsDetailsModalOpen={setIsDetailsModalOpen}
            setDetailsModalTitle={setDetailsModalTitle}
        />)
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Configure channels for " + record.name)
    };

    const handleViewMatchCountDetails = (record) => {
        setCurrentSelectVendor(record)
        setCurrentDetailsFormToRender(<MatchCountDetails
            listSearchParams={listSearchParams}
            is_pagination_enabled={true}
            setIsDetailsModalOpen={setIsDetailsModalOpen}
            setDetailsModalFooter={setDetailsModalFooter}
        />)
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Match count details for " + record.name)
    };

    const handleExportVendorsModal = (record) => {
        setCurrentSelectVendor(record)
        setCurrentDetailsFormToRender(<Export
            listSearchParams={listSearchParams}
        />)
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Export vendors")
    };

    const handleEditRecord = (record) => {
        setCurrentSidebarFormToRender(<NewVendorDataForm
            record={record}
            listSearchParams={listSearchParams}
            is_this_for_update={true}
            setIsDetailsModalOpen={setIsDetailsModalOpen}
            setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
            setDetailsModalTitle={setDetailsModalTitle}
            setCurrentSidebarFooterToRender={setCurrentSidebarFooterToRender}
            setIsVendorConfigurationSidebarOpen={setIsVendorConfigurationSidebarOpen}
        />)
        setSideBarTitle("Update vendor details")
        setIsVendorConfigurationSidebarOpen(true)
    };

    const handleDisable = (record) => {
        setCurrentDetailsFormToRender(<WarningBeforeDeletingAnyVendor record={record} setIsDetailsModalOpen={setIsDetailsModalOpen} setDetailsModalFooter={setDetailsModalFooter} />)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Confirm you want to delete it ? ")
    };

    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    // Function to handle the filter change
    const handleFilterChange = (filters) => {
        // Get the selected filter value
        const selectedFilter = filters?.status || null; // or other column key if you need different filter

        if (selectedFilter?.length > 0) {
            setSelectedStatus(selectedFilter);

        }// Optionally track the selected status
    };

    const handleRegionChange = (region) => {
        setSelectedRegion(region);
        if (region === 'all') {
            // Collect all country codes from all regions
            // const allCountryCodes = sortedCountriesGroupedBySubRegionForFilter.flatMap(r => r.countries.map(country => country.isoCode));
            setSelectedCountry(['all']);
            setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: ['all'], status: selectedStatus, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories })
            fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: ['all'], status: selectedStatus, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories });

        } else {
            // Find the selected region object
            const selectedRegionObj = sortedCountriesGroupedBySubRegionForFilter.find(r => r.subRegion === region);

            // Update selected countries based on the selected region
            if (selectedRegionObj) {
                const countryCodes = selectedRegionObj.countries.map(country => country.isoCode);
                setSelectedCountry(countryCodes);
                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: countryCodes, status: selectedStatus, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories })
                fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: countryCodes, status: selectedStatus, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories });
            } else {
                setSelectedCountry([]); // Reset if no match
                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: [], status: selectedStatus, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories })
                fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: [], status: selectedStatus, fetch_only_advertising_vendors: fetchOnlyAdvertizingVendors, iab_category_id: listOfSelectedCategories });
            }
        }

        setPagination(prev => ({
            ...prev,
            current: 1
        }));

        // Reset the Country dropdown to "All Countries"
        // setSelectedCountry(['all']);
    };

    const onTableChange = (pagination, filters) => {
        const isPaginationChange = pagination.current !== currentPaginationPage;
        const isFilterChange = JSON.stringify(filters) !== JSON.stringify(currentFilters);

        if (isPaginationChange) {
            handleTableChange(pagination);
        }
        if (isFilterChange) {
            handleFilterChange(filters);
        }

        // Update the current pagination and filters state
        setCurrentPaginationPage(pagination.current);
        setCurrentFilters(filters);
    };

    const getSelectedCategoriesLabel = () => {
        return selectedCategoriesNames.length > 0 ? selectedCategoriesNames.join(', ') : 'No categories selected';
    };

    const toggleSelectedFilters = () => {
        setShowSelectedFilters(!showSelectedFilters);
    };

    const clearSelectedFilters = () => {
        setSelectedCategoriesNames([]);
        setListOfSelectedCategories([]);
        // Add any other state resets needed for clearing filters
    };


    return (
        <div className={styles.vendor_overview_tab_container}>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total, // Total number of records from API
                    showSizeChanger: false
                }}
                // onChange={handleTableChange}
                onChange={onTableChange}

                loading={isLoadingListOfVendors}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.top_actions_row}>
                            <div className={styles.left_side}>
                                <div className={styles.platform_dropdown}>
                                    <CustomDropDown
                                        defaultValue='default'
                                        options={listOfPlatforms}
                                        onChange={(value) => {
                                            if (value != 'default') {
                                                setSelectedPlatform([value])
                                                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: [value], country_code: selectedCountry, status: selectedStatus, iab_category_id: listOfSelectedCategories })
                                                fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: [value], country_code: selectedCountry, status: selectedStatus, iab_category_id: listOfSelectedCategories });
                                            } else {
                                                setSelectedPlatform(listOfPlatforms.map((p) => p.value))
                                                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: listOfPlatforms.map((p) => p.value), country_code: selectedCountry, status: selectedStatus, iab_category_id: listOfSelectedCategories })
                                                fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: listOfPlatforms.map((p) => p.value), country_code: selectedCountry, status: selectedStatus, iab_category_id: listOfSelectedCategories });
                                            }

                                            setPagination(prev => ({
                                                ...prev,
                                                current: 1
                                            }));
                                        }}
                                    />

                                </div>
                                <div className={styles.region_dropdown}>
                                    <CustomDropDown
                                        enable_search={true}

                                        defaultValue='all'
                                        options={[
                                            { value: 'all', label: 'All Regions' },
                                            ...sortedCountriesGroupedBySubRegionForFilter.map((region) => ({
                                                value: region?.subRegion,
                                                label: region?.subRegion
                                            }))
                                        ]}
                                        onChange={handleRegionChange}
                                    />
                                </div>
                                <div className={styles.country_dropdown}>
                                    <CustomDropDown
                                        enable_search={true}
                                        defaultValue='all'

                                        // options={[
                                        //     { value: 'all', label: 'All Countries' },
                                        //     ...(selectedRegion !== 'all'
                                        //         ? sortedCountriesGroupedBySubRegionForFilter
                                        //             .find((region) => region.subRegion === selectedRegion)?.countries
                                        //             .map((country) => ({
                                        //                 value: country.isoCode,
                                        //                 label: country.name,
                                        //             })) || []
                                        //         : sortedCountriesGroupedBySubRegionForFilter.flatMap((region) =>
                                        //             region.countries.map((country) => ({
                                        //                 value: country.isoCode,
                                        //                 label: country.name,
                                        //             }))
                                        //         )),
                                        // ]}

                                        options={[
                                            { value: 'all', label: 'All Countries' },
                                            ...(selectedRegion !== 'all'
                                                ? // If a specific region is selected, filter by that region and map countries
                                                sortedCountriesGroupedBySubRegionForFilter
                                                    .find((region) => region.subRegion === selectedRegion)?.countries
                                                    .map((country) => ({
                                                        value: country.isoCode,
                                                        label: country.name,
                                                    })) || []
                                                : // Otherwise, flatten all regions and sort globally
                                                sortedCountriesGroupedBySubRegionForFilter
                                                    .flatMap((region) => region.countries) // Flatten all countries
                                                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort globally by name
                                                    .map((country) => ({
                                                        value: country.isoCode,
                                                        label: country.name,
                                                    }))
                                            ),
                                        ]}

                                        onChange={(value) => {
                                            setPagination(prev => ({
                                                ...prev,
                                                current: 1
                                            }));
                                            if (value === 'all') {
                                                let countries;

                                                if (selectedRegion !== 'all') {
                                                    const selectedRegionData = sortedCountriesGroupedBySubRegionForFilter.find(region => region.subRegion === selectedRegion);

                                                    countries = selectedRegionData
                                                        ? selectedRegionData.countries.map(country => (country.isoCode))
                                                        : [];
                                                } else {
                                                    countries = sortedCountriesGroupedBySubRegionForFilter.flatMap(region =>
                                                        region.countries.map(country => (country.isoCode))
                                                    );
                                                }
                                                setSelectedCountry(countries)

                                                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: countries, status: selectedStatus })
                                                fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: countries, status: selectedStatus });

                                            } else {
                                                setSelectedCountry([value])
                                                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: [value], status: selectedStatus })
                                                fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: [value], status: selectedStatus });
                                            }
                                        }}
                                    />
                                </div>
                                <div className={styles.country_dropdown}>
                                    <CustomButton
                                        title="Filter by categories"
                                        width="100%"
                                        font_size="0.7"
                                        unit="rem"
                                        padding="5px 5px 0px 5px"
                                        colorScheme=""
                                        border_color="#FAFAFA"
                                        background_color="#FAFAFA"
                                        icon={<img src={ic_filter_cat} width={20} />}
                                        onClick={() => handleFilterByCategory()}
                                    />
                                </div>
                            </div>
                            <div className={styles.right_side}>
                                <div className={styles.search_bar}>
                                    <CustomTextfield
                                        border_radius="10px"
                                        icon={<FaSearch size={15} />}
                                        colorScheme="primary"
                                        place_holder="Search i.e  vendor name "
                                        font_size="0.7"
                                        unit="rem"
                                        padding="10px"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                <div className={styles.export_button}>
                                    <CustomButton
                                        title={"Export"}
                                        width="100%"
                                        font_size="0.8"
                                        unit="rem"
                                        padding="5px 10px 0px 10px"
                                        onClick={handleExportVendorsModal} // Stop stream on Update click
                                        disabled={!isAllowedToExportVendors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.select_filters_row}>
                            {(selectedCategoriesNames.length > 0) &&
                                <div className={styles.show_hide_filters}>
                                    <Button onClick={toggleSelectedFilters} style={{ marginRight: '20px' }}>
                                        {showSelectedFilters ? 'Hide Selected Filters' : 'Show Selected Filters'}
                                    </Button>
                                </div>
                            }
                            {showSelectedFilters && (selectedCategoriesNames.length > 0) && (
                                <div style={{ marginTop: '10px', marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', position: 'relative' }}>
                                    <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Selected Filters:</div>
                                    {selectedCategoriesNames.length > 0 && (
                                        <div style={{ marginBottom: '5px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Categories:</span> {getSelectedCategoriesLabel()}
                                        </div>
                                    )}

                                    <Button
                                        type="danger"
                                        onClick={clearSelectedFilters}
                                        style={{ position: 'absolute', bottom: '10px', right: '10px' }}
                                    >
                                        Clear Filters
                                    </Button>
                                </div>
                            )}
                        </div>

                    </div>
                )}
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfVendors: getAllVendors(state),
    isLoadingListOfVendors: getIsLoadingListOfVendors(state),
    listOfVendorPlatforms: getAllVendorsNames(state),
    permissions: getPermissions(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfAllVendors: (data) => dispatch(fetchListOfAllVendors(data)),
    setCurrentSelectVendor: (data) => dispatch(setCurrentSelectVendor(data)),
    setIsLoadingListOfVendors: (data) => dispatch(setIsLoadingListOfVendors(data)),
    setListOfVendors: (data) => dispatch(setListOfVendors(data)),
    setIsLoadingListOfChannels: (data) => dispatch(setIsLoadingListOfChannels(data)),
    setListOfChannels: (data) => dispatch(setListOfChannels(data)),
    setCurrentChannelRecord: (data) => dispatch(setCurrentChannelRecord(data)),
});

export default connect(mapState, mapDispatchToProps)(VendorOverviewTab);
