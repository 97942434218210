import { connect } from "react-redux";
import styles from '../../styles.module.scss'
import { CustomHeading } from "../../../../../shared/ui/heading";
import { useState } from "react";
import { CustomTextfield } from "../../../../../shared/ui/text-field";

const NewPlatformDataForm = ({
    
    ...props }) => {
    const [platformName, setPlatformName] = useState("")
    return <div className={styles.new_platform_data_form_container}>

        <div className={styles.row_1}>
            <div className={styles.label}>
                <CustomHeading font_size="0.7" unit="rem" headingText="Platform Name" />
            </div>
            <div className={styles.field}>
                <CustomTextfield
                    border_radius="5px"
                    colorScheme="primary"
                    place_holder="Enter platform name"
                    font_size="0.7" unit="rem"
                    value={platformName}
                    onChange={(e) => { setPlatformName(e.target.value) }}
                />
            </div>
        </div>

    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapState, mapDispatchToProps)(NewPlatformDataForm)