import React from 'react';
import styles from './styles.module.scss';
import { Heading } from './styled'; // Import the Heading component

// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};

const CustomHeading = ({
    headingText = 'Default Heading', // Text for the heading
    font_size = '2px', // Base font size for the heading
    unit = 'px', // Unit for font size
    font_weight = 'normal', // Font weight for the heading
    colorScheme = 'primary', // Prop for color schemes
    textAlign = 'left', // Text alignment for the heading
    marginTop = '0', // Margin-top for the heading
    marginBottom = '10px', // Margin-bottom for the heading
    lineHeight = '1.4', // Line height for the heading
    pick_passed_font_size = false,
    // Optional: additional styles for the container
    containerStyle = {},
    width='100%',
    // Custom color properties (will be overridden by color schemes if specified)
    customTextColor,

    mobileFontSize,
    tabletFontSize,
    laptopFontSize,
    macbook13FontSize,

    ...props
}) => {
    // Color schemes
    const colorSchemes = {
        primary: {
            color: 'black',
        },
        secondary: {
            color: '#28a745',
        },
        danger: {
            color: '#dc3545',
        },
        warning: {
            color: '#ffc107',
        },
        info: {
            color: '#17a2b8',
        },
        custom: {
            color: customTextColor || '#333', // Fallback to default color if customTextColor is not provided
        }
    };

    // Apply the selected color scheme
    const selectedColors = colorSchemes[colorScheme] || colorSchemes.custom;

    const responsiveFontSizes = getResponsiveFontSizes(font_size, unit);

    const finalMobileFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveFontSizes.mobileFontSize;
    const finalTabletFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveFontSizes.tabletFontSize;
    const finalLaptopFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveFontSizes.laptopFontSize;
    const finalMacbook13FontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveFontSizes.macbook13FontSize;


    return (
        <div className={styles.container} style={containerStyle}>
            <Heading
            width={width}
                fontSize={font_size}
                fontWeight={font_weight}
                color={selectedColors.color}
                textAlign={textAlign}
                marginTop={marginTop}
                marginBottom={marginBottom}
                lineHeight={lineHeight}

                mobileFontSize={finalMobileFontSize}
                tabletFontSize={finalTabletFontSize}
                laptopFontSize={finalLaptopFontSize}
                macbook13FontSize={finalMacbook13FontSize}
                {...props}
            >
                {headingText}
            </Heading>
        </div>
    );
};

export { CustomHeading };
