import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Register necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const TopChannelVendorsBarChart = ({ vendors, is_loading = true }) => {
    // Prepare data for Chart.js

    const chartData = {
        labels: vendors?.map((vendor) => vendor.vendor_name), // Vendor names as labels
        datasets: [
            {
                label: 'Total Matches',
                data: vendors?.map((vendor) => parseInt(vendor.matches_count, 10)), // Total matches per vendor
                backgroundColor: '#08338A',
                borderColor: '#08338A',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)',
                hoverBorderColor: 'rgba(54, 162, 235, 1)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    },
                },
            },
        },
    };

    return (
        <div>
            <h3 style={{ marginBottom: '1rem' }}>Top 10 Vendors having most matches</h3>
            {
                is_loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Spin indicator={<LoadingOutlined spin />} size="large" />
                </div> : <Bar data={chartData} options={options} />
            }
        </div>
    );
};

export default TopChannelVendorsBarChart;