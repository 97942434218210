import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'category',
    initialState: {
        listOfCategories: [],
        isLoadingListOfCategories: false,
    },
    reducers: {
        setListOfCategories: (state, action) => {
            state.listOfCategories = action.payload;
        },
        setIsLoadingListOfCategories: (state, action) => {
            state.isLoadingListOfCategories = action.payload;
        },
    },
});

export const {
   setIsLoadingListOfCategories,
   setListOfCategories
} = appSlice.actions;

export default appSlice.reducer;
