import React from "react";
import { Typography, Row, Col, Spin } from "antd";
import ReactApexChart from "react-apexcharts";
import { eChart } from "./config/eChart";
import { LoadingOutlined } from "@ant-design/icons";
const { Title, Paragraph } = Typography;
const items = [
  {
    title: "3,6K",
    user: "Users",
  },
  {
    title: "2m",
    user: "Clicks",
  },
  {
    title: "$772",
    user: "Sales",
  },
  {
    title: "82",
    user: "Items",
  },
];

export default function Echat({ title, sub_title, columns, ...props }) {
  return (
    <>
      {/* <div id="chart">
        <ReactApexChart
          series={eChart.series}
          options={eChart.options}
          height={220}
          className="bar-chart"
          type="bar"
        />
      </div> */}
      <div className="chart-vistior">
        <Title level={5}>{title}</Title>
        {/* <Paragraph className="lastweek">
          Products overview<span className="bnb2"></span>
        </Paragraph> */}
        <Paragraph className="lastweek">
          {sub_title}
        </Paragraph>
        <Row>
          {columns?.map(({ title, user, is_loading }, index) => (
            <Col xs={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={5}>
                  {title}
                </Title>
                <span>
                  {is_loading ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : user}
                </span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}
