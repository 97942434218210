import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles.module.scss';

import { fetchBankDetails, updateBankDetails } from '../../../logic/users/actions';
import { getBankDetails, getIsLoadingBankDetails } from '../../../logic/users/selectors';
import { CustomButton } from '../../../../../shared/ui/button';
import { CustomTextfield } from '../../../../../shared/ui/text-field';

const defaultBankData = {
    bank_name: "",
    account_number: "",
    account_type: "",
    ifsc_code: "",
    branch_name: ""
};

const placeholders = {
    bank_name: "Enter bank name",
    account_number: "Enter account number",
    account_type: "Enter account type",
    ifsc_code: "Enter IFSC code",
    branch_name: "Enter branch name"
};

const labels = {
    bank_name: "Bank Name",
    account_number: "Account Number",
    account_type: "Account Type",
    ifsc_code: "IFSC Code",
    branch_name: "Branch Name"
};

const BankDetails = ({
    bankDetails,
    isLoadingBankDetails,
    fetchBankDetails,
    updateBankDetails,
    ...props
}) => {
    const [bankFormData, setBankFormData] = useState({});

    useEffect(() => {
        fetchBankDetails({});
    }, []);

    useEffect(() => {
        console.log('Bank Details:', bankDetails);
        if (bankDetails && Object.keys(bankDetails).length > 0) {
            setBankFormData(bankDetails);
        } else {
            setBankFormData(defaultBankData);
        }
    }, [bankDetails]);

    const handleBankChange = (key, value) => {
        setBankFormData({
            ...bankFormData,
            [key]: value,
        });
    };

    const handleBankSave = () => {
        console.log('Saving Bank Details:', bankFormData);
        updateBankDetails(bankFormData);
    };

    return (
        <div className={styles.profile_bank_details_container}>
            <div className={styles.top_actions_row}>
                <CustomButton
                    title="Save changes"
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={(e) => {
                        handleBankSave(e)
                    }}
                    loading={isLoadingBankDetails}
                />
            </div>
            <div className={styles.body_container}>
                <div className={styles.left_side}>
                    <div className={styles.card}>
                        <div className={styles.formSection}>
                            <h3>Bank Information</h3>
                            {['bank_name', 'account_number', 'account_type', 'ifsc_code', 'branch_name'].map((key) => (
                                <div className={styles.inputGroup} key={key}>
                                    <label className={styles.label}>{labels[key]}</label>
                                    <CustomTextfield
                                        type="text"
                                        border_radius="5px"
                                        colorScheme="primary"
                                        place_holder={placeholders[key]}
                                        font_size="0.7" unit="rem"
                                        value={bankFormData[key]}
                                        onChange={(e) => handleBankChange(key, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    bankDetails: getBankDetails(state),
    isLoadingBankDetails: getIsLoadingBankDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchBankDetails: (data) => dispatch(fetchBankDetails(data)),
    updateBankDetails: (data) => dispatch(updateBankDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);
