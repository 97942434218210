import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from '../styles.module.scss'
import { CustomHeading } from "../../../../shared/ui/heading";
import Header from "./general-components/Header";
import { CustomStatesCard } from "../../../../shared/ui/states-card";
import { FaAd, FaCartPlus, FaExpand, FaQrcode, FaShopify, FaStream, FaUsers } from "react-icons/fa";
import { getIsLoadingQRCodeStates, getIsLoadingReviewStates, getIsLoadingTop10Vendors, getIsLoadingTotalChannelCount, getIsLoadingTotalCountStates, getIsLoadingTotalMatches, getIsLoadingTotalProducts, getIsLoadingTotalUsersCount, getIsLoadingTotalVendorsCount, getIsLoadingUniqueMatches, getIsLoadingVerificationSpeed, getMatchDetails, getProductVerificationSpeed, getTotalAverageScoreCount, getTotalMatches, getTotalNumberOfChannels, getTotalNumberOfProducts, getTotalNumberOfProductsAdvertized, getTotalNumberOfQRCodeScans, getTotalNumberOfQRCodeVisits, getTotalNumberOfUsers, getTotalNumberOfVendors, getTotalScoreCount } from "../../logic/dashboard/selectors";
import { fetchProductVerificationSpeed, fetchQRCodeStates, fetchReviewStates, fetchTop10Vendors, fetchTotalChannelCounts, fetchTotalCountStates, fetchTotalMatches, fetchTotalProductsCount, fetchTotalUniqueMatches, fetchTotalUserCounts, fetchTotalVendorsCounts } from "../../logic/dashboard/actions";
import ic_speed_meter from '../../../../assets/icons/speedometer-removebg-preview.png'
import ic_score from '../../../../assets/icons/rating.png'

import ic_users from '../../../../assets/icons/team.png'
import ic_vendors from '../../../../assets/icons/supplier.png'
import ic_channels from '../../../../assets/icons/television.png'
import ic_rating from '../../../../assets/icons/rating.png'
import ic_products from '../../../../assets/icons/products.png'


import ic_average_score from '../../../../assets/icons/average.png'
import { Card, Col, Row, Spin, Typography } from "antd";
import Echat from "../subscreens/dashboard-components/charts/Echat";
import LineChart from "../subscreens/dashboard-components/charts/LineChart";
import AntCard from "../subscreens/dashboard-components/AntCard";
import TopVendorsBarChart from "./dashboard-components/charts/TopVendorsBarChart";
import DetailsModal from "./general-components/DetailsModal";
import ReviewCountDetails from "./dashboard-components/ReviewCountDetails";
import VendorCountDetails from "./dashboard-components/VendorCountDetails";
import { LoadingOutlined } from "@ant-design/icons";
import { CustomDropDown } from "../../../../shared/ui/dropdown";
import { getIsLoadingListOfChannelsWithOnlyCoreDetails, getListOfChannelsWithOnlyCoreDetails } from "../../logic/channels/selectors";
import { setCurrentChannelRecord } from "../../logic/channels/slice";
import TopChannelVendorsBarChart from "./dashboard-components/charts/TopChannelVendorsBarChart";
const { Title, Paragraph, Text } = Typography;

const OverviewNewScreen = ({
  is_loading_total_count_states,
  total_number_of_users,
  total_number_of_channels,
  total_number_of_vendors,
  total_number_of_qr_code_scans,
  total_number_of_qr_code_visits,
  total_number_of_products_advertized,
  total_number_of_products,
  product_verification_speed,
  fetchTotalCountStates,
  total_score_count,
  total_average_score_count,
  match_details,
  total_matches,
  fetchTotalUserCounts,
  fetchTotalVendorsCounts,
  is_loading_total_users,
  is_loading_total_vendors,
  is_loading_total_channels,
  fetchTotalChannelCounts,
  is_loading_review_states,
  fetchReviewStates,
  is_loading_total_products,
  fetchTotalProductsCount,
  is_loading_unique_matches,
  fetchTotalUniqueMatches,
  is_loading_total_matches,
  fetchTotalMatches,
  is_loading_verification_speed,
  fetchProductVerificationSpeed,
  is_loading_qr_code_states,
  fetchQRCodeStates,
  fetchTop10Vendors,
  is_loading_top_10_vendors,
  listOfChannelsBasicDetails,
  isLoadingListOfChannels,
  setCurrentChannelRecord,
  ...props }) => {

  const [cards, setCards] = useState([
    {
      today: "Users",
      title: "0",
      // persent: "+30%",
      icon: <img src={ic_users} />,
      is_loading: true
      // bnb: "bnb2",
    },
    {
      today: "Vendors",
      title: "0",
      // persent: "+20%",
      icon: <img src={ic_vendors} />,
      is_loading: true
      // bnb: "bnb2",
    },
    {
      today: "Channels",
      title: "0",
      // persent: "-20%",
      icon: <img src={ic_channels} />,
      is_loading: true
      // bnb: "redtext",
    },
    {
      today: "Reviews",
      title: "3.5",
      persent: "533 reviews ",
      icon: <img src={ic_average_score} />,
      is_loading: true
      // bnb: "bnb2",
    },

  ])

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  const [detailsModalTitle, setDetailsModalTitle] = useState("Vender details")
  const [currentDetailsFormToRender, setCurrentDetailsFormToRender] = useState(null)
  const [detailsModalFooter, setDetailsModalFooter] = useState(null)
  const [isSwitchingToChannelStatesMode, setIsSwitchingToChannelStatesMode] = useState(false)
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [hasSelectedChannelBefore, setHasSelectedChannelBefore] = useState(false);
  const [listOfChannels, setListOfChannels] = useState([
    {
      label: 'Overall statistics',
      value: 'default'
    }
  ])

  const [productsColumns, setProductsColumns] = useState([
    {
      title: "Total",
      user: "12,091,253",
      is_loading: true
    },
    {
      title: "Unique Matches",
      user: "90,823",
      is_loading: true
    },
    {
      title: "Matches",
      user: "90,823",
      is_loading: true
    },
    {
      title: "Product Verification Speed",
      user: "7.5 per min",
      is_loading: true
    }

  ])

  const [qrCodeColumns, setQrCodeColumns] = useState([
    {
      title: "Visits",
      user: "12,091,253",
      is_loading: true
    },
    {
      title: "Scans",
      user: "90,823",
      is_loading: true
    }

  ])

  const [topTenVendorsByMatch, setTopTenVendorsByMatch] = useState([])

  useEffect(() => {
    // Update cards data
    const cards = [
      {
        today: "Vendors",
        title: Number(total_number_of_vendors).toLocaleString() || "0",
        icon: <img src={ic_vendors} />,
        is_loading: is_loading_total_vendors
      },
      {
        today: "Reviews",
        persent: `${Number(total_average_score_count).toFixed(2)} avg: score | ${total_score_count || "0"} reviews`,
        icon: <img src={ic_average_score} />,
        is_loading: is_loading_review_states
      }
    ];

    if (selectedChannel === null) {


      cards.unshift({
        today: "Channels",
        title: Number(total_number_of_channels).toLocaleString() || "0",
        icon: <img src={ic_channels} />,
        is_loading: is_loading_total_channels
      });
      cards.unshift({
        today: "Users",
        title: Number(total_number_of_users).toLocaleString() || "0",
        icon: <img src={ic_users} />,
        is_loading: is_loading_total_users
      });
    }

    setCards(cards);

    console.log('total_matches',total_matches)
    // Update productsColumns data
    const columns = [
      {
        title: "Total",
        user: isNaN(Number(total_number_of_products)) ? "0" : Number(total_number_of_products).toLocaleString(),
        is_loading: is_loading_total_products
      },
      {
        title: "Unique Matches",
        user: isNaN(Number(total_number_of_products_advertized)) ? "0" : Number(total_number_of_products_advertized).toLocaleString(), 
        is_loading: is_loading_unique_matches
      },
      {
        title: "Matches",
        user:  isNaN(Number(total_matches)) ? "0" : Number(total_matches).toLocaleString(), 
        is_loading: is_loading_total_matches
      }
    ];

    if (selectedChannel === null) {
      columns.push({
        title: "Verification Speed",
        user: `${product_verification_speed || "0"} per min`,
        is_loading: is_loading_verification_speed
      });
    }

    setProductsColumns(columns);

    // Update qrCodeColumns data
    setQrCodeColumns([
      {
        title: "Visits",
        user: Number(total_number_of_qr_code_visits)?.toLocaleString() || "0",
        is_loading: is_loading_qr_code_states
      },
      {
        title: "Scans",
        user: Number(total_number_of_qr_code_scans)?.toLocaleString() || "0",
        is_loading: is_loading_qr_code_states
      },
    ]);

  }, [
    total_number_of_users,
    total_number_of_vendors,
    total_number_of_channels,
    total_average_score_count,
    total_score_count,
    total_number_of_products,
    total_number_of_products_advertized,
    product_verification_speed,
    total_matches,
    total_number_of_qr_code_visits,
    total_number_of_qr_code_scans,
    is_loading_total_users,
    is_loading_total_vendors,
    is_loading_total_channels,
    is_loading_review_states,
    is_loading_total_products,
    is_loading_unique_matches,
    is_loading_total_matches,
    is_loading_verification_speed,
    is_loading_qr_code_states,
    selectedChannel
  ]);


  useEffect(() => {
    setIsSwitchingToChannelStatesMode(false)
    fetchTotalCountStates()
    fetchTotalUserCounts()
    fetchTotalVendorsCounts()
    fetchTotalChannelCounts()
    fetchReviewStates()
    fetchTotalProductsCount()
    fetchTotalUniqueMatches()
    fetchTotalMatches()
    fetchProductVerificationSpeed()
    fetchQRCodeStates()
    fetchTop10Vendors()
  }, [])



  useEffect(() => {

    if (selectedChannel) {
      if (!hasSelectedChannelBefore) {
        setIsSwitchingToChannelStatesMode(true);
        setHasSelectedChannelBefore(true);
      }
      fetchTotalVendorsCounts(selectedChannel);
      fetchReviewStates(selectedChannel);
      fetchQRCodeStates(selectedChannel);
      fetchTotalMatches(selectedChannel);
      fetchTotalUniqueMatches(selectedChannel);
      fetchTotalProductsCount(selectedChannel);
      fetchTop10Vendors(selectedChannel);
    } else {
      if (hasSelectedChannelBefore) {
        setIsSwitchingToChannelStatesMode(true);
      }
      fetchTotalCountStates();
      fetchTotalUserCounts();
      fetchTotalVendorsCounts();
      fetchTotalChannelCounts();
      fetchReviewStates();
      fetchTotalProductsCount();
      fetchTotalUniqueMatches();
      fetchTotalMatches();
      fetchProductVerificationSpeed();
      fetchQRCodeStates();
      fetchTop10Vendors();
      setHasSelectedChannelBefore(false);
    }

    const timer = setTimeout(() => {
      setIsSwitchingToChannelStatesMode(false);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timeout on component unmount or when selectedChannel changes
  }, [selectedChannel]);

  useEffect(() => {
    try {
      if (match_details) {
        let vendorsWithMatches;

        if (selectedChannel === null) {
          vendorsWithMatches = match_details.total_top_10_vendors.map(vendor => {
            const totalMatches = vendor.linked_channels.reduce((acc, channel) => {
              const count = parseInt(channel.count, 10);
              return acc + (isNaN(count) ? 0 : count);
            }, 0);
            return { ...vendor, total_matches: totalMatches };
          });
        } else {
          vendorsWithMatches = match_details.total_top_10_vendors.map(vendor => {
            const totalMatches = parseInt(vendor.matches_count, 10);
            return { ...vendor, total_matches: isNaN(totalMatches) ? 0 : totalMatches };
          });
        }

        // Sort vendors by total_matches in descending order
        const sortedVendors = vendorsWithMatches.sort((a, b) => b.total_matches - a.total_matches);

        setTopTenVendorsByMatch(sortedVendors);
      }
    } catch (error) {
      console.error('Error processing match_details:', error);
      // Handle the error as needed, e.g., show a notification or set an error state
    }
  }, [match_details, selectedChannel]);


  useEffect(() => {
    try {
      if (listOfChannelsBasicDetails) {

        let formatted_data_for_drop_down = listOfChannelsBasicDetails?.formatted_data?.map(rec => ({
          label: rec.channel_name,
          value: rec.channel_id
        }));

        // Add 'Average results' as the first entry
        formatted_data_for_drop_down.unshift({
          label: 'Overall statistics',
          value: 'default'
        });

        setListOfChannels(formatted_data_for_drop_down);
      }
    } catch (error) {
      console.error('Error processing listOfChannelsBasicDetails:', error);
      // Handle the error as needed, e.g., show a notification or set an error state
    }
  }, [listOfChannelsBasicDetails]);

  const handleOpenCountDetailModal = () => {
    setDetailsModalTitle('Details of review count')
    setCurrentDetailsFormToRender(<ReviewCountDetails average_results={{
      count: total_score_count,
      score: total_average_score_count
    }
    } />)
    setDetailsModalFooter(false)
    setIsDetailsModalOpen(true)
  }


  const handleOpenVendorCountDetailModal = () => {
    setDetailsModalTitle(`Details of vendor's count`)
    setCurrentDetailsFormToRender(<VendorCountDetails average_results={{
      total: total_number_of_vendors,
    }
    } />)
    setDetailsModalFooter(false)
    setIsDetailsModalOpen(true)
  }


  // Modal
  const handleOkayDetailsModal = (e) => {
    setIsDetailsModalOpen(false)
  }
  const handleCancelDetailsModal = (e) => {
    setIsDetailsModalOpen(false)
  }
  const handleCloseDetailsModal = (e) => {
    setIsDetailsModalOpen(false)
  }



  return <div className={styles.overview_container}>
    <div>
      <Title level={2}>
        {selectedChannel ? `Overview for ${selectedChannel?.children}` : 'Overview'}
      </Title>
      {/* Your component content */}
    </div>
    <div className={styles.filters_container}>
      {/* channel selector */}
      <div className={styles.channel_selector}>
        <CustomDropDown
          defaultValue='default'
          options={listOfChannels}
          onChange={(value, record) => {
            if (value != 'default') {
              setSelectedChannel(record)
              setCurrentChannelRecord(record)
            } else {
              setSelectedChannel(null)
              setCurrentChannelRecord(null);
            }
          }}
        />
      </div>
    </div>

    {
      isSwitchingToChannelStatesMode ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <Spin indicator={<LoadingOutlined spin />} size="large" />
        <div style={{ marginLeft: "10PX" }}> Switching mode ... please wait </div>
      </div> : <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {cards.map((c, index) => {
            return (
              <Col
                key={index}
                xs={24}
                sm={24}
                md={12}
                lg={6}
                xl={6}
                className="mb-24"
              >
                <Card bordered={false} className="criclebox">
                  <div className="number">

                    {/* <div style={{ textAlign: "right" }}>
                    <FaExpand onClick={
                      c.today === 'Reviews' ? handleOpenCountDetailModal
                        : c.today === 'Vendors' ? handleOpenVendorCountDetailModal
                          : () => { }
                    } style={{
                      cursor: 'pointer',
                      visibility: (c.today === 'Reviews' || c.today === 'Vendors') ?
                        'visible' : 'hidden'
                    }} />
                  </div> */}

                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={19}>
                        <span>{c.today}</span>
                        <Title level={3}>
                          {/* {c.title}  */}
                          {c.is_loading ? <Spin indicator={<LoadingOutlined spin />} size="medium" /> : c.title}
                          {
                            c.persent && c.is_loading ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : <small className={c.bnb}>{c.persent}</small>
                          }
                        </Title>
                      </Col>
                      <Col xs={5}>
                        <div >{c.icon}</div>
                      </Col>
                    </Row>

                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row gutter={[24, 0]}>
          <AntCard xl={18}>
            <Echat
              title="Products"
              // sub_title="In this section you we will get all the numbers related to products "
              columns={productsColumns}
            />
          </AntCard>
          <AntCard xl={6}>
            {/* <Echat /> */}
            {/* <img src={ic_products} width={"50%"}/> */}
            <Echat
              title="QR Codes"
              // sub_title="In this section you we will get all the numbers related to QR codes "
              columns={qrCodeColumns}
            />
          </AntCard>
        </Row>
        <Row gutter={[24, 0]}>
          <AntCard xl={24}>
            {
              selectedChannel ?
                <TopChannelVendorsBarChart is_loading={is_loading_top_10_vendors} vendors={topTenVendorsByMatch} /> :
                <TopVendorsBarChart is_loading={is_loading_top_10_vendors} vendors={topTenVendorsByMatch} />
            }
          </AntCard>
          {/* <AntCard xl={12}>
          <TopVendorsBarChart vendors={topTenVendorsByMatch} />
        </AntCard> */}
        </Row>
      </div>
    }


    <div className={styles.configuration_modal_container}>
      <DetailsModal
        width="35%"
        bodyStyle={{ height: '15rem' }}
        title={detailsModalTitle}
        handleClose={handleCloseDetailsModal}
        handleOkay={handleOkayDetailsModal}
        handleCancel={handleCancelDetailsModal}
        okay_button_title='Okay'
        cancel_button_title='Cancel'
        body={currentDetailsFormToRender}
        isModalOpen={isDetailsModalOpen}
        footer={detailsModalFooter}
      />
    </div>

  </div>
}

const mapState = (state) => ({
  is_loading_total_count_states: getIsLoadingTotalCountStates(state),
  is_loading_total_users: getIsLoadingTotalUsersCount(state),
  is_loading_total_vendors: getIsLoadingTotalVendorsCount(state),
  is_loading_total_channels: getIsLoadingTotalChannelCount(state),
  is_loading_review_states: getIsLoadingReviewStates(state),
  is_loading_total_products: getIsLoadingTotalProducts(state),
  is_loading_unique_matches: getIsLoadingUniqueMatches(state),
  is_loading_verification_speed: getIsLoadingVerificationSpeed(state),
  is_loading_qr_code_states: getIsLoadingQRCodeStates(state),
  is_loading_top_10_vendors: getIsLoadingTop10Vendors(state),

  total_number_of_users: getTotalNumberOfUsers(state),
  total_number_of_channels: getTotalNumberOfChannels(state),
  total_number_of_vendors: getTotalNumberOfVendors(state),
  total_number_of_qr_code_scans: getTotalNumberOfQRCodeScans(state),
  total_number_of_qr_code_visits: getTotalNumberOfQRCodeVisits(state),
  total_number_of_products_advertized: getTotalNumberOfProductsAdvertized(state),
  total_number_of_products: getTotalNumberOfProducts(state),
  total_score_count: getTotalScoreCount(state),
  total_average_score_count: getTotalAverageScoreCount(state),
  product_verification_speed: getProductVerificationSpeed(state),
  match_details: getMatchDetails(state),
  total_matches: getTotalMatches(state),
  listOfChannelsBasicDetails: getListOfChannelsWithOnlyCoreDetails(state),
  isLoadingListOfChannels: getIsLoadingListOfChannelsWithOnlyCoreDetails(state),

})

const mapDispatchToProps = (dispatch) => ({
  fetchTotalCountStates: (data) => dispatch(fetchTotalCountStates(data)),
  fetchTotalUserCounts: (data) => dispatch(fetchTotalUserCounts(data)),
  fetchTotalVendorsCounts: (data) => dispatch(fetchTotalVendorsCounts(data)),
  fetchTotalChannelCounts: (data) => dispatch(fetchTotalChannelCounts(data)),
  fetchReviewStates: (data) => dispatch(fetchReviewStates(data)),
  fetchTotalProductsCount: (data) => dispatch(fetchTotalProductsCount(data)),
  fetchTotalUniqueMatches: (data) => dispatch(fetchTotalUniqueMatches(data)),
  fetchTotalMatches: (data) => dispatch(fetchTotalMatches(data)),
  fetchProductVerificationSpeed: (data) => dispatch(fetchProductVerificationSpeed(data)),
  fetchQRCodeStates: (data) => dispatch(fetchQRCodeStates(data)),
  fetchTop10Vendors: (data) => dispatch(fetchTop10Vendors(data)),
  setCurrentChannelRecord: (data) => dispatch(setCurrentChannelRecord(data)),
});


export default connect(mapState, mapDispatchToProps)(OverviewNewScreen)