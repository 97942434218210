import { connect } from "react-redux";
import styles from '../styles.module.scss';
import TabsRender from "./general-components/TabsRender";
import { useEffect, useState } from "react";
import Header from "./general-components/Header";
import DetailsModal from "./general-components/DetailsModal";
import BasicDetails from "./profile-components/BasicDetails";
import BankDetails from "./profile-components/BankDetails";

const ProfileScreen = ({ ...props }) => {
    const [listOfTabs, setListOfTabs] = useState([]);

    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [detailsModalTitle, setDetailsModalTitle] = useState("User details");
    const [currentDetailsFormToRender, setCurrentDetailsFormToRender] = useState(null);
    const [detailsModalFooter, setDetailsModalFooter] = useState(null);

    useEffect(() => {
        let temp_tabs = [
            {
                label: "Basic Details",
                screen: <BasicDetails
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                    setDetailsModalFooter={setDetailsModalFooter}
                />
            },
            {
                label: "Bank Details",
                screen: <BankDetails
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                    setDetailsModalFooter={setDetailsModalFooter}
                />
            },
        ];
        setListOfTabs(temp_tabs);
    }, []);

    const handleTabOnChange = (e) => { };

    // Modal
    const handleOkayDetailsModal = (e) => {
        setIsDetailsModalOpen(false);
    };
    const handleCancelDetailsModal = (e) => {
        setIsDetailsModalOpen(false);
    };
    const handleCloseDetailsModal = (e) => {
        setIsDetailsModalOpen(false);
    };

    return (
        <div className={styles.profile_container}>
            <div className={styles.header_container}>
                <Header title='Profile' />
            </div>
            <div className={styles.body_container}>
                <div className={styles.tabs_container}>
                    <TabsRender show_tab={true} list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
                </div>
            </div>
            <div className={styles.configuration_modal_container}>
                <DetailsModal
                    title={detailsModalTitle}
                    handleClose={handleCloseDetailsModal}
                    handleOkay={handleOkayDetailsModal}
                    handleCancel={handleCancelDetailsModal}
                    okay_button_title='Okay'
                    cancel_button_title='Cancel'
                    body={currentDetailsFormToRender}
                    isModalOpen={isDetailsModalOpen}
                    footer={detailsModalFooter}
                />
            </div>
        </div>
    );
};

const mapState = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapState, mapDispatchToProps)(ProfileScreen);