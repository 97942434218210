import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import styles from './styles.module.scss';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { FaArrowCircleRight, FaArtstation, FaProductHunt, FaShopify, FaStream, FaUserCircle, FaUsers, FaRegWindowClose } from "react-icons/fa";
import { eraseCookie } from "../../../shared/utils/utils";
import { getCurrentLoggedInUser, getRoute } from "../logic/dashboard/selectors";
import ic_deal from '../../../assets/icons/deal.png'
import { logoutGoogle } from "../../auth/logic/actions";
import { getPermissions } from "../logic/permissions/selectors";

const Side_bar = ({ logoutGoogle, isSidebarOpen, current_logged_in_user, setIsSidebarOpen, currentRoute, permissions }) => {
    const navigation = useNavigate();
    const { sub_screen } = useParams();
    // Helper function to check if the current route is active
    const isActiveRoute = (route) => sub_screen === route;

    // Check if the user has permission to view the Users tab
    const isAllowedToViewUsers = permissions?.VIEW_USERS?.enabled;
    const isAllowedToSetProfile = permissions?.ACCESS_TO_PROFILE_TAB?.enabled;
    const isAllowedToViewCustomers = permissions?.ACCESS_TO_CUSTOMER_TAB?.enabled;

    return (
        <div className={styles.container}>
            <Sidebar width="100%" collapsed={isSidebarOpen}>
                {!isSidebarOpen && (
                    <div className={styles.close_icon} onClick={() => { setIsSidebarOpen(true) }}>
                        <FaRegWindowClose />
                    </div>
                )}

                <div className={styles.heading_container}>
                    {isSidebarOpen ? (
                        <div className={styles.heading_close} onClick={() => setIsSidebarOpen(false)}>
                            {current_logged_in_user?.profile_url ? (
                                <img style={{ borderRadius: "50%" }} width={30} src={current_logged_in_user?.profile_url} />
                            ) : (
                                <FaUserCircle color="#08338A" size={40} />
                            )}
                        </div>
                    ) : (
                        <div className={styles.heading_open}>
                            <div>
                                {current_logged_in_user?.profile_url ? (
                                    <img style={{ borderRadius: "50%" }} width={30} src={current_logged_in_user?.profile_url} />
                                ) : (
                                    <FaUserCircle color="#08338A" size={40} />
                                )}
                            </div>
                            <div>{current_logged_in_user?.display_name}</div>
                        </div>
                    )}
                </div>

                <Menu>
                    <MenuItem
                        style={{ backgroundColor: isActiveRoute('home') ? "#F0F0F0" : "transparent" }}
                        icon={<FaArtstation color="#08338A" />}
                        onClick={() => { navigation("/dashboard/home") }}
                    >
                        Overview
                    </MenuItem>
                    <MenuItem
                        style={{ backgroundColor: isActiveRoute('channels') ? "#F0F0F0" : "transparent" }}
                        icon={<FaStream color="#08338A" />}
                        onClick={() => { navigation("/dashboard/channels") }}
                    >
                        Channels
                    </MenuItem>
                    <MenuItem
                        style={{ backgroundColor: isActiveRoute('vendors') ? "#F0F0F0" : "transparent" }}
                        icon={<FaShopify color="#08338A" />}
                        onClick={() => { navigation("/dashboard/vendors") }}
                    >
                        Vendors
                    </MenuItem>
                    <MenuItem
                        style={{ backgroundColor: isActiveRoute('products') ? "#F0F0F0" : "transparent" }}
                        icon={<FaProductHunt color="#08338A" />}
                        onClick={() => { navigation("/dashboard/products") }}
                    >
                        Products
                    </MenuItem>
                    <MenuItem
                        style={{ backgroundColor: isActiveRoute('deals') ? "#F0F0F0" : "transparent" }}
                        icon={<img src={ic_deal} width={15} />}
                        onClick={() => { navigation("/dashboard/deals") }}
                    >
                        Deals
                    </MenuItem>
                    <MenuItem
                        style={{ backgroundColor: isActiveRoute('people_manager') ? "#F0F0F0" : "transparent" }}
                        icon={<FaUsers color="#08338A" />}
                        onClick={() => { navigation("/dashboard/people_manager") }}
                    >
                        People
                    </MenuItem>
                    {isAllowedToViewUsers && (
                        <MenuItem
                            style={{ backgroundColor: isActiveRoute('users') ? "#F0F0F0" : "transparent" }}
                            icon={<FaUsers color="#08338A" />}
                            onClick={() => { navigation("/dashboard/users") }}
                        >
                            Users
                        </MenuItem>
                    )}
                    {isAllowedToViewCustomers && (
                        <MenuItem
                            style={{ backgroundColor: isActiveRoute('customers') ? "#F0F0F0" : "transparent" }}
                            icon={<FaUsers color="#08338A" />}
                            onClick={() => { navigation("/dashboard/customers") }}
                        >
                            Customers
                        </MenuItem>
                    )}
                    {isAllowedToSetProfile && (
                        <MenuItem
                            style={{ backgroundColor: isActiveRoute('profile') ? "#F0F0F0" : "transparent" }}
                            icon={<FaUsers color="#08338A" />}
                            onClick={() => { navigation("/dashboard/profile") }}
                        >
                            Profile
                        </MenuItem>
                    )}
                    <MenuItem
                        icon={<FaArrowCircleRight color="#08338A" />}
                        onClick={() => {
                            // eraseCookie('authToken');
                            // navigation("/");

                            logoutGoogle()

                            const domain = process.env.REACT_APP_ENV === 'staging' ? '.edgevideo.com' : 'localhost';
                            const path = '/';

                            // Clear the authToken cookie
                            document.cookie = `authToken=; Path=${path}; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;



                            // Redirect to login page or home after logout
                            // window.location.href = process.env.REACT_APP_ENV === 'staging'
                            //     ? 'https://v1.studio.edgevideo.com/'
                            //     : 'http://localhost:3001/';
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </Sidebar>
        </div>
    );
}

const mapState = (state) => ({
    current_logged_in_user: getCurrentLoggedInUser(state),
    currentRoute: getRoute(state),
    permissions: getPermissions(state),
});

const mapDispatchToProps = (dispatch) => ({
    logoutGoogle: (data) => dispatch(logoutGoogle(data)),
});

export default connect(mapState, mapDispatchToProps)(Side_bar);
