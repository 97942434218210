import styles from './styles.module.scss';
import { TextAreaStyleOne, TextFieldStyleOne } from './styled';

// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};

const CustomTextfield = ({
    width = '100%',
    background_color = '#ffffff',
    hoverBorderColor = '#007bff',
    text_color = 'black',
    border_color = '#d9d9d9',
    font_size = '16px',
    unit = 'px',
    border_radius = '4px',
    padding = '10px',
    font_weight = 'normal',

    colorScheme = 'primary', // Prop for color schemes

    onChange = () => { },
    value = 'default text',
    place_holder = 'Enter text',

    pick_passed_font_size = false,
    mobileFontSize,
    tabletFontSize,
    laptopFontSize,
    macbook13FontSize,

    icon = null,
    handleIconClick = () => { console.error('no method provided to handle icon click') },
    type = 'text',
    make_text_area = false,
    number_of_rows = 3,
    ...props
}) => {

    // Color schemes
    const colorSchemes = {
        primary: {
            background_color: '#FAFAFA',
            hoverBorderColor: 'black',
            text_color: 'black',
            border_color: '#FAFAFA',
        },
        secondary: {
            background_color: '#28a745',
            hoverBorderColor: '#218838',
            text_color: 'white',
            border_color: '#28a745',
        },
        danger: {
            background_color: '#dc3545',
            hoverBorderColor: '#c82333',
            text_color: 'white',
            border_color: '#dc3545',
        },
        warning: {
            background_color: '#ffc107',
            hoverBorderColor: '#e0a800',
            text_color: 'black',
            border_color: '#ffc107',
        },
        info: {
            background_color: '#17a2b8',
            hoverBorderColor: '#138496',
            text_color: 'white',
            border_color: '#17a2b8',
        },
        custom: {
            background_color,
            hoverBorderColor,
            text_color,
            border_color,
        }
    };

    // Apply the selected color scheme
    const selectedColors = colorSchemes[colorScheme] || colorSchemes.custom;

    const responsiveFontSizes = getResponsiveFontSizes(font_size, unit);

    const finalMobileFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveFontSizes.mobileFontSize;
    const finalTabletFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveFontSizes.tabletFontSize;
    const finalLaptopFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveFontSizes.laptopFontSize;
    const finalMacbook13FontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveFontSizes.macbook13FontSize;

    return (
        !make_text_area ? <div className={styles.container}>

            <TextFieldStyleOne
                value={value}
                placeholder={place_holder}
                width={width}
                backgroundColor={selectedColors.background_color}
                hoverBorderColor={selectedColors.hoverBorderColor}
                textColor={selectedColors.text_color}
                borderColor={selectedColors.border_color}
                fontSize={font_size}
                fontWeight={font_weight}
                borderRadius={border_radius}
                padding={padding}
                type={type}
                mobileFontSize={finalMobileFontSize}
                tabletFontSize={finalTabletFontSize}
                laptopFontSize={finalLaptopFontSize}
                macbook13FontSize={finalMacbook13FontSize}
                onChange={onChange}
                {...props}
            />
            {
                icon !== null && <div className={styles.icon} onClick={handleIconClick}>
                    {icon}
                </div>
            }
        </div> : <div className={styles.container}>

            <TextAreaStyleOne
                value={value}
                placeholder={place_holder}
                width={width}
                backgroundColor={selectedColors.background_color}
                hoverBorderColor={selectedColors.hoverBorderColor}
                textColor={selectedColors.text_color}
                borderColor={selectedColors.border_color}
                fontSize={font_size}
                fontWeight={font_weight}
                borderRadius={border_radius}
                padding={padding}
                type={type}
                mobileFontSize={finalMobileFontSize}
                tabletFontSize={finalTabletFontSize}
                laptopFontSize={finalLaptopFontSize}
                macbook13FontSize={finalMacbook13FontSize}
                onChange={onChange}
                rows={number_of_rows}
                {...props}
            />

        </div>
    );
};

export { CustomTextfield };
