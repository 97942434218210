import { connect } from "react-redux";
import styles from '../../styles.module.scss'
import { CustomHeading } from "../../../../../shared/ui/heading";
import { FaRegWindowClose } from "react-icons/fa";
import { CustomButton } from "../../../../../shared/ui/button";
import { Modal } from "antd";

const DetailsModal = ({
    handleClose = () => { console.error('no method provided') },
    handleOkay = () => { console.error('no method provided') },
    handleCancel = () => { console.error('no method provided') },

    isModalOpen = false,
    okay_button_title = "Okay",
    cancel_button_title = "Cancel",

    title = 'default title',
    bodyStyle={},
    width = '60%',

    body = null,
    footer = null,
    ...props }) => {
    
    return <div className={styles.general_details_modal_container}>
        <Modal
            bodyStyle={bodyStyle}
            width={width}
            title={title}
            open={isModalOpen}
            closeIcon={null}
            onCancel={handleClose}
            footer={footer == false ? <></> : footer == null ? <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                <CustomButton
                    title={okay_button_title}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleOkay}
                />
                <CustomButton
                    title={cancel_button_title}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    colorScheme="danger"
                    onClick={handleCancel}
                />
            </div> : footer}
        >
            <div className={styles.body_container}  >
            {body}
    </div>
        </Modal >
    </div >


}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapState, mapDispatchToProps)(DetailsModal)