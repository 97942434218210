import { ChannelAPI } from "../../../api";
import * as XLSX from 'xlsx'; // If you haven't already installed it, use: npm install xlsx
import { saveAs } from 'file-saver';
import { setIsLoadingListOfBlacklistViatorTagLinkedToChannel, setIsLoadingListOfCategoriesForChannel, setIsLoadingListOfChannels, setIsLoadingListOfChannelsForExporting, setIsLoadingListOfChannelsWithOnlyCoreDetails, setIsLoadingListOfFacialRecognitionsLinkedToChannel, setIsLoadingMatchCountDivisionForChannel, setIsLoadingReviewDetails, setIsUpdatingBlacklistKeywords, setListOfBlacklistDestinationLinkedToChannel, setListOfBlacklistViatorTagLinkedToChannel, setListOfCategoriesForChannel, setListOfChannelForExporting, setListOfChannels, setListOfChannelsWithOnlyCoreDetails, setListOfFacialRecognitionsLinkedToChannel, setMatchCountDivisionForChannel } from "./slice";
import { setIsLoadingListOfVendors, setListOfVendors } from "../vendor/slice";
import toast from "react-hot-toast";
let abortController_fetchListOfAllChannels = null;  // Declare globally within the file
let abortController_fetchListOfFacialRecognitionsLinkedToChannel = null;  // Declare globally within the file
let abortController_fetchListOfAllVendorsByChannelId = null;  // Declare globally within the file
let abortController_downloadListOfAssociateVendors = null;  // Declare globally within the file
let abortController_fetchListOfBlacklistViatorTagLinkedToChannel = null
let abortController_fetchListOfBlacklistViatorDestinationLinkedToChannel = null
let abortController_fetchMatchCountDivisionsForChannel = null
let abortController_fetchListOfAllChannelsWithOnlyCoreDetails = null
let abortController_fetchReviewDetails = null
let abortController_fetchListOfChannelsToExport = null
let abortController_fetchListOfCategoriesForChannel = null

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';


const fetchMatchCountDivisionsForChannel = (data) => (dispatch) => {
    if (abortController_fetchMatchCountDivisionsForChannel) {
        abortController_fetchMatchCountDivisionsForChannel.abort();
    }

    // Create a new AbortController
    abortController_fetchMatchCountDivisionsForChannel = new AbortController();
    const { signal } = abortController_fetchMatchCountDivisionsForChannel;

    dispatch(setIsLoadingMatchCountDivisionForChannel(true));

    ChannelAPI.getMatchCountDetailsForChannel(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {

                    return {
                        ...record
                    }
                });

                dispatch(setMatchCountDivisionForChannel({
                    formatted_data: formatted_data,
                    totalRecords: response.totalRecords,
                    totalPages: response.totalPages,
                    currentPage: response.currentPage,
                }));
            }
            dispatch(setIsLoadingMatchCountDivisionForChannel(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingMatchCountDivisionForChannel(false));
        });
};

const fetchListOfAllChannels = (data) => (dispatch) => {
    if (abortController_fetchListOfAllChannels) {
        abortController_fetchListOfAllChannels.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllChannels = new AbortController();
    const { signal } = abortController_fetchListOfAllChannels;

    dispatch(setIsLoadingListOfChannels(true))

    ChannelAPI.getAllChannels(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        channel_id: record.channel_id,
                        channelName: record.channel_name,
                        vendors: record.vendor_count,
                        services_enabled: {
                            isW2E: record.is_w2e,
                            isGamified: record.is_gamified,
                            isShopify: record.is_shopping
                        },
                        is_wager: record.is_wager,
                        is_shopping: record.is_shopping,
                        isActive: record.live,
                        gpt_model: record.chat_gpt,
                        epg_url: record.epg_url,
                        stream_hls: record.stream_hls,
                        logo_url: record.logo_url,
                        win_point: record.win_point,
                        lose_point: record.lose_point,
                        show_viator_tickets: record.show_viator_tickets,
                        resolution_time: record.resolution_time,
                        enabled: record.enabled,
                        total_product_count: record.total_product_count,
                        enable_transcript_for_shopping: record.enable_transcript_for_shopping,
                        products_per_ticket: record.products_per_ticket,
                        second_screen: process.env.REACT_APP_ENV === 'prod' ? `https://edgevideo.ai/app?channelId=${record?.channel_id}` : `https://new-edge-video.webflow.io/app?channelId=${record?.channel_id}`,
                        total_match_count: record?.total_match_count,
                        keyword_blacklist:record?.keyword_blacklist
                    }
                })

                dispatch(setListOfChannels({
                    formatted_data: formatted_data,
                    totalRecords: response.totalRecords,
                    totalPages: response.totalPages,
                    currentPage: response.currentPage,
                }))
            }

            dispatch(setIsLoadingListOfChannels(false))

        })
        .catch((error) => {
            console.error('Error fetching channels:', error);
            dispatch(setIsLoadingListOfChannels(false));
            toast.error('Failed to fetch channels. Please try again.');
        });
};

const fetchListOfChannelsToExport = (data) => (dispatch) => {
    if (abortController_fetchListOfChannelsToExport) {
        abortController_fetchListOfChannelsToExport.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfChannelsToExport = new AbortController();
    const { signal } = abortController_fetchListOfChannelsToExport;

    dispatch(setIsLoadingListOfChannelsForExporting(true))

    ChannelAPI.getAllChannelsToExport(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        ...record
                    }
                })

                dispatch(setListOfChannelForExporting(formatted_data))
            }

            dispatch(setIsLoadingListOfChannelsForExporting(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfChannelsForExporting(false))

        })
};

const fetchReviewDetails = (data) => (dispatch) => {
    if (abortController_fetchReviewDetails) {
        abortController_fetchReviewDetails.abort();
    }

    // Create a new AbortController
    abortController_fetchReviewDetails = new AbortController();
    const { signal } = abortController_fetchReviewDetails;

    // Set loading state
    dispatch(setIsLoadingReviewDetails(true));

    // Return the promise
    return ChannelAPI.getReviewDetails(data, signal)
        .then((response) => {

            if (response?.status === 'success') {
                // Reset loading state and resolve with data
                dispatch(setIsLoadingReviewDetails(false));
                return Promise.resolve(response?.data); // Explicit resolve
            }

            // Reset loading state if the status is not 'success'
            dispatch(setIsLoadingReviewDetails(false));
            return Promise.reject(
                new Error('Failed to fetch review details') // Explicit rejection
            );
        })
        .catch((error) => {
            console.error('getReviewDetails:error', error);

            // Reset loading state
            dispatch(setIsLoadingReviewDetails(false));

            return Promise.reject(error); // Propagate error to caller
        });
};

const fetchListOfAllChannelsWithOnlyCoreDetails = (data) => (dispatch) => {
    if (abortController_fetchListOfAllChannelsWithOnlyCoreDetails) {
        abortController_fetchListOfAllChannelsWithOnlyCoreDetails.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllChannelsWithOnlyCoreDetails = new AbortController();
    const { signal } = abortController_fetchListOfAllChannelsWithOnlyCoreDetails;

    dispatch(setIsLoadingListOfChannelsWithOnlyCoreDetails(true))

    ChannelAPI.getAllChannelsWithOnlyCoreDetails(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        ...record
                    }
                })

                dispatch(setListOfChannelsWithOnlyCoreDetails({
                    formatted_data: formatted_data,
                }))
            }

            dispatch(setIsLoadingListOfChannelsWithOnlyCoreDetails(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfChannelsWithOnlyCoreDetails(false))

        })
};

const fetchListOfAllVendorsByChannelId = (data) => (dispatch) => {
    if (abortController_fetchListOfAllVendorsByChannelId) {
        abortController_fetchListOfAllVendorsByChannelId.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllVendorsByChannelId = new AbortController();
    const { signal } = abortController_fetchListOfAllVendorsByChannelId;
    dispatch(setIsLoadingListOfVendors(true))
    ChannelAPI.getAllVendorsBtChannelId(data, signal)
        .then((response) => {

            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        vendor_id: record.id,
                        platform: record.platform,
                        name: record.name,
                        allowedRegions: record.regions,
                        allowedChannels: record.channels,
                        logo_url: record.logo_url,
                        enabled: record?.enabled,
                        domain_url: record.domain_url,
                        enabled_regions: record?.regions?.filter((rec) => rec.enabled).length,
                        disabled_regions: record?.regions?.filter((rec) => !rec.enabled).length,
                        enabled_channels: record?.channels?.filter((rec) => rec.enabled).length,
                        disabled_channels: record?.channels?.filter((rec) => !rec.enabled).length,
                    };
                })

                dispatch(setListOfVendors({
                    formatted_data: formatted_data,
                    totalRecords: response.totalRecords,
                    totalPages: response.totalPages,
                    currentPage: response.currentPage
                }))
            }

            dispatch(setIsLoadingListOfVendors(false))
        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfVendors(false))
        })
};

const downloadListOfAssociateVendors = (data) => (dispatch) => {
    if (abortController_downloadListOfAssociateVendors) {
        abortController_downloadListOfAssociateVendors.abort();
    }

    // Create a new AbortController
    abortController_downloadListOfAssociateVendors = new AbortController();
    const { signal } = abortController_downloadListOfAssociateVendors;

    ChannelAPI.getAllVendorsBtChannelIdWithoutPagination(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        vendor_id: record.id,
                        platform: record.platform,
                        name: record.name,
                        enabled: record?.enabled,
                        domain_url: record.domain_url,
                        total_regions: record?.regions.length,
                        total_channels: record?.channels.length,
                        regions: record.regions.map((r) => r.country_code).join(', '), // Join with a comma and space
                    };
                });

                // Convert the formatted data to a worksheet
                const worksheet = XLSX.utils.json_to_sheet(formatted_data);

                // Create a new workbook and append the worksheet
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Vendors');

                // Generate the Excel file
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const file = new Blob([excelBuffer], { type: EXCEL_TYPE });

                // Save the file using FileSaver
                saveAs(file, data?.channel_id + '_associated_vendors.xlsx');
            }

            // dispatch(setIsLoadingListOfVendors(false));
        })
        .catch((error) => {
            console.error(error);
            // dispatch(setIsLoadingListOfVendors(false));
        });
};

const fetchListOfFacialRecognitionsLinkedToChannel = (data) => (dispatch) => {
    if (abortController_fetchListOfFacialRecognitionsLinkedToChannel) {
        abortController_fetchListOfFacialRecognitionsLinkedToChannel.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfFacialRecognitionsLinkedToChannel = new AbortController();
    const { signal } = abortController_fetchListOfFacialRecognitionsLinkedToChannel;

    dispatch(setIsLoadingListOfFacialRecognitionsLinkedToChannel(true))

    ChannelAPI.getListOfFacialRecognitionsLinkedToChannel(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        id: record.id,
                        name: record.name,
                        dob: record.dob,
                        accomplishments: record.accomplishments,
                        twitter_url: record.twitter_url,
                        instagram_url: record.instagram_url,
                        facebook_url: record.facebook_url,
                        created_date: record.created_date,
                        profile_url: record.image_url,
                        enabled: record.enabled
                    }
                })

                dispatch(setListOfFacialRecognitionsLinkedToChannel({
                    formatted_data: formatted_data,
                    totalRecords: response.totalRecords,
                    totalPages: response.totalPages,
                    currentPage: response.currentPage,
                    extra_page_limit: response.extra_page_limit
                }))
            }

            dispatch(setIsLoadingListOfFacialRecognitionsLinkedToChannel(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfFacialRecognitionsLinkedToChannel(false))

        })
};

const fetchListOfListOfBlacklistViatorTagLinkedToChannel = (data) => (dispatch) => {
    if (abortController_fetchListOfBlacklistViatorTagLinkedToChannel) {
        abortController_fetchListOfBlacklistViatorTagLinkedToChannel.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfBlacklistViatorTagLinkedToChannel = new AbortController();
    const { signal } = abortController_fetchListOfBlacklistViatorTagLinkedToChannel;

    dispatch(setIsLoadingListOfBlacklistViatorTagLinkedToChannel(true))

    ChannelAPI.getListOfBlacklistViatorTagLinkedToChannel(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        tag_id: record.tag_id,
                        name: record.name,
                        enabled: record.enabled
                    }
                })

                dispatch(setListOfBlacklistViatorTagLinkedToChannel({
                    formatted_data: formatted_data,
                    totalRecords: response.totalRecords,
                    totalPages: response.totalPages,
                    currentPage: response.currentPage,
                }))
            }

            dispatch(setIsLoadingListOfBlacklistViatorTagLinkedToChannel(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfBlacklistViatorTagLinkedToChannel(false))

        })
};

const fetchListOfListOfBlacklistViatorDestinationLinkedToChannel = (data) => (dispatch) => {
    if (abortController_fetchListOfBlacklistViatorDestinationLinkedToChannel) {
        abortController_fetchListOfBlacklistViatorDestinationLinkedToChannel.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfBlacklistViatorDestinationLinkedToChannel = new AbortController();
    const { signal } = abortController_fetchListOfBlacklistViatorDestinationLinkedToChannel;

    dispatch(setIsLoadingListOfBlacklistViatorTagLinkedToChannel(true))

    ChannelAPI.getListOfBlacklistDestinationsLinkedToChannel(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        destination_id: record.destination_id,
                        destination_name: record.destination_name,
                        enabled: record.enabled
                    }
                })

                dispatch(setListOfBlacklistDestinationLinkedToChannel({
                    formatted_data: formatted_data,
                    totalRecords: response.totalRecords,
                    totalPages: response.totalPages,
                    currentPage: response.currentPage,
                }))
            }

            dispatch(setIsLoadingListOfBlacklistViatorTagLinkedToChannel(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfBlacklistViatorTagLinkedToChannel(false))

        })
};

const fetchListOfCategoriesForChannel = (data) => (dispatch) => {
    if (abortController_fetchListOfCategoriesForChannel) {
        abortController_fetchListOfCategoriesForChannel.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfCategoriesForChannel = new AbortController();
    const { signal } = abortController_fetchListOfCategoriesForChannel;
    dispatch(setIsLoadingListOfCategoriesForChannel(true));

    ChannelAPI.getChannelCategories(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {

                    return {
                        id: record?.id,
                        name: record?.name,
                        tier: record.tier,
                        parent_id: record.parent_id,
                        enabled: record.enabled
                    };
                });

                dispatch(setListOfCategoriesForChannel({
                    formatted_data: formatted_data,
                    // totalRecords: response.totalRecords,
                    // totalPages: response.totalPages,
                    // currentPage: response.currentPage,
                }));
            }
            dispatch(setIsLoadingListOfCategoriesForChannel(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingListOfCategoriesForChannel(false));
        });
};

const updateChannelCategories = (data) => (dispatch) => {
    // dispatch(setIsUpdatingChannelsForRegions(true));

    const updateCategoriesPromise = ChannelAPI.updateChannelCategories(data);

    toast.promise(updateCategoriesPromise, {
        loading: "Updating Categories ...",
        success: "Successfully updated category  status",
        error: (error) => `Error updating Categories : ${error.message}`,
    });

    return updateCategoriesPromise
        .then((response) => {
            dispatch(fetchListOfCategoriesForChannel(data));
            // dispatch(fetchListOfChannelsForVendor({ vendor_id: data.vendor_id, page: data?.currentPage }));
            // dispatch(setIsUpdatingChannelsForRegions(false));
            return response;
        })
        .catch((error) => {
            console.error(error);
            // dispatch(setIsUpdatingChannelsForRegions(false));
            return Promise.reject(error);
        });
};

const updateVendorStatus = (data) => (dispatch) => {
    const updatePromise = ChannelAPI.updateVendorStatus(data);

    toast.promise(
        updatePromise, // Promise to track
        {
            loading: data.checked ? 'Enabling..' : 'Disabling..', // Loading message
            success: data.checked ? 'Enabled' : 'Disabled', // Success message
            error: 'Error updating vendor status.' // Error message
        }
    );

    // Handle the resolved promise
    updatePromise
        .then((response) => {
            dispatch(fetchListOfAllChannels(data.listApiParams));
            // dispatch(fetchListOfAllVendorsByChannelId({
            //     channel_id: data?.channel_id,
            //     page: data.currentPage,
            //     keyword: data.searchTerm
            // }));
        })
        .catch((error) => {
            console.error(error); // The error message will be shown automatically by the toast.promise
        });
};

const updateViatorStatus = (data) => (dispatch) => {
    const updatePromise = ChannelAPI.updateViatorStatus(data);

    toast.promise(
        updatePromise, // Promise to track
        {
            loading: data.show_viator_tickets ? 'Enabling..' : 'Disabling..', // Loading message
            success: data.show_viator_tickets ? 'Enabled' : 'Disabled', // Success message
            error: 'Error updating vendor status.' // Error message
        }
    );

    // Handle the resolved promise
    return updatePromise
        .then((response) => {

            dispatch(fetchListOfAllChannels(data.listApiParams));
        })
        .catch((error) => {
            console.error(error); // The error message will be shown automatically by the toast.promise
        });
};

const updateBlacklistKeywordsList = (data) => (dispatch) => {
    const updatePromise = ChannelAPI.updateBlacklistKeywordsList(data);
    dispatch(setIsUpdatingBlacklistKeywords(true))
    toast.promise(
        updatePromise, // Promise to track
        {
            loading: data.show_viator_tickets ? 'Updating list..' : 'Updating list..', // Loading message
            success: data.show_viator_tickets ? 'Updated list' : 'Updated list', // Success message
            error: 'Error updating blacklist keyword.' // Error message
        }
    );

    // Handle the resolved promise
    return updatePromise
        .then((response) => {

            dispatch(setIsUpdatingBlacklistKeywords(false))

            // dispatch(fetchListOfAllChannels(data.listApiParams));
        })
        .catch((error) => {

            dispatch(setIsUpdatingBlacklistKeywords(false))

            console.error(error); // The error message will be shown automatically by the toast.promise
        });
};

const updateVendorStatusInBulk = (data) => (dispatch) => {
    const updatePromise = ChannelAPI.updateVendorStatusInBulk(data);

    toast.promise(
        updatePromise, // Promise to track
        {
            loading: data.checked ? 'Enabling all..' : 'Disabling all..', // Loading message
            success: data.checked ? 'Enabled all' : 'Disabled all', // Success message
            error: 'Error updating vendor status.' // Error message
        }
    );

    // Handle the resolved promise
    updatePromise
        .then((response) => {
            dispatch(fetchListOfAllChannels(data.listApiParams));
            // dispatch(fetchListOfAllVendorsByChannelId({
            //     channel_id: data?.channel_id,
            //     page: data.currentPage,
            //     keyword: data.searchTerm
            // }));
        })
        .catch((error) => {
            console.error(error); // The error message will be shown automatically by the toast.promise
        });
};

const updateFacialRecognitionStatus = (data) => (dispatch) => {
    const updatePromise = ChannelAPI.updateFacialRecognitionStatus(data);

    toast.promise(
        updatePromise, // Promise to track
        {
            loading: data.enabled ? 'Enabling..' : 'Disabling..', // Loading message
            success: data.enabled ? 'Enabled' : 'Disabled', // Success message
            error: 'Error updating facial recognition status.' // Error message
        }
    );

    // Handle the resolved promise
    updatePromise
        .then((response) => {
            // If needed, additional actions or state updates can be dispatched here
        })
        .catch((error) => {
            console.error(error); // Error message is already handled by toast.promise
        });
};


const updateBlacklistViatorTagStatus = (data) => (dispatch) => {
    const updatePromise = ChannelAPI.updateBlacklistViatorTagStatus(data);

    toast.promise(
        updatePromise, // Promise to track
        {
            loading: !data.checked ? 'Allowing..' : 'Blocking..', // Loading message
            success: !data.checked ? 'Allowed' : 'Blocked', // Success message
            error: 'Error updating black list viator tag status.' // Error message
        }
    );

    // Handle the resolved promise
    updatePromise
        .then((response) => {
            // If needed, additional actions or state updates can be dispatched here
        })
        .catch((error) => {
            console.error(error); // Error message is already handled by toast.promise
        });
};

const updateBlacklistViatorDestinationStatus = (data) => (dispatch) => {
    const updatePromise = ChannelAPI.updateBlacklistDestinationStatus(data);

    toast.promise(
        updatePromise, // Promise to track
        {
            loading: !data.checked ? 'Allowing..' : 'Blocking..', // Loading message
            success: !data.checked ? 'Allowed' : 'Blocked', // Success message
            error: 'Error updating black list viator destination status.' // Error message
        }
    );

    // Handle the resolved promise
    updatePromise
        .then((response) => {
            // If needed, additional actions or state updates can be dispatched here

        })
        .catch((error) => {
            console.error(error); // Error message is already handled by toast.promise
        });
};

const updateChannelServiceStatus = (data) => (dispatch) => {
    const updatePromise = ChannelAPI.updateChannelServiceStatus(data);

    toast.promise(updatePromise, {
        loading: "Updating service status...",
        success: "Status updated successfully!",
        error: "Error updating status."
    });

    updatePromise
        .then((response) => {
            // dispatch(fetchListOfAllChannels({ keyword: data.keyword, page:data.page, limit: data.limit }));
        })
        .catch((error) => {
            console.error(error); // Error message is already shown by toast.promise
        });
};


const addNewChannel = (data) => (dispatch) => {
    // Create a promise for adding a new channel
    const addChannelPromise = ChannelAPI.addNewChannel(data);

    // Use toast.promise to show loading and success/error messages
    toast.promise(addChannelPromise, {
        loading: "Adding new channel...",
        success: "New channel added successfully!",
        error: "Error adding channel."
    });

    // Return the promise for further chaining if needed
    return addChannelPromise
        .then((response) => {
            dispatch(fetchListOfAllChannels()); // Dispatch action to fetch updated channel list
            return response; // Return response for chaining
        })
        .catch((error) => {
            console.error(error); // Error message is handled by toast.promise
            throw error; // Rethrow error for further handling if needed
        });
};


const updateChannelDetails = (data) => (dispatch) => {
    // Create a promise for updating channel details
    const updatePromise = ChannelAPI.updateChannelDetails(data);

    // Use toast.promise to show loading and success/error messages
    toast.promise(updatePromise, {
        loading: "Updating channel...",
        success: "Channel updated successfully!",
        error: "Error updating channel."
    });

    // Return the promise for further chaining if needed
    return updatePromise
        .then(() => {
            dispatch(fetchListOfAllChannels()); // Dispatch action to fetch updated channel list
        })
        .catch((error) => {
            console.error(error); // Error is handled by toast.promise
            throw error; // Rethrow error for further handling if needed
        });
};



export {
    fetchListOfAllChannels,
    updateVendorStatus,
    updateChannelServiceStatus,
    addNewChannel,
    updateChannelDetails,
    fetchListOfFacialRecognitionsLinkedToChannel,
    updateFacialRecognitionStatus,
    fetchListOfAllVendorsByChannelId,
    downloadListOfAssociateVendors,
    fetchListOfListOfBlacklistViatorTagLinkedToChannel,
    updateBlacklistViatorTagStatus,
    updateBlacklistViatorDestinationStatus,
    fetchListOfListOfBlacklistViatorDestinationLinkedToChannel,
    fetchMatchCountDivisionsForChannel,
    fetchListOfAllChannelsWithOnlyCoreDetails,
    fetchReviewDetails,
    fetchListOfChannelsToExport,
    updateVendorStatusInBulk,
    fetchListOfCategoriesForChannel,
    updateChannelCategories,
    updateViatorStatus,
    updateBlacklistKeywordsList
}