import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from '../styles.module.scss'
import { CustomHeading } from "../../../../shared/ui/heading";
import Header from "./general-components/Header";
import { CustomStatesCard } from "../../../../shared/ui/states-card";
import { FaAd, FaCartPlus, FaQrcode, FaShopify, FaStream, FaUsers } from "react-icons/fa";
import { getIsLoadingTotalCountStates, getProductVerificationSpeed, getTotalAverageScoreCount, getTotalNumberOfChannels, getTotalNumberOfProducts, getTotalNumberOfProductsAdvertized, getTotalNumberOfQRCodeScans, getTotalNumberOfQRCodeVisits, getTotalNumberOfUsers, getTotalNumberOfVendors, getTotalScoreCount } from "../../logic/dashboard/selectors";
import { fetchTotalCountStates } from "../../logic/dashboard/actions";
import ic_speed_meter from '../../../../assets/icons/speedometer-removebg-preview.png'
import ic_score from '../../../../assets/icons/rating.png'
import ic_average_score from '../../../../assets/icons/average.png'

const OverviewScreen = ({
  is_loading_total_count_states,
  total_number_of_users,
  total_number_of_channels,
  total_number_of_vendors,
  total_number_of_qr_code_scans,
  total_number_of_qr_code_visits,
  total_number_of_products_advertized,
  total_number_of_products,
  product_verification_speed,
  fetchTotalCountStates,
  total_score_count,
  total_average_score_count,
  ...props }) => {
  useEffect(() => {
    fetchTotalCountStates()
  }, [])
  return <div className={styles.overview_container}>
    <div className={styles.header_container}>
      <Header title='Overview' />
    </div>
    <div className={styles.body_container}>
      <div className={styles.top_row}>
        <CustomStatesCard
          is_loading={is_loading_total_count_states}
          title="Total Users"
          font_size="1"
          valueFontSize="1.4"
          unit="rem"
          value={Number(total_number_of_users).toLocaleString()}
          cardWidth="230px"
          cardHeight="100px"
          icon={<FaUsers size={20} />} />
        <CustomStatesCard
          is_loading={is_loading_total_count_states}

          title="Total Channels"
          font_size="1"
          valueFontSize="1.4"
          unit="rem"
          value={total_number_of_channels}
          cardWidth="230px"
          cardHeight="100px"
          icon={<FaStream size={20} />} />
        <CustomStatesCard
          is_loading={is_loading_total_count_states}

          title="Total Vendors"
          font_size="1"
          valueFontSize="1.4"
          unit="rem"
          value={Number(total_number_of_vendors).toLocaleString()}
          cardWidth="230px"
          cardHeight="100px"
          icon={<FaShopify size={20} />} />
        <CustomStatesCard
          is_loading={is_loading_total_count_states}

          title="Total Advertized Products"
          font_size="1"
          valueFontSize="1.4"
          unit="rem"
          value={Number(total_number_of_products_advertized).toLocaleString()}
          cardWidth="230px"
          cardHeight="100px"
          icon={<FaAd size={20} />} />
        <CustomStatesCard
          is_loading={is_loading_total_count_states}

          title="Total Products"
          font_size="1"
          valueFontSize="1.4"
          unit="rem"
          value={Number(total_number_of_products).toLocaleString()}
          cardWidth="230px"
          cardHeight="100px"
          icon={<FaCartPlus size={20} />} />
        <CustomStatesCard
          is_loading={is_loading_total_count_states}
          title="Product Verification Speed"
          font_size="0.8"
          valueFontSize="1"
          unit="rem"
          value={product_verification_speed + ' per min'}
          cardWidth="230px"
          cardHeight="100px"
          icon={<img src={ic_speed_meter} width={'35%'} />} />
        <CustomStatesCard
          is_loading={is_loading_total_count_states}
          title="Match Review Count "
          font_size="0.8"
          valueFontSize="1"
          unit="rem"
          value={total_score_count}
          cardWidth="230px"
          cardHeight="100px"
          icon={<img src={ic_score} width={'35%'} />} />
        <CustomStatesCard
          is_loading={is_loading_total_count_states}
          title="Average Match Review"
          font_size="0.8"
          valueFontSize="1"
          unit="rem"
          value={total_average_score_count.toFixed(2)}
          cardWidth="230px"
          cardHeight="100px"
          icon={<img src={ic_average_score} width={'35%'} />} />
        <CustomStatesCard
          is_loading={is_loading_total_count_states}

          title="QR Code Visits"
          font_size="1"
          valueFontSize="1.5"
          unit="rem"
          value={Number(total_number_of_qr_code_visits).toLocaleString()}
          cardWidth="230px"
          cardHeight="100px"
          icon={<FaQrcode size={20} />} />
        <CustomStatesCard
          is_loading={is_loading_total_count_states}

          title="QR Code Scans"
          font_size="1"
          valueFontSize="1.4"
          unit="rem"
          value={Number(total_number_of_qr_code_scans).toLocaleString()}
          cardWidth="230px"
          cardHeight="100px"
          icon={<FaQrcode size={20} />} />


      </div>


    </div>
  </div>
}

const mapState = (state) => ({
  is_loading_total_count_states: getIsLoadingTotalCountStates(state),
  total_number_of_users: getTotalNumberOfUsers(state),
  total_number_of_channels: getTotalNumberOfChannels(state),
  total_number_of_vendors: getTotalNumberOfVendors(state),
  total_number_of_qr_code_scans: getTotalNumberOfQRCodeScans(state),
  total_number_of_qr_code_visits: getTotalNumberOfQRCodeVisits(state),
  total_number_of_products_advertized: getTotalNumberOfProductsAdvertized(state),
  total_number_of_products: getTotalNumberOfProducts(state),
  total_score_count: getTotalScoreCount(state),
  total_average_score_count: getTotalAverageScoreCount(state),
  product_verification_speed: getProductVerificationSpeed(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchTotalCountStates: (data) => dispatch(fetchTotalCountStates(data)),
});


export default connect(mapState, mapDispatchToProps)(OverviewScreen)