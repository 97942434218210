import { connect } from "react-redux";
import styles from '../../styles.module.scss';
import { Table, Button } from "antd";
import { CustomButton } from "../../../../../shared/ui/button";
import { FaEdit, FaFile, FaFileExcel, FaFilter, FaSearch } from "react-icons/fa";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { useState } from "react";
import VendorDetailsForm from "./VendorDetailsForm";
import { data, data_for_pool } from "./dummy-data";
import { CustomDropDown } from "../../../../../shared/ui/dropdown";
import NewVendorPoolRecordDataForm from "./NewVendorPoolRecordDataForm";



const VendorIndepthViewTab = ({
    setCurrentDetailsFormToRender,
    setIsDetailsModalOpen,
    setDetailsModalTitle,
    setCurrentSidebarFormToRender,
    setSideBarTitle,
    setIsVendorConfigurationSidebarOpen,
    ...props }) => {
    const [searchTerm, setSearchTerm] = useState("");
    // Define the columns structure
    const columns = [
        {
            title: 'Vendor Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: 'Type Of Product',
            dataIndex: 'type_of_products',
            key: 'type_of_products',
            align: 'center',
        },
        {
            title: 'No. Of products',
            dataIndex: 'total_number_of_products',
            key: 'total_number_of_products',
            align: 'center',
        },
        {
            title: 'No. Of approved products',
            dataIndex: 'total_number_of_products',
            key: 'total_number_of_products',
            align: 'center',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                    <CustomButton
                        title="Edit"
                        width="100%"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        onClick={() => handleEditRecord(record)}
                        icon={<FaEdit size={15} />}
                    />
                    {/* <CustomButton
                        title="Details"
                        width="100%"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        colorScheme="secondary"
                        icon={<FaSearch size={15} />}
                        onClick={() => handleViewDetails(record)}
                    /> */}
                    <CustomButton
                        title="Delete"
                        width="100%"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        colorScheme="danger"
                        icon={<FaFileExcel size={15} />}
                        onClick={() => handleDeleteRecord(record)}
                    />
                </div>
            ),
        },
    ];

    const handleViewDetails = (record) => {
        // Implement view details logic here, such as navigation or modal display
        setCurrentDetailsFormToRender(<VendorDetailsForm record={record} />)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Vendor details")
    };

    const handleEditRecord = (record) => {
        // Implement edit logic here, such as navigation or modal display
    };

    const handleDeleteRecord = (record) => {
        // Implement delete logic here, such as navigation or modal display
    };

    return (
        <div className={styles.vendor_indepth_view_tab_container}>
            <Table
                columns={columns}
                dataSource={data_for_pool}
                bordered
                pagination={{ pageSize: 10,showSizeChanger: false }}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.left_side}>
                            <div className={styles.search_bar}>
                                <CustomTextfield
                                    border_radius="10px"
                                    icon={<FaSearch size={15} />}
                                    colorScheme="primary"
                                    place_holder="Search i.e  vendor name "
                                    font_size="0.7"
                                    unit="rem"
                                    padding="10px"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div className={styles.platform_dropdown}>

                                <CustomDropDown
                                    defaultValue='default'
                                    options={[
                                        { value: 'default', label: 'Select Platform' },
                                        { value: 'trade_tracker', label: 'TradeTracker' },
                                        { value: 'awin', label: 'Awin' },
                                        { value: 'daisycon', label: 'Daisycon' },

                                    ]} />
                            </div>
                            <div className={styles.region_dropdown}>
                                <CustomDropDown
                                    defaultValue='default'
                                    options={[
                                        { value: 'default', label: 'Select Region' },
                                        { value: 'poland', label: 'Poland' },
                                        { value: 'uk', label: 'UK' },
                                        { value: 'usa', label: 'USA' },
                                        { value: 'france', label: 'france' },
                                    ]} />
                            </div>
                            <div className={styles.region_dropdown}>
                                <CustomDropDown

                                    defaultValue='default'
                                    options={[
                                        { value: 'default', label: 'Select status' },
                                        { value: 'applied', label: 'Applied' },
                                        { value: 'approved', label: 'approved' },
                                        { value: 'rejected', label: 'rejected' },
                                    ]} />
                            </div>
                            <div className={styles.region_dropdown}>

                                <CustomDropDown

                                    defaultValue='default'
                                    options={[
                                        { value: 'default', label: 'Select Catagory' },
                                        { value: 'cloths', label: 'Cloths' },
                                        { value: 'shoes', label: 'Shoes' },
                                        { value: 'vehicles', label: 'Vehicles' },
                                    ]} />
                            </div>
                            <div className={styles.apply_filter_button}>
                                <CustomButton
                                    title="Apply filter"
                                    width="100%"
                                    font_size="0.8"
                                    unit="rem"
                                    colorScheme="secondary"
                                    padding="5px 10px 0px 10px"
                                    icon={<FaFilter size={16} />}
                                    onClick={(e) => { }}
                                />
                            </div>
                        </div>
                        <div className={styles.right_side}>
                            <div className={styles.export_button}>
                                <CustomButton
                                    title="Export"
                                    width="100%"
                                    font_size="0.8"
                                    unit="rem"
                                    colorScheme="secondary"

                                    padding="5px 10px 0px 10px"
                                    icon={<FaFileExcel />}
                                    onClick={(e) => { }}
                                />
                            </div>
                            <div className={styles.add_record_button}>
                                <CustomButton
                                    title="Add Record"
                                    width="100%"
                                    font_size="0.8"
                                    unit="rem"
                                    colorScheme="secondary"
                                    padding="5px 10px 0px 10px"
                                    icon={<FaFile />}
                                    onClick={(e) => {
                                        setSideBarTitle("Add New Record")
                                        setCurrentSidebarFormToRender(<NewVendorPoolRecordDataForm />)
                                        setIsVendorConfigurationSidebarOpen(true)
                                        
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

const mapState = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapState, mapDispatchToProps)(VendorIndepthViewTab);
