import { connect } from "react-redux";
import styles from '../styles.module.scss'
import TabsRender from "./general-components/TabsRender";
import EAT_FASTSwapTab from "./fast-components/EAT_FASTSwapTab";
import FASTVisualizationTab from "./fast-components/FASTVisualizationTab";
import { useEffect, useState } from "react";
import Header from "./general-components/Header";
import FAST_tx_polygon_Tab from "./fast-components/FAST_tx_polygon_Tab";
import EAT_FAST_TX_OverviewTab from "./fast-components/EAT_FAST_TX_OverviewTab";

import ProductsVisualization from "./products-components/ProductsVisualization";
import ProductsOverview from "./products-components/ProductsOverview";
import DetailsModal from "./general-components/DetailsModal";
import DealsOverview from "./deals-components/DealsOverview";

const DealsScreen = ({ ...props }) => {
    const [listOfTabs, setListOfTabs] = useState([]);

    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
    const [detailsModalTitle, setDetailsModalTitle] = useState("Vender details")
    const [currentDetailsFormToRender, setCurrentDetailsFormToRender] = useState(null)
    const [detailsModalFooter, setDetailsModalFooter] = useState(null)

    useEffect(() => {
        let temp_tabs = [
            {
                label: "Deals",
                screen: <DealsOverview
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                    setDetailsModalFooter={setDetailsModalFooter}
                />
            },
            // {
            //     label: "Visualization",
            //     screen: <ProductsVisualization />
            // }
        ]
        setListOfTabs(temp_tabs)
    }, [])

    const handleTabOnChange = (e) => { }

    // Modal
    const handleOkayDetailsModal = (e) => {
        setIsDetailsModalOpen(false)
    }
    const handleCancelDetailsModal = (e) => {
        setIsDetailsModalOpen(false)
    }
    const handleCloseDetailsModal = (e) => {
        setIsDetailsModalOpen(false)
    }

    return <div className={styles.deals_container}>
        <div className={styles.header_container}>
            <Header title='Deals ' />
        </div>
        <div className={styles.body_container}>
            <div className={styles.tabs_container}>
                <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
            </div>
        </div>
        <div className={styles.configuration_modal_container}>
            <DetailsModal
                title={detailsModalTitle}
                handleClose={handleCloseDetailsModal}
                handleOkay={handleOkayDetailsModal}
                handleCancel={handleCancelDetailsModal}
                okay_button_title='Okay'
                cancel_button_title='Cancel'
                body={currentDetailsFormToRender}
                isModalOpen={isDetailsModalOpen}
                footer={detailsModalFooter}
            />
        </div>

    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapState, mapDispatchToProps)(DealsScreen)