import React, { useState } from "react";
import { connect } from "react-redux";
import { Table, Checkbox } from 'antd';
import styles from './modal.module.scss';
import { CustomHeading } from "../../../../../shared/ui/heading";

const VendorChannelConfigurationForm = ({ ...props }) => {
    // State to manage the selected checkboxes
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    // Sample data for the table
    const channelsData = [
        { key: '1', channel_name: 'Sports Channel' },
        { key: '2', channel_name: 'News Channel' },
        { key: '3', channel_name: 'Music Channel' },
        { key: '4', channel_name: 'Movies Channel' },
        { key: '5', channel_name: 'Kids Channel' },
        // Add more channels as needed
    ];

    // Define the columns for the table
    const columns = [
        {
            title: (
                <Checkbox
                    indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < channelsData.length}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSelectedRowKeys(channelsData.map((item) => item.key));
                        } else {
                            setSelectedRowKeys([]);
                        }
                    }}
                />
            ),
            dataIndex: 'checkbox',
            key: 'checkbox',
            render: (text, record) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.key)}
                    onChange={(e) => {
                        const newSelectedRowKeys = e.target.checked
                            ? [...selectedRowKeys, record.key]
                            : selectedRowKeys.filter((key) => key !== record.key);
                        setSelectedRowKeys(newSelectedRowKeys);
                    }}
                />
            ),
            width: '20%',
        },
        {
            title: 'Serial Number',
            dataIndex: 'key',
            key: 'serial',
            render: (text, record, index) => index + 1,
            width: '20%',
        },
        {
            title: 'Channel Name',
            dataIndex: 'channel_name',
            key: 'channel_name',
            width: '60%',
        },

    ];

    return (
        <div className={styles.vendors_channel_configuration_form_container}>
            <div className={styles.header}>
                <CustomHeading font_size="0.8" unit="rem" headingText="Select the channels which will be advertizing products by this vendor" />
            </div>

            <Table
                columns={columns}
                dataSource={channelsData}
                pagination={{ pageSize: 5,showSizeChanger: false }} // Optional: Set pagination if needed
                rowKey="key"
                bordered
                style={{ width: '100%' }}
            />
        </div>
    );
};

const mapState = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapState, mapDispatchToProps)(VendorChannelConfigurationForm);
