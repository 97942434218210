export const getListOfFacialRecognitions = (state) => state.product?.listOfFacialRecognitions
export const getListOfListOfAllProducts = (state) => state.product?.listOfAllProducts
export const getListOfAllSelectedProducts = (state) => state.product?.listOfAllSelectedProducts
export const getIsLoadingListOfAllProducts = (state) => state.product?.isLoadingListOfAllProducts
export const getIsLoadingListOfFacialRecognitions = (state) => state.product?.isLoadingListOfFacialRecognitions
export const getListOfAllProductsForOverview = (state) => state.product?.listOfAllProductsForOverview
export const getIsLoadingListOfAllProductsForOverview = (state) => state.product?.isLoadingListOfAllProductsForOverview
export const getCurrentSelectedProduct = (state) => state.product?.currentSelectedProduct
export const getListOfProductsToExport = (state) => state.product?.listOfProductsToExport
export const getIsLoadingListOfProductsToExport = (state) => state.product?.isLoadingListOfProductsToExport

export const getFormsRefresher = (state) => state.product?.refreshProductForms
export const getQueryParams = (state) => state.product?.queryParams

