import { connect } from "react-redux";
import styles from '../../styles.module.scss';
import { CustomHeading } from "../../../../../shared/ui/heading";
import { useState, useRef, useEffect, useCallback } from "react";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { Button, Form, Switch, Upload } from "antd";
import ic_filter_by_cat from '../../../../../assets/icons/filter_cat.png'
import { FaUpload } from "react-icons/fa";
import { addNewChannel, updateChannelDetails } from "../../../logic/channels/actions";
import { CustomButton } from "../../../../../shared/ui/button";
import ic_delete from '../../../../../assets/icons/delete.png'
import { getCurrentSelectedChannelRecord } from "../../../logic/channels/selectors";
import { setCurrentChannelRecord } from "../../../logic/channels/slice";
import toast from "react-hot-toast";
import { isValidHlsStream } from "../../../../../shared/utils/utils";
import ListOfCategoriesToAttach from "./ListOfCategoriesToAttach";

const ChannelDataForm = ({
    setIsChannelConfigurationSidebarOpen,
    setChannelConfigurationSidebarFooter,
    record,
    addNewChannel,
    is_this_for_update = false,
    updateChannelDetails,
    setCurrentChannelRecord,
    setCurrentDetailsFormToRender,
    setIsDetailsModalOpen,
    setDetailsModalFooter,
    setDetailsModalTitle,
    ...props
}) => {
    const [listOfSelectedCategories, setListOfSelectedCategories] = useState([])

    const [channelName, setChannelName] = useState("");
    const [hlsStream, setHlsStream] = useState("");
    const [epgUrl, setEpgUrl] = useState("");
    const [isGamificationEnabled, setIsGamificationEnabled] = useState(false);
    const [isW2EEnabled, setIsW2EEnabled] = useState(false);
    const [isWagerEnabled, setIsWagerEnabled] = useState(false);
    const [isShowViatorTicketEnabled, setIsShowViatorTicketEnabled] = useState(false);

    const [isTranscriptEnabledForShopping, setIsTranscriptEnabledForShopping] = useState(true);

    const [isShoppingEnabled, setIsShoppingEnabled] = useState(false);

    const [hasChanges, setHasChanges] = useState(null);

    const [isLive, setIsLive] = useState(false);
    const [winPoints, setWinPoints] = useState('3');
    const [loosePoints, setLoosePoints] = useState('1');
    const [resolutionTime, setResolutionTime] = useState('30');
    const [productsPerTicket, setProductsPerTicket] = useState('10');
    const [isInitialized, setIsInitialized] = useState(false);
    const [logoFile, setLogoFile] = useState(null); // New state for the logo file
    const [existingLogoFile, setExistingLogoFile] = useState(null)
    const [fileList, setFileList] = useState([]);

    // const [promptType, setPromptType] = useState('');
    // const [prompt, setPrompt] = useState('');

    // Create refs for the state variables
    const channelNameRef = useRef(channelName);
    const hlsStreamRef = useRef(hlsStream);
    const epgUrlRef = useRef(epgUrl);
    const isGamificationEnabledRef = useRef(isGamificationEnabled);
    const isW2EEnabledRef = useRef(isW2EEnabled);
    const isWagerEnabledRef = useRef(isWagerEnabled);
    const isShowViatorTicketEnabledRef = useRef(isShowViatorTicketEnabled);

    const isTranscriptEnabledForShoppingRef = useRef(isTranscriptEnabledForShopping);

    const isShoppingEnabledRef = useRef(isShoppingEnabled);
    const isLiveRef = useRef(isLive);
    const winPointsRef = useRef(winPoints);
    const loosePointsRef = useRef(loosePoints);
    const productsPerTicketRef = useRef(productsPerTicket);
    const resolutionTimeRef = useRef(resolutionTime);

    // const promptTypeRef = useRef(promptType);
    // const promptRef = useRef(prompt);

    // Update refs whenever the state changes
    useEffect(() => {
        channelNameRef.current = channelName;
        hlsStreamRef.current = hlsStream;
        isGamificationEnabledRef.current = isGamificationEnabled;
        isW2EEnabledRef.current = isW2EEnabled;
        isWagerEnabledRef.current = isWagerEnabled;
        isShowViatorTicketEnabledRef.current = isShowViatorTicketEnabled;

        isTranscriptEnabledForShoppingRef.current = isTranscriptEnabledForShopping;
        isLiveRef.current = isLive;
        winPointsRef.current = winPoints;
        loosePointsRef.current = loosePoints;
        productsPerTicketRef.current = productsPerTicket;
        resolutionTimeRef.current = resolutionTime;
        isShoppingEnabledRef.current = isShoppingEnabled
        epgUrlRef.current = epgUrl

    }, [
        channelName,
        hlsStream,
        isGamificationEnabled,
        isW2EEnabled,
        isWagerEnabled,
        isShowViatorTicketEnabled,
        isTranscriptEnabledForShopping,
        isLive,
        winPoints,
        loosePoints,
        productsPerTicket,
        resolutionTime,
        isShoppingEnabled,
        epgUrl
        // prompt
    ]);

    useEffect(() => {
        if (record !== null && !isInitialized) {
            // Set initial values based on `record`
            setChannelName(record?.channelName || "");
            setHlsStream(record?.stream_hls || "");
            setIsGamificationEnabled(record?.services_enabled?.isGamified || false);
            setIsW2EEnabled(record?.services_enabled?.isW2E || false);
            setIsShoppingEnabled(record?.services_enabled?.isShopify || false);
            setIsWagerEnabled(record?.is_wager || false);
            setIsShowViatorTicketEnabled(record?.show_viator_tickets || false);

            setIsTranscriptEnabledForShopping(record?.enable_transcript_for_shopping || false);
            setIsLive(record?.isActive || false);
            setWinPoints(record?.win_point || "");
            setLoosePoints(record?.lose_point || "");
            setProductsPerTicket(record?.products_per_ticket || "");
            setResolutionTime(record?.resolution_time || "");
            setExistingLogoFile(record?.logo_url)
            setEpgUrl(record?.epg_url)
            // Set initialized to true after setting default values
            setIsInitialized(true);
            setHasChanges(false);
        }
    }, [record, isInitialized]);

    // Detect changes after initialization
    useEffect(() => {
        if (isInitialized) {
            const hasUnsavedChanges =
                (record?.channelName || "") !== channelName ||
                (record?.stream_hls || "") !== hlsStream ||
                (record?.services_enabled?.isGamified || false) !== isGamificationEnabled ||
                (record?.services_enabled?.isW2E || false) !== isW2EEnabled ||
                (record?.services_enabled?.isShopify || false) !== isShoppingEnabled ||
                (record?.is_wager || false) !== isWagerEnabled ||
                (record?.show_viator_tickets || false) !== isShowViatorTicketEnabled ||
                (record?.enable_transcript_for_shopping || false) !== isTranscriptEnabledForShopping ||
                (record?.isActive || false) !== isLive ||
                (record?.win_point || "") !== winPoints ||
                (record?.lose_point || "") !== loosePoints ||
                (record?.products_per_ticket || "") !== productsPerTicket ||
                (record?.resolution_time || "") !== resolutionTime ||
                (record?.epg_url || "") !== epgUrl ||
                logoFile !== null;

            setHasChanges(hasUnsavedChanges);
        }
    }, [
        channelName,
        hlsStream,
        isGamificationEnabled,
        isW2EEnabled,
        isShoppingEnabled,
        isWagerEnabled,
        isShowViatorTicketEnabled,
        isTranscriptEnabledForShopping,
        isLive,
        winPoints,
        loosePoints,
        productsPerTicket,
        resolutionTime,
        logoFile,
        record,
        epgUrl,
        isInitialized
    ]);


    useEffect(() => {
        if (record !== null) {
            setChannelName(record?.channelName || "");
            setHlsStream(record?.stream_hls || "");
            setIsGamificationEnabled(record?.services_enabled.isGamified || false);
            setIsW2EEnabled(record?.services_enabled.isW2E || false);
            setIsShoppingEnabled(record?.services_enabled.isShopify || false);

            setIsWagerEnabled(record?.is_wager || false);
            setIsShowViatorTicketEnabled(record?.show_viator_tickets || false);

            setIsTranscriptEnabledForShopping(record?.enable_transcript_for_shopping || false);
            setIsLive(record?.isActive || false);
            setWinPoints(record?.win_point || "");
            setLoosePoints(record?.lose_point || "");
            setProductsPerTicket((record?.products_per_ticket) || "");
            setResolutionTime(record?.resolution_time || "");
            setExistingLogoFile(record?.logo_url)
            setEpgUrl(record?.epg_url || "")

            // setPromptType(record?.promptType || "");
            // setPrompt(record?.prompt || "");
        }
    }, [record]);

    const handleCreateOrUpdate = useCallback(() => {

        const updatedChannelName = channelNameRef.current;
        const updatedHlsStream = hlsStreamRef.current;
        const updatedIsGamificationEnabled = isGamificationEnabledRef.current;
        const updatedIsW2EEnabled = isW2EEnabledRef.current;
        const updatedIsWagerEnabled = isWagerEnabledRef.current;
        const updatedIsShowViatorTicketEnabled = isShowViatorTicketEnabledRef.current;

        const updatedIsTranscriptEnabledForShopping = isTranscriptEnabledForShoppingRef.current;

        const updatedIsShoppingEnabled = isShoppingEnabledRef.current;
        const updatedIsLive = isLiveRef.current;
        const updatedWinPoints = winPointsRef.current;
        const updatedLoosePoints = loosePointsRef.current;
        const updatedProductsPerTicket = productsPerTicketRef.current;
        const updatedPpgUrl = epgUrlRef.current;
        const updatedResolutionTime = resolutionTimeRef.current;
        // Validation: Check if required fields are empty
        if (
            !updatedChannelName ||
            !updatedHlsStream ||
            !updatedWinPoints ||
            !updatedLoosePoints ||
            !updatedProductsPerTicket ||
            !updatedResolutionTime
        ) {
            // Show an error message and prevent the form submission
            toast.error("Please fill in all required fields.");
            return;

        }

        // Validate HLS stream
        if (!isValidHlsStream(updatedHlsStream)) {
            toast.error("Please enter a valid HLS stream URL.");
            return;
        }

        if (updatedProductsPerTicket <= 0) {
            toast.error("Products per ticket must be greater than 0");
            return
        }

        if ((logoFile == null || logoFile == undefined) && record == null) {
            toast.error("Please select logo.");
            return
        }

        const formData = new FormData();
        formData.append("channel_name", updatedChannelName);
        formData.append("stream_hls", updatedHlsStream);
        formData.append("live", updatedIsLive);
        formData.append("is_gamified", updatedIsGamificationEnabled);
        formData.append("is_w2e", updatedIsW2EEnabled);
        formData.append("is_shoppify", updatedIsShoppingEnabled);
        formData.append("win_point", updatedWinPoints);
        formData.append("products_per_ticket", updatedProductsPerTicket);
        formData.append("lose_point", updatedLoosePoints);
        formData.append("resolution_time", updatedResolutionTime);
        formData.append("chat_gpt", "3.5");
        formData.append("is_wager", updatedIsWagerEnabled);
        formData.append("show_viator_tickets", updatedIsShowViatorTicketEnabled);

        formData.append("epg_url", updatedPpgUrl);
        formData.append("enable_transcript_for_shopping", updatedIsTranscriptEnabledForShopping);

        // Append the list of selected categories
        listOfSelectedCategories.forEach((category, index) => {
            formData.append(`categories[${index}]`, category);
        });

        if (logoFile) {
            formData.append("file", logoFile); // Append the logo file if available
        }

        if (!is_this_for_update) {
            addNewChannel(formData).then((res) => {
                setIsChannelConfigurationSidebarOpen(false)
            })

        } else {
            formData.append("channel_id", record?.channel_id) // Add channel_id for updates
            updateChannelDetails(formData).then((res) => {

                setHasChanges(false)
                // setIsInitialized(false);
                setLogoFile(null)
                setFileList([])
            })

        }
    }, [logoFile]);


    useEffect(() => {
        setChannelConfigurationSidebarFooter(
            <div className={styles.footer_container}>
                <CustomButton
                    title={is_this_for_update ? "Update" : "Add"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleCreateOrUpdate}
                    disabled={is_this_for_update == true ? !hasChanges : false} // Disable button if no changes
                />
                <CustomButton
                    title={"Cancel"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    colorScheme="danger"
                    onClick={() => {
                        setCurrentChannelRecord(null)
                        setIsChannelConfigurationSidebarOpen(false)
                    }}
                />
            </div>
        );
    }, [handleCreateOrUpdate, record, hasChanges]);




    const handleChange = (info) => {
        if (info.fileList.length > 0) {
            const file = info.fileList[0]; // Get the first file from the list

            setLogoFile(file.originFileObj)
            // Create an object URL for the selected file
            const objectUrl = URL.createObjectURL(file.originFileObj);
            // Update the state with the new file list containing only the object URL
            setFileList([objectUrl]);

        } else {
            setFileList([]); // Clear the file list if no files are present

        }
    };

    const handleRemoveImage = () => {
        setFileList([]); // Clear the file list
        setLogoFile(null)

    };

    useEffect(() => {
        // Cleanup: Revoke the object URL when it's no longer needed
        return () => {
            if (fileList.length > 0) {
                URL.revokeObjectURL(fileList[0]); // Revoke the object URL
            }
        };
    }, [fileList]);


    const handleFilterByCategories = () => {
        setCurrentDetailsFormToRender(
            <ListOfCategoriesToAttach
                setIsDetailsModalOpen={setIsDetailsModalOpen}
                setDetailsModalFooter={setDetailsModalFooter}
                listOfSelectedCategories={listOfSelectedCategories}
                setListOfSelectedCategories={setListOfSelectedCategories}
            />)
        setIsDetailsModalOpen(true)
        setDetailsModalFooter(false)
        setDetailsModalTitle("Select categories to enable related vendors for channels")
    }

    return (
        <div className={styles.channel_data_form_container}>
            <div className={styles.row_1}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Channel Name*" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter channel name"
                        font_size="0.7" unit="rem"
                        value={channelName}
                        onChange={(e) => setChannelName(e.target.value)}
                    />
                </div>
            </div>

            <div className={styles.row_2}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="HLS Stream*" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter HLS stream URL"
                        font_size="0.7" unit="rem"
                        value={hlsStream}
                        onChange={(e) => setHlsStream(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.row_2}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="EPG Url" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter EPG URL"
                        font_size="0.7" unit="rem"
                        value={epgUrl}
                        onChange={(e) => setEpgUrl(e.target.value)}
                    />
                </div>
            </div>


            <div className={styles.row_4}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Win Points *" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        type="number"
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter win points"
                        font_size="0.7" unit="rem"
                        value={winPoints}
                        onChange={(e) => setWinPoints(e.target.value)}
                        min="0"
                    />
                </div>
            </div>

            <div className={styles.row_5}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Lose Points *" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        type="number"
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter lose points"
                        font_size="0.7" unit="rem"
                        value={loosePoints}
                        onChange={(e) => setLoosePoints(e.target.value)}
                        min="0"
                    />
                </div>
            </div>
            <div className={styles.row_5}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Products Per Ticket *" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        type="number"
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter 1 for setting 10 products"
                        font_size="0.7" unit="rem"
                        value={productsPerTicket}
                        onChange={(e) => setProductsPerTicket(e.target.value)}
                        min="0"
                    />
                </div>
            </div>

            <div className={styles.row_5}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Resolution Time *" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        type="number"
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter resolution time"
                        font_size="0.7" unit="rem"
                        value={resolutionTime}
                        onChange={(e) => setResolutionTime(e.target.value)}
                        min="0"
                    />
                </div>
            </div>

            <div className={styles.row_10}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.8" unit="rem" headingText="Wagering" />
                </div>
                <div className={styles.field}>
                    <Switch
                        checked={isWagerEnabled}
                        onChange={(checked) => setIsWagerEnabled(checked)}
                    />
                </div>
            </div>
            <div className={styles.row_10}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.8" unit="rem" headingText="Shopping Transcript" />
                </div>
                <div className={styles.field}>
                    <Switch
                        checked={isTranscriptEnabledForShopping}
                        onChange={(checked) => setIsTranscriptEnabledForShopping(checked)}
                    />
                </div>
            </div>
            {
                !is_this_for_update && <div className={styles.row_10}>
                    <div className={styles.label}>
                        <CustomHeading font_size="0.8" unit="rem" headingText="Show Viator Tickets" />
                    </div>
                    <div className={styles.field}>
                        <Switch
                            checked={isShowViatorTicketEnabled}
                            onChange={(checked) => setIsShowViatorTicketEnabled(checked)}
                        />
                    </div>
                </div>
            }


            <div className={styles.row_10}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Categories" />
                </div>
                <div className={styles.field}>
                    <CustomButton
                        title={`Categories (${listOfSelectedCategories.length})`}
                        width="100%"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        colorScheme=""
                        icon={<img src={ic_filter_by_cat} width={20} />}
                        border_color="#FAFAFA"
                        background_color="#FAFAFA"
                        onClick={handleFilterByCategories} // Stop stream on Update click
                    />
                </div>
            </div>

            <div className={styles.row_12}>
                <div className={styles.field} style={{ textAlign: 'center' }}>
                    {/* Display the existing logo if available */}

                    {existingLogoFile && (
                        <div style={{ marginBottom: '1rem', width: '100%', textAlign: 'center' }}>
                            <img
                                src={typeof existingLogoFile === 'string' ? existingLogoFile : URL.createObjectURL(logoFile)}
                                alt="Current Logo"
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            />
                        </div>
                    )}

                    <Form.Item

                        name="upload"
                        rules={[{ required: true, message: 'Please upload a logo!' }]}
                    // getValueFromEvent={normFile} // Update function to handle file selection correctly
                    >
                        <Upload
                            name="logo"
                            action="/upload.do"
                            listType="picture"
                            accept=".jpg,.jpeg,.png,.gif"
                            beforeUpload={() => false} // Prevent automatic upload
                            showUploadList={false}
                            fileList={[]}
                            onChange={handleChange} // Set the onChange event handler
                        >
                            <Button icon={<FaUpload />}>{record != null ? 'Change Logo' : 'Upload New Logo'}</Button>
                        </Upload>
                        {/* Display the uploaded image if it exists */}
                        {fileList.length > 0 && (
                            <div style={{ marginTop: 16, border: "1px solid black", display: 'flex', justifyContent: "space-between", alignItems: "center", padding: "1%", borderRadius: "10px" }}>
                                <img
                                    width={30} // Adjust width as needed
                                    src={fileList[0]} // Use the object URL from fileList
                                    alt="Uploaded Image"
                                />
                                <Button
                                    type="link"
                                    onClick={handleRemoveImage}
                                    style={{ marginLeft: 8 }}
                                >
                                    <img src={ic_delete} width={20} />
                                </Button>
                            </div>
                        )}

                    </Form.Item>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    record: getCurrentSelectedChannelRecord(state)
});

const mapDispatchToProps = (dispatch) => ({
    addNewChannel: (data) => dispatch(addNewChannel(data)),
    updateChannelDetails: (data) => dispatch(updateChannelDetails(data)),
    setCurrentChannelRecord: (data) => dispatch(setCurrentChannelRecord(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelDataForm);
