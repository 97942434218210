import { Navigate } from 'react-router';
import { getCookie } from '../../shared/utils/utils';
export const RouteAuthenticator = ({ path, authenticationPath, component }) => {
    const authToken = getCookie('authToken')
    if (path === '/auth' || path === '/') {
        // if (true) {
        if (authToken != undefined) {
            return <Navigate to={{ pathname: '/dashboard/home' }} />;
        } else {
            return component;
        }

    } else {
        if (authToken != undefined) {
            return component;
        } else {
            return <Navigate to={{ pathname: authenticationPath }} />;
        }
    }
}