import { connect } from "react-redux";
import styles from '../styles.module.scss'
import TabsRender from "./general-components/TabsRender";
import EAT_FASTSwapTab from "./fast-components/EAT_FASTSwapTab";
import FASTVisualizationTab from "./fast-components/FASTVisualizationTab";
import { useEffect, useState } from "react";
import Header from "./general-components/Header";
import FAST_tx_polygon_Tab from "./fast-components/FAST_tx_polygon_Tab";
import EAT_FAST_TX_OverviewTab from "./fast-components/EAT_FAST_TX_OverviewTab";

import ProductsVisualization from "./products-components/ProductsVisualization";
import ProductsOverview from "./products-components/ProductsOverview";
import DetailsModal from "./general-components/DetailsModal";
import { CustomButton } from "../../../../shared/ui/button";
// import NewProductDataForm from "./products-components/EditProductDataForm";
import { setCurrentSelectedProduct, setRefreshProductForms } from "../../logic/product/slice";
import ConfigurationSidebar from "./general-components/ConfigurationSidebar";
import NewProductDataForm from "./products-components/NewProductDataForm";
import { getPermissions } from "../../logic/permissions/selectors";

const ProductsScreen = ({
    setCurrentSelectedProduct,
    permissions,
    setRefreshProductForms,
    ...props }) => {
    const [listOfTabs, setListOfTabs] = useState([]);

    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
    const [detailsModalTitle, setDetailsModalTitle] = useState("Vender details")
    const [currentDetailsFormToRender, setCurrentDetailsFormToRender] = useState(null)
    const [detailsModalFooter, setDetailsModalFooter] = useState(null)
    const [currentSidebarFormToRender, setCurrentSidebarFormToRender] = useState(null)
    const [channelConfigurationSidebarFooter, setChannelConfigurationSidebarFooter] = useState(null)
    const [isChannelConfigurationSidebarOpen, setIsChannelConfigurationSidebarOpen] = useState(false)
    const [currentSidebarFooterToRender, setCurrentSidebarFooterToRender] = useState(null)
    const [isAllowedToAddProducts, setIsAllowedToAddProducts] = useState(false);
    const [refresh,setRefresh]=useState(false)
    useEffect(() => {
        let temp_tabs = [
            {
                label: "Products",
                screen: <ProductsOverview
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                    setDetailsModalFooter={setDetailsModalFooter}
                    setChannelConfigurationSidebarFooter={setChannelConfigurationSidebarFooter}
                    setCurrentSidebarFormToRender={setCurrentSidebarFormToRender}
                    setIsChannelConfigurationSidebarOpen={setIsChannelConfigurationSidebarOpen}

                />
            },
            // {
            //     label: "Visualization",
            //     screen: <ProductsVisualization />
            // }
        ]
        setListOfTabs(temp_tabs)
    }, [])

    useEffect(() => {
        if (permissions) {
            setIsAllowedToAddProducts(permissions?.ADD_PRODUCTS?.enabled);
        }
    }, [permissions]);

    const handleTabOnChange = (e) => { }

    // Modal
    const handleOkayDetailsModal = (e) => {
        setIsDetailsModalOpen(false)
    }
    const handleCancelDetailsModal = (e) => {
        setIsDetailsModalOpen(false)
    }
    const handleCloseDetailsModal = (e) => {
        e.preventDefault()
        setIsDetailsModalOpen(false)
        // setCurrentSelectedProduct(null)
        setRefreshProductForms()
    }

    const handleCloseSidebar = (e) => {
        setIsChannelConfigurationSidebarOpen(false)
    }

    const handleCancelCreateChannel = (e) => {
        setIsChannelConfigurationSidebarOpen(false)
    }

    useEffect(()=>{
        console.log('detailsModalFooter',detailsModalFooter)
    },[detailsModalFooter])

    return <div className={styles.products_container}>
        <div className={styles.header_container}>
            <Header title='Products ' />
            <div className={styles.actions_container}>
                <CustomButton
                    title="Add new product"
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={(e) => {
                        e.preventDefault()

                        // setCurrentSidebarFormToRender(<NewProductDataForm
                        //     setCurrentSidebarFormToRender={setCurrentSidebarFormToRender}
                        //     setCurrentSidebarFooterToRender={setCurrentSidebarFooterToRender}
                        //     setIsChannelConfigurationSidebarOpen={setIsChannelConfigurationSidebarOpen}
                        //     setChannelConfigurationSidebarFooter={setChannelConfigurationSidebarFooter}
                        // />)
                        // setIsChannelConfigurationSidebarOpen(true)

                        // setCurrentSelectedProduct(null)

                        setCurrentDetailsFormToRender(
                            <NewProductDataForm
                            is_this_for_update={false}
                            record={null}
                            refresh={!refresh}
                            setRefresh={setRefresh}
                            setIsChannelConfigurationSidebarOpen={setIsDetailsModalOpen}
                            setChannelConfigurationSidebarFooter={setDetailsModalFooter}
                        /> )

                        setIsDetailsModalOpen(true)
                        // setDetailsModalFooter(false)
                        setDetailsModalTitle("Add new product")
                    }}
                    disabled={!isAllowedToAddProducts}
                />
            </div>
        </div>
        <div className={styles.body_container}>
            <div className={styles.tabs_container}>
                <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
            </div>
        </div>
        {
            isChannelConfigurationSidebarOpen && <div className={styles.configuration_side_container}>
                <ConfigurationSidebar
                    title="Configure product"
                    handleClose={handleCloseSidebar}
                    handleOkay={() => { }}
                    handleCancel={handleCancelCreateChannel}
                    okay_button_title='Add'
                    cancel_button_title='Cancel'
                    footer={channelConfigurationSidebarFooter}
                    body={currentSidebarFormToRender}
                />
            </div>
        }
        <div className={styles.configuration_modal_container}>
            <DetailsModal
                title={detailsModalTitle}
                handleClose={handleCloseDetailsModal}
                handleOkay={handleOkayDetailsModal}
                handleCancel={handleCancelDetailsModal}
                okay_button_title='Okay'
                cancel_button_title='Cancel'
                body={currentDetailsFormToRender}
                isModalOpen={isDetailsModalOpen}
                footer={detailsModalFooter}
            />
        </div>

    </div>
}

const mapState = (state) => ({
    permissions: getPermissions(state),
})

const mapDispatchToProps = (dispatch) => ({
    setRefreshProductForms:(data) => dispatch(setRefreshProductForms(data)),
    setCurrentSelectedProduct: (data) => dispatch(setCurrentSelectedProduct(data)),
});


export default connect(mapState, mapDispatchToProps)(ProductsScreen)