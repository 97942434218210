import { apiGet, apiPost } from "../../shared/api/api";

const getTotalCountsStates = (data) => apiGet('states/get_total_counts', data);
const getTotalUsersCounts = (data) => apiGet('states/get_total_users', data);
const getTotalVendorsCounts = (data) => apiGet('states/get_total_vendors', data);
const getTotalChannelsCounts = (data) => apiGet('states/get_total_channels', data);
const getReviewStates = (data) => apiGet('states/get_review_states', data);
const getTotalProductsCounts = (data) => apiGet('states/get_total_products', data);
const getTotalUniqueMatches = (data) => apiGet('states/get_unique_matches', data);
const getTotalMatches = (data) => apiGet('states/get_total_matches', data);
const getVerificationSpeed = (data) => apiGet('states/get_verification_speed', data);
const getQRCodeStates = (data) => apiGet('states/get_qr_code_states', data);
const getTop10Vendors = (data) => apiGet('states/get_top_10_vendors', data);


const getQRCodeStatesForChannel = (data) => apiPost('states/get_qr_code_states_for_channel', data);
const getTotalMatchesForChannel = (data) => apiPost('states/get_total_matches_for_channel', data);
const getTotalUniqueMatchesForChannel = (data) => apiPost('states/get_unique_matches_for_channel', data);
const getTotalProductsCountsForChannel = (data) => apiPost('states/get_total_products_for_channel', data);
const getTop10VendorsForChannel = (data) => apiPost('states/get_top_10_vendors_for_channel', data);

export {
    getTotalCountsStates,
    getTotalUsersCounts,
    getTotalVendorsCounts,
    getTotalChannelsCounts,
    getReviewStates,
    getTotalProductsCounts,
    getTotalUniqueMatches,
    getTotalMatches,
    getVerificationSpeed,
    getQRCodeStates,
    getTop10Vendors,
    getQRCodeStatesForChannel,
    getTotalMatchesForChannel,
    getTotalUniqueMatchesForChannel,
    getTotalProductsCountsForChannel,
    getTop10VendorsForChannel
}