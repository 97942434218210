import { connect } from "react-redux";
import styles from './model.module.scss'
import { useEffect, useState } from "react";
import TabsRender from "../general-components/TabsRender";

import ListOfAllAvailableProducts from "./ListOfAllAvailableProducts";

const FaceProductConfigurationModal = ({
    title = 'default title',
    record,
    ...props }) => {

    const [listOfTabs, setListOfTabs] = useState([]);
    useEffect(() => {
        let temp_tabs = [
            // {
            //     label: "Selected Products",
            //     screen: <ListOfConfiguredProducts  row_record={record}/>
            // },
            {
                label: "Product Pool",
                screen: <ListOfAllAvailableProducts/>
            }
        ]
        setListOfTabs(temp_tabs)
    }, [record])



    const handleTabOnChange = (e) => { }


    return <div className={styles.face_product_config_container}>
        <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapState, mapDispatchToProps)(FaceProductConfigurationModal)