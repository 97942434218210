import { connect } from "react-redux";
import styles from '../../styles.module.scss'
import { CustomHeading } from "../../../../../shared/ui/heading";
import { useState } from "react";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { Button, Form, Switch, Upload } from "antd";
import { CustomDropDown } from "../../../../../shared/ui/dropdown";
import { FaUpload } from "react-icons/fa";
import { CustomButton } from "../../../../../shared/ui/button";
import VendorRegionConfigurationForm from "./VendorRegionConfigurationForm";
import VendorChannelConfigurationForm from "./VendorChannelConfigurationForm";

const NewVendorPoolRecordDataForm = ({
    setCurrentDetailsFormToRender,
    setIsDetailsModalOpen,
    setDetailsModalTitle,
    ...props }) => {
    const [platformName, setPlatformName] = useState("")
    const [vendorName, setVendorName] = useState("")

    const handleStartListingProductsSwitch = (e) => { }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return <div className={styles.new_vendor_pool_record_data_form_container}>

        <div className={styles.row_0}>
            <div className={styles.label}>
                <CustomHeading font_size="0.7" unit="rem" headingText="Platform" />
            </div>
            <div className={styles.field}>
                <CustomDropDown
                    padding='0px'
                    defaultValue='default'
                    options={[
                        { value: 'default', label: 'Select Platform' },
                        { value: 'trade_tracker', label: 'TradeTracker' },
                        { value: 'awin', label: 'Awin' },
                        { value: 'daisycon', label: 'Daisycon' },

                    ]} />
            </div>
        </div>
        <div className={styles.row_1}>
            <div className={styles.label}>
                <CustomHeading font_size="0.7" unit="rem" headingText="Vendor Name" />
            </div>
            <div className={styles.field}>
                <CustomTextfield
                    border_radius="5px"
                    colorScheme="primary"
                    place_holder="Enter vendor name"
                    font_size="0.7" unit="rem"
                    value={vendorName}
                    onChange={(e) => { setVendorName(e.target.value) }}
                />
            </div>
        </div>
        <div className={styles.row_2}>
            <div className={styles.label}>
                <CustomHeading font_size="0.7" unit="rem" headingText="No. of total products" />
            </div>
            <div className={styles.field}>
                <CustomTextfield
                    border_radius="5px"
                    colorScheme="primary"
                    place_holder="i.e 30"
                    font_size="0.7" unit="rem"
                    value={vendorName}
                    onChange={(e) => { setVendorName(e.target.value) }}
                />
            </div>
        </div>
        <div className={styles.row_3}>
            <div className={styles.label}>
                <CustomHeading font_size="0.7" unit="rem" headingText="No. of applied products" />
            </div>
            <div className={styles.field}>
                <CustomTextfield
                    border_radius="5px"
                    colorScheme="primary"
                    place_holder="i.e 23"
                    font_size="0.7" unit="rem"
                    value={vendorName}
                    onChange={(e) => { setVendorName(e.target.value) }}
                />
            </div>
        </div>
        <div className={styles.row_4}>
            <div className={styles.label}>
                <CustomHeading font_size="0.7" unit="rem" headingText="Product Type" />
            </div>
            <div className={styles.field}>
                <CustomDropDown
                    padding='0px'
                    defaultValue='default'
                    options={[
                        { value: 'default', label: 'Select type' },
                        { value: 'cloths', label: 'Cloths' },
                        { value: 'shoes', label: 'Shoes' },
                        { value: 'vehicles', label: 'Vehicles' },
                    ]} />
            </div>
        </div>
        <div className={styles.row_5}>
            <div className={styles.label}>
                <CustomHeading font_size="0.7" unit="rem" headingText="Status" />
            </div>
            <div className={styles.field}>
                <CustomDropDown
                    padding="0px"
                    defaultValue='default'
                    options={[
                        { value: 'default', label: 'Select status' },
                        { value: 'applied', label: 'Applied' },
                        { value: 'approved', label: 'approved' },
                        { value: 'rejected', label: 'rejected' },
                    ]} />
            </div>
        </div>


    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapState, mapDispatchToProps)(NewVendorPoolRecordDataForm)