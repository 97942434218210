import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'app',
    initialState: {  route: null },
    reducers: {
        setRoute: (state, action) => {
            state.route = action.payload;
        },
    },
});

export const {
    setRoute,
} = appSlice.actions;

export default appSlice.reducer;
