import { connect } from "react-redux";
import styles from './modal.module.scss'
import { useEffect, useState } from "react";
import TabsRender from "../general-components/TabsRender";

import ListOfAllBlacklistViatorTags from "./ListOfAllBlacklistViatorTags";
import ListOfAllBlacklistViatorDestination from "./ListOfAllBlacklistViatorDestination";
import { CustomHeading } from "../../../../../shared/ui/heading";
import { Switch } from "antd";
import { updateViatorStatus } from "../../../logic/channels/actions";


const TicketConfigurationModal = ({
    title = 'default title',
    record,
    refresh,
    setRefresh,
    updateViatorStatus,
    setIsDetailsModalOpen,
    ...props }) => {

    const [isShowViatorTicketEnabled, setIsShowViatorTicketEnabled] = useState(false);
    const [listOfTabs, setListOfTabs] = useState([]);
    
    useEffect(() => {

        setIsShowViatorTicketEnabled(record?.show_viator_tickets || false);

        let temp_tabs = [
            {
                label: "Block Tags",
                screen: <ListOfAllBlacklistViatorTags isShowViatorTicketEnabled={isShowViatorTicketEnabled} record={record} refresh={refresh} />
            },
            {
                label: "Block Destinations",
                screen: <ListOfAllBlacklistViatorDestination isShowViatorTicketEnabled={isShowViatorTicketEnabled} record={record} refresh={refresh} />
            }
        ]

        setListOfTabs(temp_tabs)

    }, [record, refresh])



    const handleUpdateViatorStatus = (showViatorTickets) => {
        const data = {
            channel_id: record?.channel_id,
            show_viator_tickets: showViatorTickets, // Toggle the status
            listApiParams: record.listApiParams
        };

        setIsDetailsModalOpen(false)  
        
        updateViatorStatus(data).then((res) => {
            
        })
    };


    const handleTabOnChange = (e) => { }
    return <div className={styles.tickets_config_container}>
        <div className={styles.viator_switch_container}>
            <div className={styles.row}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.8" unit="rem" headingText="Show Viator Tickets" />
                </div>
                <div className={styles.field}>
                    <Switch
                        checked={isShowViatorTicketEnabled}
                        onChange={(checked) => {
                            handleUpdateViatorStatus(checked)
                            setIsShowViatorTicketEnabled(checked)
                        }}
                    />
                </div>
            </div>
        </div>
        <TabsRender show_tab={true} list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    updateViatorStatus: (data) => dispatch(updateViatorStatus(data)),
});

export default connect(mapState, mapDispatchToProps)(TicketConfigurationModal)