
import { connect } from "react-redux";
import styles from './model.module.scss';
import { CustomHeading } from "../../../../../shared/ui/heading";
import { useState, useRef, useEffect, useCallback } from "react";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { Button, Form, Input, Switch, Upload } from "antd";

import { FaUpload } from "react-icons/fa";
import { CustomButton } from "../../../../../shared/ui/button";
import ic_delete from '../../../../../assets/icons/delete.png'

import toast from "react-hot-toast";
// import { isValidProductAltTitle } from "../../../../../shared/utils/utils";
import { addProductRecord, updateProductRecord } from "../../../logic/product/actions";
import { setCurrentSelectedProduct } from "../../../logic/product/slice";
import { getCurrentSelectedProduct } from "../../../logic/product/selectors";

const EditProductDataForm = ({
    setIsChannelConfigurationSidebarOpen,
    setChannelConfigurationSidebarFooter,
    record,
    addProductRecord,
    is_this_for_update = false,
    updateProductRecord,
    listSearchParams = {},
    setCurrentSelectedProduct,
    ...props
}) => {

    const [productTitle, setProductTitle] = useState("");
    const [productAltTitle, setProductAltTitle] = useState("");
    const [productDescription, setProductDescription] = useState("");

    const [productAffiliateLink, setProductAffiliateLink] = useState("");

    const [isInitialized, setIsInitialized] = useState(false);
    const [logoFile, setLogoFile] = useState(null); // New state for the logo file
    const [existingLogoFile, setExistingLogoFile] = useState(null)
    const [fileList, setFileList] = useState([]);
    const [isLinkMode, setIsLinkMode] = useState(false); // New state for link mode
    const [logoLink, setLogoLink] = useState(''); // New state for logo link

    const [hasChanges, setHasChanges] = useState(false);

    // const [promptType, setPromptType] = useState('');
    // const [prompt, setPrompt] = useState('');

    // Create refs for the state variables
    const productTitleRef = useRef(productTitle);
    const productAltTitleRef = useRef(productAltTitle);
    const productDescriptionRef = useRef(productDescription);
    const productAffiliateLinkRef = useRef(productAffiliateLink);

    useEffect(() => {
        productTitleRef.current = productTitle;
        productAltTitleRef.current = productAltTitle;
        productAffiliateLinkRef.current = productAffiliateLink;
        productDescriptionRef.current = productDescription

    }, [
        productTitle,
        productAltTitle,
        productAffiliateLink,
        productDescription
        // prompt
    ]);

    useEffect(() => {

        // console.log("record", record?.english_title)
        // if (is_this_for_adding_new_rec) {
        //     setProductTitle( "");
        //     setProductAltTitle("");
        //     setProductAffiliateLink(false);
        //     setExistingLogoFile(null)
        //     setLogoLink(''); // Set the logo link if available
        //     setProductDescription('')
        // }

        return () => {
            setHasChanges(false)
            setIsInitialized(false)
            setCurrentSelectedProduct(null)
        }
    }, [])

    useEffect(() => {
        console.log('record', record, "isInitialized:", isInitialized)
        if (record !== null && !isInitialized) {
            // Set initial values based on `record`
            setProductTitle(record?.english_title || "");
            setProductAltTitle(record?.alt_title || "");
            setProductAffiliateLink(record?.affiliate_link || false);
            setExistingLogoFile(record?.image_link)
            setLogoLink(record?.image_link || ''); // Set the logo link if available
            setProductDescription(record?.description)
            setIsInitialized(true);
            setHasChanges(false);
        }
    }, [record, isInitialized]);

    // Detect changes after initialization
    useEffect(() => {

        if (isInitialized) {
            const hasUnsavedChanges =
                (record?.english_title || "") !== productTitle ||
                (record?.alt_title || "") !== productAltTitle ||
                (record?.affiliate_link || false) !== productAffiliateLink ||
                (record?.description || "") !== productDescription ||
                logoFile !== null ||
                record?.image_link !== logoLink; // Check for changes in logo link

            setHasChanges(hasUnsavedChanges);
        }
    }, [
        productTitle,
        productAltTitle,
        productAffiliateLink,
        logoFile,
        logoLink,
        record,
        productDescription,
        isInitialized
    ]);


    useEffect(() => {
        if (record !== null) {
            setProductTitle(record?.english_title || "");
            setProductAltTitle(record?.alt_title || "");
            setProductAffiliateLink(record?.affiliate_link || false);
            setProductDescription(record?.description || "")
            setExistingLogoFile(record?.image_link)
            setLogoLink(record?.image_link || '');

        }
    }, [record]);

    const handleCreateOrUpdate = useCallback(() => {

        const updatedProductTitle = productTitleRef.current;
        const updatedProductAltTitle = productAltTitleRef.current;
        const updatedProductDescription = productDescriptionRef.current;
        const updatedProductAffiliateLink = productAffiliateLinkRef.current;
        // Validation: Check if required fields are empty
        if (
            !updatedProductTitle ||
            !updatedProductAffiliateLink ||
            !updatedProductDescription
        ) {
            // Show an error message and prevent the form submission
            toast.error("Please fill in all required fields.");
            return;

        }
        if ((logoFile == null || logoFile == undefined) && (logoLink === '') && record == null) {
            toast.error("Please select or provide a logo.");
            return;
        }

        const formData = new FormData();
        formData.append("title", updatedProductTitle);
        // formData.append("alt_title", updatedProductAltTitle);
        formData.append("affiliate_link", updatedProductAffiliateLink);
        formData.append("description", updatedProductDescription);
        formData.append("vendor_id", 948);


        if (logoFile) {
            formData.append("file", logoFile); // Append the logo file if available
        } else if (logoLink) {
            formData.append("logo_link", logoLink); // Append the logo link if available
        }


        if (!is_this_for_update) {
            addProductRecord(formData)
                .then((res) => {
                    setIsChannelConfigurationSidebarOpen(false)
                }).catch((error) => {
                    toast.error(error.message);
                })

        } else {

            formData.append("product_id", record?.product_id) // Add channel_id for updates
            updateProductRecord(formData, listSearchParams).then((res) => {
                setHasChanges(false)
                // setIsInitialized(false);
                setLogoFile(null)
                setFileList([])
                setLogoLink('');
            }).catch((error) => {
                toast.error(error.message);
            })

        }
    }, [logoFile, logoLink]);


    useEffect(() => {
        setChannelConfigurationSidebarFooter(
            <div className={styles.footer_container}>
                <CustomButton
                    title={is_this_for_update ? "Update" : "Add"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleCreateOrUpdate}
                    disabled={is_this_for_update == true ? !hasChanges : false} // Disable button if no changes
                />
                <CustomButton
                    title={"Cancel"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    colorScheme="danger"
                    onClick={() => {
                        setCurrentSelectedProduct(null)
                        setIsChannelConfigurationSidebarOpen(false)
                    }}
                />
            </div>
        );
    }, [handleCreateOrUpdate, record, hasChanges]);

    const handleChange = (info) => {
        if (info.fileList.length > 0) {
            const file = info.fileList[0]; // Get the first file from the list

            setLogoFile(file.originFileObj)
            // Create an object URL for the selected file
            const objectUrl = URL.createObjectURL(file.originFileObj);
            // Update the state with the new file list containing only the object URL
            setFileList([objectUrl]);

        } else {
            setFileList([]); // Clear the file list if no files are present

        }
    };

    const handleRemoveImage = () => {
        setFileList([]); // Clear the file list
        setLogoFile(null)

    };

    useEffect(() => {
        // Cleanup: Revoke the object URL when it's no longer needed
        return () => {
            if (fileList.length > 0) {
                URL.revokeObjectURL(fileList[0]); // Revoke the object URL
            }
        };
    }, [fileList]);


    return (
        <div className={styles.product_data_form_container}>
            <div className={styles.left_side}>
                <div className={styles.row_1}>
                    <div className={styles.label}>
                        <CustomHeading font_size="0.7" unit="rem" headingText="Product title" />
                    </div>
                    <div className={styles.field}>
                        <CustomTextfield
                            border_radius="5px"
                            colorScheme="primary"
                            place_holder="Enter product title"
                            font_size="0.7" unit="rem"
                            value={productTitle}
                            onChange={(e) => setProductTitle(e.target.value)}
                        />
                    </div>
                </div>

                <div className={styles.row_3}>
                    <div className={styles.label}>
                        <CustomHeading font_size="0.7" unit="rem" headingText="Affiliate link" />
                    </div>
                    <div className={styles.field}>
                        <CustomTextfield
                            border_radius="5px"
                            colorScheme="primary"
                            place_holder="Affiliate link"
                            font_size="0.7" unit="rem"
                            value={productAffiliateLink}
                            onChange={(e) => setProductAffiliateLink(e.target.value)}
                        />
                    </div>
                </div>

                <div className={styles.row_2}>
                    <div className={styles.label}>
                        <CustomHeading font_size="0.7" unit="rem" headingText="Product description" />
                    </div>
                    <div className={styles.field}>
                        <CustomTextfield
                            make_text_area={true}
                            border_radius="5px"
                            colorScheme="primary"
                            place_holder="Enter product description"
                            font_size="0.7" unit="rem"
                            number_of_rows={10}
                            value={productDescription}
                            onChange={(e) => setProductDescription(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.right_side}>
                <div className={styles.row_1}>
                    <div className={styles.field} style={{ textAlign: 'center' }}>
                        {/* Display the existing logo if available */}

                        {existingLogoFile && (
                            <div style={{ marginBottom: '1rem', width: '100%', textAlign: 'center' }}>
                                <img
                                    src={typeof existingLogoFile === 'string' ? existingLogoFile : URL.createObjectURL(logoFile)}
                                    alt="Current Logo"
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                            </div>
                        )}

                        <div style={{ marginBottom: '10px' }}>
                            <Switch
                                checked={isLinkMode}
                                onChange={() => setIsLinkMode(!isLinkMode)}
                                checkedChildren="Link Mode"
                                unCheckedChildren="File Mode"
                            />
                        </div>

                        {isLinkMode ? (
                            <div style={{ paddingBottom: "15px" }}> <CustomTextfield
                                border_radius="5px"
                                colorScheme="primary"
                                place_holder="Enter image link"
                                font_size="0.7" unit="rem"
                                value={logoLink}
                                onChange={(e) => setLogoLink(e.target.value)}
                            />
                            </div>
                        ) : (
                            <Form.Item
                                name="upload"
                                rules={[{ required: true, message: 'Please upload a logo!' }]}
                            >
                                <Upload
                                    name="logo"
                                    action="/upload.do"
                                    listType="picture"
                                    accept=".jpg,.jpeg,.png,.gif"
                                    beforeUpload={() => false} // Prevent automatic upload
                                    showUploadList={false}
                                    fileList={[]}
                                    onChange={handleChange} // Set the onChange event handler
                                >
                                    <Button icon={<FaUpload />}>{record != null ? 'Change Image' : 'Upload New Logo'}</Button>
                                </Upload>
                                {/* Display the uploaded image if it exists */}
                                {fileList.length > 0 && (
                                    <div style={{ marginTop: 16, border: "1px solid black", display: 'flex', justifyContent: "space-between", alignItems: "center", padding: "1%", borderRadius: "10px" }}>
                                        <img
                                            width={30} // Adjust width as needed
                                            src={fileList[0]} // Use the object URL from fileList
                                            alt="Uploaded Image"
                                        />
                                        <Button
                                            type="link"
                                            onClick={handleRemoveImage}
                                            style={{ marginLeft: 8 }}
                                        >
                                            <img src={ic_delete} width={20} />
                                        </Button>
                                    </div>
                                )}
                            </Form.Item>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    record: getCurrentSelectedProduct(state)
});

const mapDispatchToProps = (dispatch) => ({
    addProductRecord: (data) => dispatch(addProductRecord(data)),
    updateProductRecord: (data, listSearchParams) => dispatch(updateProductRecord(data, listSearchParams)),
    setCurrentSelectedProduct: (data) => dispatch(setCurrentSelectedProduct(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProductDataForm);