import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import copy from 'copy-to-clipboard';
import styles from '../../styles.module.scss';
import { FaSearch } from 'react-icons/fa';
import { CustomTextfield } from '../../../../../shared/ui/text-field';

// Function to shorten the address or hash
const shortenString = (str) => {
    return str ? `${str.substring(0, 6)}...${str.substring(str.length - 4)}` : '';
};

// Function to handle copying to clipboard
const handleCopy = (text) => {
    if (text) {
        copy(text);
        alert('Copied to clipboard!');
    }
};

const EAT_FASTSwapTab = ({ ...props }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Define the columns structure for the transaction table
    const columns = [
        {
            title: 'From Address',
            dataIndex: 'from_address',
            key: 'from_address',
            width: '25%', // Adjust width as needed
            render: (text) => (
                <div className={styles.txHashContainer}>
                    <span>{shortenString(text)}</span>
                    {text && (
                        <Button 
                            type="link" 
                            onClick={() => handleCopy(text)}
                            style={{ marginLeft: 8 }}
                        >
                            Copy
                        </Button>
                    )}
                </div>
            )
        },
        {
            title: 'Transaction Hash',
            dataIndex: 'tx_hash',
            key: 'tx_hash',
            width: '30%', // Adjust width as needed
            render: (text) => (
                <div className={styles.txHashContainer}>
                    <span>{shortenString(text)}</span>
                    {text && (
                        <Button 
                            type="link" 
                            onClick={() => handleCopy(text)}
                            style={{ marginLeft: 8 }}
                        >
                            Copy
                        </Button>
                    )}
                </div>
            )
        },
        {
            title: 'Timestamp',
            dataIndex: 'tx_timestamp',
            key: 'tx_timestamp',
            width: '25%' // Adjust width as needed
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: '20%', // Adjust width as needed
            render: (amount) => <span>{amount.toFixed(2)}</span> // Format amount as currency
        }
    ];

    // Transaction data for the table
    const transactionData = [
        { from_address: "0x25284abf9c20302def4f258acc469cd1873d9819", tx_hash: "0xd6ffd63b675b872901775e4b10b8cd44c0c2b69c8e8c156e904203444c08c83e", tx_timestamp: "2024-07-11 18:20:41", amount: 100 },
        { from_address: "0x25284abf9c20302def4f258acc469cd1873d9819", tx_hash: "0xfa0626bd47a67a730be7ecbd853d55782eda4f392a8b8ef2d62b1e81948f1e9b", tx_timestamp: "2024-07-12 07:15:18", amount: 101 },
        { from_address: "0x25284abf9c20302def4f258acc469cd1873d9819", tx_hash: "0xa1f6ea39d298fd8aba3c74c18a69b851ec72adb785f1f3df44f87e307875ec37", tx_timestamp: "2024-07-13 18:29:38", amount: 102 },
        { from_address: "0x6875c161f0d04257f6a9366d035761580800d7fb", tx_hash: "0x5d988b0986ef061549aa28854cb8f2661eb62300e4d845e582cfe2be1ee71d28", tx_timestamp: "2024-07-15 09:58:34", amount: 274060 },
        { from_address: "0xb04439127063c8acf3893ec8f36274f67325e8da", tx_hash: "0xd3c721b2f0e99b4520d079ee3adb76b1afccdafa2fc8768e90e285c5321f7633", tx_timestamp: "2024-07-15 10:32:04", amount: 22850 },
        { from_address: "0x9793d88fd35101ee9ecddb4719011653912294c9", tx_hash: "0xdbaa40b28b9e990fb6314eaff726eb1644cf4add2f41b1c76f42f1ba042932c2", tx_timestamp: "2024-07-15 13:32:25", amount: 56578.95333828517 },
        { from_address: "0x59e5c7bb4273a26ee3be966781de84c3f29c21fd", tx_hash: "0x03bae42c0716c8f0e4f466a5eb533fb60026601e72236a7a1b883e0b77284442", tx_timestamp: "2024-07-15 17:31:28", amount: 8319.397608782681 },
        { from_address: "0x5b39e1563f0ed7e4673ee8855d1de1bb891d9b15", tx_hash: "0x17fb200e4bfc6f28d07918e5c7527c3fa729dba5e037767bd4bed3eb78d57c7b", tx_timestamp: "2024-07-15 17:43:18", amount: 90997.0730671 },
        { from_address: "0xd538db789873fde2842f64a6c9a845b2402df1e7", tx_hash: "0x1ea35db102174bce11370dc6037e8f10fd91d2fc0ad6777386821da1e413bd29", tx_timestamp: "2024-07-16 03:37:22", amount: 2014.6056794893027 },
        { from_address: "0xdadd53d528e09ad8fd58ec6bde461e27fa7d1547", tx_hash: "0xdfd86a200353e0f1e9df255c2126312b1c928c95fa18ed068d3be08bfd88c683", tx_timestamp: "2024-07-16 06:24:02", amount: 26636.765684627157 }
    ];

    // Filter data based on search term
    const filteredData = transactionData.filter(item =>
        item.from_address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.tx_hash.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.eat_fast_swap_tab_container}>
            <Table
                columns={columns}
                dataSource={filteredData}
                bordered
                pagination={{ pageSize: 10,showSizeChanger: false }}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.search_bar}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search transactions..."
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                )}
                rowKey="tx_hash" // Use tx_hash as the unique key for each row
            />
        </div>
    );
};

const mapState = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapState, mapDispatchToProps)(EAT_FASTSwapTab);
