import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { Table, Switch, Checkbox } from "antd";
import styles from './modal.module.scss';
import { CustomTextfield } from '../../../../../shared/ui/text-field';
import { FaSearch } from 'react-icons/fa';
import debounce from 'lodash.debounce'; // Import lodash debounce function
import { getCurrentSelectedChannelRecord, getIsLoadingListOfCategoriesForChannel, getListOfCategoriesForChannel } from '../../../logic/channels/selectors';
import { fetchListOfCategoriesForChannel, updateChannelCategories } from '../../../logic/channels/actions';


const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
};

const ListOfCatagories = ({
    listOfAllCategories,
    record,
    setDetailsModalFooter,
    setIsDetailsModalOpen,
    updateChannelCategories,
    fetchListOfCategoriesForChannel,
    isLoadingListOfCategories,
    is_pagination_enabled = true,
    refresh,
    ...props
}) => {
    const [channelsData, setChannelsData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });


    useEffect(() => {
        if (record?.channel_id) {
            fetchListOfCategoriesForChannel({ keyword: "", page: 1, limit: 10, is_pagination_enabled: true, channel_id: record?.channel_id });
        }
    }, [record?.channel_id, refresh]);

    // Update the footer when `handleOkay` is updated


    useEffect(() => {
     
        const allChannels = listOfAllCategories?.formatted_data || [];
      
        setChannelsData(allChannels);
    }, [listOfAllCategories, record, searchTerm, is_pagination_enabled]);

    const handleCheckboxChange = (iab_category_id, checked) => {
        updateChannelCategories({
            channel_id: record?.channel_id,
            iab_category_id: iab_category_id,
            checked: checked
        });
    };

    // Debounce the search function to avoid excessive requests
    const debouncedSearch = useCallback(
        debounce((searchTerm, page, pageSize, is_pagination_enabled, record) => {
      
            fetchListOfCategoriesForChannel({ keyword: searchTerm, page, limit: pageSize, is_pagination_enabled, channel_id: record?.channel_id });
        }, 1000), // 1-second debounce delay
        []
    );

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, 10, is_pagination_enabled, record);
    }, [searchTerm, pagination.current, debouncedSearch, is_pagination_enabled, record?.channel_id]);

    const columns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record) => (
                <Checkbox
                    checked={record.enabled}
                    onChange={(e) => handleCheckboxChange(record.iab_category_id, e.target.checked)} // Use iab_category_id for checkbox change
                />
            ),
        },
        {
            title: 'Title',
            dataIndex: 'name',
            key: 'name',
        },


    ];

    const data = channelsData.map((channel, index) => ({
        key: index + 1,
        serialNumber: index + 1,
        name: channel.name,
        enabled: channel.enabled,
        iab_category_id: channel.id
    }));



    const handleCancel = (e) => {
        setIsDetailsModalOpen(false)
    }

    const handleTableChange = (pagination) => {
        // setPagination(prev => ({
        //     ...prev,
        //     current: pagination.current,
        //     pageSize: pagination.pageSize,
        // }));
    };

    const chunkedData = chunkArray(channelsData, 15);

    return (
        <div className={styles.list_of_allowed_channels_container}>

            <div className={styles.checkboxGrid}>
                {chunkedData.map((columnData, columnIndex) => (
                    <div key={columnIndex} className={styles.column}>
                        {columnData.map((channel, index) => (
                            <div key={index} className={styles.checkboxItem}>
                                <Checkbox
                                    checked={channel.enabled}
                                    onChange={(e) => handleCheckboxChange(channel.id, e.target.checked)}
                                >
                                    {channel.name}
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

const mapState = (state) => ({
    listOfAllCategories: getListOfCategoriesForChannel(state),
    isLoadingListOfCategories: getIsLoadingListOfCategoriesForChannel(state),
    record: getCurrentSelectedChannelRecord(state)
});

const mapDispatchToProps = (dispatch) => ({
    updateChannelCategories: (data) => dispatch(updateChannelCategories(data)),
    fetchListOfCategoriesForChannel: (data) => dispatch(fetchListOfCategoriesForChannel(data)),
});

export default connect(mapState, mapDispatchToProps)(ListOfCatagories);
