import {  PermissionsAPI } from "../../../api";
import { setPermissions } from "./slice";

const fetchPermissions = (data) => (dispatch) => {
    PermissionsAPI.getPermissions(data)
        .then((response) => {
         
            let formatted_data = response?.data?.permissions
            console.log(formatted_data)
            dispatch(setPermissions(formatted_data))

        })
        .catch((error) => {
            console.error(error)
        })
};

export {
    fetchPermissions
}