
import { setCurrentLoggedInUser } from "./slice";
const BASE_URL = process.env.REACT_APP_BACKEND_URL
const loginUsingGoogle = (data) => (dispatch) => {
    window.location.href = `${BASE_URL}auth/loginWithGoogle`;
};
const logoutGoogle = (data) => (dispatch) => {
    window.location.href = `${BASE_URL}auth/logout`;
};
    
export {
    loginUsingGoogle,
    logoutGoogle
}