import { apiGet, apiPost, apiPostWithFile, apiPut } from "../../shared/api/api";

const getAllUsers = (data) => apiPost('users/get_all_users', data);
const updateUserRole = (data) => apiPost('users/update_user_role', data);
const getAllUserRoles = (data) => apiPost('users/get_all_user_roles', data);

export {
    getAllUsers,
    updateUserRole,
    getAllUserRoles
}