import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Radio } from 'antd';
import styles from '../../styles.module.scss';

import { fetchListOfUsers, fetchListOfUserRoles, updateUserRole } from '../../../logic/users/actions';
import { getListOfUsers, getListOfUserRoles, getIsLoadingListOfUsers, getIsLoadingListOfUserRoles } from '../../../logic/users/selectors';

const UsersOverview = ({
    listOfUsers,
    listOfUserRoles,
    isLoadingListOfUsers,
    isLoadingListOfUserRoles,
    fetchListOfUsers,
    fetchListOfUserRoles,
    updateUserRole,
    setIsDetailsModalOpen,
    setDetailsModalTitle,
    setCurrentDetailsFormToRender,
    setDetailsModalFooter,
    ...props
}) => {
    const [selectedRole, setSelectedRole] = useState({});

    useEffect(() => {
        fetchListOfUsers({});
        fetchListOfUserRoles({});
    }, [fetchListOfUsers, fetchListOfUserRoles]);

    useEffect(() => {
        const initialSelectedRoles = {};
        listOfUsers.forEach(user => {
            initialSelectedRoles[user.id] = user.role_id;
        });
        setSelectedRole(initialSelectedRoles);
    }, [listOfUsers]);

    const handleRoleChange = (userId, roleId) => {
        setSelectedRole({ ...selectedRole, [userId]: roleId });
        updateUserRole({ userId, roleId });
    };

    useEffect(()=>{ 
        console.log("listOfUserRoles",listOfUserRoles) 
    },[listOfUserRoles])

    const columns = [
        {
            title: 'User',
            dataIndex: 'display_name',
            key: 'display_name',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (text, record) => (
                <Radio.Group
                    onChange={(e) => handleRoleChange(record.id, e.target.value)}
                    value={selectedRole[record.id]}
                >
                    {listOfUserRoles.map(role => (
                        <Radio key={role.id} value={role.id}>
                            {role.role_name}
                        </Radio>
                    ))}
                </Radio.Group>
            ),
        },
    ];

    return (
        <div className={styles.users_overview_container}>
            <Table
                bordered
                columns={columns}
                dataSource={listOfUsers}
                loading={isLoadingListOfUsers || isLoadingListOfUserRoles}
                rowKey="id"
                pagination={false}
            />
        </div>
    );
};
 
const mapStateToProps = (state) => ({
    listOfUsers: getListOfUsers(state),
    listOfUserRoles: getListOfUserRoles(state),
    isLoadingListOfUsers: getIsLoadingListOfUsers(state),
    isLoadingListOfUserRoles: getIsLoadingListOfUserRoles(state),
});

const mapDispatchToProps = {
    fetchListOfUsers,
    fetchListOfUserRoles,
    updateUserRole,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersOverview);
