import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Switch } from 'antd'; // Assuming Ant Design's Switch
import styles from './modal.module.scss';
import { CustomButton } from '../../../../../shared/ui/button';
import { updateVendorChannelsInBulk } from '../../../logic/vendor/actions';
import ic_warning from '../../../../../assets/icons/warning-sign.png';
import { CustomHeading } from '../../../../../shared/ui/heading';
import { getCurrentSelectedVendor } from '../../../logic/vendor/selectors';

const WarningBeforeEnablingOrDisablingAllChannels = ({
    record,
    setIsDetailsModalOpen,
    setDetailsModalFooter,
    updateVendorChannelsInBulk,
    ...props
}) => {
    const [isEnableAction, setIsEnableAction] = useState(true); // State to toggle enable/disable action

    const handleOkay = useCallback(() => {
        updateVendorChannelsInBulk({ 
            vendor_id: record.vendor_id, 
            checked: isEnableAction, 
            keyword: "" 
        });
        setIsDetailsModalOpen(false);
    }, [record?.vendor_id, isEnableAction]);

    const handleCancel = () => {
        setIsDetailsModalOpen(false);
    };

    useEffect(() => {
        setDetailsModalFooter(
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                <CustomButton
                    title={isEnableAction ? "Yes, enable all channels" : "Yes, disable all channels"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    colorScheme={isEnableAction ? "success" : "danger"}
                    padding="5px 10px 0px 10px"
                    onClick={handleOkay}
                />
                <CustomButton
                    title={"Cancel"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleCancel}
                />
            </div>
        );
    }, [handleOkay, isEnableAction]);

    return (
        <div className={styles.delete_vendor_warning_modal}>
            <div className={styles.image_container}>
                <img src={ic_warning} width={'45%'} />
            </div>
            <div className={styles.message_container}>
                <CustomHeading 
                    textAlign='center' 
                    font_size="1.4" 
                    unit="rem" 
                    headingText={`Are you sure you want to ${isEnableAction ? "enable" : "disable"} all the channels for ${record?.name}? It's a massive action to take!!`} 
                />
            </div>
            <div className={styles.switch_container} style={{ marginTop: "15px", textAlign: "center" }}>
                {/* <span style={{ fontSize: "1rem", marginRight: "10px" }}>
                    {isEnableAction ? "Enable All" : "Disable All"}
                </span> */}
                <Switch 
                    checked={isEnableAction} 
                    onChange={setIsEnableAction} 
                    checkedChildren="Enable All" 
                    unCheckedChildren="Disable All" 
                    style={{ transform: "scale(1.2)" }} 
                />
            </div>
        </div>
    );
};

const mapState = (state) => ({
    record: getCurrentSelectedVendor(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateVendorChannelsInBulk: (data) => dispatch(updateVendorChannelsInBulk(data)),
});

export default connect(mapState, mapDispatchToProps)(WarningBeforeEnablingOrDisablingAllChannels);
