import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Table, Button } from "antd";
import styles from './model.module.scss';
import { updateVendorStatus } from "../../../logic/channels/actions";
import { CustomButton } from "../../../../../shared/ui/button";
import { FaPlus, FaRegWindowClose, FaSearch } from "react-icons/fa";
import { FaCross, FaXbox } from "react-icons/fa";
import { addFaceProductionRecord, fetchListOfAllProductsForPeople, removeFaceProductionRecord } from "../../../logic/product/actions";
import { getIsLoadingListOfAllProducts, getListOfListOfAllProducts } from "../../../logic/product/selectors";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import debounce from 'lodash.debounce'; // Import lodash debounce function
import { getCurrentSelectedPeople } from "../../../logic/people-manager/selectors";


const ListOfAllAvailableProducts = ({
    listOfAllProducts,
    fetchListOfAllProductsForPeople,
    addFaceProductionRecord,
    isLoadingListOfAllProducts,
    removeFaceProductionRecord,
    row_record = null,
    is_pagination_enabled = true,
    ...props }) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });
    const prevSearchTerm = useRef(''); // Store the previous search term

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
      
        return () => {
            setPagination(null)
        }
    }, [])

    useEffect(() => {

            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
       
    }, [row_record?.id]);

    const debouncedSearch = useCallback(
        debounce((searchTerm, page, pageSize, row_record, is_pagination_enabled) => {
             fetchListOfAllProductsForPeople({ keyword: searchTerm, page, limit: pageSize, facial_recognition_id: row_record?.id, is_pagination_enabled });
        }, 1000), // 1-second debounce delay
        []
    );

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, 10, row_record, is_pagination_enabled);
    }, [searchTerm, pagination.current, debouncedSearch, row_record?.id]);


    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);

    useEffect(() => {
    
        setData(listOfAllProducts?.formatted_data);
        setPagination(prev => ({
            ...prev,
            total: listOfAllProducts?.totalRecords,
            pageSize: 10,
            page: listOfAllProducts?.currentPage
        }));

    }, [listOfAllProducts, row_record]);

    const handleAdd = (record) => {
        if (row_record != null) {

            setData((prev) => {
                let updated_list = prev.map((rec) => {
                    if (rec.product_id === record.product_id) {
                        return {
                            ...rec,
                            selected: true
                        }
                    } else {
                        return rec
                    }
                })
                return updated_list
            })

            addFaceProductionRecord({
                facial_recognition_id: row_record.id,
                product_id: record.product_id,
                currentPage: pagination?.current,
                searchTerm: searchTerm
            })

            // setPagination((prev) => ({
            //     ...prev,
            //     current: 1,
            // }));
        }
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: '    ',
            key: 'logo_url',
            render: (logo_url, record) => {
            
                const renderProfileImage = (url, name) => {
                    if (url) {
                        return (
                            <img
                                src={url}
                                alt={name}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ''; // Reset the image source if it fails
                                }}
                                style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                            />
                        );
                    } else {
                        const initials = name.split(' ').map(n => n[0]).join('');
                        return (
                            <div style={{
                                width: '30px',
                                height: '30px',
                                // borderRadius: '50%',
                                backgroundColor: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fff',
                            }}>
                                {initials}
                            </div>
                        );
                    }
                };

                return renderProfileImage(record?.logo_url, record.product_title);
            },
        },

        {
            title: "Product Title",
            dataIndex: "product_title",
            key: "product_title",
        },
        {
            title: "Vendor",
            dataIndex: "vendor_name",
            key: "vendor_name",
        },
        {
            title: "Action",
            key: "action",

            render: (text, record) => (
                !record?.selected ?
                    <CustomButton
                        title="Add"
                        width="100%"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        onClick={() => handleAdd(record)}
                        icon={<FaPlus size={15} />}
                    /> : <CustomButton
                        title="Remove"
                        width="100%"
                        colorScheme="danger"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        onClick={() => handleRemove(record)}
                        icon={<FaRegWindowClose size={15} />}
                    />
            ),
        },
    ];

    const handleTableChange = (pagination) => {
        setPagination({
            ...pagination,
            current: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    const handleRemove = (record) => {
        // Remove the product from the data
        // const newData = data.filter((item) => item.key !== key);
        // setData(newData);
        // setPagination((prev) => ({
        //     ...prev,
        //     current: 1,
        // }));
        setData((prev) => {
            let updated_list = prev.map((rec) => {
                if (rec.product_id === record.product_id) {
                    return {
                        ...rec,
                        selected: false
                    }
                } else {
                    return rec
                }
            })
            return updated_list
        })
        if (row_record)
            removeFaceProductionRecord({
                facial_recognition_id: row_record?.id,
                product_id: record.product_id,
                currentPage: pagination?.current,
                searchTerm: searchTerm
            })
    };

    return (
        <div className={styles.list_of_available_products_container}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: listOfAllProducts?.totalRecords, // Assuming totalRecords is returned from the API
                    showSizeChanger: false
                }}
                onChange={handleTableChange}
                bordered
                loading={isLoadingListOfAllProducts}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.search_bar}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search i.e Sports TV"
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfAllProducts: getListOfListOfAllProducts(state),
    isLoadingListOfAllProducts: getIsLoadingListOfAllProducts(state),
    row_record: getCurrentSelectedPeople(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfAllProductsForPeople: (data) => dispatch(fetchListOfAllProductsForPeople(data)),
    addFaceProductionRecord: (data) => dispatch(addFaceProductionRecord(data)),
    removeFaceProductionRecord: (data) => dispatch(removeFaceProductionRecord(data)),

});

export default connect(mapState, mapDispatchToProps)(ListOfAllAvailableProducts);
