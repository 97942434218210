export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};



export const eraseCookie = (name) => {
    const path = process.env.REACT_APP_ENV === 'dev' ? '/' : '/';
    const domain = process.env.REACT_APP_ENV === 'dev' ? 'localhost' : 'studio.edgevideo.com';
    document.cookie = `${name}=; Path=${path}; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  
    window.location.href = '/';
};

export const capitalizeFirstLetter = (string) => {
    return string
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};


export const isValidHlsStream = (url) => {
    // Regular expression to validate HLS stream URL allowing various formats
    const hlsStreamRegex = /^(https?:\/\/[^\s]+(\.m3u8|\/hls\/|\/playlist\.m3u8|\/stream\.m3u8|\/live\.m3u8|\/index\.m3u8)(\?.*)?)$/;
    return hlsStreamRegex.test(url);
};

export const isValidUrl = (url) => {
    const urlRegex = /^(https?:\/\/)[\w.-]+(\.[a-zA-Z]{2,})([\/\w .-]*)*\/?$/;
    return url ? urlRegex.test(url) : true; // Allow empty URLs to pass validation
};
