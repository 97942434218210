import toast from "react-hot-toast";
import { ProductAPI } from "../../../api";
import { setIsLoadingListOfAllProducts, setIsLoadingListOfAllProductsForOverview, setIsLoadingListOfFacialRecognitions, setIsLoadingListOfProductsToExport, setListOfAllProducts, setListOfAllProductsForOverview, setListOfAllSelectedProducts, setListOfFacialRecognitions, setListOfProductsToExport } from "./slice";

let abortController_fetchListOfAllProducts = null;  // Declare globally within the file

let abortController_fetchListOfAllProductsForPeople = null;  // Declare globally within the file
let abortController_fetchListOfProductsToExport = null
const fetchListOfAllProductsForOverview = (data) => (dispatch) => {
    if (abortController_fetchListOfAllProducts) {
        abortController_fetchListOfAllProducts.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllProducts = new AbortController();
    const { signal } = abortController_fetchListOfAllProducts;
    dispatch(setIsLoadingListOfAllProductsForOverview(true))
    ProductAPI.getAllProducts(data, signal)
        .then((response) => {

            let formatted_data = response?.data?.map((record) => {
                return {
                    image_link: record.image_link,
                    english_title: record.english_title,
                    product_id: record.product_id,
                    vendor_name: record.vendor_name,
                    vendor_id: record?.vendor_id,
                    vendor_platform: record.platform,
                    description: record.description,
                    affiliate_link: record.affiliate_link,
                    alt_title: record?.alt_title
                }
            })

            dispatch(setListOfAllProductsForOverview({
                formatted_data: formatted_data,
                totalRecords: response.totalRecords,
                totalPages: response.totalPages,
                currentPage: response.currentPage
            }))

            dispatch(setIsLoadingListOfAllProductsForOverview(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfAllProductsForOverview(false))
        })
};

const fetchListOfAllProductsForPeople = (data) => (dispatch) => {
    if (abortController_fetchListOfAllProductsForPeople) {
        abortController_fetchListOfAllProductsForPeople.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllProductsForPeople = new AbortController();
    const { signal } = abortController_fetchListOfAllProductsForPeople;
    dispatch(setIsLoadingListOfAllProducts(true))
    ProductAPI.getAllProductsForPeople(data, signal)
        .then((response) => {

            let formatted_data = response?.data?.map((record) => {
                return {
                    product_id: record.product_id,
                    product_title: record.english_title,
                    selected: record?.selected,
                    logo_url: record?.image_link,
                    vendor_name: record?.vendor_name
                }
            })

            dispatch(setListOfAllProducts({
                formatted_data: formatted_data,
                totalRecords: response.totalRecords,
                totalPages: response.totalPages,
                currentPage: response.currentPage
            }))

            dispatch(setIsLoadingListOfAllProducts(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfAllProducts(true))

        })
};

const fetchListOfProductsToExport = (data) => (dispatch) => {
    if (abortController_fetchListOfProductsToExport) {
        abortController_fetchListOfProductsToExport.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfProductsToExport = new AbortController();
    const { signal } = abortController_fetchListOfProductsToExport;

    dispatch(setIsLoadingListOfProductsToExport(true));

    return ProductAPI.getAllProductsToExport(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        ...record
                    };
                });

                dispatch(setListOfProductsToExport(formatted_data));
            }

            dispatch(setIsLoadingListOfProductsToExport(false));
            return response; // Return the response to the caller
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingListOfProductsToExport(false));
            throw error; // Rethrow the error to the caller
        });
};

const addFaceProductionRecord = (data) => (dispatch) => {
    // Make the API call to add a face production record
    const addPromise = ProductAPI.addFaceProductRecord(data);

    // Use toast.promise for cleaner toast handling
    toast.promise(addPromise, {
        loading: 'Adding record...',
        success: (response) => {
            return 'Successfully added!';  // Return a string for success message
        },
        error: (error) => {
            // Check for specific error messages
            if (error?.error?.includes('duplicate key value violates unique constraint')) {
                return 'Already selected..!!';  // Return a string for specific error
            } else {
                return 'Error while adding product';  // General error message
            }
        },
    }, {
        // Set toast options for duration
        duration: 10000, // Auto-cancel after 10 seconds
    });
};

const addProductRecord = (data, queryParams) => (dispatch) => {
    // Make the API call to add a face production record
    const addPromise = ProductAPI.addProductRecord(data);

    // Use toast.promise for cleaner toast handling
    return toast.promise(addPromise, {
        loading: 'Adding product...',
        success: (response) => {
            console.log('queryParams',queryParams)
            dispatch(fetchListOfAllProductsForOverview(queryParams)); // Fetch updated list with query params
            return 'Successfully added!';  // Return a string for success message
        },
        error: (error) => {
            // Check for specific error messages
            if (error?.error?.includes('duplicate key value violates unique constraint')) {
                return 'Already selected..!!';  // Return a string for specific error
            } else {
                return 'Error while adding product';  // General error message
            }
        },
    }, {
        // Set toast options for duration
        duration: 2000, // Auto-cancel after 10 seconds
    });
};


const updateProductRecord = (data, listSearchParams) => (dispatch) => {
    // Make the API call to add a face production record
    const addPromise = ProductAPI.updateProduct(data);

    // Use toast.promise for cleaner toast handling
    return toast.promise(addPromise, {
        loading: 'Updating product...',
        success: (response) => {
            dispatch(fetchListOfAllProductsForOverview(listSearchParams))
            return 'Successfully updated!';  // Return a string for success message
        },
        error: (error) => {
            // Check for specific error messages
            if (error?.error?.includes('duplicate key value violates unique constraint')) {
                return 'Already selected..!!';  // Return a string for specific error
            } else {
                return 'Error while adding product';  // General error message
            }
        },
    }, {
        // Set toast options for duration
        duration: 2000, // Auto-cancel after 10 seconds
    });
};

const fetchListOfAllSelectedProducts = (data) => (dispatch) => {
    ProductAPI.getAllFaceProdRecords(data)
        .then((response) => {

            let formatted_data = response?.data?.map((record) => {
                return {
                    product_id: record.product_id,
                    product_title: record.product_title,
                }
            })

            dispatch(setListOfAllSelectedProducts(formatted_data))
        })
        .catch((error) => {
            console.error(error)
        })
};

const removeFaceProductionRecord = (data) => (dispatch) => {
    const removePromise = ProductAPI.removeFaceProductRecord(data);

    // Use toast.promise for cleaner toast handling
    toast.promise(removePromise, {
        loading: 'Removing record...',
        success: (response) => {

            return 'Successfully removed!'; // Return a string for success message
        },
        error: (error) => {
            console.error(error);
            return 'Error while removing product'; // Return a string for general error message
        },
    });
};




export {
    // Note user
    fetchListOfAllProductsForOverview,
    fetchListOfAllSelectedProducts,
    fetchListOfAllProductsForPeople,
    addFaceProductionRecord,
    removeFaceProductionRecord,
    addProductRecord,
    updateProductRecord,
    fetchListOfProductsToExport
}