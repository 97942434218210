import { Routes } from "../../routes/Routes"
import { connect } from "react-redux";
import styles from './styles.module.scss'
import { CustomHeading } from "../../../shared/ui/heading";
import { CustomTextfield } from "../../../shared/ui/text-field";
import { useEffect, useState } from "react";
import { FaEye, FaGoogle, FaWallet } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { CustomButton } from "../../../shared/ui/button";
import right_side_image from '../../../assets/images/3279619.jpg'
import { useNavigate } from "react-router-dom";
import { loginUsingGoogle } from "../logic/actions";
const AuthenticationPage = ({ loginUsingGoogle, ...props }) => {
    useEffect(() => {
        loginUsingGoogle()
    }, [])
    // const navigation = useNavigate()
    // const [email, setEmail] = useState("")
    // const [password, setPassword] = useState("")
    // const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    // const handleIconClick = (e) => {
    //     setIsPasswordVisible(!isPasswordVisible)
    // }
    // return <div className={styles.container}>
    //     <div className={styles.left_side}>
    //         <div className={styles.heading}>
    //             <CustomHeading font_size="2" unit="rem" headingText="Welcome back" />
    //         </div>
    //         <div className={styles.sub_heading}>
    //             <CustomHeading font_size="1" unit="rem" headingText="Please enter your details." />
    //         </div>
    //         <div className={styles.email_label}>
    //             <CustomHeading font_size="0.7" unit="rem" headingText="Your email" />
    //         </div>
    //         <div className={styles.email}>
    //             <CustomTextfield border_radius="10px" colorScheme="primary" place_holder="Enter your email" font_size="0.7" unit="rem" value={email} onChange={(e) => { setEmail(e.target.value) }} />
    //         </div>
    //         <div className={styles.password_label}>
    //             <CustomHeading font_size="0.7" unit="rem" headingText="Your password" />
    //         </div>
    //         <div className={styles.password}>
    //             <CustomTextfield border_radius="10px" handleIconClick={handleIconClick} icon={!isPasswordVisible ? <FaEye size={20} /> : <FaEyeSlash size={20} />} type={isPasswordVisible ? "text" : "password"} colorScheme="primary" place_holder="Enter your password" font_size="0.7" unit="rem" value={password} onChange={(e) => { setPassword(e.target.value) }} />
    //         </div>
    //         {/* <div className={styles.or_text_container}>
    //             <CustomHeading width="fit-content" font_size="0.8" unit="rem" headingText="Or" />
    //         </div> */}
    //         {/* <div className={styles.button_wallet}>
    //             <CustomButton onClick={() => {

    //             }} icon={<FaWallet size={18} />} border_radius="7px" title="Connect wallet and login" width="100%" padding="05px 10px 5px 10px" font_size="0.8" unit="rem" />
    //         </div> */}
    //         <div className={styles.button_google}>
    //             <CustomButton onClick={() => {
    //                 loginUsingGoogle()
    //             }} colorScheme="secondary" icon={<FaGoogle size={18} />} border_radius="7px" title="Continue with Google" width="100%" padding="05px 10px 5px 10px" font_size="0.8" unit="rem" />
    //         </div>
    //     </div>
    //     <div className={styles.right_side}>
    //         <img src={right_side_image} width="100%" />
    //     </div>
    // </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    loginUsingGoogle: (data) => dispatch(loginUsingGoogle(data)),
});


export default connect(mapState, mapDispatchToProps)(AuthenticationPage)