import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Checkbox, Row, Col, Button, Input, List } from "antd";
import styles from './modal.module.scss';
import { FaPlus, FaTrash } from "react-icons/fa";
import { getIsUpdatingBlacklistKeywords } from "../../../logic/channels/selectors";
import { updateBlacklistKeywordsList } from "../../../logic/channels/actions";
import { CustomButton } from "../../../../../shared/ui/button";
import { CustomTextfield } from "../../../../../shared/ui/text-field";

const Blacklisted_Keywords = ({
    updateBlacklistKeywordsList,
    isUpdatingBlacklistKeywords,
    record,
    refresh,
    ...props
}) => {
    const [blackListViatorDestination, setBlackListViatorDestination] = useState([]);
    const [blacklistKeywords, setBlacklistKeywords] = useState([]);
    const [newKeyword, setNewKeyword] = useState("");

    useEffect(()=>{
        return ()=>{
            setBlacklistKeywords([])
        }
    },[])

    useEffect(() => {
        console.log('record?.keyword_blacklist', record)
        if (record?.keyword_blacklist) {
            setBlacklistKeywords(record.keyword_blacklist.split(","));
        }else{
            setBlacklistKeywords([])
        }
    }, [record.channel_id,refresh]);

    const handleToggle = (selectedFacialRecognition) => {
        setBlackListViatorDestination(prevDestinations =>
            prevDestinations.map(destination =>
                destination.destination_id === selectedFacialRecognition?.destination_id
                    ? { ...destination, enabled: !destination.enabled }
                    : destination
            )
        );

        updateBlacklistKeywordsList({
            channel_id: record?.channel_id,
            keywords: []
        });
    };

    const handleAddKeyword = () => {
        if (newKeyword.trim() !== "") {
            const updatedKeywords = [...blacklistKeywords, newKeyword.trim()];
            setBlacklistKeywords(updatedKeywords);
            updateBlacklistKeywordsList({
                channel_id: record?.channel_id,
                keywords: updatedKeywords
            });
            setNewKeyword("");
        }
    };

    const handleRemoveKeyword = (keyword) => {
        const updatedKeywords = blacklistKeywords.filter(k => k !== keyword);
        setBlacklistKeywords(updatedKeywords);
        updateBlacklistKeywordsList({
            channel_id: record?.channel_id,
            keywords: updatedKeywords
        });
    };

    return (
        <div className={styles.list_of_black_list_keywords}>
            <div className={styles.add_keyword_section}>

                {/* <Input
                    placeholder="Enter keyword to blacklist"
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                    style={{ width: "80%", marginRight: "10px" }}
                /> */}

                <CustomTextfield
                    type="text"
                    border_radius="5px"
                    colorScheme="primary"
                    place_holder="nter keyword to blacklist"
                    font_size="0.7" unit="rem"
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                />
                <CustomButton
                    title="Add to Blacklist"
                    width="100%"
                    font_size="0.7"
                    unit="rem"
                    padding="5px 5px 0px 5px"
                    colorScheme="secondary"
                    icon={<FaPlus />}
                    onClick={() => handleAddKeyword()}
                />
                {/* <Button
                        type="primary"
                        icon={<FaPlus />}
                        onClick={handleAddKeyword}
                    >
                        Add to Blacklist
                    </Button> */}
            </div>
            <List
                header={<div>Blacklisted Keywords</div>}
                bordered

                dataSource={blacklistKeywords}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <Button
                                type="danger"
                                icon={<FaTrash />}
                                onClick={() => handleRemoveKeyword(item)}
                            >
                                Remove
                            </Button>
                        ]}
                    >
                        {item}
                    </List.Item>
                )}
                className={styles.list_of_keyword}
                // style={{ marginTop: "20px",height:"30vh",overflowY:"scroll" }}
            />

        </div>
    );
};

const mapState = (state) => ({
    isUpdatingBlacklistKeywords: getIsUpdatingBlacklistKeywords(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateBlacklistKeywordsList: (data) => dispatch(updateBlacklistKeywordsList(data)),
});

export default connect(mapState, mapDispatchToProps)(Blacklisted_Keywords);
