import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import copy from 'copy-to-clipboard';
import styles from '../../styles.module.scss';
import { FaSearch } from 'react-icons/fa';
import { CustomTextfield } from '../../../../../shared/ui/text-field';

// Function to shorten the address or hash
const shortenString = (str) => {
    return str ? `${str.substring(0, 6)}...${str.substring(str.length - 4)}` : '';
};

// Status mapping for better readability
const statusMap = {
    0: 'Unsent',
    1: 'Sent (Not Verified)',
    2: 'Sent and Verified'
};

// Function to handle copying to clipboard
const handleCopy = (text) => {
    if (text) {
        copy(text);
        alert('Copied to clipboard!');
    }
};

const transactionData = [
    { 
        from_address: "0x25284abf9c20302def4f258acc469cd1873d9819",
        to_address: "0x25284abf9c20302def4f258acc469cd1873d9819",
        fast_eat_amount: 100,
        tx_polygon_amount: 2.5,
        txt_polygon_tx_hash: "0xdb8117b6cf51ed0a317ca4599ecae2f9333d8f5e5363d64878662400f320bb40",
        fast_eat_tx_hash: "0xd6ffd63b675b872901775e4b10b8cd44c0c2b69c8e8c156e904203444c08c83e",
        created: "2024-07-12 07:51:24",
        status: 2,
        modified: "2024-07-12 15:59:07",
        send_after: "2024-07-12 00:51:24"
    },
    { 
        from_address: "0x25284abf9c20302def4f258acc469cd1873d9819",
        to_address: "0x25284abf9c20302def4f258acc469cd1873d9819",
        fast_eat_amount: 100,
        tx_polygon_amount: 7.575,
        txt_polygon_tx_hash: "0x65ee3ed7cec50d11d1405b9a16df660187141df49eff883de3a00c1a43f1d576",
        fast_eat_tx_hash: "0xd6ffd63b675b872901775e4b10b8cd44c0c2b69c8e8c156e904203444c08c83e",
        created: "2024-07-12 07:51:24",
        status: 2,
        modified: "2024-08-12 00:52:05",
        send_after: "2024-08-12 00:51:24"
    },
    // ... (more data entries following the same structure)
];


const EAT_FAST_TX_OverviewTab = ({ ...props }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Define the columns structure for the transaction table
    const columns = [
        {
            title: 'From Address',
            dataIndex: 'from_address',
            key: 'from_address',
            width: '20%',
            render: (text) => (
                <div className={styles.txHashContainer}>
                    <span>{shortenString(text)}</span>
                    {text && (
                        <Button 
                            type="link" 
                            onClick={() => handleCopy(text)}
                            style={{ marginLeft: 8 }}
                        >
                            Copy
                        </Button>
                    )}
                </div>
            )
        },
        {
            title: 'To Address',
            dataIndex: 'to_address',
            key: 'to_address',
            width: '20%',
            render: (text) => (
                <div className={styles.txHashContainer}>
                    <span>{shortenString(text)}</span>
                    {text && (
                        <Button 
                            type="link" 
                            onClick={() => handleCopy(text)}
                            style={{ marginLeft: 8 }}
                        >
                            Copy
                        </Button>
                    )}
                </div>
            )
        },
        {
            title: 'Eat-FAST Swap Tx Hash',
            dataIndex: 'fast_eat_tx_hash',
            key: 'fast_eat_tx_hash',
            width: '25%',
            render: (text) => (
                <div className={styles.txHashContainer}>
                    <span>{shortenString(text)}</span>
                    {text && (
                        <Button 
                            type="link" 
                            onClick={() => handleCopy(text)}
                            style={{ marginLeft: 8 }}
                        >
                            Copy
                        </Button>
                    )}
                </div>
            )
        },
        {
            title: 'Polygon Transaction Hash',
            dataIndex: 'txt_polygon_tx_hash',
            key: 'txt_polygon_tx_hash',
            width: '25%',
            render: (text) => (
                <div className={styles.txHashContainer}>
                    <span>{shortenString(text)}</span>
                    {text && (
                        <Button 
                            type="link" 
                            onClick={() => handleCopy(text)}
                            style={{ marginLeft: 8 }}
                        >
                            Copy
                        </Button>
                    )}
                </div>
            )
        },
        {
            title: 'Eat-FAST Swap Amount',
            dataIndex: 'fast_eat_amount',
            key: 'fast_eat_amount',
            width: '15%',
            render: (amount) => <span>{amount.toFixed(2)}</span>
        },
        {
            title: 'Polygon Amount',
            dataIndex: 'tx_polygon_amount',
            key: 'tx_polygon_amount',
            width: '15%',
            render: (amount) => <span>{amount.toFixed(2)}</span>
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            width: '25%'
        },
        {
            title: 'Modified',
            dataIndex: 'modified',
            key: 'modified',
            width: '25%'
        },
        {
            title: 'Send After',
            dataIndex: 'send_after',
            key: 'send_after',
            width: '25%'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%', // Set the width for Status column
            render: (status) => <span>{statusMap[status] || 'Unknown'}</span> // Map status codes to text
        },
    ];

    // Filter data based on search term
    const filteredData = transactionData.filter(item =>
        item.from_address.includes(searchTerm) ||
        item.to_address.includes(searchTerm) ||
        item.fast_eat_tx_hash.includes(searchTerm) ||
        item.txt_polygon_tx_hash.includes(searchTerm)
    );

    return (
        // <div>
        //     <div className={styles.searchContainer}>
        //         <CustomTextfield 
        //             placeholder="Search transactions..."
        //             value={searchTerm}
        //             onChange={(e) => setSearchTerm(e.target.value)}
        //             prefix={<FaSearch />}
        //         />
        //     </div>
        //     <Table 
        //         columns={columns} 
        //         dataSource={filteredData} 
        //         rowKey="fast_eat_tx_hash" // Unique key for each row
        //         pagination={{ pageSize: 10 }} // Adjust pagination as needed
        //     />
        // </div>
        <div className={styles.eat_fast_swap_tab_container}>
            <Table
                columns={columns}
                dataSource={filteredData}
                bordered
                pagination={{ pageSize: 10,showSizeChanger: false }}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.search_bar}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search transactions..."
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                )}
                rowKey="tx_hash" // Use tx_hash as the unique key for each row
            />
        </div>
    );
};

// Optionally connect to Redux if needed
export default connect(null, null)(EAT_FAST_TX_OverviewTab);
