import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'permissions',
    initialState: {
        permissions: [],
    },
    reducers: {
        setPermissions: (state, action) => {
            state.permissions = action.payload;
        }
    },
});

export const {
    setPermissions
} = appSlice.actions;

export default appSlice.reducer;
