import { connect } from "react-redux";
import styles from './modal.module.scss'
import { useEffect, useState } from "react";
import TabsRender from "../general-components/TabsRender";
import ListOfAllVendors from "./ListOfAllVendors";

const VendorConfigurationModal = ({
    title = 'default title',
    record,
    listApiParams,
    setCurrentDetailsFormToRender,
    setDetailsModalTitle,
    setIsDetailsModalOpen,
    setDetailsModalFooter,
    ...props }) => {

    const [listOfTabs, setListOfTabs] = useState([]);
    useEffect(() => {
        let temp_tabs = [
            {
                label: "Vendor Configuration",
                screen: <ListOfAllVendors
                    listApiParams={listApiParams}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    record={record}
                    setDetailsModalFooter={setDetailsModalFooter}
                />
            }
        ]
        setListOfTabs(temp_tabs)
    }, [record, listApiParams])



    const handleTabOnChange = (e) => { }
    return <div className={styles.face_product_config_container}>
        <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapState, mapDispatchToProps)(VendorConfigurationModal)