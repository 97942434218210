import { createSlice, current } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'channel',
    initialState: {
        listOfChannels: [],
        listOfFacialRecognitionsLinkedToChannel: [],
        listOfBlacklistViatorTagLinkedToChannel: [],
        listOfBlacklistDestinationLinkedToChannel: [],

        listOfChannelWithOnlyCoreDetails: [],

        listOfChannelsForExporting: [],

        is_loading_list_of_channels_for_export: false,

        is_loading_list_of_channels_with_only_core_details: false,

        is_loading_list_of_facial_recognitions: false,
        is_loading_list_of_black_list_viator_tags: false,
        is_loading_list_of_black_list_destination: false,

        is_updating_blacklist_keywords:false,

        match_count_division_for_channel: [],
        is_loading_match_count_division_for_channel: false,

        listOfCategoriesForChannel: [],
        is_loading_list_of_categories_for_channels: false,

        is_loading_list_of_channels: false,
        stop_playing_stream: false,
        current_select_channel: null,

        is_loading_review_details: false
    },
    reducers: {
        setListOfChannels: (state, action) => {
            state.listOfChannels = action.payload;
        },
        setIsLoadingListOfChannels: (state, action) => {
            state.is_loading_list_of_channels = action.payload;
        },
        setStopPlayingStream: (state, action) => {
            state.stop_playing_stream = action.payload;
        },
        setListOfFacialRecognitionsLinkedToChannel: (state, action) => {
            state.listOfFacialRecognitionsLinkedToChannel = action.payload;
        },
        setIsLoadingListOfFacialRecognitionsLinkedToChannel: (state, action) => {
            state.is_loading_list_of_facial_recognitions = action.payload;
        },

        setListOfBlacklistViatorTagLinkedToChannel: (state, action) => {
            state.listOfBlacklistViatorTagLinkedToChannel = action.payload;
        },

        setIsLoadingListOfBlacklistViatorTagLinkedToChannel: (state, action) => {
            state.is_loading_list_of_black_list_viator_tags = action.payload;
        },


        setListOfBlacklistDestinationLinkedToChannel: (state, action) => {
            state.listOfBlacklistDestinationLinkedToChannel = action.payload;
        },

        setIsLoadingListOfBlacklistDestinationLinkedToChannel: (state, action) => {
            state.is_loading_list_of_black_list_destination = action.payload;
        },

        setCurrentChannelRecord: (state, action) => {
            state.current_select_channel = action.payload;
        },
        setMatchCountDivisionForChannel: (state, action) => {
            state.match_count_division_for_channel = action.payload;
        },

        setIsLoadingMatchCountDivisionForChannel: (state, action) => {
            state.is_loading_match_count_division_for_channel = action.payload;
        },
        setListOfChannelsWithOnlyCoreDetails: (state, action) => {
            state.listOfChannelWithOnlyCoreDetails = action.payload;
        },

        setIsLoadingListOfChannelsWithOnlyCoreDetails: (state, action) => {
            state.is_loading_list_of_channels_with_only_core_details = action.payload;
        },

        setIsLoadingReviewDetails: (state, action) => {
            state.is_loading_review_details = action.payload;
        },

        setListOfChannelForExporting: (state, action) => {
            state.listOfChannelsForExporting = action.payload;
        },

        setIsLoadingListOfChannelsForExporting: (state, action) => {
            state.is_loading_list_of_channels_for_export = action.payload;
        },

        setListOfCategoriesForChannel: (state, action) => {
            state.listOfCategoriesForChannel = action.payload;
        },

        setIsLoadingListOfCategoriesForChannel: (state, action) => {
            state.is_loading_list_of_categories_for_channels = action.payload;
        },

        setIsUpdatingBlacklistKeywords: (state, action) => {
            state.is_updating_blacklist_keywords = action.payload;
        },
    },
});

export const {
    setListOfChannels,
    setIsLoadingListOfChannels,
    setStopPlayingStream,
    setListOfFacialRecognitionsLinkedToChannel,
    setIsLoadingListOfFacialRecognitionsLinkedToChannel,
    setCurrentChannelRecord,
    setIsLoadingListOfBlacklistViatorTagLinkedToChannel,
    setListOfBlacklistViatorTagLinkedToChannel,
    setIsLoadingListOfBlacklistDestinationLinkedToChannel,
    setListOfBlacklistDestinationLinkedToChannel,
    setMatchCountDivisionForChannel,
    setIsLoadingMatchCountDivisionForChannel,
    setListOfChannelsWithOnlyCoreDetails,
    setIsLoadingListOfChannelsWithOnlyCoreDetails,
    setIsLoadingReviewDetails,
    setListOfChannelForExporting,
    setIsLoadingListOfChannelsForExporting,
    setListOfCategoriesForChannel,
    setIsLoadingListOfCategoriesForChannel,
    setIsUpdatingBlacklistKeywords
} = appSlice.actions;

export default appSlice.reducer;
