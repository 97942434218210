import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import styles from './modal.module.scss';
import { CustomDropDown } from '../../../../../shared/ui/dropdown';
import { getIsLoadingListOfChannelsWithOnlyCoreDetails, getIsLoadingLReviewDetails, getListOfChannelsWithOnlyCoreDetails } from '../../../logic/channels/selectors';
import { Card, Col, Divider, Progress, Row, Spin, Typography } from 'antd';
import ic_average_score from '../../../../../assets/icons/average.png'
import { fetchReviewDetails } from '../../../logic/channels/actions';
import { LoadingOutlined } from '@ant-design/icons';

const { Title } = Typography;

const ReviewCountDetails = ({
    //  record = null,
    listOfChannelsBasicDetails,
    isLoadingListOfChannels,
    fetchReviewDetails,
    average_results = null,
    isLoadingReviewDetails,
    ...props
}) => {
    const [channelReviewDetailsToRender, setChannelReviewDetailsToRender] = useState({
        count: 0,
        score: 0.0
    })

    const [listOfChannels, setListOfChannels] = useState([
        {
            label: 'Average result',
            value: 'default'
        }
    ])

    const [selectedChannel, setSelectedChannel] = useState(null)

    useEffect(() => {
        if (average_results) {
            setChannelReviewDetailsToRender({
                count: average_results.count,
                score: average_results.score?.toFixed(2)
            })
        }
    }, [average_results])

    useEffect(() => {
        if (selectedChannel!=null) {
            fetchReviewDetails({ channel_id: selectedChannel?.value })
                .then((response) => {
                    const averageScore = Number(response?.average_score);
                    const formattedScore = !isNaN(averageScore) 
                        ? averageScore.toFixed(2)  // Format to 2 decimal places
                        : "0.00"; // Default value if it's not a valid number
    
                    setChannelReviewDetailsToRender({
                        count: response?.score_count || 0,
                        score: formattedScore,
                    });
                })
                .catch((error) => {
                    console.error('Error fetching review details:', error);

                    setChannelReviewDetailsToRender({
                        count: 0,
                        score: 0.0,
                    });
                });
        }else{
            setChannelReviewDetailsToRender({
                count: average_results.count,
                score: average_results.score?.toFixed(2)
            }) 
        }
    }, [selectedChannel])
    useEffect(() => {
        if (listOfChannelsBasicDetails) {
            
            let formatted_data_for_drop_down = listOfChannelsBasicDetails.formatted_data.map(rec => ({
                label: rec.channel_name,
                value: rec.channel_id
            }));
        
            // Add 'Average results' as the first entry
            formatted_data_for_drop_down.unshift({
                label: 'Average results',
                value: 'default'
            });
        
            setListOfChannels(formatted_data_for_drop_down);
        }
    }, [listOfChannelsBasicDetails])
    return <div className={styles.review_count_details_modal_container}>
        <div>
            <Card bordered={false} className="criclebox">
                <div className={styles.header_container}>
                    <CustomDropDown
                        defaultValue='default'
                        options={listOfChannels}
                        onChange={(value, record) => {
                            if (value != 'default') {
                                setSelectedChannel(record)
                            } else {
                                setSelectedChannel(null)
                            }
                        }}
                    />
                </div>
                <Divider />
                <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                        <Col xs={18}>
                            <span>{'Reviews'}</span>
                            <Title level={3}>
                                {isLoadingReviewDetails ?  <Spin indicator={<LoadingOutlined spin />} size="medium" /> : channelReviewDetailsToRender.score}
                                <small style={{ marginLeft: "20px" }}>{isLoadingReviewDetails ?  '': channelReviewDetailsToRender?.count+' reviews' } </small>
                            </Title>
                        </Col>
                        <Col xs={6}>
                            {/* <div >{c.icon}</div> */}
                            <img src={ic_average_score} />
                        </Col>
                    </Row>

                </div>
                {/* <div style={{ marginTop: "5rem" }}>
                    <div>
                        <Progress percent={30} showInfo={false} />
                    </div>
                </div> */}
            </Card>
        </div>
    </div>
};

const mapState = (state) => ({
    listOfChannelsBasicDetails: getListOfChannelsWithOnlyCoreDetails(state),
    isLoadingListOfChannels: getIsLoadingListOfChannelsWithOnlyCoreDetails(state),
    isLoadingReviewDetails:getIsLoadingLReviewDetails(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchReviewDetails: (data) => dispatch(fetchReviewDetails(data)),
});

export default connect(mapState, mapDispatchToProps)(ReviewCountDetails);
