import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Button } from "antd";
import styles from './model.module.scss';
import { updateVendorStatus } from "../../../logic/channels/actions";
import { CustomButton } from "../../../../../shared/ui/button";
import { FaCross, FaXbox } from "react-icons/fa";
import { fetchListOfAllSelectedProducts, removeFaceProductionRecord } from "../../../logic/product/actions";
import { getListOfAllSelectedProducts } from "../../../logic/product/selectors";

const generateDummyData = (numRecords) => {
    const dummyData = [];
    for (let i = 1; i <= numRecords; i++) {
        dummyData.push({
            key: i, // Unique key for each row
            product_id: `PID-${i}`,
            product_title: `Product Title ${i}`,
        });
    }
    return dummyData;
};

const ListOfConfiguredProducts = ({
    listOfAllSelectedProducts,
    fetchListOfAllSelectedProducts,
    removeFaceProductionRecord,
    row_record = null,
    ...props }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (row_record)
            fetchListOfAllSelectedProducts({ facial_recognition_id: row_record?.id })
    }, [row_record,fetchListOfAllSelectedProducts])

    useEffect(() => {
        // if (listOfAllSelectedProducts)
            setData(listOfAllSelectedProducts);
    }, [listOfAllSelectedProducts]);

    const handleRemove = (record) => {
        // Remove the product from the data
        // const newData = data.filter((item) => item.key !== key);
        // setData(newData);
        if (row_record)
            removeFaceProductionRecord({ facial_recognition_id: row_record?.id, product_id: record.product_id })
    };

    const columns = [
        {
            title: "Product ID",
            dataIndex: "product_id",
            key: "product_id",
        },
        {
            title: "Product Title",
            dataIndex: "product_title",
            key: "product_title",
        },
        {
            title: "Action",
            key: "action",
            align: 'center',
            render: (text, record) => (
                <CustomButton
                    title="Remove"
                    width="100%"
                    colorScheme="danger"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={() => handleRemove(record)}
                    icon={<FaXbox size={15} />}
                />
            ),
        },
    ];

    return (
        <div className={styles.list_of_configured_products_container}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{ pageSize: 5,showSizeChanger: false }}
                bordered
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfAllSelectedProducts: getListOfAllSelectedProducts(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfAllSelectedProducts: (data) => dispatch(fetchListOfAllSelectedProducts(data)),
    removeFaceProductionRecord: (data) => dispatch(removeFaceProductionRecord(data)),
});

export default connect(mapState, mapDispatchToProps)(ListOfConfiguredProducts);
