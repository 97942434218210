import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Table, Switch } from "antd";
import styles from './modal.module.scss';
import { downloadListOfAssociateVendors, fetchListOfAllVendorsByChannelId, updateVendorStatus } from "../../../logic/channels/actions";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { getAllVendors, getAllVendorsNames, getIsLoadingListOfVendors } from "../../../logic/vendor/selectors";
import { CustomButton } from "../../../../../shared/ui/button";
import { FaDownload, FaPlus } from "react-icons/fa";
import { FaEdit, FaFileExcel, FaFilter, FaSearch } from "react-icons/fa";
import debounce from 'lodash.debounce';
import { fetchListOfAllVendors } from "../../../logic/vendor/actions";
import { sortedCountriesGroupedBySubRegionForFilter } from "../../../../../shared/utils/regions_with_countries";
import { CustomDropDown } from "../../../../../shared/ui/dropdown";
import { setListOfVendors } from "../../../logic/vendor/slice";
import WarningBeforeEnablingOrDisablingAllVendors from "./WarningBeforeEnablingOrDisablingAllVendors";

const ListOfAllVendors = ({
    updateVendorStatus,
    fetchListOfAllVendorsByChannelId,
    listOfVendors,
    isLoadingListOfVendors,
    downloadListOfAssociateVendors,
    fetchListOfAllVendors,
    listOfVendorPlatforms,
    listApiParams,
    setListOfVendors,
    setCurrentDetailsFormToRender,
    setDetailsModalTitle,
    setDetailsModalFooter,
    setIsDetailsModalOpen,
    record,

    ...props }) => {
    // State to track enabled/disabled vendors
    const [vendors, setVendors] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const prevSearchTerm = useRef('');
    const [listOfPlatforms, setListOfPlatforms] = useState([{ value: "default", label: "All platforms" }])
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [selectedCountry, setSelectedCountry] = useState(['all']);
    const [vendorsListSearchFilter, setVendorsListSearchFilter] = useState(null)
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });

    const [allowedVendors, setAllowedVendors] = useState([]);
    const initialLoadRef = useRef(false);

    useEffect(() => {
        return () => {
            setPagination(null)
        }
    }, [])


    useEffect(() => {
        setListOfVendors({
            formatted_data: [],
            totalRecords: 0,
            totalPages: 0,
            currentPage: 1,
        })
        setPagination({
            current: 1, // Reset to the first page
            pageSize: 10, // Reset the page size
        });
    }, [record.channel_id]);


    const debouncedSearch = useCallback(
        debounce((searchTerm, page, record, selectedPlatform, selectedCountry) => {
            setVendorsListSearchFilter({ keyword: searchTerm, page, limit: 10, channel_id: record?.channel_id, platform: selectedPlatform, country_code: selectedCountry })
            fetchListOfAllVendorsByChannelId({ keyword: searchTerm, page, limit: 10, channel_id: record?.channel_id, platform: selectedPlatform, country_code: selectedCountry });
        }, 1000), // 1-second debounce delay
        []
    );

    // useEffect(() => {
    //     if (record) {
    //         fetchListOfAllVendorsByChannelId({ keyword: "", page: 1, limit: 10, channel_id: record?.channel_id, platform: selectedPlatform, country_code: selectedCountry });
    //         setPagination(prev => ({
    //             ...prev,
    //             current: 1
    //         }));
    //         setSelectedCountry(['all'])
    //         setSelectedPlatform([])
    //         setSelectedRegion('all')
    //     }
    // }, [record?.channel_id])

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, record, selectedPlatform, selectedCountry);
    }, [searchTerm, pagination.current, debouncedSearch, record?.channel_id, selectedPlatform, selectedCountry]);


    useEffect(() => {
        if (listOfVendors) {
            const uniqueVendors = (vendors) => {
                const seen = new Set();
                return vendors?.filter(vendor => {
                    const duplicate = seen.has(vendor.vendor_id);
                    seen.add(vendor.vendor_id);
                    return !duplicate;
                });
            };

            const formatVendors = (vendors) => vendors?.map(vendor => ({
                vendor_id: vendor.vendor_id,
                vendor_name: vendor.name,
                vendor_platform: vendor.platform,
                vendor_domain_url: vendor.domain_url,
                enabled: vendor?.enabled
            }));

            const allVendors = uniqueVendors(listOfVendors?.formatted_data);
            const sortedVendors = formatVendors(allVendors)?.sort((a, b) => b.enabled - a.enabled);

            setVendors(sortedVendors);

            setPagination(prev => ({
                ...prev,
                total: listOfVendors.totalRecords,
                page: listOfVendors.currentPage,
                pageSize: 10,
            }));
        }
    }, [listOfVendors, record?.channel_id]);


    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);

    useEffect(() => {
        if (listOfVendorPlatforms) {
            let formatted_platforms = listOfVendorPlatforms.map((v) => {

                return {
                    value: v.platform,
                    label: v.platform
                }
            })
            setListOfPlatforms([{ value: "default", label: "All platforms" }, ...formatted_platforms])
            let ar = listOfVendorPlatforms.map(p => p.platform); // No need for return statement

            setSelectedPlatform(ar);

        }
    }, [listOfVendorPlatforms])

    // useEffect(() => {
    //     handleApplyFilter()
    // }, [selectedCountry, selectedPlatform, selectedRegion])

    // const handleApplyFilter = () => {
    //     setVendorsListSearchFilter({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: selectedCountry })
    //     fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: selectedCountry });
    // };

    // Handle enabling/disabling of vendors
    const handleToggle = (selectedVendor) => {

        setVendors((prev) => {
            let update_list = prev.map((v) => {
                if (v.vendor_id === selectedVendor?.vendor_id) {
                    return {
                        ...v,
                        enabled: !selectedVendor.enabled
                    }
                } else {
                    return v
                }
            })
            return update_list
        })
        updateVendorStatus({
            vendor_id: selectedVendor?.vendor_id,
            channel_id: record?.channel_id,
            checked: !selectedVendor.enabled,
            currentPage: pagination?.current,
            searchTerm: searchTerm,
            listApiParams: listApiParams
        });

    };
    // Define columns for the vendor table
    const columns = [

        {
            title: 'Vendor Name',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
            render: (value, record) => {
                let domainUrl = record?.vendor_domain_url;

                // Add 'www.' if it is missing from the domain URL
                if (domainUrl && !domainUrl.startsWith('www.')) {
                    domainUrl = `www.${domainUrl}`;
                }

                return domainUrl ? (
                    <a
                        href={`https://${domainUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {value}
                    </a>
                ) : (
                    <span>{value}</span> // Render plain text if the URL is missing
                );
            },
        },
        {
            title: 'Vendor Platform',
            dataIndex: 'vendor_platform',
            key: 'vendor_platform',
        },
        {
            title: 'Vendor Domain',
            dataIndex: 'vendor_domain_url',
            key: 'vendor_domain_url',
        },
        {
            title: (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>Action</span>
                    {/* <button
                        style={{
                            marginLeft: '8px',
                            padding: '4px 8px',
                            fontSize: '12px',
                            borderRadius: '4px',
                            backgroundColor: '#1890ff',
                            color: '#fff',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            handleBulkAction()
                        }}
                    >
                        Enable/Disable All
                    </button> */}
                    <CustomButton
                        title="Enable/Disable All"
                        width="100%"
                        font_size="0.7"
                        unit="rem"
                        padding="5px 5px 5px 5px"
                        colorScheme="primary"
                        border_color="#FAFAFA"
                        background_color="#FAFAFA"
                        onClick={() => handleBulkAction()}
                    />
                </div>
            ),

            dataIndex: 'action',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <Switch
                    checked={record.enabled}
                    onChange={() => handleToggle(record)}
                    checkedChildren="Enabled"
                    unCheckedChildren="Disabled"
                />
            ),
        },
    ];

    // Add serial numbers to vendor data
    const data = vendors?.map((vendor, index) => ({
        key: vendor.vendor_id,
        serialNumber: index + 1,
        ...vendor,
    }));


    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    const handleRegionChange = (region) => {
        setSelectedRegion(region);

        if (region === 'all') {
            // Collect all country codes from all regions
            // const allCountryCodes = sortedCountriesGroupedBySubRegionForFilter.flatMap(r => r.countries.map(country => country.isoCode));
            setSelectedCountry(['all']);
        } else {
            // Find the selected region object
            const selectedRegionObj = sortedCountriesGroupedBySubRegionForFilter.find(r => r.subRegion === region);

            // Update selected countries based on the selected region
            if (selectedRegionObj) {
                const countryCodes = selectedRegionObj.countries.map(country => country.isoCode);
                setSelectedCountry(countryCodes);
            } else {
                setSelectedCountry([]); // Reset if no match
            }
        }

        setPagination(prev => ({
            ...prev,
            current: 1
        }));

        // Reset the Country dropdown to "All Countries"
        // setSelectedCountry(['all']);
    };


    const handleBulkAction = (record) => {
        setCurrentDetailsFormToRender(
            <WarningBeforeEnablingOrDisablingAllVendors

                vendorsListSearchFilter={vendorsListSearchFilter}
                setDetailsModalFooter={setDetailsModalFooter}
                setIsDetailsModalOpen={setIsDetailsModalOpen}
            />)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Confirm you want to perform this bulk action ? ")
    };

    return (
        <div className={styles.list_of_allowed_channels_container}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total, // Total number of records from API
                    showSizeChanger: false
                }}
                onChange={handleTableChange}
                bordered
                loading={isLoadingListOfVendors}

                title={() => (
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <div style={{ width: "20%" }}>
                            <CustomDropDown
                                defaultValue='default'
                                options={listOfPlatforms}
                                onChange={(value) => {
                                    if (value != 'default') {
                                        setSelectedPlatform([value])
                                    } else {
                                        setSelectedPlatform(listOfPlatforms.map((p) => p.value))
                                    }

                                    setPagination(prev => ({
                                        ...prev,
                                        current: 1
                                    }));
                                }}
                            />
                        </div>
                        <div style={{ width: "20%" }}>
                            <CustomDropDown
                                enable_search={true}

                                defaultValue='all'
                                options={[
                                    { value: 'all', label: 'All Regions' },
                                    ...sortedCountriesGroupedBySubRegionForFilter.map((region) => ({
                                        value: region?.subRegion,
                                        label: region?.subRegion
                                    }))
                                ]}
                                onChange={handleRegionChange}
                            />
                        </div>

                        <div style={{ width: "20%" }}>
                            <CustomDropDown
                                enable_search={true}
                                defaultValue='all'
                                // options={[
                                //     { value: 'all', label: 'All Countries' },
                                //     ...(selectedRegion !== 'all'
                                //         ? sortedCountriesGroupedBySubRegionForFilter
                                //             .find((region) => region.subRegion === selectedRegion)?.countries
                                //             .map((country) => ({
                                //                 value: country.isoCode,
                                //                 label: country.name,
                                //             })) || []
                                //         : sortedCountriesGroupedBySubRegionForFilter.flatMap((region) =>
                                //             region.countries.map((country) => ({
                                //                 value: country.isoCode,
                                //                 label: country.name,
                                //             }))
                                //         )),
                                // ]}

                                options={[
                                    { value: 'all', label: 'All Countries' },
                                    ...(selectedRegion !== 'all'
                                        ? // If a specific region is selected, filter by that region and map countries
                                        sortedCountriesGroupedBySubRegionForFilter
                                            .find((region) => region.subRegion === selectedRegion)?.countries
                                            .map((country) => ({
                                                value: country.isoCode,
                                                label: country.name,
                                            })) || []
                                        : // Otherwise, flatten all regions and sort globally
                                        sortedCountriesGroupedBySubRegionForFilter
                                            .flatMap((region) => region.countries) // Flatten all countries
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort globally by name
                                            .map((country) => ({
                                                value: country.isoCode,
                                                label: country.name,
                                            }))
                                    ),
                                ]}
                                onChange={(value) => {
                                    setPagination(prev => ({
                                        ...prev,
                                        current: 1
                                    }));
                                    if (value === 'all') {
                                        let countries;

                                        if (selectedRegion !== 'all') {
                                            const selectedRegionData = sortedCountriesGroupedBySubRegionForFilter.find(region => region.subRegion === selectedRegion);

                                            countries = selectedRegionData
                                                ? selectedRegionData.countries.map(country => (country.isoCode))
                                                : [];
                                        }
                                        else if (selectedRegion === "all") {
                                            countries = ["all"]
                                        }
                                        else {
                                            countries = sortedCountriesGroupedBySubRegionForFilter.flatMap(region =>
                                                region.countries.map(country => (country.isoCode))
                                            );
                                        }
                                        setSelectedCountry(countries)
                                    } else {
                                        setSelectedCountry([value])
                                    }
                                }}
                            />
                        </div>

                        <div style={{ width: "40%" }}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search i.e  vendor name "
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        {/* <div style={{ width: "20%" }}>
                            <CustomButton onClick={() => {
                                downloadListOfAssociateVendors({ channel_id: record?.channel_id, channel_name: record?.channel_name })
                            }} colorScheme="secondary" icon={<FaDownload size={18} />} border_radius="7px" title="Export" width="100%" padding="5px 10px 5px 10px" font_size="0.8" unit="rem" />
                        </div> */}
                    </div>
                )}
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfVendors: getAllVendors(state),
    isLoadingListOfVendors: getIsLoadingListOfVendors(state),
    listOfVendorPlatforms: getAllVendorsNames(state)
});

const mapDispatchToProps = (dispatch) => ({
    updateVendorStatus: (data) => dispatch(updateVendorStatus(data)),
    fetchListOfAllVendors: (data) => dispatch(fetchListOfAllVendors(data)),
    fetchListOfAllVendorsByChannelId: (data) => dispatch(fetchListOfAllVendorsByChannelId(data)),
    downloadListOfAssociateVendors: (data) => dispatch(downloadListOfAssociateVendors(data)),
    setListOfVendors: (data) => dispatch(setListOfVendors(data)),
});

export default connect(mapState, mapDispatchToProps)(ListOfAllVendors);
