import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import copy from 'copy-to-clipboard';
import styles from '../../styles.module.scss';
import { FaSearch } from 'react-icons/fa';
import { CustomTextfield } from '../../../../../shared/ui/text-field';

// Status mapping for better readability
const statusMap = {
    0: 'Unsent',
    1: 'Sent (Not Verified)',
    2: 'Sent and Verified'
};

// Function to shorten the address
const shortenAddress = (address) => {
    return address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';
};

// Function to handle copying the address to clipboard
const handleCopy = (address) => {
    if (address) {
        copy(address);
        alert('Address copied to clipboard!');
    }
};

const FAST_tx_polygon_Tab = ({ ...props }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Define the columns structure for the transaction table
    const columns = [
        {
            title: 'To Address',
            dataIndex: 'to_address',
            key: 'to_address',
            width: '25%', // Set the width for To Address column
            render: (text) => (
                <div className={styles.txHashContainer}>
                    <span>{shortenAddress(text)}</span>
                    {text && (
                        <Button 
                            type="link" 
                            onClick={() => handleCopy(text)}
                            style={{ marginLeft: 8 }}
                        >
                            Copy
                        </Button>
                    )}
                </div>
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: '15%', // Set the width for Amount column
            render: (amount) => <span>${amount.toFixed(2)}</span> // Format amount as currency
        },
        {
            title: 'Transaction Hash',
            dataIndex: 'txhash',
            key: 'txhash',
            width: '20%', // Set the width for Transaction Hash column
            render: (text) => (
                <div className={styles.txHashContainer}>
                    <span>{shortenAddress(text)}</span>
                    {text && (
                        <Button 
                            type="link" 
                            onClick={() => handleCopy(text)}
                            style={{ marginLeft: 8 }}
                        >
                            Copy
                        </Button>
                    )}
                </div>
            )
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            width: '15%', // Set the width for Created column
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '15%', // Set the width for Status column
            render: (status) => <span>{statusMap[status] || 'Unknown'}</span> // Map status codes to text
        },
        {
            title: 'Modified',
            dataIndex: 'modified',
            key: 'modified',
            width: '5%', // Set the width for Modified column
        },
        {
            title: 'Send After',
            dataIndex: 'send_after',
            key: 'send_after',
            width: '10%', // Set the width for Send After column
        }
    ];

    // Transaction data for the table
    const transactionData = [
        { to_address: "0xc69fb5bdfc377a42c44544172d1d4d1b0cb4445e", amount: 20, txhash: "0xffd83476674fd60c29e662728e8f840d1aa7b0f884a7407876fef523b392ac67", created: "2024-07-23 04:52:59.342081+00", status: 2, modified: "2024-07-24 19:05:03.505292+00", send_after: "2024-07-23 04:52:59.342081" },
        { to_address: "0x59e5c7bb4273a26ee3be966781de84c3f29c21fd", amount: 207.98494021956702, txhash: "", created: "2024-07-18 16:28:41.82947+00", status: 0, modified: "2024-07-18 16:28:41.82947+00", send_after: "2024-09-18 09:28:44.838" },
        { to_address: "0x59e5c7bb4273a26ee3be966781de84c3f29c21fd", amount: 207.98494021956702, txhash: "", created: "2024-07-18 16:28:42.071744+00", status: 0, modified: "2024-07-18 16:28:42.071744+00", send_after: "2024-10-18 09:28:44.838" },
        { to_address: "0x59e5c7bb4273a26ee3be966781de84c3f29c21fd", amount: 207.98494021956702, txhash: "", created: "2024-07-18 16:28:42.332921+00", status: 0, modified: "2024-07-18 16:28:42.332921+00", send_after: "2024-11-18 09:28:44.838" },
        { to_address: "0x59e5c7bb4273a26ee3be966781de84c3f29c21fd", amount: 207.98494021956702, txhash: "", created: "2024-07-18 16:28:42.58636+00", status: 0, modified: "2024-07-18 16:28:42.58636+00", send_after: "2024-12-18 09:28:44.838" },
        { to_address: "0x59e5c7bb4273a26ee3be966781de84c3f29c21fd", amount: 207.98494021956702, txhash: "", created: "2024-07-18 16:28:42.828805+00", status: 0, modified: "2024-07-18 16:28:42.828805+00", send_after: "2025-01-18 09:28:44.838" },
        { to_address: "0x59e5c7bb4273a26ee3be966781de84c3f29c21fd", amount: 207.98494021956702, txhash: "", created: "2024-07-18 16:28:43.074227+00", status: 0, modified: "2024-07-18 16:28:43.074227+00", send_after: "2025-02-18 09:28:44.838" },
        { to_address: "0x25284abf9c20302def4f258acc469cd1873d9819", amount: 2.525, txhash: "", created: "2024-07-12 07:51:28.287402+00", status: 0, modified: "2024-07-12 07:51:28.287402+00", send_after: "2025-02-12 00:51:26.352" },
        { to_address: "0x6875c161f0d04257f6a9366d035761580800d7fb", amount: 6851.5, txhash: "0x95e301938be8c6992829c2e4dd1489a183bd815af44bd01780825aee7f22d194", created: "2024-07-18 16:28:35.453985+00", status: 2, modified: "2024-07-18 16:28:35.453985+00", send_after: "2024-08-18 09:28:38.697" },
        { to_address: "0x25284abf9c20302def4f258acc469cd1873d9819", amount: 7.575, txhash: "0x65ee3ed7cec50d11d1405b9a16df660187141df49eff883de3a00c1a43f1d576", created: "2024-07-12 07:51:24.545032+00", status: 2, modified: "2024-08-12 00:52:05.935012+00", send_after: "2024-08-12 00:51:24.195" }
    ];

    // Filter data based on search term
    const filteredData = transactionData.filter(item =>
        item.to_address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.txhash.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={styles.eat_fast_swap_tab_container}>
            <Table
                columns={columns}
                dataSource={filteredData}
                bordered
                pagination={{ pageSize: 10,showSizeChanger: false }}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.search_bar}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search transactions..."
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                )}
                rowKey="txhash" // Use txhash as the unique key for each row
            />
        </div>
    );
};

const mapState = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapState, mapDispatchToProps)(FAST_tx_polygon_Tab);
