import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Checkbox } from "antd";
import styles from './model.module.scss';
import { fetchListOfAllCategories } from '../../../logic/category/actions';
import { getIsLoadingListOfCategories, getListOfCategories } from '../../../logic/category/selectors';

// Function to chunk the array into smaller arrays
const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
};

const ListOfCategoriesForFilter = ({
    listOfAllCategories,
    isLoadingListOfCategories,
    listOfSelectedCategories,
    setListOfSelectedCategories,
    selectedCategoriesNames,
    setSelectedCategoriesNames,
    ...props
}) => {
    const [channelsData, setChannelsData] = useState([]);
    const [selectedCategoryIds, setSelectedCategoryIds] = useState(listOfSelectedCategories || []); // State to track selected category IDs

    // Update channelsData when the categories list changes
    useEffect(() => {
        const allCategories = listOfAllCategories?.formatted_data || [];
        setChannelsData(allCategories);
    }, [listOfAllCategories]);

    // Handle checkbox changes
    const handleCheckboxChange = (categoryId, checked,category_name) => {
        if (checked) {

            setSelectedCategoriesNames((prevSelectedNames) => [...prevSelectedNames, category_name]);
            setSelectedCategoryIds((prevSelectedIds) => [...prevSelectedIds, categoryId]); // Add the category ID
        
        } else {

            setSelectedCategoryIds((prevSelectedIds) =>
                prevSelectedIds.filter((id) => id !== categoryId) // Remove the category ID
            );

            setSelectedCategoriesNames((prevSelectedNames) =>
                prevSelectedNames.filter((name) => name !== category_name)
            );
        }
    };

    // Split data into chunks for display
    const chunkedData = chunkArray(channelsData, 15);

    // Pass selected category IDs back to the parent (if needed)
    useEffect(() => {
        setListOfSelectedCategories(selectedCategoryIds); // Updating parent state with selected categories
    }, [selectedCategoryIds, setListOfSelectedCategories]);

    useEffect(() => {
        // Update checked categories when listOfSelectedCategories changes
        if (listOfSelectedCategories.length === 0) {
            setSelectedCategoryIds([]);
        }
    }, [listOfSelectedCategories])

    return (
        <div className={styles.list_of_allowed_channels_container}>
            <div className={styles.checkboxGrid}>
                {chunkedData.map((columnData, columnIndex) => (
                    <div key={columnIndex} className={styles.column}>
                        {columnData.map((category, index) => (
                            <div key={index} className={styles.checkboxItem}>
                                <Checkbox
                                    checked={selectedCategoryIds.includes(category.id)} // Check if the category ID is in the selected list
                                    onChange={(e) => handleCheckboxChange(category.id, e.target.checked,category.name)}
                                >
                                    {category.name}
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

const mapState = (state) => ({
    listOfAllCategories: getListOfCategories(state),
    isLoadingListOfCategories: getIsLoadingListOfCategories(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfAllCategories: (data) => dispatch(fetchListOfAllCategories(data)),
});

export default connect(mapState, mapDispatchToProps)(ListOfCategoriesForFilter);
