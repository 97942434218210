import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'deals',
    initialState: {
        listOfDeals: [],
        isLoadingListOfDeals: false,
        
    },
    reducers: {
        setListOfDeals: (state, action) => {
            state.listOfDeals = action.payload;
        },
        setIsLoadingListOfDeals: (state, action) => {
            state.isLoadingListOfDeals = action.payload;
        },
    },
});

export const {
   setIsLoadingListOfDeals,
   setListOfDeals
} = appSlice.actions;

export default appSlice.reducer;
