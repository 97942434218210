import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { Table, Switch } from "antd";
import styles from './modal.module.scss';
import debounce from 'lodash.debounce'; // Import lodash debounce function

import { setCurrentChannelRecord, setIsLoadingMatchCountDivisionForChannel, setMatchCountDivisionForChannel } from '../../../logic/channels/slice';
import { getCurrentSelectedChannelRecord, getIsLoadingMatchCountDivisionForChannel, getMatchCountDivisionForChannel } from '../../../logic/channels/selectors';
import { fetchMatchCountDivisionsForChannel } from '../../../logic/channels/actions';
import { FaSearch } from 'react-icons/fa';
import { CustomTextfield } from '../../../../../shared/ui/text-field';
import { getAllVendorsNames } from '../../../logic/vendor/selectors';
import { sortedCountriesGroupedBySubRegionForFilter } from '../../../../../shared/utils/regions_with_countries';
import { CustomDropDown } from '../../../../../shared/ui/dropdown';

const MatchCountDetails = ({
    listOfVendorsWithMatchCounts,
    record = null,
    setDetailsModalFooter,
    setIsDetailsModalOpen,
    setIsLoadingMatchCountDivisionForChannel,
    fetchMatchCountDivisionsForChannel,
    isLoadingMatchCountDivision,
    is_pagination_enabled = true,
    listSearchParams = null,
    setMatchCountDivisionForChannel,
    setCurrentChannelRecord,
    listOfVendorPlatforms,
    ...props
}) => {
    const [channelsData, setChannelsData] = useState([]);
    const [enabledChannels, setEnabledChannels] = useState([]);
    const [channelType, setChannelType] = useState([]);
    const [newlyEnabledChannels, setNewlyEnabledChannels] = useState([]);
    const [alreadyAllowedChannels, setAlreadyAllowedChannels] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const prevSearchTerm = useRef('');
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });

    const [listOfPlatforms, setListOfPlatforms] = useState([{ value: "default", label: "All platforms" }])
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [selectedCountry, setSelectedCountry] = useState(['all']);

    useEffect(() => {
        setPagination({
            current: 1, // Reset to the first page
            pageSize: 10, // Reset the page size
        });
    }, [record.channel_id]);
    
    useEffect(() => {
        setIsLoadingMatchCountDivisionForChannel(true)
        return () => {

            setChannelsData([])
            setCurrentChannelRecord(null)
            setMatchCountDivisionForChannel({
                formatted_data: [],
                totalRecords: 0,
                totalPages: 0,
                currentPage: 0,
            })
            setPagination(null)
        }
    }, [])

    // Debounce the search function to avoid excessive requests
    const debouncedSearch = useCallback(
        debounce((searchTerm, page, pageSize, is_pagination_enabled, record,selectedPlatform,selectedCountry) => {
            fetchMatchCountDivisionsForChannel({ keyword: searchTerm, page, limit: pageSize, is_pagination_enabled, channel_id: record?.channel_id, platform: selectedPlatform, country_code: selectedCountry[0] == 'all' ? [] : selectedCountry });
        }, 1000), // 1-second debounce delay
        []
    );

    useEffect(() => {
        // if (!initialLoadRef.current) {
        // initialLoadRef.current = true
        debouncedSearch(searchTerm, pagination.current, pagination.pageSize, is_pagination_enabled, record,selectedPlatform,selectedCountry);
        // }
    }, [searchTerm, pagination.current, debouncedSearch, record?.channel_id]);

    // useEffect(() => {
    //     handleApplyFilter()
    // }, [selectedCountry, selectedPlatform, selectedRegion])

    // const handleApplyFilter = () => {
    //     fetchMatchCountDivisionsForChannel({ keyword: searchTerm,page: pagination.page, limit: pagination.pagination.pageSize, is_pagination_enabled, channel_id: record?.channel_id,platform: selectedPlatform, country_code: selectedCountry[0]=='all'?[]:selectedCountry });
    // };


    useEffect(() => {
        // Initialize channels data from listOfVendorsWithMatchCounts
        const allChannels = listOfVendorsWithMatchCounts?.formatted_data || [];
        setChannelsData(allChannels);
        setPagination(prev => ({
            ...prev,
            total: listOfVendorsWithMatchCounts.totalRecords,
            pageSize: 10,
            // page: listOfVendorsWithMatchCounts.currentPage
        }));

    }, [listOfVendorsWithMatchCounts, record?.channel_id]);

    useEffect(() => {
        if (listOfVendorPlatforms) {
            let formatted_platforms = listOfVendorPlatforms.map((v) => {

                return {
                    value: v.platform,
                    label: v.platform
                }
            })

            setListOfPlatforms([{ value: "default", label: "All platforms" }, ...formatted_platforms])
            let ar = listOfVendorPlatforms.map(p => p.platform); // No need for return statement

            setSelectedPlatform(ar);

        }
    }, [listOfVendorPlatforms])

    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);

    const columns = [
        {
            title: 'Logo',
            dataIndex: 'vendor_logo',
            key: 'vendor_logo',
            render: (vendor_logo, record) => {

                const renderProfileImage = (url, vendor_name) => {
                    if (url) {
                        return (
                            <img
                                src={url}
                                alt={vendor_name}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ''; // Reset the image source if it fails
                                }}
                                style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                            />
                        );
                    } else {
                        const initials = vendor_name.split(' ').map(n => n[0]).join('');
                        return (
                            <div style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fff',
                            }}>
                                {initials}
                            </div>
                        );
                    }
                };

                return renderProfileImage(vendor_logo, record?.vendor_name);
            },
        },
        {
            title: 'Vendor Name',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: 'Total Matches',
            dataIndex: 'matches_count',
            key: 'matches_count',
            align: 'center',
            render: (value, record) => (
                <div >
                    {Number(value).toLocaleString()}
                </div>
            ),
        },
        // {
        //     title: 'Enabled',
        //     dataIndex: 'enabled',
        //     key: 'enabled',
        //     render: (text, record) => (
        //         <Switch
        //             checked={record?.enabled}
        //             onChange={(checked) => handleSwitchChange(record?.channel_id, checked)}
        //         />
        //     ),
        // },
    ];

    const data = channelsData.map((channel, index) => ({
        key: index + 1,
        serialNumber: index + 1,
        vendor_name: channel.vendor_name,
        matches_count: channel.matches_count,
        enabled: channel.enabled,
        channel_id: channel.channel_id,
        vendor_logo: channel.vendor_logo,
        platform: channel.platform
    }));

    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };


    const handleRegionChange = (region) => {
        setSelectedRegion(region);

        if (region === 'all') {
            // Collect all country codes from all regions
            // const allCountryCodes = sortedCountriesGroupedBySubRegionForFilter.flatMap(r => r.countries.map(country => country.isoCode));
            setSelectedCountry([]);
            fetchMatchCountDivisionsForChannel({ keyword: searchTerm, page:pagination.current, limit: pagination.pageSize, is_pagination_enabled, channel_id: record?.channel_id, platform: selectedPlatform, country_code: [] });

        } else {
            // Find the selected region object
            const selectedRegionObj = sortedCountriesGroupedBySubRegionForFilter.find(r => r.subRegion === region);

            // Update selected countries based on the selected region
            if (selectedRegionObj) {
                const countryCodes = selectedRegionObj.countries.map(country => country.isoCode);
                setSelectedCountry(countryCodes);
                fetchMatchCountDivisionsForChannel({ keyword: searchTerm, page:pagination.current, limit: pagination.pageSize, is_pagination_enabled, channel_id: record?.channel_id, platform: selectedPlatform, country_code: countryCodes });

            } else {
                setSelectedCountry([]); // Reset if no match
                fetchMatchCountDivisionsForChannel({ keyword: searchTerm, page:pagination.current, limit: pagination.pageSize, is_pagination_enabled, channel_id: record?.channel_id, platform: selectedPlatform, country_code: [] });

            }
        }

        setPagination(prev => ({
            ...prev,
            current: 1
        }));

        // Reset the Country dropdown to "All Countries"
        // setSelectedCountry(['all']);
    };

    return (
        <div className={styles.list_of_allowed_channels_container}>

            <div className={styles.table_container}>
                <Table
                    columns={columns}
                    dataSource={data} // Use the filtered data for the table
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: pagination.total, // Total number of records from API
                        showSizeChanger: false
                    }}
                    onChange={handleTableChange}
                    bordered
                    loading={isLoadingMatchCountDivision}
                    title={() => (<div style={{ display: 'flex', alignContent: 'flex-end' }}>

                        <div style={{ width: "20%" }}>
                            <CustomDropDown
                                defaultValue='default'
                                options={listOfPlatforms}
                                onChange={(value) => {
                                    if (value != 'default') {
                                        setSelectedPlatform([value])
                                        fetchMatchCountDivisionsForChannel({ keyword: searchTerm, page:pagination.current, limit: pagination.pageSize, is_pagination_enabled, channel_id: record?.channel_id, platform: [value], country_code: selectedCountry[0] == 'all' ? [] : selectedCountry });

                                    } else {
                                        setSelectedPlatform(listOfPlatforms.map((p) => p.value))
                                        fetchMatchCountDivisionsForChannel({ keyword: searchTerm, page:pagination.current, limit: pagination.pageSize, is_pagination_enabled, channel_id: record?.channel_id, platform: listOfPlatforms.map((p) => p.value), country_code: selectedCountry[0] == 'all' ? [] : selectedCountry });
                                    }

                                    setPagination(prev => ({
                                        ...prev,
                                        current: 1
                                    }));
                                }}
                            />
                        </div>
                        <div style={{ width: "20%" }}>
                            <CustomDropDown
                                enable_search={true}

                                defaultValue='all'
                                options={[
                                    { value: 'all', label: 'All Regions' },
                                    ...sortedCountriesGroupedBySubRegionForFilter.map((region) => ({
                                        value: region?.subRegion,
                                        label: region?.subRegion
                                    }))
                                ]}
                                onChange={handleRegionChange}
                            />
                        </div>

                        <div style={{ width: "20%" }}>
                            <CustomDropDown
                                enable_search={true}
                                defaultValue='all'
                                // options={[
                                //     { value: 'all', label: 'All Countries' },
                                //     ...(selectedRegion !== 'all'
                                //         ? sortedCountriesGroupedBySubRegionForFilter
                                //             .find((region) => region.subRegion === selectedRegion)?.countries
                                //             .map((country) => ({
                                //                 value: country.isoCode,
                                //                 label: country.name,
                                //             })) || []
                                //         : sortedCountriesGroupedBySubRegionForFilter.flatMap((region) =>
                                //             region.countries.map((country) => ({
                                //                 value: country.isoCode,
                                //                 label: country.name,
                                //             }))
                                //         )),
                                // ]}
                                options={[
                                    { value: 'all', label: 'All Countries' },
                                    ...(selectedRegion !== 'all'
                                        ? // If a specific region is selected, filter by that region and map countries
                                        sortedCountriesGroupedBySubRegionForFilter
                                            .find((region) => region.subRegion === selectedRegion)?.countries
                                            .map((country) => ({
                                                value: country.isoCode,
                                                label: country.name,
                                            })) || []
                                        : // Otherwise, flatten all regions and sort globally
                                        sortedCountriesGroupedBySubRegionForFilter
                                            .flatMap((region) => region.countries) // Flatten all countries
                                            .sort((a, b) => a.name.localeCompare(b.name)) // Sort globally by name
                                            .map((country) => ({
                                                value: country.isoCode,
                                                label: country.name,
                                            }))
                                    ),
                                ]}
                                onChange={(value) => {
                                    setPagination(prev => ({
                                        ...prev,
                                        current: 1
                                    }));
                                    if (value === 'all') {
                                        let countries;

                                        if (selectedRegion !== 'all') {
                                            const selectedRegionData = sortedCountriesGroupedBySubRegionForFilter.find(region => region.subRegion === selectedRegion);

                                            countries = selectedRegionData
                                                ? selectedRegionData.countries.map(country => (country.isoCode))
                                                : [];
                                        }
                                        else if (selectedRegion === "all") {
                                            countries = []
                                        }
                                        else {
                                            countries = sortedCountriesGroupedBySubRegionForFilter.flatMap(region =>
                                                region.countries.map(country => (country.isoCode))
                                            );
                                        }
                                        setSelectedCountry(countries)
                                        fetchMatchCountDivisionsForChannel({ keyword: searchTerm, page:pagination.current, limit: pagination.pageSize, is_pagination_enabled, channel_id: record?.channel_id, platform: selectedPlatform, country_code: countries });

                                    } else {
                                        setSelectedCountry([value])
                                        fetchMatchCountDivisionsForChannel({ keyword: searchTerm, page:pagination.current, limit: pagination.pageSize, is_pagination_enabled, channel_id: record?.channel_id, platform: selectedPlatform, country_code: [value] });
                                    }
                                }}
                            />
                        </div>

                        <div style={{ width: "40%" }}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search i.e  vendor name "
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>)}
                />
            </div>
        </div>
    );
};

const mapState = (state) => ({
    listOfVendorsWithMatchCounts: getMatchCountDivisionForChannel(state),
    isLoadingMatchCountDivision: getIsLoadingMatchCountDivisionForChannel(state),
    record: getCurrentSelectedChannelRecord(state),
    listOfVendorPlatforms: getAllVendorsNames(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchMatchCountDivisionsForChannel: (data) => dispatch(fetchMatchCountDivisionsForChannel(data)),
    setMatchCountDivisionForChannel: (data) => dispatch(setMatchCountDivisionForChannel(data)),
    setCurrentChannelRecord: (data) => dispatch(setCurrentChannelRecord(data)),
    setIsLoadingMatchCountDivisionForChannel: (data) => dispatch(setIsLoadingMatchCountDivisionForChannel(data)),
});

export default connect(mapState, mapDispatchToProps)(MatchCountDetails);
