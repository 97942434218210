const countriesGroupedBySubRegion = [
    {
        subRegion: "Australia and New Zealand",
        countries: [
            { name: "Australia", isoCode: "AU" },
            { name: "New Zealand", isoCode: "NZ" }
        ]
    },
    {
        subRegion: "Caribbean",
        countries: [
            { name: "Antigua and Barbuda", isoCode: "AG" },
            { name: "Bahamas", isoCode: "BS" },
            { name: "Barbados", isoCode: "BB" },
            { name: "Cuba", isoCode: "CU" },
            { name: "Dominica", isoCode: "DM" },
            { name: "Dominican Republic", isoCode: "DO" },
            { name: "Grenada", isoCode: "GD" },
            { name: "Haiti", isoCode: "HT" },
            { name: "Jamaica", isoCode: "JM" },
            { name: "Saint Vincent and the Grenadines", isoCode: "VC" },
            { name: "Saint Kitts and Nevis", isoCode: "KN" },
            { name: "Saint Lucia", isoCode: "LC" },
            { name: "Trinidad and Tobago", isoCode: "TT" }
        ]
    },
    {
        subRegion: "Central Africa",
        countries: [
            { name: "Burundi", isoCode: "BI" },
            { name: "Cameroon", isoCode: "CM" },
            { name: "Central African Republic", isoCode: "CF" },
            { name: "Chad", isoCode: "TD" },
            { name: "Congo", isoCode: "CG" },
            { name: "Democratic Republic of the Congo", isoCode: "CD" },
            { name: "Equatorial Guinea", isoCode: "GQ" },
            { name: "Gabon", isoCode: "GA" },
            { name: "Rwanda", isoCode: "RW" },
            { name: "Sao Tome and Principe", isoCode: "ST" }
        ]
    },
    {
        subRegion: "Central America",
        countries: [
            { name: "Belize", isoCode: "BZ" },
            { name: "Costa Rica", isoCode: "CR" },
            { name: "El Salvador", isoCode: "SV" },
            { name: "Guatemala", isoCode: "GT" },
            { name: "Honduras", isoCode: "HN" },
            { name: "Nicaragua", isoCode: "NI" },
            { name: "Panama", isoCode: "PA" }
        ]
    },
    {
        subRegion: "Central and Eastern Europe",
        countries: [
            { name: "Albania", isoCode: "AL" },
            { name: "Armenia", isoCode: "AM" },
            { name: "Azerbaijan", isoCode: "AZ" },
            { name: "Belarus", isoCode: "BY" },
            { name: "Bosnia and Herzegovina", isoCode: "BA" },
            { name: "Bulgaria", isoCode: "BG" },
            { name: "Croatia", isoCode: "HR" },
            { name: "Czechia", isoCode: "CZ" },
            { name: "Estonia", isoCode: "EE" },
            { name: "Georgia", isoCode: "GE" },
            { name: "Hungary", isoCode: "HU" },
            { name: "Latvia", isoCode: "LV" },
            { name: "Lithuania", isoCode: "LT" },
            { name: "Montenegro", isoCode: "ME" },
            { name: "Poland", isoCode: "PL" },
            { name: "Republic of Moldova", isoCode: "MD" },
            { name: "Romania", isoCode: "RO" },
            { name: "Serbia", isoCode: "RS" },
            { name: "Slovakia", isoCode: "SK" },
            { name: "North Macedonia", isoCode: "MK" },
            { name: "Ukraine", isoCode: "UA" },
            { name: "Slovenia", isoCode: "SI" }
        ]
    },
    {
        subRegion: "Central Asia",
        countries: [
            { name: "Kazakhstan", isoCode: "KZ" },
            { name: "Kyrgyzstan", isoCode: "KG" },
            { name: "Uzbekistan", isoCode: "UZ" },
            { name: "Tajikistan", isoCode: "TJ" },
            { name: "Turkmenistan", isoCode: "TM" }
        ]
    },
    {
        subRegion: "East Africa",
        countries: [
            { name: "Comoros", isoCode: "KM" },
            { name: "Djibouti", isoCode: "DJ" },
            { name: "Eritrea", isoCode: "ER" },
            { name: "Ethiopia", isoCode: "ET" },
            { name: "Kenya", isoCode: "KE" },
            { name: "South Sudan", isoCode: "SS" },
            { name: "Uganda", isoCode: "UG" },
            { name: "United Republic of Tanzania", isoCode: "TZ" },
            { name: "Somalia", isoCode: "SO" }
        ]
    },
    {
        subRegion: "East Asia",
        countries: [
            { name: "China", isoCode: "CN" },
            { name: "Democratic People's Republic of Korea", isoCode: "KP" },
            { name: "Japan", isoCode: "JP" },
            { name: "Mongolia", isoCode: "MN" },
            { name: "Republic of Korea", isoCode: "KR" }
        ]
    },
    {
        subRegion: "Middle East",
        countries: [
            { name: "Bahrain", isoCode: "BH" },
            { name: "Egypt", isoCode: "EG" },
            { name: "Iraq", isoCode: "IQ" },
            { name: "Israel", isoCode: "IL" },
            { name: "Jordan", isoCode: "JO" },
            { name: "Kuwait", isoCode: "KW" },
            { name: "Lebanon", isoCode: "LB" },
            { name: "Oman", isoCode: "OM" },
            { name: "Qatar", isoCode: "QA" },
            { name: "Saudi Arabia", isoCode: "SA" },
            { name: "Syrian Arab Republic", isoCode: "SY" },
            { name: "Yemen", isoCode: "YE" },
            { name: "United Arab Emirates", isoCode: "AE" }
        ]
    },
    {
        subRegion: "Nordic countries",
        countries: [
            { name: "Denmark", isoCode: "DK" },
            { name: "Finland", isoCode: "FI" },
            { name: "Iceland", isoCode: "IS" },
            { name: "Norway", isoCode: "NO" },
            { name: "Sweden", isoCode: "SE" }
        ]
    },
    {
        subRegion: "North Africa",
        countries: [
            { name: "Algeria", isoCode: "DZ" },
            { name: "Libya", isoCode: "LY" },
            { name: "Mauritania", isoCode: "MR" },
            { name: "Morocco", isoCode: "MA" },
            { name: "Tunisia", isoCode: "TN" },
            { name: "Sudan", isoCode: "SD" }
        ]
    },
    {
        subRegion: "North America",
        countries: [
            { name: "Canada", isoCode: "CA" },
            { name: "Mexico", isoCode: "MX" },
            { name: "United States of America", isoCode: "US" }
        ]
    },
    {
        subRegion: "Pacific Islands",
        countries: [
            { name: "Fiji", isoCode: "FJ" },
            { name: "Kiribati", isoCode: "KI" },
            { name: "Marshall Islands", isoCode: "MH" },
            { name: "Micronesia (Federated States of)", isoCode: "FM" },
            { name: "Nauru", isoCode: "NR" },
            { name: "Palau", isoCode: "PW" },
            { name: "Papua New Guinea", isoCode: "PG" },
            { name: "Samoa", isoCode: "WS" },
            { name: "Solomon Islands", isoCode: "SB" },
            { name: "Tonga", isoCode: "TO" },
            { name: "Tuvalu", isoCode: "TV" },
            { name: "Vanuatu", isoCode: "VU" }
        ]
    },
    {
        subRegion: "South America",
        countries: [
            { name: "Argentina", isoCode: "AR" },
            { name: "Bolivia (Plurinational State of)", isoCode: "BO" },
            { name: "Brazil", isoCode: "BR" },
            { name: "Chile", isoCode: "CL" },
            { name: "Colombia", isoCode: "CO" },
            { name: "Ecuador", isoCode: "EC" },
            { name: "Guyana", isoCode: "GY" },
            { name: "Paraguay", isoCode: "PY" },
            { name: "Peru", isoCode: "PE" },
            { name: "Uruguay", isoCode: "UY" },
            { name: "Suriname", isoCode: "SR" },
            { name: "Venezuela (Bolivarian Republic of)", isoCode: "VE" }
        ]
    },
    {
        subRegion: "South Asia",
        countries: [
            { name: "Afghanistan", isoCode: "AF" },
            { name: "Bangladesh", isoCode: "BD" },
            { name: "Bhutan", isoCode: "BT" },
            { name: "India", isoCode: "IN" },
            { name: "Iran (Islamic Republic of)", isoCode: "IR" },
            { name: "Maldives", isoCode: "MV" },
            { name: "Nepal", isoCode: "NP" },
            { name: "Pakistan", isoCode: "PK" },
            { name: "Sri Lanka", isoCode: "LK" }
        ]
    },
    {
        subRegion: "South East Asia",
        countries: [
            { name: "Brunei Darussalam", isoCode: "BN" },
            { name: "Cambodia", isoCode: "KH" },
            { name: "Indonesia", isoCode: "ID" },
            { name: "Lao People's Democratic Republic", isoCode: "LA" },
            { name: "Malaysia", isoCode: "MY" },
            { name: "Myanmar", isoCode: "MM" },
            { name: "Philippines", isoCode: "PH" },
            { name: "Viet Nam", isoCode: "VN" },
            { name: "Thailand", isoCode: "TH" },
            { name: "Singapore", isoCode: "SG" },
            { name: "Timor-Leste", isoCode: "TL" }
        ]
    },
    {
        subRegion: "Southern Africa",
        countries: [
            { name: "Angola", isoCode: "AO" },
            { name: "Botswana", isoCode: "BW" },
            { name: "Lesotho", isoCode: "LS" },
            { name: "Madagascar", isoCode: "MG" },
            { name: "Malawi", isoCode: "MW" },
            { name: "Mauritius", isoCode: "MU" },
            { name: "Mozambique", isoCode: "MZ" },
            { name: "Namibia", isoCode: "NA" },
            { name: "Seychelles", isoCode: "SC" },
            { name: "Eswatini", isoCode: "SZ" },
            { name: "South Africa", isoCode: "ZA" },
            { name: "Zambia", isoCode: "ZM" },
            { name: "Zimbabwe", isoCode: "ZW" }
        ]
    },
    {
        subRegion: "Southern Europe",
        countries: [
            { name: "Cyprus", isoCode: "CY" },
            { name: "Greece", isoCode: "GR" },
            { name: "Italy", isoCode: "IT" },
            { name: "Malta", isoCode: "MT" },
            { name: "Portugal", isoCode: "PT" },
            { name: "San Marino", isoCode: "SM" },
            { name: "Spain", isoCode: "ES" },
            { name: "Türkiye", isoCode: "TR" }
        ]
    },
    {
        subRegion: "West Africa",
        countries: [
            { name: "Benin", isoCode: "BJ" },
            { name: "Burkina Faso", isoCode: "BF" },
            { name: "Cabo Verde", isoCode: "CV" },
            { name: "Côte d'Ivoire", isoCode: "CI" },
            { name: "Gambia (The)", isoCode: "GM" },
            { name: "Ghana", isoCode: "GH" },
            { name: "Guinea", isoCode: "GN" },
            { name: "Guinea-Bissau", isoCode: "GW" },
            { name: "Liberia", isoCode: "LR" },
            { name: "Mali", isoCode: "ML" },
            { name: "Niger", isoCode: "NE" },
            { name: "Nigeria", isoCode: "NG" },
            { name: "Sierra Leone", isoCode: "SL" },
            { name: "Senegal", isoCode: "SN" },
            { name: "Togo", isoCode: "TG" }
        ]
    },
    {
        subRegion: "Western Europe",
        countries: [
            { name: "Andorra", isoCode: "AD" },
            { name: "Austria", isoCode: "AT" },
            { name: "Belgium", isoCode: "BE" },
            { name: "France", isoCode: "FR" },
            { name: "Germany", isoCode: "DE" },
            { name: "Ireland", isoCode: "IE" },
            { name: "Liechtenstein", isoCode: "LI" },
            { name: "Luxembourg", isoCode: "LU" },
            { name: "Monaco", isoCode: "MC" },
            { name: "Netherlands", isoCode: "NL" },
            { name: "United Kingdom", isoCode: "UK" },
            { name: "Switzerland", isoCode: "CH" }
        ]
    }
];


const countriesGroupedBySubRegionForFilter = [
    {
        subRegion: "Pacific",
        countries: [
            { name: "Australia", isoCode: "AU" },
            { name: "New Zealand", isoCode: "NZ" },
            { name: "Fiji", isoCode: "FJ" },
            { name: "Kiribati", isoCode: "KI" },
            { name: "Marshall Islands", isoCode: "MH" },
            { name: "Micronesia (Federated States of)", isoCode: "FM" },
            { name: "Nauru", isoCode: "NR" },
            { name: "Palau", isoCode: "PW" },
            { name: "Papua New Guinea", isoCode: "PG" },
            { name: "Samoa", isoCode: "WS" },
            { name: "Solomon Islands", isoCode: "SB" },
            { name: "Tonga", isoCode: "TO" },
            { name: "Tuvalu", isoCode: "TV" },
            { name: "Vanuatu", isoCode: "VU" }
        ]
    },

    {
        subRegion: "Sub-Saharan Africa",
        countries: [
            { name: "Burundi", isoCode: "BI" },
            { name: "Cameroon", isoCode: "CM" },
            { name: "Central African Republic", isoCode: "CF" },
            { name: "Chad", isoCode: "TD" },
            { name: "Congo", isoCode: "CG" },
            { name: "Democratic Republic of the Congo", isoCode: "CD" },
            { name: "Equatorial Guinea", isoCode: "GQ" },
            { name: "Gabon", isoCode: "GA" },
            { name: "Rwanda", isoCode: "RW" },
            { name: "Sao Tome and Principe", isoCode: "ST" },
            { name: "Comoros", isoCode: "KM" },
            { name: "Djibouti", isoCode: "DJ" },
            { name: "Eritrea", isoCode: "ER" },
            { name: "Ethiopia", isoCode: "ET" },
            { name: "Kenya", isoCode: "KE" },
            { name: "South Sudan", isoCode: "SS" },
            { name: "Uganda", isoCode: "UG" },
            { name: "United Republic of Tanzania", isoCode: "TZ" },
            { name: "Somalia", isoCode: "SO" },
            { name: "Benin", isoCode: "BJ" },
            { name: "Burkina Faso", isoCode: "BF" },
            { name: "Cabo Verde", isoCode: "CV" },
            { name: "Côte d'Ivoire", isoCode: "CI" },
            { name: "Gambia (The)", isoCode: "GM" },
            { name: "Ghana", isoCode: "GH" },
            { name: "Guinea", isoCode: "GN" },
            { name: "Guinea-Bissau", isoCode: "GW" },
            { name: "Liberia", isoCode: "LR" },
            { name: "Mali", isoCode: "ML" },
            { name: "Niger", isoCode: "NE" },
            { name: "Nigeria", isoCode: "NG" },
            { name: "Sierra Leone", isoCode: "SL" },
            { name: "Senegal", isoCode: "SN" },
            { name: "Togo", isoCode: "TG" },
            { name: "Angola", isoCode: "AO" },
            { name: "Botswana", isoCode: "BW" },
            { name: "Lesotho", isoCode: "LS" },
            { name: "Madagascar", isoCode: "MG" },
            { name: "Malawi", isoCode: "MW" },
            { name: "Mauritius", isoCode: "MU" },
            { name: "Mozambique", isoCode: "MZ" },
            { name: "Namibia", isoCode: "NA" },
            { name: "Seychelles", isoCode: "SC" },
            { name: "Eswatini", isoCode: "SZ" },
            { name: "South Africa", isoCode: "ZA" },
            { name: "Zambia", isoCode: "ZM" },
            { name: "Zimbabwe", isoCode: "ZW" },


        ]
    },

    {
        subRegion: "Americas",
        countries: [
            { name: "Belize", isoCode: "BZ" },
            { name: "Costa Rica", isoCode: "CR" },
            { name: "El Salvador", isoCode: "SV" },
            { name: "Guatemala", isoCode: "GT" },
            { name: "Honduras", isoCode: "HN" },
            { name: "Nicaragua", isoCode: "NI" },
            { name: "Panama", isoCode: "PA" },
            { name: "Canada", isoCode: "CA" },
            { name: "Mexico", isoCode: "MX" },
            { name: "United States of America", isoCode: "US" },
            { name: "Argentina", isoCode: "AR" },
            { name: "Bolivia (Plurinational State of)", isoCode: "BO" },
            { name: "Brazil", isoCode: "BR" },
            { name: "Chile", isoCode: "CL" },
            { name: "Colombia", isoCode: "CO" },
            { name: "Ecuador", isoCode: "EC" },
            { name: "Guyana", isoCode: "GY" },
            { name: "Paraguay", isoCode: "PY" },
            { name: "Peru", isoCode: "PE" },
            { name: "Uruguay", isoCode: "UY" },
            { name: "Suriname", isoCode: "SR" },
            { name: "Venezuela (Bolivarian Republic of)", isoCode: "VE" },
            { name: "Antigua and Barbuda", isoCode: "AG" },
            { name: "Bahamas", isoCode: "BS" },
            { name: "Barbados", isoCode: "BB" },
            { name: "Cuba", isoCode: "CU" },
            { name: "Dominica", isoCode: "DM" },
            { name: "Dominican Republic", isoCode: "DO" },
            { name: "Grenada", isoCode: "GD" },
            { name: "Haiti", isoCode: "HT" },
            { name: "Jamaica", isoCode: "JM" },
            { name: "Saint Vincent and the Grenadines", isoCode: "VC" },
            { name: "Saint Kitts and Nevis", isoCode: "KN" },
            { name: "Saint Lucia", isoCode: "LC" },
            { name: "Trinidad and Tobago", isoCode: "TT" }
        ]
    },



    {
        subRegion: "Asia",
        countries: [
            { name: "Kazakhstan", isoCode: "KZ" },
            { name: "Kyrgyzstan", isoCode: "KG" },
            { name: "Uzbekistan", isoCode: "UZ" },
            { name: "Tajikistan", isoCode: "TJ" },
            { name: "Turkmenistan", isoCode: "TM" },
            { name: "China", isoCode: "CN" },
            { name: "Democratic People's Republic of Korea", isoCode: "KP" },
            { name: "Japan", isoCode: "JP" },
            { name: "Mongolia", isoCode: "MN" },
            { name: "Republic of Korea", isoCode: "KR" },
            { name: "Afghanistan", isoCode: "AF" },
            { name: "Bangladesh", isoCode: "BD" },
            { name: "Bhutan", isoCode: "BT" },
            { name: "India", isoCode: "IN" },
            { name: "Iran (Islamic Republic of)", isoCode: "IR" },
            { name: "Maldives", isoCode: "MV" },
            { name: "Nepal", isoCode: "NP" },
            { name: "Pakistan", isoCode: "PK" },
            { name: "Sri Lanka", isoCode: "LK" },
            { name: "Brunei Darussalam", isoCode: "BN" },
            { name: "Cambodia", isoCode: "KH" },
            { name: "Indonesia", isoCode: "ID" },
            { name: "Lao People's Democratic Republic", isoCode: "LA" },
            { name: "Malaysia", isoCode: "MY" },
            { name: "Myanmar", isoCode: "MM" },
            { name: "Philippines", isoCode: "PH" },
            { name: "Viet Nam", isoCode: "VN" },
            { name: "Thailand", isoCode: "TH" },
            { name: "Singapore", isoCode: "SG" },
            { name: "Timor-Leste", isoCode: "TL" }
        ]
    },

    {
        subRegion: "Middle East and North Africa",
        countries: [
            { name: "Bahrain", isoCode: "BH" },
            { name: "Egypt", isoCode: "EG" },
            { name: "Iraq", isoCode: "IQ" },
            { name: "Israel", isoCode: "IL" },
            { name: "Jordan", isoCode: "JO" },
            { name: "Kuwait", isoCode: "KW" },
            { name: "Lebanon", isoCode: "LB" },
            { name: "Oman", isoCode: "OM" },
            { name: "Qatar", isoCode: "QA" },
            { name: "Saudi Arabia", isoCode: "SA" },
            { name: "Syrian Arab Republic", isoCode: "SY" },
            { name: "Yemen", isoCode: "YE" },
            { name: "United Arab Emirates", isoCode: "AE" },
            { name: "Algeria", isoCode: "DZ" },
            { name: "Libya", isoCode: "LY" },
            { name: "Mauritania", isoCode: "MR" },
            { name: "Morocco", isoCode: "MA" },
            { name: "Tunisia", isoCode: "TN" },
            { name: "Sudan", isoCode: "SD" },
            { name: "Denmark", isoCode: "DK" },
            { name: "Finland", isoCode: "FI" },
            { name: "Iceland", isoCode: "IS" },
            { name: "Norway", isoCode: "NO" },
            { name: "Sweden", isoCode: "SE" }
        ]
    },

    {
        subRegion: "Europe",
        countries: [
            { name: "Cyprus", isoCode: "CY" },
            { name: "Greece", isoCode: "GR" },
            { name: "Italy", isoCode: "IT" },
            { name: "Malta", isoCode: "MT" },
            { name: "Portugal", isoCode: "PT" },
            { name: "San Marino", isoCode: "SM" },
            { name: "Spain", isoCode: "ES" },
            { name: "Türkiye", isoCode: "TR" },
            { name: "Andorra", isoCode: "AD" },
            { name: "Austria", isoCode: "AT" },
            { name: "Belgium", isoCode: "BE" },
            { name: "France", isoCode: "FR" },
            { name: "Germany", isoCode: "DE" },
            { name: "Ireland", isoCode: "IE" },
            { name: "Liechtenstein", isoCode: "LI" },
            { name: "Luxembourg", isoCode: "LU" },
            { name: "Monaco", isoCode: "MC" },
            { name: "Netherlands", isoCode: "NL" },
            { name: "United Kingdom", isoCode: "UK" },
            { name: "Switzerland", isoCode: "CH" },
            { name: "Albania", isoCode: "AL" },
            { name: "Armenia", isoCode: "AM" },
            { name: "Azerbaijan", isoCode: "AZ" },
            { name: "Belarus", isoCode: "BY" },
            { name: "Bosnia and Herzegovina", isoCode: "BA" },
            { name: "Bulgaria", isoCode: "BG" },
            { name: "Croatia", isoCode: "HR" },
            { name: "Czechia", isoCode: "CZ" },
            { name: "Estonia", isoCode: "EE" },
            { name: "Georgia", isoCode: "GE" },
            { name: "Hungary", isoCode: "HU" },
            { name: "Latvia", isoCode: "LV" },
            { name: "Lithuania", isoCode: "LT" },
            { name: "Montenegro", isoCode: "ME" },
            { name: "Poland", isoCode: "PL" },
            { name: "Republic of Moldova", isoCode: "MD" },
            { name: "Romania", isoCode: "RO" },
            { name: "Serbia", isoCode: "RS" },
            { name: "Slovakia", isoCode: "SK" },
            { name: "North Macedonia", isoCode: "MK" },
            { name: "Ukraine", isoCode: "UA" },
            { name: "Slovenia", isoCode: "SI" }
        ]
    },


];

export const sortedCountriesGroupedBySubRegion = countriesGroupedBySubRegion
    .map(region => ({
        ...region,
        countries: region.countries.sort((a, b) => a.name.localeCompare(b.name)) // Sort countries alphabetically by name
    }))
    .sort((a, b) => a.subRegion.localeCompare(b.subRegion)); // Sort sub-regions alphabetically by subRegion

export const sortedCountriesGroupedBySubRegionForFilter = countriesGroupedBySubRegionForFilter
    .map(region => ({
        ...region,
        countries: region.countries.sort((a, b) => a.name.localeCompare(b.name)) // Sort countries alphabetically by name
    }))
    .sort((a, b) => a.subRegion.localeCompare(b.subRegion)); // Sort sub-regions alphabetically by subRegion


export const getCountryNameByCode = (isoCode) => {
    // Iterate through the grouped country data to find the country by isoCode
    for (const region of countriesGroupedBySubRegionForFilter) {
        const country = region.countries.find((c) => c.isoCode === isoCode);
        if (country) {
            return country.name; // Return the name if found
        }
    }
    return null; // Return null if the country code is not found
};
