import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import Hls from 'hls.js'; // Import hls.js for handling HLS stream
import styles from './modal.module.scss';
import { CustomHeading } from "../../../../../shared/ui/heading";
import { CustomButton } from '../../../../../shared/ui/button';
import { getStopPlayingStream } from '../../../logic/channels/selectors';
import { setStopPlayingStream } from '../../../logic/channels/slice';

const ChannelDetailsForm = ({
    setDetailsModalFooter,
    setIsDetailsModalOpen,
    stop_playing_stream,
    setStopPlayingStream,
    record, // assuming record contains stream_hls
    ...props
}) => {
    const videoRef = useRef(null);
    const hlsRef = useRef(null); // Ref to hold the HLS instance
    const [isPlaying, setIsPlaying] = useState(false); // State to track if the stream is playing

    const handleStopStream = useCallback(() => {

        if (hlsRef.current) {
            hlsRef.current.destroy(); // Destroy HLS instance
            hlsRef.current = null;
        }
        if (videoRef.current) {
            videoRef.current.pause(); // Pause the video
            videoRef.current.src = ''; // Optionally remove the video source
            videoRef.current.load(); // Reset the video element
        }

        setIsPlaying(false); // Update state

    }, []);

    useEffect(() => {
        handleStopStream()
    }, [stop_playing_stream])
    useEffect(() => {
        setDetailsModalFooter(
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                {/* <CustomButton
                    title={"Update"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleStopStream} // Stop stream on Update click
                /> */}
                <CustomButton
                    title={"Stop and close"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    colorScheme="danger"
                    onClick={() => {
                        handleStopStream(); // Stop stream on Cancel click
                        setIsDetailsModalOpen(false); // Close modal
                    }}
                />
            </div>
        );
    }, [handleStopStream, setDetailsModalFooter, setIsDetailsModalOpen]);

    useEffect(() => {
        if (record?.stream_hls && Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(record.stream_hls); // Use record?.stream_hls as the HLS stream URL
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                videoRef.current.play();
                setIsPlaying(true); // Update state to indicate stream is playing
            });
            hlsRef.current = hls; // Store HLS instance

            return () => {
                if (hlsRef.current) {
                    hlsRef.current.destroy(); // Clean up on unmount
                }
            };
        } else if (videoRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = record.stream_hls;
            videoRef.current.play();
            setIsPlaying(true); // Update state
        }
    }, [record?.stream_hls]);

    return (
        <div className={styles.channels_hls_player_container}>
            <CustomHeading>Channel HLS Stream</CustomHeading>
            <video
                ref={videoRef}
                controls
                className={styles.video_player}
                style={{ width: '100%', height: 'auto' }}
            />
        </div>
    );
};

const mapState = (state) => ({
    stop_playing_stream: getStopPlayingStream(state)
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapState, mapDispatchToProps)(ChannelDetailsForm);
