import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import styles from './styles.module.scss';
import Sidebar from "./Sidebar";
import Overview from "./subscreens/Overview";
import PeopleManager from "./subscreens/PeopleManager";
import Channels from "./subscreens/Channels";
import Fast from "./subscreens/Fast";
import Venders from "./subscreens/Venders";
import Settings from "./subscreens/Settings";
import { useEffect, useState } from "react";
import { fetchUserProfile } from "../logic/dashboard/actions";
import Products from "./subscreens/Products";
import { fetchListOfVendorsNames } from "../logic/vendor/actions";
import OverviewNew from "./subscreens/Overview-new";
import Deals from "./subscreens/Deals";
import { fetchListOfAllChannelsWithOnlyCoreDetails } from "../logic/channels/actions";
import { fetchListOfAllCategories } from "../logic/category/actions";
import { fetchPermissions } from "../logic/permissions/actions";
import UsersScreen from "./subscreens/Users";
import CustomersScreen from "./subscreens/Customers";
import ic_process from '../../../assets/icons/business-process.gif'
import ic_close from '../../../assets/icons/remove.png'
import { CustomButton } from "../../../shared/ui/button";
import Profile from "./subscreens/Profile";
const DashboardPage = ({
    fetchUserProfile,
    fetchListOfVendorsNames,
    fetchListOfAllChannelsWithOnlyCoreDetails,
    fetchListOfAllCategories,
    fetchPermissions,
    ...props }) => {
    const { sub_screen } = useParams();
    const [currentSubScreen, setCurrentSubScreen] = useState(<Overview />);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        fetchUserProfile({});
        fetchListOfVendorsNames({});
        fetchListOfAllChannelsWithOnlyCoreDetails({});
        fetchListOfAllCategories({});
        fetchPermissions({});
    }, []);

    const renderSubScreen = (sub_screen) => {
        switch (sub_screen) {
            case 'home':
                return <OverviewNew />;
            // case 'home_new':
            //     return <OverviewNew />;

            case 'people_manager':
                return <PeopleManager />;

            case 'channels':
                return <Channels />;

            case 'fast':
                return <Fast />;

            case 'vendors':
                return <Venders />;

            case 'products':
                return <Products />;

            case 'deals':
                return <Deals />;

            case 'settings':
                return <Settings />;

            case 'users':
                return <UsersScreen />;
            case 'profile':
                return <Profile />;
            case 'customers':
                return <CustomersScreen />;
        }
    };

    useEffect(() => {
        setCurrentSubScreen(renderSubScreen(sub_screen));
    }, [sub_screen]);

    return (
        <div className={styles.container}>
            <div className={styles.left_side} style={{ width: isSidebarOpen ? "5%" : "15%" }}>
                <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
            </div>
            <div className={styles.right_side} style={{ width: isSidebarOpen ? "95%" : "85%" }}>
                {/* <div className={styles.progress_bar_container}>
                    <div className={styles.icon_container}>
                        <img src={ic_process} width={"80%"} />
                    </div>
                  
                    <div className={styles.message_container}>Exporting vendor list .. </div>
                    <div className={styles.action_container}>
                        <CustomButton
                            title="Cancel"
                            width="100%"
                            font_size="0.7"
                            unit="rem"
                            padding="5px 5px 5px 5px"
                            colorScheme=""
                            border_color="#FAFAFA"
                            background_color="#FAFAFA"
                            icon={<img src={ic_close} width={"80%"} />}
                            onClick={() => { }}
                        />
                    </div>
                </div> */}

                {currentSubScreen}
            </div>
        </div>
    );
};

const mapState = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    fetchPermissions: (data) => dispatch(fetchPermissions(data)),
    fetchUserProfile: (data) => dispatch(fetchUserProfile(data)),
    fetchListOfAllCategories: (data) => dispatch(fetchListOfAllCategories(data)),
    fetchListOfVendorsNames: (data) => dispatch(fetchListOfVendorsNames(data)),
    fetchListOfAllChannelsWithOnlyCoreDetails: (data) => dispatch(fetchListOfAllChannelsWithOnlyCoreDetails(data)),
});

export default connect(mapState, mapDispatchToProps)(DashboardPage);