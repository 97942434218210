import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'users',
    initialState: {
        listOfUsers: [],
        isLoadingListOfUsers: false,
        listOfUserRoles: [],
        isLoadingListOfUserRoles: false,
        profileDetails: null,
        isLoadingProfileDetails: false,
        bankDetails: null,
        isLoadingBankDetails: false,
    },
    reducers: {
        setListOfUsers: (state, action) => {
            state.listOfUsers = action.payload;
        },
        setIsLoadingListOfUsers: (state, action) => {
            state.isLoadingListOfUsers = action.payload;
        },
        setListOfUserRoles: (state, action) => {
            state.listOfUserRoles = action.payload;
        },
        setIsLoadingListOfUserRoles: (state, action) => {
            state.isLoadingListOfUserRoles = action.payload;
        },
        setProfileDetails: (state, action) => {
            state.profileDetails = action.payload;
        },
        setIsLoadingProfileDetails: (state, action) => {
            state.isLoadingProfileDetails = action.payload;
        },
        setBankDetails: (state, action) => {
            state.bankDetails = action.payload;
        },
        setIsLoadingBankDetails: (state, action) => {
            state.isLoadingBankDetails = action.payload;
        },
    },
});

export const {
    setListOfUsers,
    setIsLoadingListOfUsers,
    setListOfUserRoles,
    setIsLoadingListOfUserRoles,
    setProfileDetails,
    setIsLoadingProfileDetails,
    setBankDetails,
    setIsLoadingBankDetails
} = appSlice.actions;

export default appSlice.reducer;
