// TopVendorsBarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Register necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const TopVendorsBarChart = ({ vendors, is_loading = true }) => {
    // Prepare data for Chart.js
    const chartData = {
        labels: vendors?.map((vendor) => vendor.vendor_name), // Vendor names as labels
        datasets: [
            {
                label: 'Total Matches',
                data: vendors?.map((vendor) => vendor.total_matches), // Total matches per vendor
                backgroundColor: '#08338A',
                borderColor: '#08338A',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)',
                hoverBorderColor: 'rgba(54, 162, 235, 1)',
            },
        ],
    };

    const options = {
        responsive: true,

        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const vendor = vendors[context.dataIndex];
                        const channelInfo = vendor.linked_channels
                            .map((channel) => `${channel.channel_name}: ${channel.count}`)
                            .join('\n');
                        return [
                            `Total Matches: ${vendor.total_matches}`,
                            `Channels:`,
                            `${channelInfo}`,
                        ];
                    },

                },
            },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    font: {
                        size: 12,
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Vendors',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 30,
                    minRotation: 0,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Match Count',
                    font: {
                        size: 10,
                        weight: 'bold',
                    },
                },
                ticks: {
                    precision: 0,
                },
            },
        },
    };

    return (
        <div>
            <h3 style={{ marginBottom: '1rem' }}>Top 10 Vendors having most matches</h3>
            {
                is_loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Spin indicator={<LoadingOutlined spin />} size="large" />
                </div> : <Bar  data={chartData} options={options} />
            }
        </div>
    );
};

export default TopVendorsBarChart;
