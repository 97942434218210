import { connect } from "react-redux";
import styles from '../styles.module.scss'

const SettingsScreen = ({ ...props }) => {
    return <div className={styles.container}>
        SettingsScreen
    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapState, mapDispatchToProps)(SettingsScreen)