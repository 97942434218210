import { apiGet, apiPost, apiPostWithFile, apiPut } from "../../shared/api/api";

const addNewPeople = (data) => apiPostWithFile('facial_recognition/add_facial_recognition_record', data);
const getAllPeople = (data,signal) => apiPost('facial_recognition/get_all_facial_recognition_records', data,signal);
const updatePeopleRec = (data) => apiPostWithFile('facial_recognition/update_facial_recognition_record', data);
const getAllTrainingImages = (data) => apiPost('facial_recognition/get_facial_training_images_by_user_id', data);
const deleteTrainingImage = (data) => apiPost('facial_recognition/delete_facial_training_image', data);
const configureTrainingImages = (data) => apiPostWithFile('facial_recognition/configure_facial_training_images', data);

export {
    addNewPeople,
    getAllPeople,
    updatePeopleRec,
    getAllTrainingImages,
    deleteTrainingImage,
    configureTrainingImages
}