import { connect } from "react-redux";
import styles from '../styles.module.scss'
import TabsRender from "./general-components/TabsRender";
import ConfigurationSidebar from "./general-components/ConfigurationSidebar";
import DetailsModal from "./general-components/DetailsModal";
import { useEffect, useState } from "react";
import Header from "./general-components/Header";
import { CustomButton } from "../../../../shared/ui/button";
import PeopleOverviewTab from "./people_manager-components/PeopleOverviewTab";
import NewPeopleDataForm from "./people_manager-components/NewPeopleDataForm";
import { setCurrentSelectPeople } from "../../logic/people-manager/slice";
import { getPermissions } from "../../logic/permissions/selectors";

const PeopleManager = ({
    setCurrentSelectPeople,
    permissions,
    ...props }) => {
    const [isPersonConfigurationSidebarOpen, setIsPersonConfigurationSidebarOpen] = useState(false)
    const [personConfigurationSidebarFooter, setPersonConfigurationSidebarFooter] = useState(null)
    const [currentSidebarFormToRender, setCurrentSidebarFormToRender] = useState(null)
    const [listOfTabs, setListOfTabs] = useState([]);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
    const [detailsModalTitle, setDetailsModalTitle] = useState("Person details")
    const [currentDetailsFormToRender, setCurrentDetailsFormToRender] = useState(null)
    const [detailsModalFooter, setDetailsModalFooter] = useState(null)
    const [isAllowedToAddPeople, setIsAllowedToAddPeople] = useState(false);

    useEffect(() => {

    }, [])

    useEffect(() => {
        let temp_tabs = [
            {
                label: "Overview",
                screen: <PeopleOverviewTab
                    setCurrentSidebarFormToRender={setCurrentSidebarFormToRender}
                    setIsPersonConfigurationSidebarOpen={setIsPersonConfigurationSidebarOpen}
                    setPersonConfigurationSidebarFooter={setPersonConfigurationSidebarFooter}

                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}

                    setDetailsModalFooter={setDetailsModalFooter}

                />
            },
            // {
            //     label: "Visualization",
            //     screen: null
            // }
        ]
        setListOfTabs(temp_tabs)
    }, [])

    useEffect(() => {
        if (permissions) {
            setIsAllowedToAddPeople(permissions?.ADD_PEOPLE?.enabled);
        }
    }, [permissions]);

    const handleCreatePerson = (e) => { }

    const handleCancelCreatePerson = (e) => {
        setIsPersonConfigurationSidebarOpen(false)
    }

    const handleCloseSidebar = (e) => {
        setIsPersonConfigurationSidebarOpen(false)
    }

    const handleTabOnChange = (e) => { }

    // Modal

    const handleOkayPersonDetailModal = (e) => {
        setIsDetailsModalOpen(false)
    }

    const handleCancelPersonDetailModal = (e) => {
        setIsDetailsModalOpen(false)
    }

    const handleClosePersonDetailModal = (e) => {
        setIsDetailsModalOpen(false)
    }

    return <div className={styles.people_manager_container}>
        <div className={styles.header_container}>

            <Header title='People' />

            <div className={styles.actions_container}>
                <CustomButton
                    title="Add new person"
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={(e) => {
                        setCurrentSelectPeople(null)
                        setCurrentSidebarFormToRender(<NewPeopleDataForm
                            setPersonConfigurationSidebarFooter={setPersonConfigurationSidebarFooter}
                            setIsPersonConfigurationSidebarOpen={setIsPersonConfigurationSidebarOpen}

                        />)
                        setIsPersonConfigurationSidebarOpen(true)
                    }}
                    disabled={!isAllowedToAddPeople}
                />
            </div>
        </div>
        <div className={styles.body_container}>
            <div className={styles.tabs_container}>
                <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
            </div>
        </div>
        {
            isPersonConfigurationSidebarOpen && <div className={styles.configuration_side_container}>
                <ConfigurationSidebar
                    title="Configure person"
                    handleClose={handleCloseSidebar}
                    handleOkay={handleCreatePerson}
                    handleCancel={handleCancelCreatePerson}
                    okay_button_title='Add person'
                    cancel_button_title='Cancel'
                    footer={personConfigurationSidebarFooter}
                    body={currentSidebarFormToRender}

                />
            </div>
        }
        <div className={styles.configuration_modal_container}>
            <DetailsModal
                title={detailsModalTitle}
                handleClose={handleClosePersonDetailModal}
                handleOkay={handleOkayPersonDetailModal}
                handleCancel={handleCancelPersonDetailModal}
                okay_button_title='Okay'
                cancel_button_title='Cancel'
                body={currentDetailsFormToRender}
                isModalOpen={isDetailsModalOpen}
                footer={detailsModalFooter}
            />
        </div>
    </div>
}

const mapState = (state) => ({
    permissions: getPermissions(state),
})

const mapDispatchToProps = (dispatch) => ({
    setCurrentSelectPeople: (formData) => dispatch(setCurrentSelectPeople(formData)),

});


export default connect(mapState, mapDispatchToProps)(PeopleManager)