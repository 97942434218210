import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'dashboard',
    initialState: {
        route: null,
        currentLoggedInUser: null,
        total_number_of_users: 0,
        total_number_of_channels: 0,
        total_number_of_vendors: 0,
        total_number_of_qr_code_scans: 0,
        total_number_of_qr_code_visits: 0,
        total_number_of_products_advertized: 0,
        total_number_of_products: 0,
        product_verification_speed: 0,
        is_loading_total_count_states: false,
        total_score_count: 0,
        total_average_score: 0,
        match_details: null,
        total_matches:0,
        is_loading_match: false,
        is_loading_total_users:false,
        is_loading_total_vendors:false,
        is_loading_total_channels:false,
        is_loading_review_states:false,
        is_loading_total_products:false,
        is_loading_unique_matches:false,
        is_loading_total_matches:false,
        is_loading_verification_speed:false,
        is_loading_qr_code_states:false,
        is_loading_top_10_vendors:false
    },
    reducers: {
        setRoute: (state, action) => {
            state.route = action.payload;
        },
        setCurrentLoggedInUser: (state, action) => {
            state.currentLoggedInUser = action.payload;
        },
        
        setMatchDetails: (state, action) => {
            state.match_details = action.payload;
        },
        setLoadingMatchDetails: (state, action) => {
            state.is_loading_match = action.payload;
        },
        setTotalScoreCount: (state, action) => {
            state.total_score_count = action.payload;
        },
        setTotalAverageScoreCount: (state, action) => {
            state.total_average_score = action.payload;
        },
        // Reducer for total number of users
        setTotalNumberOfUsers: (state, action) => {
            state.total_number_of_users = action.payload;
        },
        // Reducer for total number of channels
        setTotalNumberOfChannels: (state, action) => {
            state.total_number_of_channels = action.payload;
        },
        // Reducer for total number of vendors
        setTotalNumberOfVendors: (state, action) => {
            state.total_number_of_vendors = action.payload;
        },
        // Reducer for total number of QR code scans
        setTotalNumberOfQRCodeScans: (state, action) => {
            state.total_number_of_qr_code_scans = action.payload;
        },
        setTotalNumberOfQRCodeVisits: (state, action) => {
            state.total_number_of_qr_code_visits = action.payload;
        },
        // Reducer for total number of advertised products
        setTotalNumberOfProductsAdvertized: (state, action) => {
            state.total_number_of_products_advertized = action.payload;
        },
        // Reducer for total number of products
        setTotalNumberOfProducts: (state, action) => {
            state.total_number_of_products = action.payload;
        },
        setIsLoadingTotalCountsStates: (state, action) => {
            state.is_loading_total_count_states = action.payload;
        },
        setProductVerificationSpeed: (state, action) => {
            state.product_verification_speed = action.payload;
        },
        setTotalMatches: (state, action) => {
            state.total_matches = action.payload;
        },
        setIsLoadingTotalUsersCounts: (state, action) => {
            state.is_loading_total_users = action.payload;
        },
        setIsLoadingTotalVendorsCounts: (state, action) => {
            state.is_loading_total_vendors = action.payload;
        },
        setIsLoadingTotalChannelsCounts: (state, action) => {
            state.is_loading_total_channels = action.payload;
        },
        setIsLoadingReviewStates: (state, action) => {
            state.is_loading_review_states = action.payload;
        },
        setIsLoadingTotalProducts: (state, action) => {
            state.is_loading_total_products = action.payload;
        },
        setIsLoadingUniqueMatch: (state, action) => {
            state.is_loading_unique_matches = action.payload;
        },
        setIsLoadingTotalMatch: (state, action) => {
            state.is_loading_total_matches = action.payload;
        },
        setIsLoadingVerificationSpeed: (state, action) => {
            state.is_loading_verification_speed = action.payload;
        },
        setIsLoadingQRCodeStates: (state, action) => {
            state.is_loading_qr_code_states = action.payload;
        },
        setIsLoadingTop10Vendors: (state, action) => {
            state.is_loading_top_10_vendors = action.payload;
        },
    },
});

// Exporting actions for all reducers
export const {
    setRoute,
    setCurrentLoggedInUser,
    setTotalNumberOfUsers,
    setTotalNumberOfChannels,
    setTotalNumberOfVendors,
    setTotalNumberOfQRCodeScans,
    setTotalNumberOfQRCodeVisits,
    setTotalNumberOfProductsAdvertized,
    setTotalNumberOfProducts,
    setIsLoadingTotalCountsStates,
    setProductVerificationSpeed,
    setTotalAverageScoreCount,
    setTotalScoreCount,
    setMatchDetails,
    setLoadingMatchDetails,
    setIsLoadingTotalUsersCounts,
    setIsLoadingTotalVendorsCounts,
    setIsLoadingTotalChannelsCounts,
    setIsLoadingReviewStates,
    setIsLoadingTotalProducts,
    setIsLoadingUniqueMatch,
    setIsLoadingTotalMatch,
    setTotalMatches,
    setIsLoadingVerificationSpeed,
    setIsLoadingQRCodeStates,
    setIsLoadingTop10Vendors
} = appSlice.actions;

// Export the reducer
export default appSlice.reducer;
