import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles.module.scss';

import { fetchProfileDetails, updateBasicDetails } from '../../../logic/users/actions';
import { getProfileDetails, getIsLoadingProfileDetails } from '../../../logic/users/selectors';
import { CustomButton } from '../../../../../shared/ui/button';
import { CustomTextfield } from '../../../../../shared/ui/text-field';

const defaultData = {
    company_name: "",
    phone_number: "",
    address: "",
    website_url: "",
    instagram_url: "",
    facebook_url: "",
    linkedin_url: "",
    twitter_url: ""
};

const placeholders = {
    company_name: "Enter company name",
    phone_number: "Enter phone number",
    address: "Enter address",
    website_url: "Enter website URL",
    instagram_url: "Enter Instagram URL",
    facebook_url: "Enter Facebook URL",
    linkedin_url: "Enter LinkedIn URL",
    twitter_url: "Enter Twitter URL"
};

const labels = {
    company_name: "Company Name",
    phone_number: "Phone Number",
    address: "Address",
    website_url: "Website URL",
    instagram_url: "Instagram URL",
    facebook_url: "Facebook URL",
    linkedin_url: "LinkedIn URL",
    twitter_url: "Twitter URL"
};

const BasicDetails = ({
    profileDetails,
    isLoadingProfileDetails,
    fetchProfileDetails,
    updateBasicDetails,
    setIsDetailsModalOpen,
    setDetailsModalTitle,
    setCurrentDetailsFormToRender,
    setDetailsModalFooter,
    ...props
}) => {
    const [formData, setFormData] = useState({});

    useEffect(() => {
        fetchProfileDetails({});
    }, []);

    useEffect(() => {
        if (profileDetails && Object.keys(profileDetails).length > 0) {
            setFormData(profileDetails);
        } else {
            setFormData(defaultData);
        }
    }, [profileDetails]);

    const handleChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    const handleSave = () => {
        console.log('Saving Basic Details:', formData);
        updateBasicDetails(formData);
    };

    return (
        <div className={styles.profile_basic_details_container}>
            <div className={styles.top_actions_row}>
                <CustomButton
                    title="Save changes"
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={(e) => {
                        handleSave(e)
                    }}
                    loading={isLoadingProfileDetails}
                />
            </div>
            <div className={styles.body_container}>
                <div className={styles.left_side}>
                    <div className={styles.card}>
                        <div className={styles.formSection}>
                            <h3>Basic Information</h3>
                            {['company_name', 'phone_number', 'address'].map((key) => (
                                <div className={styles.inputGroup} key={key}>
                                    <label className={styles.label}>{labels[key]}</label>
                                    <CustomTextfield
                                        type="text"
                                        border_radius="5px"
                                        colorScheme="primary"
                                        place_holder={placeholders[key]}
                                        font_size="0.7" unit="rem"
                                        value={formData[key]}
                                        onChange={(e) => handleChange(key, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.right_side}>
                    <div className={styles.card}>
                        <div className={styles.formSection}>
                            <h3>Social Media</h3>
                            {['website_url', 'instagram_url', 'facebook_url', 'linkedin_url', 'twitter_url'].map((key) => (
                                <div className={styles.inputGroup} key={key}>
                                    <label className={styles.label}>{labels[key]}</label>
                                    <CustomTextfield
                                        type="text"
                                        border_radius="5px"
                                        colorScheme="primary"
                                        place_holder={placeholders[key]}
                                        font_size="0.7" unit="rem"
                                        value={formData[key]}
                                        onChange={(e) => handleChange(key, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    profileDetails: getProfileDetails(state),
    isLoadingProfileDetails: getIsLoadingProfileDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchProfileDetails: (data) => dispatch(fetchProfileDetails(data)),
    updateBasicDetails: (data) => dispatch(updateBasicDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
