import { connect } from "react-redux";
import styles from '../styles.module.scss'
import TabsRender from "./general-components/TabsRender";
import EAT_FASTSwapTab from "./fast-components/EAT_FASTSwapTab";
import FASTVisualizationTab from "./fast-components/FASTVisualizationTab";
import { useEffect, useState } from "react";
import Header from "./general-components/Header";
import FAST_tx_polygon_Tab from "./fast-components/FAST_tx_polygon_Tab";
import EAT_FAST_TX_OverviewTab from "./fast-components/EAT_FAST_TX_OverviewTab";

const FASTScreen = ({ ...props }) => {
    const [listOfTabs, setListOfTabs] = useState([]);

    useEffect(() => {
        let temp_tabs = [
            {
                label: "EAT-FAST-TXT Overview",
                screen: <EAT_FAST_TX_OverviewTab />
            },
            {
                label: "EAT-FAST-Swaps",
                screen: <EAT_FASTSwapTab />
            },
            {
                label: "FAST-txt @ Polygon",
                screen: <FAST_tx_polygon_Tab />
            },
            {
                label: "Visualization",
                screen: <FASTVisualizationTab />
            }
        ]
        setListOfTabs(temp_tabs)
    }, [])

    const handleTabOnChange = (e) => { }

    return <div className={styles.fast_container}>
        <div className={styles.header_container}>
            <Header title='$FAST' />
        </div>
        <div className={styles.body_container}>
            <div className={styles.tabs_container}>
                <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
            </div>
        </div>
    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapState, mapDispatchToProps)(FASTScreen)