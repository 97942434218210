
import toast from "react-hot-toast";
import { CategoryAPI } from "../../../api";
import { setIsLoadingListOfCategories, setListOfCategories } from "./slice";

let abortController_fetchListOfAllCategories = null;  // Declare globally within the file

const fetchListOfAllCategories = (data) => (dispatch) => {
    if (abortController_fetchListOfAllCategories) {
        abortController_fetchListOfAllCategories.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllCategories = new AbortController();
    const { signal } = abortController_fetchListOfAllCategories;
    dispatch(setIsLoadingListOfCategories(true))
    CategoryAPI.getAllCategories(data, signal)
        .then((response) => {
         
            let formatted_data = response?.data?.map((record) => {
                return {
                   ...record
                }
            })

            dispatch(setListOfCategories({
                formatted_data: formatted_data,
                // totalRecords: response.totalRecords,
                // totalPages: response.totalPages,
                // currentPage: response.currentPage
            }))

            dispatch(setIsLoadingListOfCategories(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfCategories(true))

        })
};


export {
    // Note user
    fetchListOfAllCategories
}