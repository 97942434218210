import { useState, CSSProperties } from "react";
import styles from './styles.module.scss';
import { CustomCard } from './styled'; // Import the CustomCard component
import PropagateLoader from "react-spinners/PropagateLoader";
// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};
const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

const CustomStatesCard = ({
    title = '',
    font_size = '24px', // Base font size for the heading
    unit = 'px', // Unit for font size
    font_weight = 'normal', // Font weight for the heading
    colorScheme = 'primary', // Prop for color schemes
    textAlign = 'left', // Text alignment for the heading
    marginTop = '0', // Margin-top for the heading
    marginBottom = '10px', // Margin-bottom for the heading
    lineHeight = '1.4', // Line height for the heading
    pick_passed_font_size = false,
    mobileFontSize,
    tabletFontSize,
    laptopFontSize,
    macbook13FontSize,
    cardHeight = '50px',
    // Custom color properties (will be overridden by color schemes if specified)
    customTextColor,
    icon,
    value = 'Default Value',
    valueFontSize = '16px',
    valueFontWeight = 'normal',
    valueColor,
    headingFontSize = '18px',
    is_loading = true,
    cardWidth,
    ...props
}) => {
    // Color schemes
    const colorSchemes = {
        primary: {
            color: 'black',
        },
        secondary: {
            color: '#28a745',
        },
        danger: {
            color: '#dc3545',
        },
        warning: {
            color: '#ffc107',
        },
        info: {
            color: '#17a2b8',
        },
        custom: {
            color: customTextColor || '#333', // Fallback to default color if customTextColor is not provided
        }
    };

    // Apply the selected color scheme
    const selectedColors = colorSchemes[colorScheme] || colorSchemes.custom;

    const responsiveFontSizes = getResponsiveFontSizes(font_size, unit);
    const responsiveValueFontSizes = getResponsiveFontSizes(valueFontSize, unit);

    const finalMobileFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveFontSizes.mobileFontSize;
    const finalTabletFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveFontSizes.tabletFontSize;
    const finalLaptopFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveFontSizes.laptopFontSize;
    const finalMacbook13FontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveFontSizes.macbook13FontSize;

    const finalMobileValueFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveValueFontSizes.mobileFontSize;
    const finalTabletValueFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveValueFontSizes.tabletFontSize;
    const finalLaptopValueFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveValueFontSizes.laptopFontSize;
    const finalMacbook13ValueFontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveValueFontSizes.macbook13FontSize;

    return (
         <div className={styles.container}>
            <CustomCard
                    width={cardWidth}
                    title={title}
                    height={cardHeight}
                    headingBgColor="#f0f0f0"
                    borderColor="#d9d9d9"
                    headingColor={selectedColors.color}
                    headingFontSize={headingFontSize}
                    mobileHeadingFontSize={finalMobileFontSize}
                    tabletHeadingFontSize={finalTabletFontSize}
                    laptopHeadingFontSize={finalLaptopFontSize}
                    macbook13HeadingFontSize={finalMacbook13FontSize}
                    iconColor="#000"
                    valueColor={valueColor || selectedColors.color}
                    valueFontSize={valueFontSize}
                    mobileValueFontSize={finalMobileValueFontSize}
                    tabletValueFontSize={finalTabletValueFontSize}
                    laptopValueFontSize={finalLaptopValueFontSize}
                    macbook13ValueFontSize={finalMacbook13ValueFontSize}
                >
                    <div className="icon-container">
                        {icon}
                    </div>
                    <div className="value-container">
                         {is_loading ? <p style={{fontSize:"1rem"}}>Loading... </p> : value}
                    </div>
                </CustomCard>
        </div>
    );
};

export { CustomStatesCard };
