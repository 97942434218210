export const getAllChannels = (state) => state.channel?.listOfChannels
export const getIsLoadingListOfChannels = (state) => state.channel?.is_loading_list_of_channels
export const getStopPlayingStream = (state) => state.channel?.stop_playing_stream
export const getListOfFacialRecognitionsLinkedToChannel = (state) => state.channel?.listOfFacialRecognitionsLinkedToChannel
export const getIsLoadingListOfFacialRecognitionsLinkedToChannel = (state) => state.channel?.is_loading_list_of_facial_recognitions
export const getCurrentSelectedChannelRecord = (state) => state.channel?.current_select_channel

export const getListOfBlacklistViatorTagLinkedToChannel = (state) => state.channel?.listOfBlacklistViatorTagLinkedToChannel
export const getIsLoadingListOfBlacklistViatorTagLinkedToChannel = (state) => state.channel?.is_loading_list_of_black_list_viator_tags
export const getIsLoadingMatchCountDivisionForChannel= (state) => state.channel?.is_loading_match_count_division_for_channel
export const getMatchCountDivisionForChannel= (state) => state.channel?.match_count_division_for_channel

export const getListOfBlacklistDestinationLinkedToChannel = (state) => state.channel?.listOfBlacklistDestinationLinkedToChannel
export const getIsLoadingListOfBlacklistDestinationLinkedToChannel = (state) => state.channel?.is_loading_list_of_black_list_destination

export const getListOfChannelsWithOnlyCoreDetails = (state) => state.channel?.listOfChannelWithOnlyCoreDetails
export const getIsLoadingListOfChannelsWithOnlyCoreDetails = (state) => state.channel?.is_loading_list_of_channels_with_only_core_details

export const getIsLoadingLReviewDetails = (state) => state.channel?.is_loading_review_details

export const getIsLoadingListOfChannelsToExport = (state) => state.channel?.is_loading_list_of_channels_for_export
export const getListOfChannelsToExport = (state) => state.channel?.listOfChannelsForExporting

export const getIsLoadingListOfCategoriesForChannel = (state) => state.channel?.is_loading_list_of_categories_for_channels
export const getListOfCategoriesForChannel = (state) => state.channel?.listOfCategoriesForChannel


export const getIsUpdatingBlacklistKeywords = (state) => state.channel?.is_updating_blacklist_keywords




