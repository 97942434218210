import { connect } from "react-redux";
import styles from '../../styles.module.scss';
import { CustomHeading } from "../../../../../shared/ui/heading";
import { useState, useRef, useEffect, useCallback } from "react";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { Button, Form, DatePicker, Upload } from "antd";
import { FaUpload } from "react-icons/fa";
import { addNewChannel, updateChannelDetails } from "../../../logic/channels/actions";
import { CustomButton } from "../../../../../shared/ui/button";
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin if needed
import localizedFormat from "dayjs/plugin/localizedFormat"; // Import localized format plugin

import { addPeopleRecord, updatePeopleRecord } from "../../../logic/people-manager/actions";
import ic_delete from '../../../../../assets/icons/delete.png'
import { getCurrentSelectedPeople } from "../../../logic/people-manager/selectors";
import dayjs from "dayjs";
import { refreshListOfPeople } from "../../../logic/people-manager/slice";
import toast from "react-hot-toast";
import { isValidUrl } from "../../../../../shared/utils/utils";
import { use } from "react";

dayjs.extend(utc)
dayjs.extend(timezone); // Extend with timezone if needed
dayjs.extend(localizedFormat); // Extend with localized format if needed


const NewPeopleDataForm = ({
    record,
    is_this_for_update = false,
    setIsPersonConfigurationSidebarOpen,
    setPersonConfigurationSidebarFooter,
    addPeopleRecord,
    updatePeopleRecord,
    refreshListOfPeople,
    searchParams,
}) => {
    const [name, setName] = useState("");
    const [dob, setDob] = useState(null);
    const [description, setDescription] = useState("");
    const [twitter, setTwitter] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [personalUrl, setPersonalUrl] = useState("");
    const [logoFile, setLogoFile] = useState(null); // New state for the logo file
    const [hasChanges, setHasChanges] = useState(false); // Track unsaved changes
    const [existingLogoFile, setExistingLogoFile] = useState(null)

    const [fileList, setFileList] = useState([]);

    // Refs to hold the state values for updating or adding new records
    const nameRef = useRef(name);
    const dobRef = useRef(dob);
    const descriptionRef = useRef(description);
    const twitterRef = useRef(twitter);
    const facebookRef = useRef(facebook);
    const instagramRef = useRef(instagram);
    const personalUrlRef = useRef(personalUrl);


    // Update refs when state changes
    useEffect(() => {
        nameRef.current = name;
        dobRef.current = dob;
        descriptionRef.current = description;
        twitterRef.current = twitter;
        facebookRef.current = facebook;
        instagramRef.current = instagram;
        personalUrlRef.current = personalUrl;
    }, [name, dob, description, twitter, facebook, instagram, personalUrl]);

    useEffect(() => {
        const initialValues = {
            name: record?.name || "",
            description: record?.accomplishments || "",
            twitter: record?.twitter_url || "",
            facebook: record?.facebook_url || "",
            instagram: record?.instagram_url || "",
            personalUrl: record?.personal_url || "",
            // No initial value for logoFile, tracking only if it's not null
        };

        const currentValues = {
            name,
            description,
            twitter,
            facebook,
            personalUrl,
            instagram,
        };

        // Compare current values with initial values to detect changes
        const hasUnsavedChanges = Object.keys(initialValues).some(key => {
            return initialValues[key] !== currentValues[key]; // Compare other values as normal
        });

        setHasChanges(hasUnsavedChanges);
    }, [name, description, twitter, facebook, instagram,personalUrl, record]);


    // Populate fields if record exists (for update functionality)
    useEffect(() => {
        if (record !== null) {
        
            setName(record?.name || "");

            setDob(record.dob); // Store as a Day.js object for internal use
            setDescription(record?.accomplishments || "");
            setTwitter(record?.twitter_url || "");
            setFacebook(record?.facebook_url || "");
            setInstagram(record?.instagram_url || "");
            setPersonalUrl(record?.personal_url || "");
            setExistingLogoFile(record?.profile_url)
        }
    }, [record]);

    const handleCreateOrUpdate = useCallback(() => {
        const updatedName = nameRef.current;
        let updatedDob = dobRef.current ? dobRef.current : null;
        const updatedDescription = descriptionRef.current;
        const updatedTwitter = twitterRef.current;
        const updatedFacebook = facebookRef.current;
        const updatedInstagram = instagramRef.current;
        const updatedPersonalUrl = personalUrlRef.current;
        const dobForQuery = updatedDob ? dayjs(updatedDob).format('YYYY-MM-DD') : null
        // Check for mandatory fields
        if (!updatedName) {
            toast.error('Name is required!');
            return; // Exit the function if name is empty
        }

        // // Validate URLs
        // if (!isValidUrl(updatedTwitter)) {
        //     toast.error('Please enter a valid Twitter URL!');
        //     return;
        // }

        // if (!isValidUrl(updatedFacebook)) {
        //     toast.error('Please enter a valid Facebook URL!');
        //     return;
        // }

        // if (!isValidUrl(updatedInstagram)) {
        //     toast.error('Please enter a valid Instagram URL!');
        //     return;
        // }


        const formData = new FormData();
        formData.append("name", updatedName);
        formData.append("dob", dobForQuery);
        formData.append("accomplishments", updatedDescription);
        formData.append("twitter_url", updatedTwitter);
        formData.append("facebook_url", updatedFacebook);
        formData.append("instagram_url", updatedInstagram);
        formData.append("personal_url", updatedPersonalUrl);
        if (logoFile) {
            formData.append("file", logoFile); // Append the logo file if available
        }else if(!is_this_for_update){
            toast.error('Logo is required!');
            return; // Exit the function if name is empty
        }

        if (!is_this_for_update) {
            addPeopleRecord(formData).then((res) => {
                setIsPersonConfigurationSidebarOpen(false)
            })
        } else {
            formData.append("id", record?.id); // Adjust according to your ID field
            updatePeopleRecord(formData,searchParams).then((res) => {
                setHasChanges(false)
                setLogoFile(null)
                setFileList([])
                refreshListOfPeople()
            })
        }
    }, [logoFile]);


    useEffect(() => {
        setPersonConfigurationSidebarFooter(
            <div className={styles.footer_container}>
                <CustomButton
                    title={is_this_for_update ? "Update" : "Add"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleCreateOrUpdate}
                    disabled={is_this_for_update == true ? !hasChanges : false} // Disable the button if no changes
                />
                <CustomButton
                    title={"Cancel"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    colorScheme="danger"
                    onClick={() => { setIsPersonConfigurationSidebarOpen(false) }}
                />
            </div>
        );
    }, [handleCreateOrUpdate, hasChanges]);


    const handleRemoveImage = () => {
        setFileList([]); // Clear the file list
        setLogoFile(null)
    };

 

    const handleChange = (info) => {
        if (info.fileList.length > 0) {
            const file = info.fileList[0]; // Get the first file from the list
           
            setLogoFile(file.originFileObj)
            // Create an object URL for the selected file
            const objectUrl = URL.createObjectURL(file.originFileObj);
            // Update the state with the new file list containing only the object URL
            setFileList([objectUrl]);
            setHasChanges(true)
        } else {
            setFileList([]); // Clear the file list if no files are present

        }
    };

    // Handler for DatePicker to update dob state without resetting to the default value
    const handleDateChange = (date) => {
        const formattedDob = dayjs.utc(date);
        setDob(formattedDob);
        setHasChanges(true); // Update hasChanges when a new date is selected
    };

    return (
        <div className={styles.person_data_form_container}>
            <div className={styles.row_1}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Name" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter name"
                        font_size="0.7" unit="rem"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </div>

            <div className={styles.row_1}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Date of Birth" />
                </div>
                <div className={styles.field}>
                    <DatePicker
                        style={{ width: '100%', borderRadius: '5px' }}
                        value={dob ? dayjs.tz(dob, dayjs.tz.guess()) : null}
                        onChange={handleDateChange}
                        format="MM-DD-YYYY"
                        placeholder="Select date of birth"
                    />
                </div>
            </div>

            <div className={styles.row_1}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Description" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter description"
                        font_size="0.7" unit="rem"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        rows={4}
                    />
                </div>
            </div>

            <div className={styles.row_1}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Twitter" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter Twitter URL"
                        font_size="0.7" unit="rem"
                        value={twitter}
                        onChange={(e) => setTwitter(e.target.value)}
                    />
                </div>
            </div>

            <div className={styles.row_1}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Facebook" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter Facebook URL"
                        font_size="0.7" unit="rem"
                        value={facebook}
                        onChange={(e) => setFacebook(e.target.value)}
                    />
                </div>
            </div>

            <div className={styles.row_1}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Instagram" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter Instagram URL"
                        font_size="0.7" unit="rem"
                        value={instagram}
                        onChange={(e) => setInstagram(e.target.value)}
                    />
                </div>
            </div>

            <div className={styles.row_1}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Personal Url" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter Personal URL"
                        font_size="0.7" unit="rem"
                        value={personalUrl}
                        onChange={(e) => setPersonalUrl(e.target.value)}
                    />
                </div>
            </div>

            <div className={styles.row_1}>
                <div className={styles.field} style={{ textAlign: 'center' }}>
                    {/* Display the existing logo if available */}

                    {existingLogoFile && (
                        <div style={{ marginBottom: '1rem', width: '100%', textAlign: 'center' }}>
                            <img
                                src={typeof existingLogoFile === 'string' ? existingLogoFile : URL.createObjectURL(logoFile)}
                                alt="Current Logo"
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            />
                        </div>
                    )}

                    <Form.Item

                        name="upload"
                        rules={[{ required: true, message: 'Please upload a logo!' }]}
                    // getValueFromEvent={normFile} // Update function to handle file selection correctly
                    >
                        <Upload
                            name="logo"
                            action="/upload.do"
                            listType="picture"
                            accept=".jpg,.jpeg,.png,.gif"
                            beforeUpload={() => false} // Prevent automatic upload
                            showUploadList={false}
                            fileList={[]}
                            onChange={handleChange} // Set the onChange event handler
                        >
                            <Button icon={<FaUpload />}>{record != null ? 'Change Logo' : 'Upload New Logo'}</Button>
                        </Upload>
                        {/* Display the uploaded image if it exists */}
                        {fileList.length > 0 && (
                            <div style={{ marginTop: 16, border: "1px solid black", display: 'flex', justifyContent: "space-between", alignItems: "center", padding: "1%", borderRadius: "10px" }}>
                                <img
                                    width={30} // Adjust width as needed
                                    src={fileList[0]} // Use the object URL from fileList
                                    alt="Uploaded Image"
                                />
                                <Button
                                    type="link"
                                    onClick={handleRemoveImage}
                                    style={{ marginLeft: 8 }}
                                >
                                    <img src={ic_delete} width={20} />
                                </Button>
                            </div>
                        )}

                    </Form.Item>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    record: getCurrentSelectedPeople(state)
});

const mapDispatchToProps = (dispatch) => ({
    addPeopleRecord: (formData) => dispatch(addPeopleRecord(formData)),
    updatePeopleRecord: (formData,searchParams) => dispatch(updatePeopleRecord(formData,searchParams)),
    refreshListOfPeople: (data) => dispatch(refreshListOfPeople(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPeopleDataForm);
