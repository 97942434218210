import { connect } from "react-redux";
import styles from '../../styles.module.scss'
import { CustomHeading } from "../../../../../shared/ui/heading";

const Header = ({ title = 'default title', ...props }) => {
    return <div className={styles.general_header_container}>
        <CustomHeading headingText={title} font_size="1.5" unit="rem" />
    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});


export default connect(mapState, mapDispatchToProps)(Header)