import { connect } from "react-redux";
import styles from '../styles.module.scss';
import TabsRender from "./general-components/TabsRender";
import { useEffect, useState } from "react";
import Header from "./general-components/Header";
import DetailsModal from "./general-components/DetailsModal";
import UsersOverview from "./user-components/UsersOverview";

const UsersScreen = ({ ...props }) => {
    const [listOfTabs, setListOfTabs] = useState([]);

    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [detailsModalTitle, setDetailsModalTitle] = useState("User details");
    const [currentDetailsFormToRender, setCurrentDetailsFormToRender] = useState(null);
    const [detailsModalFooter, setDetailsModalFooter] = useState(null);

    useEffect(() => {
        let temp_tabs = [
            {
                label: "Users",
                screen: <UsersOverview
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                    setDetailsModalFooter={setDetailsModalFooter}
                />
            },
            // {
            //     label: "Visualization",
            //     screen: <ProductsVisualization />
            // }
        ];
        setListOfTabs(temp_tabs);
    }, []);

    const handleTabOnChange = (e) => { };

    // Modal
    const handleOkayDetailsModal = (e) => {
        setIsDetailsModalOpen(false);
    };
    const handleCancelDetailsModal = (e) => {
        setIsDetailsModalOpen(false);
    };
    const handleCloseDetailsModal = (e) => {
        setIsDetailsModalOpen(false);
    };

    return (
        <div className={styles.users_container}>
            <div className={styles.header_container}>
                <Header title='Users' />
            </div>
            <div className={styles.body_container}>
                <div className={styles.tabs_container}>
                    <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
                </div>
            </div>
            <div className={styles.configuration_modal_container}>
                <DetailsModal
                    title={detailsModalTitle}
                    handleClose={handleCloseDetailsModal}
                    handleOkay={handleOkayDetailsModal}
                    handleCancel={handleCancelDetailsModal}
                    okay_button_title='Okay'
                    cancel_button_title='Cancel'
                    body={currentDetailsFormToRender}
                    isModalOpen={isDetailsModalOpen}
                    footer={detailsModalFooter}
                />
            </div>
        </div>
    );
};

const mapState = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapState, mapDispatchToProps)(UsersScreen);