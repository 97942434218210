import { ChannelAPI, StatesAPI, UserAPI, VendorPI } from "../../../api";

import { setCurrentLoggedInUser, setIsLoadingQRCodeStates, setIsLoadingReviewStates, setIsLoadingTop10Vendors, setIsLoadingTotalChannelsCounts, setIsLoadingTotalCountsStates, setIsLoadingTotalMatch, setIsLoadingTotalProducts, setIsLoadingTotalUsersCounts, setIsLoadingTotalVendorsCounts, setIsLoadingUniqueMatch, setIsLoadingVerificationSpeed, setMatchDetails, setProductVerificationSpeed, setTotalAverageScoreCount, setTotalMatches, setTotalNumberOfChannels, setTotalNumberOfProducts, setTotalNumberOfProductsAdvertized, setTotalNumberOfQRCodeScans, setTotalNumberOfQRCodeVisits, setTotalNumberOfUsers, setTotalNumberOfVendors, setTotalScoreCount } from "./slice";

let abortController_fetchVendorCountDetailsByChannel = null
let abortController_fetchReviewDetails = null

const fetchUserProfile = (data) => (dispatch) => {
    UserAPI.getUserProfile(data)
        .then((response) => {
            dispatch(setCurrentLoggedInUser(response?.user?.user))
        })
        .catch((error) => {
            // TODO: un-comment it
            const fullUrl = `${window.location.origin}`;
            // window.location.href = fullUrl;
        })
};

const fetchTotalCountStates = (data) => (dispatch) => {
    dispatch(setIsLoadingTotalCountsStates(true))
    StatesAPI.getTotalCountsStates(data)
        .then((response) => {

            if (response?.status === 'success') {
                let formatted_data = {
                    match_details: {
                        total_matches: response?.data?.totalMatches,
                        total_top_10_vendors: response?.data?.topVendorsWithMatches
                    },
                    product_verification_speed: parseFloat(response?.data?.productVerificationSpeed).toFixed(2)
                };


                // dispatch(setMatchDetails(formatted_data.match_details))


                dispatch(setIsLoadingTotalCountsStates(false))

            }
            dispatch(setIsLoadingTotalCountsStates(false))


        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingTotalCountsStates(false))

        })
};

const fetchTotalUserCounts = (data) => (dispatch) => {
    dispatch(setIsLoadingTotalUsersCounts(true))
    StatesAPI.getTotalUsersCounts(data)
        .then((response) => {

            if (response?.status === 'success') {
                let formatted_data = {
                    total_number_of_users: response?.data?.totalUsers,
                };
                dispatch(setTotalNumberOfUsers(formatted_data.total_number_of_users))
                dispatch(setIsLoadingTotalUsersCounts(false))
            }
            dispatch(setIsLoadingTotalUsersCounts(false))
        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingTotalCountsStates(false))

        })
};

const fetchTotalVendorsCounts = (data) => (dispatch) => {
    dispatch(setIsLoadingTotalVendorsCounts(true))

    if (data) {
        if (abortController_fetchVendorCountDetailsByChannel) {
            abortController_fetchVendorCountDetailsByChannel.abort();
        }

        // Create a new AbortController
        abortController_fetchVendorCountDetailsByChannel = new AbortController();
        const { signal } = abortController_fetchVendorCountDetailsByChannel;

        VendorPI.getVendorCountDetailsByChannel({ channel_id: data?.value }, signal)
            .then((response) => {
              
                if (response?.status === 'success') {
                    // Reset loading state and resolve with data
                    let formatted_data = {
                        total_number_of_vendors: response?.data?.counts?.total,
                    };

                    dispatch(setTotalNumberOfVendors(formatted_data.total_number_of_vendors))

                    dispatch(setIsLoadingTotalVendorsCounts(false))
                }
            }).catch((error) => {
                console.error(error)
                dispatch(setIsLoadingTotalVendorsCounts(false))
            });

    } else {
        StatesAPI.getTotalVendorsCounts(data)
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        total_number_of_vendors: response?.data?.totalVendors,
                    };

                    dispatch(setTotalNumberOfVendors(formatted_data.total_number_of_vendors))

                    dispatch(setIsLoadingTotalVendorsCounts(false))

                }
                dispatch(setIsLoadingTotalVendorsCounts(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingTotalVendorsCounts(false))
            })
    }

};


const fetchTotalChannelCounts = (data) => (dispatch) => {
    dispatch(setIsLoadingTotalChannelsCounts(true))
    StatesAPI.getTotalChannelsCounts(data)
        .then((response) => {

            if (response?.status === 'success') {
                let formatted_data = {
                    total_number_of_channels: response?.data?.totalChannels,
                };

                dispatch(setTotalNumberOfChannels(formatted_data.total_number_of_channels))

                dispatch(setIsLoadingTotalChannelsCounts(false))

            }
            dispatch(setIsLoadingTotalChannelsCounts(false))


        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingTotalChannelsCounts(false))

        })
};

const fetchReviewStates = (data) => (dispatch) => {
    dispatch(setIsLoadingReviewStates(true))
    if (data) {
        if (abortController_fetchReviewDetails) {
            abortController_fetchReviewDetails.abort();
        }

        // Create a new AbortController
        abortController_fetchReviewDetails = new AbortController();
        const { signal } = abortController_fetchReviewDetails;
        ChannelAPI.getReviewDetails({ channel_id: data?.value }, signal)
            .then((response) => {
            
                if (response?.status === 'success') {

                    let formatted_data = {
                        total_score_count: response?.data?.score_count,
                        total_average_score: response?.data?.average_score,
                    };

                    dispatch(setTotalScoreCount(formatted_data.total_score_count))
                    dispatch(setTotalAverageScoreCount(formatted_data.total_average_score))
                    dispatch(setIsLoadingReviewStates(false))

                }
            }).catch((error) => {
                console.error(error)
                dispatch(setTotalScoreCount(0))
                dispatch(setTotalAverageScoreCount(0.00))
                dispatch(setIsLoadingReviewStates(false))
            });
    } else {

        StatesAPI.getReviewStates(data)
            .then((response) => {

                if (response?.status === 'success') {

                    let formatted_data = {
                        total_score_count: response?.data?.totalScoreStates.score_count,
                        total_average_score: response?.data?.totalScoreStates.average_score,
                    };

                    dispatch(setTotalScoreCount(formatted_data.total_score_count))
                    dispatch(setTotalAverageScoreCount(formatted_data.total_average_score))
                    dispatch(setIsLoadingReviewStates(false))

                }
                dispatch(setIsLoadingReviewStates(false))

            })
            .catch((error) => {
                console.error(error)
                dispatch(setTotalScoreCount(0))
                dispatch(setTotalAverageScoreCount(0.00))
                dispatch(setIsLoadingReviewStates(false))
            })
    }
};

const fetchTotalProductsCount = (data) => (dispatch) => {
    dispatch(setIsLoadingTotalProducts(true))
    if (data) {
        StatesAPI.getTotalProductsCountsForChannel({ channel_id: data?.value })
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        total_number_of_products: response?.data?.totalOverallProducts,
                    };


                    dispatch(setTotalNumberOfProducts(formatted_data.total_number_of_products))

                    dispatch(setIsLoadingTotalProducts(false))

                }
                dispatch(setIsLoadingTotalProducts(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingTotalProducts(false))

            })
    } else {
        StatesAPI.getTotalProductsCounts(data)
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        total_number_of_products: response?.data?.totalOverallProducts,
                    };


                    dispatch(setTotalNumberOfProducts(formatted_data.total_number_of_products))

                    dispatch(setIsLoadingTotalProducts(false))

                }
                dispatch(setIsLoadingTotalProducts(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingTotalProducts(false))

            })
    }
};


const fetchTotalUniqueMatches = (data) => (dispatch) => {
    dispatch(setIsLoadingUniqueMatch(true))
    if (data) {
        StatesAPI.getTotalUniqueMatchesForChannel({ channel_id: data?.value })
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        total_number_of_products_advertized: response?.data?.totalAdvertisedProducts,
                    };


                    dispatch(setTotalNumberOfProductsAdvertized(formatted_data.total_number_of_products_advertized))

                    dispatch(setIsLoadingUniqueMatch(false))

                }
                dispatch(setIsLoadingUniqueMatch(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingUniqueMatch(false))

            })
    } else {
        StatesAPI.getTotalUniqueMatches(data)
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        total_number_of_products_advertized: response?.data?.totalAdvertisedProducts,
                    };


                    dispatch(setTotalNumberOfProductsAdvertized(formatted_data.total_number_of_products_advertized))

                    dispatch(setIsLoadingUniqueMatch(false))

                }
                dispatch(setIsLoadingUniqueMatch(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingUniqueMatch(false))

            })
    }
};


const fetchTotalMatches = (data) => (dispatch) => {
    dispatch(setIsLoadingTotalMatch(true))
    if (data) {
        StatesAPI.getTotalMatchesForChannel({ channel_id: data?.value })
            .then((response) => {
                if (response?.status === 'success') {
                    let formatted_data = {
                        total_matches: response?.data?.totalMatches,
                    };

                    dispatch(setTotalMatches(formatted_data.total_matches))
                    dispatch(setIsLoadingTotalMatch(false))

                }
                dispatch(setIsLoadingTotalMatch(false))
            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingTotalMatch(false))

            })
    } else {
        StatesAPI.getTotalMatches(data)
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        total_matches: response?.data?.totalMatches,
                    };

                    dispatch(setTotalMatches(formatted_data.total_matches))
                    dispatch(setIsLoadingTotalMatch(false))

                }
                dispatch(setIsLoadingTotalMatch(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingTotalMatch(false))

            })
    }
};


const fetchProductVerificationSpeed = (data) => (dispatch) => {
    dispatch(setIsLoadingVerificationSpeed(true))
    StatesAPI.getVerificationSpeed(data)
        .then((response) => {

            if (response?.status === 'success') {
                let formatted_data = {

                    product_verification_speed: parseFloat(response?.data?.productVerificationSpeed).toFixed(2)
                };


                dispatch(setProductVerificationSpeed(formatted_data.product_verification_speed))

                dispatch(setIsLoadingVerificationSpeed(false))

            }
            dispatch(setIsLoadingVerificationSpeed(false))


        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingVerificationSpeed(false))

        })
};

const fetchQRCodeStates = (data) => (dispatch) => {
    dispatch(setIsLoadingQRCodeStates(true))
    if (data?.value) {
        StatesAPI.getQRCodeStatesForChannel({ channel_id: data?.value })
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        total_number_of_qr_code_scans: response?.data?.totalQRScans,
                        total_number_of_qr_code_visits: response?.data?.totalQRSVisits,
                    };


                    dispatch(setTotalNumberOfQRCodeScans(formatted_data.total_number_of_qr_code_scans))
                    dispatch(setTotalNumberOfQRCodeVisits(formatted_data.total_number_of_qr_code_visits))
                    dispatch(setIsLoadingQRCodeStates(false))

                }
                dispatch(setIsLoadingQRCodeStates(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingQRCodeStates(false))

            })
    } else {
        StatesAPI.getQRCodeStates(data)
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        total_number_of_qr_code_scans: response?.data?.totalQRScans,
                        total_number_of_qr_code_visits: response?.data?.totalQRSVisits,
                    };


                    dispatch(setTotalNumberOfQRCodeScans(formatted_data.total_number_of_qr_code_scans))
                    dispatch(setTotalNumberOfQRCodeVisits(formatted_data.total_number_of_qr_code_visits))
                    dispatch(setIsLoadingQRCodeStates(false))

                }
                dispatch(setIsLoadingQRCodeStates(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingQRCodeStates(false))

            })
    }
};


const fetchTop10Vendors = (data) => (dispatch) => {
    dispatch(setIsLoadingTop10Vendors(true))
    if (data?.value) {
        StatesAPI.getTop10VendorsForChannel({ channel_id: data?.value })
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        match_details: {
                            total_top_10_vendors: response?.data?.topVendors
                        },
                    };

                    dispatch(setMatchDetails(formatted_data.match_details))


                    dispatch(setIsLoadingTop10Vendors(false))

                }
                
                dispatch(setIsLoadingTop10Vendors(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingTop10Vendors(false))

            })
    } else {

        StatesAPI.getTop10Vendors(data)
            .then((response) => {

                if (response?.status === 'success') {
                    let formatted_data = {
                        match_details: {
                            total_top_10_vendors: response?.data?.topVendors
                        },
                    };

                    dispatch(setMatchDetails(formatted_data.match_details))


                    dispatch(setIsLoadingTop10Vendors(false))

                }
                dispatch(setIsLoadingTop10Vendors(false))


            })
            .catch((error) => {
                console.error(error)
                dispatch(setIsLoadingTop10Vendors(false))

            })
    }
};

export {
    fetchUserProfile,
    fetchTotalCountStates,
    fetchTotalUserCounts,
    fetchTotalVendorsCounts,
    fetchTotalChannelCounts,
    fetchReviewStates,
    fetchTotalProductsCount,
    fetchTotalUniqueMatches,
    fetchTotalMatches,
    fetchProductVerificationSpeed,
    fetchQRCodeStates,
    fetchTop10Vendors
}