import { connect } from "react-redux";
import styles from '../../styles.module.scss';
import { Table, Switch, notification, Tooltip, Dropdown, Menu } from "antd";
import { CustomButton } from "../../../../../shared/ui/button";
import { FaEdit, FaFileExcel, FaIdCard, FaSearch, FaCog } from "react-icons/fa";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { useCallback, useEffect, useRef, useState } from "react";
import ic_catagory from '../../../../../assets/icons/market-segment.png'
import { getAllChannels, getIsLoadingListOfChannels } from "../../../logic/channels/selectors";
import { fetchListOfAllChannels, updateChannelServiceStatus } from "../../../logic/channels/actions"; // Import the action creator

import ChannelDataForm from "./ChannelDataForm";
import { toast } from "react-toastify";
import debounce from 'lodash.debounce'; // Import lodash debounce function
import VendorConfigurationModal from "./VendorConfigurationModal";
import ChannelHlsPlayer from "./ChannelHlsPlayer";
import PeopleConfigurationModal from "./PeopleConfigurationModal";
import { capitalizeFirstLetter } from "../../../../../shared/utils/utils";
import { setCurrentChannelRecord, setIsLoadingListOfChannels, setListOfChannels } from "../../../logic/channels/slice";
import TicketConfigurationModal from "./TicketConfigurationModal";
import ic_ticket from '../../../../../assets/icons/ticket.png'
import ic_filter_by_cat from '../../../../../assets/icons/filter_cat.png'
import ic_view_match_count_details from '../../../../../assets/icons/split.png'
import MatchCountDetails from "./MatchCountDetails";
import Export from "./Export";
import { setCurrentSelectVendor } from "../../../logic/vendor/slice";
import ListOfCatagories from "./ListOfCatagories";
import ListOfCategoriesToAttach from "./ListOfCategoriesToAttach";
import { getPermissions } from "../../../logic/permissions/selectors";
import Blacklisted_Keywords from "./Blacklisted_Keywords";

const ChannelOverviewTab = ({
    setCurrentDetailsFormToRender,
    setDetailsModalTitle,
    setIsDetailsModalOpen,
    listOfChannels,
    isLoadingListOfChannels,
    setDetailsModalFooter,
    setCurrentSidebarFormToRender,
    setIsChannelConfigurationSidebarOpen,
    setChannelConfigurationSidebarFooter,
    updateChannelServiceStatus, // Add updateChannelServiceStatus action
    fetchListOfAllChannels,
    setIsLoadingListOfChannels,
    setCurrentChannelRecord,
    setListOfChannels,
    setCurrentSelectVendor,
    setModalWidth,
    permissions
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [currentSelectedRecordIndex, setCurrentSelectedRecordIndex] = useState(-1)
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 50, // number of items per page
    });
    const [listOfSelectedCategories, setListOfSelectedCategories] = useState([])
    const [refresh, setRefresh] = useState(false)
    const prevSearchTerm = useRef(''); // Store the previous search term
    const [listApiParams, setListApiParams] = useState(null)
    const [isAllowedToUpdateChannel, setIsAllowedToUpdateChannel] = useState(false);
    const [isAllowedToManageVendors, setIsAllowedToManageVendors] = useState(false);
    const [isAllowedToManageCategories, setIsAllowedToManageCategories] = useState(false);
    const [isAllowedToManageTickets, setIsAllowedToManageTickets] = useState(false);
    const [isAllowedToManagePeople, setIsAllowedToManagePeople] = useState(false);
    const [isAllowedToExportChannel, setIsAllowedToExportChannel] = useState(false);
    const [isAllowedToManageMatch, setIsAllowedToManageMatch] = useState(false);
    const [isAllowedToManageKeywords, setIsAllowedToManageKeywords] = useState(false);

    // Debounce the search function to avoid excessive requests

    useEffect(() => {
        setIsLoadingListOfChannels(true)
        return () => {
            setListOfChannels({
                formatted_data: [],
                totalRecords: 0,
                totalPages: 0,
                currentPage: 0,
            })
        }
    }, [])


    useEffect(() => {
        if (permissions) {
            setIsAllowedToUpdateChannel(permissions?.UPDATE_CHANNEL?.enabled);
            setIsAllowedToManageVendors(permissions?.MANAGE_VENDORS?.enabled);
            setIsAllowedToManageCategories(permissions?.MANAGE_CHANNEL_CATEGORIES?.enabled);
            setIsAllowedToManageTickets(permissions?.MANAGE_TICKETS?.enabled);
            setIsAllowedToManagePeople(permissions?.MANAGE_PEOPLE?.enabled);
            setIsAllowedToExportChannel(permissions?.EXPORT_CHANNEL?.enabled);
            setIsAllowedToManageMatch(permissions?.MANAGE_CHANNEL_MATCHES?.enabled);
            setIsAllowedToManageKeywords(permissions?.MANAGE_KEYWORDS?.enabled);
        }
    }, [permissions]);

    const debouncedSearch = useCallback(
        debounce((searchTerm, page, pageSize, listOfSelectedCategories) => {
            setListApiParams({ keyword: searchTerm, page, limit: pageSize, categories: listOfSelectedCategories })
            fetchListOfAllChannels({ keyword: searchTerm, page, limit: pageSize, categories: listOfSelectedCategories });
        }, 1000),
        [] // Include any dependencies if needed
    );



    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, pagination.pageSize, listOfSelectedCategories);
    }, [searchTerm, pagination.current, debouncedSearch, listOfSelectedCategories]);


    // Define the columns structure
    const columns = [
        {
            title: 'Logo',
            dataIndex: 'logo_url',
            key: 'logo_url',
            render: (logo_url, record) => {
                const renderProfileImage = (url, name) => {
                    if (url) {
                        return (
                            <img
                                src={url}
                                alt={name}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ''; // Reset the image source if it fails
                                }}
                                style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                            />
                        );
                    } else {
                        const initials = name.split(' ').map(n => n[0]).join('');
                        return (
                            <div style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fff',
                            }}>
                                {initials}
                            </div>
                        );
                    }
                };

                return renderProfileImage(logo_url, record.name);
            },
        },
        {
            title: 'Channel Name',
            dataIndex: 'channelName',
            key: 'channelName',

            render: (value, record) => (
                <a onClick={() => handlePlayHlsStream(record)}>{value}</a>
            )
        },
        {
            title: 'Vendors Enabled',
            dataIndex: 'vendors',
            key: 'vendors',
            render: (value, record) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: isAllowedToManageVendors ? "pointer" : "not-allowed",
                    }}
                >
                    {isAllowedToManageVendors ? (
                        <p onClick={() => handleEditVendors(record)}>
                            <FaEdit size={15} />
                        </p>
                    ) : (
                        <p style={{ color: "grey" }}>
                            <FaEdit size={15} />
                        </p>
                    )}
                    <span>{Number(value).toLocaleString()}</span>
                </div>
            ),
            align: 'center',
        },

        {
            title: 'Total Products',
            dataIndex: 'total_product_count',
            key: 'total_product_count',
            render: (value, record) => (
                <div style={{}}>
                    {Number(value).toLocaleString()}
                </div>
            ),
            align: 'center',
        },
        {
            title: 'Total Matches',
            dataIndex: 'total_match_count',
            key: 'total_match_count',
            align: 'center',
            render: (value, record, index) => (
                <Tooltip title={`Click to view division of match count `}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                            cursor: isAllowedToManageMatch ? "pointer" : "not-allowed",
                        }}
                        onClick={() => { if (isAllowedToManageMatch) handleViewMatchCountDetails(record, index) }}
                    >
                        <img
                            src={ic_view_match_count_details}
                            width={15}
                            onClick={() => { if (isAllowedToManageMatch) handleViewMatchCountDetails(record, index) }}
                        />
                        {Number(value).toLocaleString()}

                    </div>
                </Tooltip>
            ),
        },
        {
            title: 'Services Enabled',
            dataIndex: 'services_enabled',
            key: 'services_enabled',
            render: (value, record) => (
                <div style={{}}>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>Gamification:</div>
                        <div>
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={value?.isGamified}
                                onChange={(checked) => handleSwitchToggle(record, 'is_gamified', checked)}
                                disabled={!isAllowedToUpdateChannel} // Add this line
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>Shopping:</div>
                        <div>
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={value?.isShopify}
                                onChange={(checked) => handleSwitchToggle(record, 'is_shoppify', checked)}
                                disabled={!isAllowedToUpdateChannel} // Add this line
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Second Screen',
            dataIndex: 'second_screen',
            key: 'second_screen',
            render: (second_screen) => (
                <CustomButton
                    title="Get URL"
                    width="100%"
                    font_size="0.7"
                    unit="rem"
                    padding="5px 5px 0px 5px"
                    onClick={() => {
                        navigator.clipboard.writeText(second_screen)
                        if (second_screen) {
                            toast.info("Copied to clip board...!");
                            window.open(second_screen, '_blank'); // Open in a new tab
                        } else {
                            toast.error("No URL available.");
                        }
                    }}
                />
            ),
            align: 'center',
        },
        {
            title: 'Action',
            key: 'action',
            align: "right",
            render: (_, record, index) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="1" onClick={() => handleEditPeople(record)} disabled={!isAllowedToManagePeople}>
                            Configure People
                        </Menu.Item>
                        <Menu.Item key="2" onClick={() => handleEditTickets(record)} disabled={!isAllowedToManageTickets}>
                            Configure Tickets
                        </Menu.Item>
                        <Menu.Item key="3" onClick={() => handleConfigureCategories(record, index)} disabled={!isAllowedToManageCategories}>
                            Configure Categories
                        </Menu.Item>
                        <Menu.Item key="4" onClick={() => handleBlacklistKeywords(record, index)} disabled={!isAllowedToManageKeywords}>
                            Configure Blacklisted Keywords
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                        <CustomButton
                            title="Edit Details"
                            width="100%"
                            font_size="0.7"
                            unit="rem"
                            padding="5px 5px 0px 5px"
                            disabled={!isAllowedToUpdateChannel}
                            onClick={() => handleEditRecord(record, index)}
                            icon={<FaEdit size={15} />}
                        />
                        <Dropdown overlay={menu} trigger={['click']}>
                            <CustomButton
                                title="Configure"
                                width="100%"
                                font_size="0.7"
                                unit="rem"
                                padding="5px 5px 0px 5px"
                                colorScheme="secondary"
                                icon={<FaCog size={15} />}
                            />
                        </Dropdown>
                        {/* <CustomButton
                            title="Configure People"
                            width="100%"
                            font_size="0.7"
                            unit="rem"
                            padding="5px 5px 0px 5px"
                            colorScheme="secondary"
                            icon={<FaIdCard size={15} />}
                            onClick={() => handleEditPeople(record)}
                            disabled={!isAllowedToManagePeople}
                        />
                        <CustomButton
                            title="Configure Tickets"
                            width="100%"
                            font_size="0.7"
                            unit="rem"
                            padding="5px 5px 0px 5px"
                            colorScheme="info"
                            icon={<img src={ic_ticket} width={20} />}
                            onClick={() => handleEditTickets(record)}
                            disabled={!isAllowedToManageTickets}
                        />
                        <CustomButton
                            title="Configure Categories"
                            width="100%"
                            font_size="0.7"
                            unit="rem"
                            padding="5px 5px 0px 5px"
                            colorScheme="secondary"
                            icon={<img src={ic_catagory} width={20} />}
                            onClick={() => handleConfigureCategories(record, index)}
                            disabled={!isAllowedToManageCategories}
                        />
                        <CustomButton
                            title="Configure Blacklist keywords"
                            width="100%"
                            font_size="0.7"
                            unit="rem"
                            padding="5px 5px 0px 5px"
                            colorScheme="secondary"
                            icon={<img src={ic_catagory} width={20} />}
                            onClick={() => handleBlacklistKeywords(record, index)}
                            disabled={!isAllowedToManageKeywords}
                        /> */}
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        const isSearchTermEmpty = searchTerm === '';
        if (listOfChannels) {
            setFilteredData(listOfChannels.formatted_data);
            if (currentSelectedRecordIndex != -1) {
                setCurrentChannelRecord(listOfChannels.formatted_data[currentSelectedRecordIndex])
            }
            setPagination(prev => ({
                ...prev,
                total: listOfChannels.totalRecords,
                pageSize: 50,
            }));
        }
    }, [listOfChannels, searchTerm]);

    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);



    const handleViewMatchCountDetails = (record, index) => {
        setCurrentChannelRecord(record)
        setCurrentSelectedRecordIndex(index)
        setModalWidth("60%")

        setCurrentDetailsFormToRender(<MatchCountDetails

            listSearchParams={listApiParams}
            setIsDetailsModalOpen={setIsDetailsModalOpen}
            setDetailsModalFooter={setDetailsModalFooter}
        />)
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Match count details for " + record.channelName)
    };

    const handlePlayHlsStream = (record) => {
        setModalWidth("60%")

        setCurrentDetailsFormToRender(<ChannelHlsPlayer setIsDetailsModalOpen={setIsDetailsModalOpen} setDetailsModalFooter={setDetailsModalFooter} record={record} />)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Channel Hls Player")
    }

    const handleEditVendors = (record) => {
        setModalWidth("60%")
        setCurrentSelectVendor(record)
        setCurrentDetailsFormToRender(<VendorConfigurationModal
            listApiParams={listApiParams}
            record={record}
            setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
            setDetailsModalTitle={setDetailsModalTitle}
            setIsDetailsModalOpen={setIsDetailsModalOpen}
            setDetailsModalFooter={setDetailsModalFooter}
        />);

        setDetailsModalTitle(`Vendors enabled for  ${capitalizeFirstLetter(record.channelName)} `);
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true);

    };

    const handleEditPeople = (record) => {
        setRefresh(!refresh)
        setModalWidth("60%")

        setCurrentDetailsFormToRender(<PeopleConfigurationModal record={record} refresh={refresh} />);
        setDetailsModalTitle(`People Recognized on ${capitalizeFirstLetter(record.channelName)}`);
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true);

    };

    const handleEditTickets = (record) => {
        setRefresh(!refresh)
        setModalWidth("60%")
        setCurrentDetailsFormToRender(<TicketConfigurationModal setIsDetailsModalOpen={setIsDetailsModalOpen} record={record} setRefresh={setRefresh} refresh={refresh} />);
        setDetailsModalTitle(`Configure tickets for  ${capitalizeFirstLetter(record.channelName)}`);
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true);

    };

    const handleBlacklistKeywords = (record) => {
        setRefresh(!refresh)
        setModalWidth("60%")
        setCurrentDetailsFormToRender(<Blacklisted_Keywords setIsDetailsModalOpen={setIsDetailsModalOpen} record={record} setRefresh={setRefresh} refresh={refresh} />);
        setDetailsModalTitle(`Configure blacklist keywords for  ${capitalizeFirstLetter(record.channelName)}`);
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true);

    };

    const handleEditRecord = (record, index) => {
        // Implement edit record logic
        setCurrentSelectedRecordIndex(index)
        setCurrentChannelRecord(record)
        setCurrentSidebarFormToRender(<ChannelDataForm
            // record={record}
            is_this_for_update={true}
            setIsChannelConfigurationSidebarOpen={setIsChannelConfigurationSidebarOpen}
            setChannelConfigurationSidebarFooter={setChannelConfigurationSidebarFooter}
            setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
            setIsDetailsModalOpen={setIsDetailsModalOpen}
            setDetailsModalFooter={setDetailsModalFooter}
            setDetailsModalTitle={setDetailsModalTitle}
        />)
        setIsChannelConfigurationSidebarOpen(true)
    };

    const handleExportModal = () => {
        setModalWidth("30%")
        setCurrentDetailsFormToRender(<Export />)
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Export")
    };

    const handleDeleteRecord = (record) => {
        // Implement delete record logic
    };

    const handleSwitchToggle = (channelRec, serviceType, enabled) => {
        // Prepare the payload for the API call based on the serviceType

        const payload = {
            channel_id: channelRec.channel_id,
            keyword: searchTerm,
            page: pagination.current,
            limit: pagination.pageSize
        };
        if (serviceType === 'is_w2e') {
            payload.is_w2e = !channelRec.services_enabled.isW2E;
            payload.is_gamified = channelRec.services_enabled.isGamified
            payload.is_shoppify = channelRec.services_enabled.isShopify;
        } else if (serviceType === 'is_gamified') {
            payload.is_gamified = !channelRec.services_enabled.isGamified;
            payload.is_w2e = channelRec.services_enabled.isW2E
            payload.is_shoppify = channelRec.services_enabled.isShopify;
        }
        else if (serviceType === 'is_shoppify') {
            payload.is_shoppify = !channelRec.services_enabled.isShopify;
            payload.is_w2e = channelRec.services_enabled.isW2E
            payload.is_gamified = channelRec.services_enabled.isGamified
        }
        else if (serviceType === 'live') {
            payload.live = !channelRec.isActive;
            payload.is_w2e = channelRec.services_enabled.isW2E
            payload.is_gamified = channelRec.services_enabled.isGamified
            payload.is_shoppify = channelRec.services_enabled.isShopify;
        }

        setFilteredData(prevData => {
            const updatedList = prevData.map(c => {
                if (c.channel_id === channelRec.channel_id) {
                    const updatedChannel = {
                        ...c, // Spread existing channel properties first
                        services_enabled: {
                            isGamified: serviceType === 'is_gamified' ? enabled : c.services_enabled.isGamified,
                            isW2E: serviceType === 'is_w2e' ? enabled : c.services_enabled.isW2E,
                            isShopify: serviceType === 'is_shoppify' ? enabled : c.services_enabled.isShopify,
                        }
                    };

                    return updatedChannel; // Return the modified channel
                } else {
                    return c; // Return channels that don't match without changes
                }
            });

            return updatedList;
        });

       
        // Call the API to update the channel services
        updateChannelServiceStatus(payload)

    };

    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));

    };


    const handleConfigureCategories = (record, index) => {
        setCurrentSelectedRecordIndex(index)
        setCurrentChannelRecord(record)
        setRefresh(!refresh)
        setCurrentDetailsFormToRender(<ListOfCatagories setIsDetailsModalOpen={setIsDetailsModalOpen} setDetailsModalFooter={setDetailsModalFooter} refresh={refresh} />)
        setIsDetailsModalOpen(true)
        setDetailsModalFooter(false)
        setDetailsModalTitle("Configure Categories for " + record.channelName)
    };

    const handleFilterByCategories = () => {
        setCurrentDetailsFormToRender(
            <ListOfCategoriesToAttach
                setIsDetailsModalOpen={setIsDetailsModalOpen}
                setDetailsModalFooter={setDetailsModalFooter}
                listOfSelectedCategories={listOfSelectedCategories}
                setListOfSelectedCategories={setListOfSelectedCategories}
            />)
        setIsDetailsModalOpen(true)
        setDetailsModalFooter(false)
        setDetailsModalTitle("Select categories to filter channels")
    }

    return (
        <div className={styles.channel_overview_tab_container}>
            <Table
                columns={columns}
                dataSource={filteredData}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total, // Total number of records from API
                    showSizeChanger: false
                }}
                onChange={handleTableChange}
                bordered
                loading={isLoadingListOfChannels}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.left_side}>

                        </div>
                        <div className={styles.right_side}>
                            <div className={styles.search_bar}>
                                <CustomTextfield
                                    border_radius="10px"
                                    icon={<FaSearch size={15} />}
                                    colorScheme="primary"
                                    place_holder="Search i.e Sports TV"
                                    font_size="0.7"
                                    unit="rem"
                                    padding="10px"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div style={{ paddingLeft: "10px", display: "flex", alignItems: "center" }}>
                                <CustomButton
                                    title={"Export"}
                                    width="100%"
                                    font_size="0.8"
                                    unit="rem"
                                    padding="5px 10px 0px 10px"
                                    onClick={handleExportModal} // Stop stream on Update click
                                    disabled={!isAllowedToExportChannel}
                                />
                            </div>
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfChannels: getAllChannels(state),
    isLoadingListOfChannels: getIsLoadingListOfChannels(state),
    permissions: getPermissions(state)
});

const mapDispatchToProps = (dispatch) => ({
    updateChannelServiceStatus: (data) => dispatch(updateChannelServiceStatus(data)), // Add updateChannelServiceStatus dispatch
    fetchListOfAllChannels: (data) => dispatch(fetchListOfAllChannels(data)),
    setCurrentChannelRecord: (data) => dispatch(setCurrentChannelRecord(data)),
    setIsLoadingListOfChannels: (data) => dispatch(setIsLoadingListOfChannels(data)),
    setListOfChannels: (data) => dispatch(setListOfChannels(data)),
    setCurrentSelectVendor: (data) => dispatch(setCurrentSelectVendor(data)),
});

export default connect(mapState, mapDispatchToProps)(ChannelOverviewTab);
