import { connect } from "react-redux";
import styles from '../../styles.module.scss';
import { CustomHeading } from "../../../../../shared/ui/heading";
import { useCallback, useEffect, useRef, useState } from "react";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { Button, Form, Upload } from "antd";
import { CustomDropDown } from "../../../../../shared/ui/dropdown";
import { FaUpload } from "react-icons/fa";
import { CustomButton } from "../../../../../shared/ui/button";
import { addNewVendor, updateVendorDetails } from "../../../logic/vendor/actions";
import ic_delete from '../../../../../assets/icons/delete.png'
import toast from "react-hot-toast";

const NewVendorDataForm = ({
    setCurrentSidebarFooterToRender,
    setIsVendorConfigurationSidebarOpen,
    addNewVendor,
    updateVendorDetails,
    record = null,
    listSearchParams=null,
    is_this_for_update = false,
}) => {

    const [platformName, setPlatformName] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [domainUrl, setDomainUrl] = useState("");
    const [logoFile, setLogoFile] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [existingLogoFile, setExistingLogoFile] = useState(null)
    const [fileList, setFileList] = useState([]);

    // Initial values for comparison
    const initialValues = useRef({
        platformName: "",
        vendorName: "",
        domainUrl: "",
        logoFile: null
    });

    useEffect(() => {
        if (record !== null) {
            setPlatformName(record?.platform || "");
            setVendorName(record?.name || "");
            setDomainUrl(record?.domain_url || "");
            setExistingLogoFile(record?.logo_url)
            initialValues.current = {
                platformName: record?.platform || "",
                vendorName: record?.name || "",
                domainUrl: record?.domain_url || "",
                logoFile: null,
            };
        }
    }, [record]);

    useEffect(() => {
        // Check if the current values differ from the initial values
        const isChanged = (
            platformName !== initialValues.current.platformName ||
            vendorName !== initialValues.current.vendorName ||
            domainUrl !== initialValues.current.domainUrl ||
            logoFile !== initialValues.current.logoFile
        );
        setHasChanges(isChanged);
    }, [platformName, vendorName, domainUrl, logoFile]);

    const handleCreateOrUpdate = useCallback(() => {
        if (!vendorName) {
            toast.error('Vendor name is required!');
            return;
        }
      
        const formData = new FormData();
        formData.append("platform", platformName);
        formData.append("name", vendorName);
        formData.append("domain_url", domainUrl);
    
        if (logoFile) {
            formData.append("file", logoFile);
        }
    
        if (!is_this_for_update) {
            addNewVendor(formData).then((res) => {
                if (res.status === 'success') {
                    setIsVendorConfigurationSidebarOpen(false);
                }
            });
        } else {
            formData.append("id", record?.vendor_id);
            updateVendorDetails(formData,listSearchParams).then((res) => {
                if (res.status === 'success') {
                     setHasChanges(false)
                }
            });
        }
    }, [logoFile, platformName, vendorName, domainUrl, is_this_for_update]);

    useEffect(() => {
        setCurrentSidebarFooterToRender(
            <div className={styles.footer_container}>
                <CustomButton
                    title={is_this_for_update ? "Update" : "Add"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleCreateOrUpdate}
                    disabled={is_this_for_update==true ?  !hasChanges : false} // Disable if no changes detected
                />
                <CustomButton
                    title={"Cancel"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    colorScheme="danger"
                    onClick={() => { setIsVendorConfigurationSidebarOpen(false) }}
                />
            </div>
        );
    }, [handleCreateOrUpdate, hasChanges]);

    const normFile = (e) => {
        setLogoFile(e?.fileList[0]?.originFileObj || null);
        return e?.fileList;
    };

    const handleChange = (info) => {
        if (info.fileList.length > 0) {
            const file = info.fileList[0]; // Get the first file from the list
          
            setLogoFile(file.originFileObj)
            // Create an object URL for the selected file
            const objectUrl = URL.createObjectURL(file.originFileObj);
            // Update the state with the new file list containing only the object URL
            setFileList([objectUrl]);
          
        } else {
            setFileList([]); // Clear the file list if no files are present
        
        }
    };

    const handleRemoveImage = () => {
        setFileList([]); // Clear the file list
        setLogoFile(null)
     
    };

    return (
        <div className={styles.vendors_data_form_container}>
            {!is_this_for_update && (
                <div className={styles.row_1}>
                    <CustomDropDown
                        padding='0px'
                        defaultValue={platformName}
                        options={[
                            { value: 'default', label: 'Select Platform' },
                            { value: 'TradeTracker', label: 'TradeTracker' },
                            { value: 'Awin', label: 'Awin' },
                        ]}
                        onChange={(value) => setPlatformName(value)}
                    />
                </div>
            )}
            <div className={styles.row_2}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Vendor Name" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter vendor name"
                        font_size="0.7" unit="rem"
                        value={vendorName}
                        onChange={(e) => setVendorName(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.row_3}>
                <div className={styles.label}>
                    <CustomHeading font_size="0.7" unit="rem" headingText="Domain url" />
                </div>
                <div className={styles.field}>
                    <CustomTextfield
                        border_radius="5px"
                        colorScheme="primary"
                        place_holder="Enter domain url"
                        font_size="0.7" unit="rem"
                        value={domainUrl}
                        onChange={(e) => setDomainUrl(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.row_7}>
            <div className={styles.field} style={{ textAlign: 'center' }}>
                    {/* Display the existing logo if available */}

                    {existingLogoFile && (
                        <div style={{ marginBottom: '1rem', width: '100%', textAlign: 'center' }}>
                            <img
                                src={typeof existingLogoFile === 'string' ? existingLogoFile : URL.createObjectURL(logoFile)}
                                alt="Current Logo"
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            />
                        </div>
                    )}

                    <Form.Item

                        name="upload"
                        rules={[{ required: true, message: 'Please upload a logo!' }]}
                        // getValueFromEvent={normFile} // Update function to handle file selection correctly
                    >
                        <Upload
                            name="logo"
                            action="/upload.do"
                            listType="picture"
                            accept=".jpg,.jpeg,.png,.gif"
                            beforeUpload={() => false} // Prevent automatic upload
                            showUploadList={false}
                            fileList={[]}
                            onChange={handleChange} // Set the onChange event handler
                        >
                            <Button icon={<FaUpload />}>{record != null ? 'Change Logo' : 'Upload New Logo'}</Button>
                        </Upload>
                        {/* Display the uploaded image if it exists */}
                        {fileList.length > 0 && (
                            <div style={{ marginTop: 16,border:"1px solid black",display:'flex',justifyContent:"space-between",alignItems:"center",padding:"1%",borderRadius:"10px" }}>
                                <img
                                    width={30} // Adjust width as needed
                                    src={fileList[0]} // Use the object URL from fileList
                                    alt="Uploaded Image"
                                />
                                <Button
                                    type="link"
                                    onClick={handleRemoveImage}
                                    style={{ marginLeft: 8 }}
                                >
                                    <img src={ic_delete} width={20}/>
                                </Button>
                            </div>
                        )}

                    </Form.Item>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addNewVendor: (data) => dispatch(addNewVendor(data)),
    updateVendorDetails: (data,params) => dispatch(updateVendorDetails(data,params)),
    
});

export default connect(null, mapDispatchToProps)(NewVendorDataForm);
