import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import styles from './modal.module.scss';
import { CustomButton } from '../../../../../shared/ui/button';
import { disableVendor, updateVendorChannels } from '../../../logic/vendor/actions';
import ic_warning from '../../../../../assets/icons/warning-sign.png'
import { CustomHeading } from '../../../../../shared/ui/heading';
const WarningBeforeDeletingAnyVendor = ({
    record,
    setIsDetailsModalOpen,
    setDetailsModalFooter,
    disableVendor,
    ...props
}) => {


    const handleOkay = useCallback(() => {
        disableVendor({id:record.vendor_id})
        setIsDetailsModalOpen(false)
    }, [record?.vendor_id]);

    useEffect(() => {
        setDetailsModalFooter(
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                <CustomButton
                    title={"Yes, I am sure so delete it"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    colorScheme="danger"
                    padding="5px 10px 0px 10px"
                    onClick={handleOkay}
                />
                <CustomButton
                    title={"Cancel"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleCancel}
                />
            </div>
        );
    }, [handleOkay]);


    const handleCancel = (e) => {
        setIsDetailsModalOpen(false)
    }

    return (
        <div className={styles.delete_vendor_warning_modal}>
            <div className={styles.image_container}>
                <img src={ic_warning} width={'45%'} />
            </div>
            <div className={styles.message_container}>
                <CustomHeading textAlign='center' font_size="1.4" unit="rem" headingText={`Deleting ${record.name} will prevent any products from ${record.name} from being displayed for any channel, are you sure?`} />
            </div>
        </div>
    );
};

const mapState = (state) => ({
    // listOfAllChannels: getAllChannels(state),
});

const mapDispatchToProps = (dispatch) => ({
    // updateVendorChannels: (data) => dispatch(updateVendorChannels(data)),
    disableVendor: (data) => dispatch(disableVendor(data)),
});

export default connect(mapState, mapDispatchToProps)(WarningBeforeDeletingAnyVendor);
