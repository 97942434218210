 // Map the provided data to the structure required by Ant Design
 export const data = [
    {
        key: '1',
        platform: "TradeTracker",
        name: "Dagboekreizen.nl",
        logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9dxy2lpfFtjHCfhau83Tp2MnNAxxwlLDDl1qu5izXQwOreduwoKDLA_iI5J4eSmIzsnE&usqp=CAU",
        allowedRegions: 5,
        numberOfChannels: 10,
        channels_list:[
            {
                channel_name:"Channel",
                number_of_products:"4242",
            },
            {
                channel_name:"Channel",
                number_of_products:"1234",
            },
            {
                channel_name:"Channel",
                number_of_products:"9866",
            },
            {
                channel_name:"Channel",
                number_of_products:"32",
            },
            {
                channel_name:"Channel",
                number_of_products:"5442",
            },
            {
                channel_name:"Channel",
                number_of_products:"42",
            },
        
        ],

        regions_list: [
            {
                country_name: "United States",
                sub_regions: [
                    { sub_region_name: "California", other_info: "West Coast" },
                    { sub_region_name: "Texas", other_info: "South" },
                    { sub_region_name: "New York", other_info: "East Coast" },
                ]
            },
            {
                country_name: "Canada",
                sub_regions: [
                    { sub_region_name: "Ontario", other_info: "Central" },
                    { sub_region_name: "British Columbia", other_info: "West Coast" },
                ]
            },
            {
                country_name: "Australia",
                sub_regions: [
                    { sub_region_name: "New South Wales", other_info: "East" },
                    { sub_region_name: "Victoria", other_info: "South-East" },
                ]
            },
        ]
    },
    {
        key: '2',
        platform: "TradeTracker",
        name: "Bigsizeshirts.com",
        logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9dxy2lpfFtjHCfhau83Tp2MnNAxxwlLDDl1qu5izXQwOreduwoKDLA_iI5J4eSmIzsnE&usqp=CAU",
        allowedRegions: 3,
        numberOfChannels: 7,
        channels_list:[
             {
                channel_name:"Channel",
                number_of_products:"4242",
            },
            {
                channel_name:"Channel",
                number_of_products:"1234",
            },
            {
                channel_name:"Channel",
                number_of_products:"9866",
            },
            {
                channel_name:"Channel",
                number_of_products:"32",
            },
            {
                channel_name:"Channel",
                number_of_products:"5442",
            },
            {
                channel_name:"Channel",
                number_of_products:"42",
            },
        ],

        regions_list: [
            {
                country_name: "United States",
                sub_regions: [
                    { sub_region_name: "California", other_info: "West Coast" },
                    { sub_region_name: "Texas", other_info: "South" },
                    { sub_region_name: "New York", other_info: "East Coast" },
                ]
            },
            {
                country_name: "Canada",
                sub_regions: [
                    { sub_region_name: "Ontario", other_info: "Central" },
                    { sub_region_name: "British Columbia", other_info: "West Coast" },
                ]
            },
            {
                country_name: "Australia",
                sub_regions: [
                    { sub_region_name: "New South Wales", other_info: "East" },
                    { sub_region_name: "Victoria", other_info: "South-East" },
                ]
            },
        ]
    },
    {
        key: '3',
        platform: "Awin",
        name: "The Cambridge Satchel Company US",
        logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGJtbYMATzlJJ3NEGj-txNQx_TA5G_PiywXG9EHiJJxKl2YdFWRtKAcYwY88qHaNGwws0&usqp=CAU",
        allowedRegions: 10,
        numberOfChannels: 15,
        channels_list:[
             {
                channel_name:"Channel",
                number_of_products:"4242",
            },
            {
                channel_name:"Channel",
                number_of_products:"1234",
            },
            {
                channel_name:"Channel",
                number_of_products:"9866",
            },
            {
                channel_name:"Channel",
                number_of_products:"32",
            },
            {
                channel_name:"Channel",
                number_of_products:"5442",
            },
            {
                channel_name:"Channel",
                number_of_products:"42",
            },
        ],

        regions_list: [
            {
                country_name: "United States",
                sub_regions: [
                    { sub_region_name: "California", other_info: "West Coast" },
                    { sub_region_name: "Texas", other_info: "South" },
                    { sub_region_name: "New York", other_info: "East Coast" },
                ]
            },
            {
                country_name: "Canada",
                sub_regions: [
                    { sub_region_name: "Ontario", other_info: "Central" },
                    { sub_region_name: "British Columbia", other_info: "West Coast" },
                ]
            },
            {
                country_name: "Australia",
                sub_regions: [
                    { sub_region_name: "New South Wales", other_info: "East" },
                    { sub_region_name: "Victoria", other_info: "South-East" },
                ]
            },
        ]
        
    },
    {
        key: '3',
        platform: "Awin",
        name: "The Cambridge Satchel Company US",
        logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGJtbYMATzlJJ3NEGj-txNQx_TA5G_PiywXG9EHiJJxKl2YdFWRtKAcYwY88qHaNGwws0&usqp=CAU",
        allowedRegions: 10,
        numberOfChannels: 15,
        channels_list:[
             {
                channel_name:"Channel",
                number_of_products:"4242",
            },
            {
                channel_name:"Channel",
                number_of_products:"1234",
            },
            {
                channel_name:"Channel",
                number_of_products:"9866",
            },
            {
                channel_name:"Channel",
                number_of_products:"32",
            },
            {
                channel_name:"Channel",
                number_of_products:"5442",
            },
            {
                channel_name:"Channel",
                number_of_products:"42",
            },
        ],

        regions_list: [
            {
                country_name: "United States",
                sub_regions: [
                    { sub_region_name: "California", other_info: "West Coast" },
                    { sub_region_name: "Texas", other_info: "South" },
                    { sub_region_name: "New York", other_info: "East Coast" },
                ]
            },
            {
                country_name: "Canada",
                sub_regions: [
                    { sub_region_name: "Ontario", other_info: "Central" },
                    { sub_region_name: "British Columbia", other_info: "West Coast" },
                ]
            },
            {
                country_name: "Australia",
                sub_regions: [
                    { sub_region_name: "New South Wales", other_info: "East" },
                    { sub_region_name: "Victoria", other_info: "South-East" },
                ]
            },
        ]
        
    },
    {
        key: '3',
        platform: "Awin",
        name: "The Cambridge Satchel Company US",
        logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGJtbYMATzlJJ3NEGj-txNQx_TA5G_PiywXG9EHiJJxKl2YdFWRtKAcYwY88qHaNGwws0&usqp=CAU",
        allowedRegions: 10,
        numberOfChannels: 15,
        channels_list:[
             {
                channel_name:"Channel",
                number_of_products:"4242",
            },
            {
                channel_name:"Channel",
                number_of_products:"1234",
            },
            {
                channel_name:"Channel",
                number_of_products:"9866",
            },
            {
                channel_name:"Channel",
                number_of_products:"32",
            },
            {
                channel_name:"Channel",
                number_of_products:"5442",
            },
            {
                channel_name:"Channel",
                number_of_products:"42",
            },
        ],

        regions_list: [
            {
                country_name: "United States",
                sub_regions: [
                    { sub_region_name: "California", other_info: "West Coast" },
                    { sub_region_name: "Texas", other_info: "South" },
                    { sub_region_name: "New York", other_info: "East Coast" },
                ]
            },
            {
                country_name: "Canada",
                sub_regions: [
                    { sub_region_name: "Ontario", other_info: "Central" },
                    { sub_region_name: "British Columbia", other_info: "West Coast" },
                ]
            },
            {
                country_name: "Australia",
                sub_regions: [
                    { sub_region_name: "New South Wales", other_info: "East" },
                    { sub_region_name: "Victoria", other_info: "South-East" },
                ]
            },
        ]
        
    },
    {
        key: '3',
        platform: "Awin",
        name: "The Cambridge Satchel Company US",
        logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGJtbYMATzlJJ3NEGj-txNQx_TA5G_PiywXG9EHiJJxKl2YdFWRtKAcYwY88qHaNGwws0&usqp=CAU",
        allowedRegions: 10,
        numberOfChannels: 15,
        channels_list:[
             {
                channel_name:"Channel",
                number_of_products:"4242",
            },
            {
                channel_name:"Channel",
                number_of_products:"1234",
            },
            {
                channel_name:"Channel",
                number_of_products:"9866",
            },
            {
                channel_name:"Channel",
                number_of_products:"32",
            },
            {
                channel_name:"Channel",
                number_of_products:"5442",
            },
            {
                channel_name:"Channel",
                number_of_products:"42",
            },
        ],

        regions_list: [
            {
                country_name: "United States",
                sub_regions: [
                    { sub_region_name: "California", other_info: "West Coast" },
                    { sub_region_name: "Texas", other_info: "South" },
                    { sub_region_name: "New York", other_info: "East Coast" },
                ]
            },
            {
                country_name: "Canada",
                sub_regions: [
                    { sub_region_name: "Ontario", other_info: "Central" },
                    { sub_region_name: "British Columbia", other_info: "West Coast" },
                ]
            },
            {
                country_name: "Australia",
                sub_regions: [
                    { sub_region_name: "New South Wales", other_info: "East" },
                    { sub_region_name: "Victoria", other_info: "South-East" },
                ]
            },
        ]
        
    },
    {
        key: '3',
        platform: "Awin",
        name: "The Cambridge Satchel Company US",
        logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGJtbYMATzlJJ3NEGj-txNQx_TA5G_PiywXG9EHiJJxKl2YdFWRtKAcYwY88qHaNGwws0&usqp=CAU",
        allowedRegions: 10,
        numberOfChannels: 15,
        channels_list:[
             {
                channel_name:"Channel",
                number_of_products:"4242",
            },
            {
                channel_name:"Channel",
                number_of_products:"1234",
            },
            {
                channel_name:"Channel",
                number_of_products:"9866",
            },
            {
                channel_name:"Channel",
                number_of_products:"32",
            },
            {
                channel_name:"Channel",
                number_of_products:"5442",
            },
            {
                channel_name:"Channel",
                number_of_products:"42",
            },
        ],

        regions_list: [
            {
                country_name: "United States",
                sub_regions: [
                    { sub_region_name: "California", other_info: "West Coast" },
                    { sub_region_name: "Texas", other_info: "South" },
                    { sub_region_name: "New York", other_info: "East Coast" },
                ]
            },
            {
                country_name: "Canada",
                sub_regions: [
                    { sub_region_name: "Ontario", other_info: "Central" },
                    { sub_region_name: "British Columbia", other_info: "West Coast" },
                ]
            },
            {
                country_name: "Australia",
                sub_regions: [
                    { sub_region_name: "New South Wales", other_info: "East" },
                    { sub_region_name: "Victoria", other_info: "South-East" },
                ]
            },
        ]
        
    },
    {
        key: '3',
        platform: "Awin",
        name: "The Cambridge Satchel Company US",
        logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGJtbYMATzlJJ3NEGj-txNQx_TA5G_PiywXG9EHiJJxKl2YdFWRtKAcYwY88qHaNGwws0&usqp=CAU",
        allowedRegions: 10,
        numberOfChannels: 15,
        channels_list:[
             {
                channel_name:"Channel",
                number_of_products:"4242",
            },
            {
                channel_name:"Channel",
                number_of_products:"1234",
            },
            {
                channel_name:"Channel",
                number_of_products:"9866",
            },
            {
                channel_name:"Channel",
                number_of_products:"32",
            },
            {
                channel_name:"Channel",
                number_of_products:"5442",
            },
            {
                channel_name:"Channel",
                number_of_products:"42",
            },
        ],

        regions_list: [
            {
                country_name: "United States",
                sub_regions: [
                    { sub_region_name: "California", other_info: "West Coast" },
                    { sub_region_name: "Texas", other_info: "South" },
                    { sub_region_name: "New York", other_info: "East Coast" },
                ]
            },
            {
                country_name: "Canada",
                sub_regions: [
                    { sub_region_name: "Ontario", other_info: "Central" },
                    { sub_region_name: "British Columbia", other_info: "West Coast" },
                ]
            },
            {
                country_name: "Australia",
                sub_regions: [
                    { sub_region_name: "New South Wales", other_info: "East" },
                    { sub_region_name: "Victoria", other_info: "South-East" },
                ]
            },
        ]
        
    },
    {
        key: '3',
        platform: "Awin",
        name: "The Cambridge Satchel Company US",
        logo:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGJtbYMATzlJJ3NEGj-txNQx_TA5G_PiywXG9EHiJJxKl2YdFWRtKAcYwY88qHaNGwws0&usqp=CAU",
        allowedRegions: 10,
        numberOfChannels: 15,
        channels_list:[
             {
                channel_name:"Channel",
                number_of_products:"4242",
            },
            {
                channel_name:"Channel",
                number_of_products:"1234",
            },
            {
                channel_name:"Channel",
                number_of_products:"9866",
            },
            {
                channel_name:"Channel",
                number_of_products:"32",
            },
            {
                channel_name:"Channel",
                number_of_products:"5442",
            },
            {
                channel_name:"Channel",
                number_of_products:"42",
            },
        ],

        regions_list: [
            {
                country_name: "United States",
                sub_regions: [
                    { sub_region_name: "California", other_info: "West Coast" },
                    { sub_region_name: "Texas", other_info: "South" },
                    { sub_region_name: "New York", other_info: "East Coast" },
                ]
            },
            {
                country_name: "Canada",
                sub_regions: [
                    { sub_region_name: "Ontario", other_info: "Central" },
                    { sub_region_name: "British Columbia", other_info: "West Coast" },
                ]
            },
            {
                country_name: "Australia",
                sub_regions: [
                    { sub_region_name: "New South Wales", other_info: "East" },
                    { sub_region_name: "Victoria", other_info: "South-East" },
                ]
            },
        ]
        
    },
    // Add more data as needed
];




export const data_for_pool = [
    {
        key: '1',
        name: 'Vikoperdinbil SE',
        status: 'Applied',
        type_of_products: 'Used cars',
        total_number_of_products: 0,
        total_number_of_approved_products: 0,
    },
    {
        key: '2',
        name: 'Homeroom SE',
        status: 'Applied',
        type_of_products: 'Furniture and home decor',
        total_number_of_products: 43664,
        total_number_of_approved_products: 0,
    },
    {
        key: '3',
        name: 'Bulk SE',
        status: 'Applied',
        type_of_products: 'Nutrition and inspiration',
        total_number_of_products: 297,
        total_number_of_approved_products: 0,
    },
    {
        key: '4',
        name: 'Mister Spex SE',
        status: 'Applied',
        type_of_products: 'Glasses',
        total_number_of_products: 33062,
        total_number_of_approved_products: 0,
    },
    {
        key: '5',
        name: 'Kjell&Company SE',
        status: 'Applied',
        type_of_products: 'Home electronics',
        total_number_of_products: 8992,
        total_number_of_approved_products: 0,
    },
    {
        key: '6',
        name: 'Miinto SE',
        status: 'Applied',
        type_of_products: 'Online shopping',
        total_number_of_products: 1618051,
        total_number_of_approved_products: 0,
    },
    {
        key: '7',
        name: 'Under Armour SE',
        status: 'Applied',
        type_of_products: 'T-shirt',
        total_number_of_products: 20667,
        total_number_of_approved_products: 0,
    },
    {
        key: '8',
        name: 'Etsy SCAN',
        status: 'Applied',
        type_of_products: 'Unique and creative goods',
        total_number_of_products: 0,
        total_number_of_approved_products: 0,
    },
    {
        key: '9',
        name: 'Apotek Hjärtat SE',
        status: 'Applied',
        type_of_products: 'Medicines',
        total_number_of_products: 17253,
        total_number_of_approved_products: 0,
    },
    {
        key: '10',
        name: 'Smugglers Crate',
        status: 'Approved',
        type_of_products: 'Beds, Bedding',
        total_number_of_products: 61536,
        total_number_of_approved_products: 61536,
    },
    {
        key: '11',
        name: 'Tyg SE',
        status: 'Applied',
        type_of_products: 'Sewing and craft accessories',
        total_number_of_products: 0,
        total_number_of_approved_products: 0,
    }
]

const list_of_vendors=[
    {

    }
]

const list_of_platforms=[
    {}
]