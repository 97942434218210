import { connect } from "react-redux";
import styles from './model.module.scss'
import { useEffect, useState } from "react";
import TabsRender from "../general-components/TabsRender";
import ListOfAllTrainingImages from "./ListOfAllTrainingImages";

const FacialRecordConfigurationModal = ({
    title = 'default title',
    record,
    ...props }) => {


    const [listOfTabs, setListOfTabs] = useState([]);
    useEffect(() => {
        let temp_tabs = [
            {
                label: "List of Images",
                screen: <ListOfAllTrainingImages record={record}/>
            }
        ]
        setListOfTabs(temp_tabs)
    }, [record])



    const handleTabOnChange = (e) => { }

    return <div className={styles.facial_rec_config_container}>
        <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapState, mapDispatchToProps)(FacialRecordConfigurationModal)