import { Routes } from "../../routes/Routes"
import { connect } from "react-redux";

const App = ({ }) => {
    // TODO: redirect to page 404 if url does not exists. 
    return <Routes />
}

const mapState = (state) => ({
    
})

const mapDispatchToProps = (dispatch) => ({
    
});


export default connect(mapState, mapDispatchToProps)(App)