export const getAllVendors = (state) => state.vendor?.listOfVendors
export const getAllVendorsNames = (state) => state.vendor?.listOfVendorsNames
export const getDetailsModalOpenedFor = (state) => state.vendor?.details_modal_opened_for
export const getIsUpdatingRegionForVendors = (state) => state.vendor?.is_updating_regions
export const getIsUpdatingChannelsForVendors = (state) => state.vendor?.is_updating_channels_for_vendors
export const getListOfCatagoriesForVendor = (state) => state.vendor?.listOfCatagoriesForVendor
export const getIsLoadingListOfCatagoriesForVendor = (state) => state.vendor?.is_loading_catagories_for_vendor
export const getCurrentSelectedVendor = (state) => state.vendor?.current_select_vendor
export const getMatchCountDivisionForVendor = (state) => state.vendor?.match_count_division_for_vendor
export const getIsLoadingMatchCountDivisionForVendor = (state) => state.vendor?.is_loading_match_count_division_for_vendor
export const getIsLoadingListOfVendors = (state) => state.vendor?.is_loading_list_of_vendors
export const getIsLoadingVendorCountDetailsByChannel = (state) => state.vendor?.is_loading_vendor_count_details_by_channel

export const getListOfVendorsForExport = (state) => state.vendor?.listOfVendorsToExport
export const getIsLoadingListOfVendorsForExport = (state) => state.vendor?.is_loading_list_of_vendors_for_export



