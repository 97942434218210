import styled from 'styled-components';

const Heading = styled.div`
  font-size: ${(props) => props.fontSize || '24px'};
  font-weight: ${(props) => props.fontWeight || 'bold'};
  color: ${(props) => props.color || '#333'};
  text-align: ${(props) => props.textAlign || 'left'};
  margin-top: ${(props) => props.marginTop || '0'};
  // margin-bottom: ${(props) => props.marginBottom || '10px'};
  line-height: ${(props) => props.lineHeight || '1.4'};
  width: ${(props) => props.width || '100%'};
   @media (max-width: 600px) {
    font-size: ${(props) => props.mobileFontSize};
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: ${(props) => props.tabletFontSize};
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: ${(props) => props.laptopFontSize};
  }

  @media (min-width: 1201px) {
    font-size: ${(props) => props.macbook13FontSize};
  }
`;

export { Heading };
