import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import styles from '../../styles.module.scss';
import { FaSearch, FaCog, FaFunnelDollar, FaEdit } from 'react-icons/fa';
import { CustomTextfield } from '../../../../../shared/ui/text-field';
import { CustomButton } from '../../../../../shared/ui/button';
import FaceProductConfigurationModal from './FaceProductConfigurationModal';
import ic_filter_cat from '../../../../../assets/icons/filter_cat.png'

import { fetchListOfAllProductsForOverview } from '../../../logic/product/actions';
import { getIsLoadingListOfAllProductsForOverview, getListOfAllProductsForOverview } from '../../../logic/product/selectors';
import debounce from 'lodash.debounce'; // Import lodash debounce function
import { setCurrentSelectedProduct, setIsLoadingListOfAllProductsForOverview, setListOfAllProductsForOverview, setQueryParams } from '../../../logic/product/slice';
import { getAllVendorsNames } from '../../../logic/vendor/selectors';
import { CustomDropDown } from '../../../../../shared/ui/dropdown';
import ListOfAllVendors from './ListOfAllVendors';
// import NewProductDataForm from './EditProductDataForm';
import { sortedCountriesGroupedBySubRegionForFilter } from '../../../../../shared/utils/regions_with_countries';
import Export from './Export';
import ListOfCategoriesForFilter from './ListOfCategoriesForFilter';
import { has, set } from 'lodash';
import EditProductDataForm from './EditProductDataForm';
import { getPermissions } from '../../../logic/permissions/selectors';

const ProductsOverview = ({
    listOfAllProductsForOverview,
    fetchListOfAllProductsForOverview,
    setIsDetailsModalOpen,
    setDetailsModalTitle,
    setCurrentDetailsFormToRender,
    setDetailsModalFooter,
    isLoadingListOfAllProductsForOverview,
    setIsLoadingListOfAllProductsForOverview,
    setListOfAllProductsForOverview,
    listOfVendorPlatforms,
    setCurrentSidebarFormToRender,
    setIsChannelConfigurationSidebarOpen,
    setCurrentSelectedProduct,
    setChannelConfigurationSidebarFooter,
    permissions,
    setQueryParams, // Add this line
    ...props
}) => {

    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });

    const [currentSelectedRecordIndex, setCurrentSelectedRecordIndex] = useState(-1)
    const [showSelectedFilters, setShowSelectedFilters] = useState(false);

    const prevSearchTerm = useRef(''); // Store the previous search term
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [listOfSelectedVendorIds, setListOfSelectedVendorIds] = useState([])
    const [listSearchParams, setListSearchParams] = useState({})
    const [expandedRows, setExpandedRows] = useState({}); // To track which rows have expanded accomplishments
    const [listOfPlatforms, setListOfPlatforms] = useState([{ value: "default", label: "All platforms" }])
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [selectedCountry, setSelectedCountry] = useState(['all']);
    const [listOfSelectedCategories, setListOfSelectedCategories] = useState([])
    const [numberOfRecordsToLoad, setNumberOfRecordsToLoad] = useState(10)
    const [selectedCategoriesNames, setSelectedCategoriesNames] = useState([])
    const [selectedVendorsNames, setSelectedVendorsNames] = useState([])
    const [hasFiltersChanged, setHasFiltersChanged] = useState(true);
    const [isAllowedToAddProducts, setIsAllowedToAddProducts] = useState(false);
    const [isAllowedToEditProducts, setIsAllowedToEditProducts] = useState(false);
    const [isAllowedToExportProducts, setIsAllowedToExportProducts] = useState(false);

    useEffect(() => {
        setIsLoadingListOfAllProductsForOverview(true)
        return () => {
            setListOfAllProductsForOverview({
                formatted_data: [],
                totalRecords: 0,
                totalPages: 0,
                currentPage: 1
            })
            setCurrentSelectedProduct(null)
        }
    }, [])

    const prevFiltersRef = useRef({
        searchTerm: '',
        selectedPlatform: [],
        listOfSelectedVendorIds: [],
        selectedCountry: ['all'],
        listOfSelectedCategories: [],
        numberOfRecordsToLoad: 10
    });

    useEffect(() => {
        const currentFilters = {
            searchTerm,
            selectedPlatform,
            listOfSelectedVendorIds,
            selectedCountry,
            listOfSelectedCategories,
            numberOfRecordsToLoad
        };

        const filtersChanged = JSON.stringify(prevFiltersRef.current) !== JSON.stringify(currentFilters);
        setHasFiltersChanged(filtersChanged);

        // Update the previous filters reference
        prevFiltersRef.current = currentFilters;

        if (numberOfRecordsToLoad === 'All records' && selectedPlatform !== 'all' && selectedCountry !== 'all') {
            setNumberOfRecordsToLoad('All records');
        } else {
            console.log('numberOfRecordsToLoad', numberOfRecordsToLoad)
            setNumberOfRecordsToLoad(numberOfRecordsToLoad)
        }

    }, [searchTerm, pagination, selectedPlatform, listOfSelectedVendorIds, selectedCountry, listOfSelectedCategories, numberOfRecordsToLoad]);

    // Debounce the search function to avoid excessive requests
    const debouncedSearch = useCallback(
        debounce((searchTerm, page, pageSize, selectedPlatform, listOfSelectedVendorIds, selectedCountry, listOfSelectedCategories, numberOfRecordsToLoad, hasFiltersChanged, pagination) => {
            console.log('Debounced search called : numberOfRecordsToLoad :', numberOfRecordsToLoad);
            setListSearchParams({
                keyword: searchTerm,
                page,
                limit: pageSize,
                platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform,
                vendorIds: listOfSelectedVendorIds,
                countryCodes: selectedCountry[0] === 'all' ? [] : selectedCountry,
                categories: listOfSelectedCategories,
                numberOfRecordsToLoad: numberOfRecordsToLoad,
                calculateTotalRecords: hasFiltersChanged,
                calculatedTotalRecords: pagination.total
            })
            fetchListOfAllProductsForOverview({
                keyword: searchTerm,
                page,
                limit: pageSize,
                platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform,
                vendorIds: listOfSelectedVendorIds,
                countryCodes: selectedCountry[0] === 'all' ? [] : selectedCountry,
                categories: listOfSelectedCategories,
                numberOfRecordsToLoad: numberOfRecordsToLoad,
                calculateTotalRecords: hasFiltersChanged,
                calculatedTotalRecords: pagination.total
            });
        }, 1000), // 1-second debounce delay
        []
    );

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, pagination.pageSize, selectedPlatform, listOfSelectedVendorIds, selectedCountry, listOfSelectedCategories, numberOfRecordsToLoad, hasFiltersChanged, pagination);
        setQueryParams(listSearchParams); // Add this line to set query params in Redux
    }, [searchTerm, pagination.current, pagination.pageSize, debouncedSearch, listOfSelectedVendorIds, listOfSelectedCategories]);

    useEffect(() => {
        if (listOfAllProductsForOverview) {
            setData(listOfAllProductsForOverview.formatted_data);

            if (currentSelectedRecordIndex != -1) {
                setCurrentSelectedProduct(listOfAllProductsForOverview.formatted_data[currentSelectedRecordIndex])
            }

            setPagination(prev => ({
                ...prev,
                total: listOfAllProductsForOverview.totalRecords,
            }));

        }
    }, [listOfAllProductsForOverview]);

    useEffect(() => {
        // Check if the search term has actually changed
        // setNumberOfRecordsToLoad(10)
        if (searchTerm.length === 0) {
            setPagination(prev => ({
                ...prev,
                total: 10,
            }));

            if (numberOfRecordsToLoad !== 'All records')
                setNumberOfRecordsToLoad(10)


        }
        if (prevSearchTerm.current !== searchTerm) {

            setPagination((prev) => ({
                ...prev,
                current: 1,
                total: 10
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);

    useEffect(() => {
        if (listOfVendorPlatforms) {

            let formatted_platforms = listOfVendorPlatforms.map((v) => {

                return {
                    value: v.platform,
                    label: v.platform
                }
            })

            setListOfPlatforms([{ value: "default", label: "All platforms" }, ...formatted_platforms])
            let ar = listOfVendorPlatforms.map(p => p.platform); // No need for return statement

            setSelectedPlatform(ar);

        }
    }, [listOfVendorPlatforms])

    useEffect(() => {
        if (permissions) {
            setIsAllowedToAddProducts(permissions?.ADD_PRODUCTS?.enabled);
            setIsAllowedToEditProducts(permissions?.EDIT_PRODUCTS?.enabled);
            setIsAllowedToExportProducts(permissions?.EXPORT_PRODUCTS?.enabled);
        }
    }, [permissions]);

    const ProfileImage = (profileUrl, name) => {

        return profileUrl ? (
            <img
                src={profileUrl}
                alt="Profile"
                onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop in case fallback also fails
                    e.target.src = ''; // Clear the src to hide the broken image icon
                    e.target.style.display = 'none'; // Hide image element if loading fails
                }}
                style={{ width: '30px', height: '30px', borderRadius: '0%' }}
                onLoad={(e) => {
                    e.target.style.display = 'block'; // Ensure image shows if it loads successfully
                }}
            />
        ) : (
            <div
                style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '0%',
                    backgroundColor: '#ccc',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1.5rem',
                }}
            >
                {name ? name.charAt(0).toUpperCase() : '?'}
            </div>
        );
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image_link',
            key: 'image_link',
            render: (image_link, record) => ProfileImage(image_link, record.english_title),
        },
        {
            title: 'Title',
            dataIndex: 'english_title',
            key: 'english_title',

            render: (value, record) => {
                let domainUrl = record?.affiliate_link;

                if (domainUrl) {
                    // Check if the domain URL already has a scheme (http:// or https://)
                    if (!domainUrl.startsWith('http://') && !domainUrl.startsWith('https://')) {
                        domainUrl = `https://${domainUrl}`;
                    }
                }

                return domainUrl ? (
                    <a
                        href={domainUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {value}
                    </a>
                ) : (
                    <span>{value}</span> // Render plain text if the URL is missing
                );
            }


        },

        {
            title: 'Vendor',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
        },
        {
            title: 'Platform',
            dataIndex: 'vendor_platform',
            key: 'vendor_platform',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record, index) => (
                <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                    <CustomButton
                        title="Edit details"
                        width="100%"
                        font_size="0.7"
                        unit="rem"
                        disabled={!isAllowedToEditProducts||record.vendor_platform!=='manual'}
                        padding="5px 5px 0px 5px"
                        onClick={() => handleEditDetails(record, index)}
                        icon={<FaEdit size={15} />}
                    />
                </div>
            ),
        },
    ];

    const handleEditDetails = (record, index) => {
        setCurrentSelectedRecordIndex(index)

        setCurrentSelectedProduct(record)

        // setCurrentSidebarFormToRender(<NewProductDataForm
        //     // record={record}
        //     listSearchParams={listSearchParams}
        //     is_this_for_update={true}
        //     setIsChannelConfigurationSidebarOpen={setIsChannelConfigurationSidebarOpen}
        //     setChannelConfigurationSidebarFooter={setChannelConfigurationSidebarFooter}
        // />)

        // setIsChannelConfigurationSidebarOpen(true)

        setCurrentDetailsFormToRender(
            <EditProductDataForm
                // record={record}
                listSearchParams={listSearchParams}
                is_this_for_update={true}
                setIsChannelConfigurationSidebarOpen={setIsDetailsModalOpen}
                setChannelConfigurationSidebarFooter={setDetailsModalFooter}
            />
        )
        setIsDetailsModalOpen(true)
        // setDetailsModalFooter(false)
        setDetailsModalTitle("Edit details")
    }

    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    const handleSelectVendorsForFiler = () => {
        setCurrentDetailsFormToRender(<ListOfAllVendors
            setListOfSelectedVendorIds={setListOfSelectedVendorIds}
            listOfSelectedVendorIds={listOfSelectedVendorIds}
            defaultSelectedCountry={selectedCountry}
            defaultSelectedPlatform={selectedPlatform}
            defaultSelectedRegion={selectedRegion}
            setSelectedVendorsNames={setSelectedVendorsNames}
            selectedVendorsNames={selectedVendorsNames}
        />);
        setDetailsModalTitle("Select vendors for filter");
        setIsDetailsModalOpen(true);
        setDetailsModalFooter(false)
    }

    const handleExportVendorsModal = (record) => {
        setCurrentSelectedProduct(record)
        setCurrentDetailsFormToRender(<Export
            listSearchParams={listSearchParams}
        />)
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true)
        setDetailsModalTitle("Export products")
    };

    const handleRegionChange = (region) => {
        setSelectedRegion(region);

        if (region === 'all') {
            // Collect all country codes from all regions
            // const allCountryCodes = sortedCountriesGroupedBySubRegionForFilter.flatMap(r => r.countries.map(country => country.isoCode));
            setSelectedCountry(['all']);
            setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: [], categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total })
            fetchListOfAllProductsForOverview({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: [], categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total });

        } else {

            if (numberOfRecordsToLoad !== 'All records')
                setNumberOfRecordsToLoad(numberOfRecordsToLoad)

            // Find the selected region object
            const selectedRegionObj = sortedCountriesGroupedBySubRegionForFilter.find(r => r.subRegion === region);

            // Update selected countries based on the selected region
            if (selectedRegionObj) {
                const countryCodes = selectedRegionObj.countries.map(country => country.isoCode);
                setSelectedCountry(countryCodes);
                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: countryCodes, categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total })
                fetchListOfAllProductsForOverview({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: countryCodes, categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total });
            } else {
                setSelectedCountry([]); // Reset if no match
                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: [], categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total })
                fetchListOfAllProductsForOverview({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: [], categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total });
            }
        }

        setPagination(prev => ({
            ...prev,
            current: 1
        }));

        setHasFiltersChanged(true);

        // Reset the Country dropdown to "All Countries"
        // setSelectedCountry(['all']);
    };

    const handleFilterByCategory = () => {
        setCurrentDetailsFormToRender(
            <ListOfCategoriesForFilter
                setIsDetailsModalOpen={setIsDetailsModalOpen}
                setDetailsModalFooter={setDetailsModalFooter}
                listOfSelectedCategories={listOfSelectedCategories}
                setListOfSelectedCategories={setListOfSelectedCategories}
                selectedCategoriesNames={selectedCategoriesNames}
                setSelectedCategoriesNames={setSelectedCategoriesNames}
            />)
        setIsDetailsModalOpen(true)
        setDetailsModalFooter(false)
        setDetailsModalTitle("Select categories to filter list of vendors")
    };

    const getSelectedCategoriesLabel = () => {
        return selectedCategoriesNames.length > 0 ? selectedCategoriesNames.join(', ') : 'No categories selected';
    };

    const getSelectedVendorsNames = () => {
        return selectedVendorsNames.length > 0 ? selectedVendorsNames.join(', ') : 'No vendors selected'
    }

    const toggleSelectedFilters = () => {
        setShowSelectedFilters(!showSelectedFilters);
    };

    const clearSelectedFilters = () => {
        setSelectedCategoriesNames([]);
        setSelectedVendorsNames([]);
        setListOfSelectedCategories([]);
        setListOfSelectedVendorIds([]);
        // Add any other state resets needed for clearing filters
    };


    return (
        <div className={styles.product_face_config_tab_container}>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total, // Total number of records from API
                    showSizeChanger: false
                }}
                loading={isLoadingListOfAllProductsForOverview}
                onChange={handleTableChange}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.top_actions_row}>
                            <div className={styles.left_side}>
                                <div className={styles.platform_dropdown}>
                                    <CustomDropDown
                                        defaultValue='default'
                                        options={listOfPlatforms}
                                        onChange={(value) => {
                                            if (value != 'default') {
                                                console.log('platform drop down numberOfRecordsToLoad', numberOfRecordsToLoad)

                                                setSelectedPlatform([value])
                                                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: [value], countryCodes: selectedCountry[0] === 'all' ? [] : selectedCountry, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total })
                                                fetchListOfAllProductsForOverview({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: value === 'all' ? [] : [value], vendorIds: listOfSelectedVendorIds, countryCodes: selectedCountry[0] === 'all' ? [] : selectedCountry, categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total });
                                                // if (numberOfRecordsToLoad !== 'All records')
                                                setNumberOfRecordsToLoad(numberOfRecordsToLoad)

                                                // fetchListOfAllVendors({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: [value]  });
                                            } else {
                                                console.log('platform drop down numberOfRecordsToLoad', numberOfRecordsToLoad)
                                                setSelectedPlatform([])
                                                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: [], countryCodes: selectedCountry[0] === 'all' ? [] : selectedCountry, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total })
                                                fetchListOfAllProductsForOverview({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: [], vendorIds: listOfSelectedVendorIds, countryCodes: selectedCountry[0] === 'all' ? [] : selectedCountry, categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total });
                                                // if (numberOfRecordsToLoad !== 'All records')
                                                setNumberOfRecordsToLoad(numberOfRecordsToLoad)

                                            }

                                            setPagination(prev => ({
                                                ...prev,
                                                current: 1,

                                            }));
                                        }}
                                    />

                                </div>
                                <div className={styles.region_dropdown}>
                                    <CustomDropDown
                                        enable_search={true}

                                        defaultValue='all'
                                        options={[
                                            { value: 'all', label: 'All Regions' },
                                            ...sortedCountriesGroupedBySubRegionForFilter.map((region) => ({
                                                value: region?.subRegion,
                                                label: region?.subRegion
                                            }))
                                        ]}
                                        onChange={handleRegionChange}
                                    />
                                </div>
                                <div className={styles.country_dropdown}>
                                    <CustomDropDown
                                        enable_search={true}
                                        defaultValue='all'

                                        // options={[
                                        //     { value: 'all', label: 'All Countries' },
                                        //     ...(selectedRegion !== 'all'
                                        //         ? sortedCountriesGroupedBySubRegionForFilter
                                        //             .find((region) => region.subRegion === selectedRegion)?.countries
                                        //             .map((country) => ({
                                        //                 value: country.isoCode,
                                        //                 label: country.name,
                                        //             })) || []
                                        //         : sortedCountriesGroupedBySubRegionForFilter.flatMap((region) =>
                                        //             region.countries.map((country) => ({
                                        //                 value: country.isoCode,
                                        //                 label: country.name,
                                        //             }))
                                        //         )),
                                        // ]}

                                        options={[
                                            { value: 'all', label: 'All Countries' },
                                            ...(selectedRegion !== 'all'
                                                ? // If a specific region is selected, filter by that region and map countries
                                                sortedCountriesGroupedBySubRegionForFilter
                                                    .find((region) => region.subRegion === selectedRegion)?.countries
                                                    .map((country) => ({
                                                        value: country.isoCode,
                                                        label: country.name,
                                                    })) || []
                                                : // Otherwise, flatten all regions and sort globally
                                                sortedCountriesGroupedBySubRegionForFilter
                                                    .flatMap((region) => region.countries) // Flatten all countries
                                                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort globally by name
                                                    .map((country) => ({
                                                        value: country.isoCode,
                                                        label: country.name,
                                                    }))
                                            ),
                                        ]}
                                        onChange={(value) => {
                                            setPagination(prev => ({
                                                ...prev,
                                                current: 1
                                            }));
                                            if (numberOfRecordsToLoad !== 'All records')
                                                setNumberOfRecordsToLoad(numberOfRecordsToLoad)

                                            if (value === 'all') {
                                                let countries;

                                                if (selectedRegion !== 'all') {
                                                    const selectedRegionData = sortedCountriesGroupedBySubRegionForFilter.find(region => region.subRegion === selectedRegion);

                                                    countries = selectedRegionData
                                                        ? selectedRegionData.countries.map(country => (country.isoCode))
                                                        : [];
                                                } else {
                                                    countries = sortedCountriesGroupedBySubRegionForFilter.flatMap(region =>
                                                        region.countries.map(country => (country.isoCode))
                                                    );
                                                }
                                                setSelectedCountry(countries)

                                                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: countries, categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total })
                                                fetchListOfAllProductsForOverview({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: countries, categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total });

                                            } else {
                                                setSelectedCountry([value])
                                                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: [value], categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total })
                                                fetchListOfAllProductsForOverview({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform[0] === 'all' ? [] : selectedPlatform, vendorIds: listOfSelectedVendorIds, countryCodes: [value], categories: listOfSelectedCategories, numberOfRecordsToLoad: numberOfRecordsToLoad, calculateTotalRecords: true, calculatedTotalRecords: pagination.total });
                                            }
                                        }}
                                    />
                                </div>

                                <div className={styles.vendors_filter}>
                                    <CustomButton
                                        title={`Filter by vendors (${listOfSelectedVendorIds?.length})`}
                                        width="100%"
                                        font_size="0.7"
                                        unit="rem"
                                        padding="5px 5px 0px 5px"
                                        colorScheme=''
                                        border_color="#FAFAFA"
                                        background_color="#FAFAFA"
                                        onClick={() => handleSelectVendorsForFiler()}
                                        icon={<FaFunnelDollar size={15} />}
                                    />
                                </div>
                                <div className={styles.categories_filter}>
                                    <CustomButton
                                        title="Filter by categories"
                                        width="100%"
                                        font_size="0.7"
                                        unit="rem"
                                        padding="5px 5px 0px 5px"
                                        colorScheme=""
                                        border_color="#FAFAFA"
                                        background_color="#FAFAFA"
                                        icon={<img src={ic_filter_cat} width={20} />}
                                        onClick={() => handleFilterByCategory()}
                                    />
                                </div>
                            </div>
                            <div className={styles.right_side}>
                                <div className={styles.search_bar}>
                                    <CustomTextfield
                                        border_radius="10px"
                                        icon={<FaSearch size={15} />}
                                        colorScheme="primary"
                                        place_holder="Search name..."
                                        font_size="0.7"
                                        unit="rem"
                                        padding="10px"
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value)
                                            setHasFiltersChanged(true)
                                        }}
                                    />
                                </div>
                                {
                                    prevSearchTerm.current != "" && <div className={styles.platform_dropdown}>
                                        <CustomDropDown
                                            defaultValue={numberOfRecordsToLoad}
                                            options={["10", "20", "30", "50", "100", "All records"].map((value) => ({
                                                label: `${value} ${value != 'All records' ? 'records' : ''}`,
                                                value: value,
                                            }))}

                                            // value={numberOfRecordsToLoad}

                                            onChange={(value) => {

                                                const newPageSize = value != 'All records' ? parseInt(value, 10) : 'All records';

                                                setListSearchParams({
                                                    keyword: searchTerm,
                                                    page: 1, // Reset to first page when the page size changes
                                                    limit: pagination.pageSize,
                                                    numberOfRecordsToLoad: newPageSize,
                                                    platform: selectedPlatform,
                                                    countryCodes: selectedCountry[0] === "all" ? [] : selectedCountry,
                                                    categories: listOfSelectedCategories,
                                                    vendorIds: listOfSelectedVendorIds,
                                                    calculateTotalRecords: true, calculatedTotalRecords: pagination.total
                                                });

                                                fetchListOfAllProductsForOverview({
                                                    keyword: searchTerm,
                                                    page: 1,
                                                    limit: pagination.pageSize,
                                                    numberOfRecordsToLoad: newPageSize,
                                                    platform: selectedPlatform,
                                                    vendorIds: listOfSelectedVendorIds,
                                                    countryCodes: selectedCountry[0] === "all" ? [] : selectedCountry,
                                                    categories: listOfSelectedCategories,
                                                    calculateTotalRecords: true, calculatedTotalRecords: pagination.total
                                                });

                                                setNumberOfRecordsToLoad(newPageSize)
                                                setHasFiltersChanged(true);
                                                setPagination((prev) => ({
                                                    ...prev,
                                                    current: 1, // Reset to page 1
                                                }));
                                            }}
                                        />

                                    </div>
                                }
                                <div className={styles.export_button}>
                                    <CustomButton
                                        title={"Export"}
                                        width="100%"
                                        font_size="0.8"
                                        unit="rem"
                                        padding="5px 10px 0px 10px"
                                        onClick={handleExportVendorsModal} // Stop stream on Update click
                                        disabled={!isAllowedToExportProducts}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.select_filters_row}>
                            {(selectedCategoriesNames.length > 0 || selectedVendorsNames.length > 0) &&
                                <div className={styles.show_hide_filters}>
                                    <Button onClick={toggleSelectedFilters} style={{ marginRight: '20px' }}>
                                        {showSelectedFilters ? 'Hide Selected Filters' : 'Show Selected Filters'}
                                    </Button>
                                </div>
                            }
                            {showSelectedFilters && (selectedCategoriesNames.length > 0 || selectedVendorsNames.length > 0) && (
                                <div style={{ marginTop: '10px', marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', position: 'relative' }}>
                                    <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>Selected Filters:</div>
                                    {selectedCategoriesNames.length > 0 && (
                                        <div style={{ marginBottom: '5px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Categories:</span> {getSelectedCategoriesLabel()}
                                        </div>
                                    )}
                                    {selectedVendorsNames.length > 0 && (
                                        <div>
                                            <span style={{ fontWeight: 'bold' }}>Vendors:</span> {getSelectedVendorsNames()}
                                        </div>
                                    )}
                                    <Button
                                        type="danger"
                                        onClick={clearSelectedFilters}
                                        style={{ position: 'absolute', bottom: '10px', right: '10px' }}
                                    >
                                        Clear Filters
                                    </Button>
                                </div>
                            )}
                        </div>

                    </div>
                )}
                rowKey="id" // Use 'id' as the unique key for each row
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfAllProductsForOverview: getListOfAllProductsForOverview(state),
    listOfVendorPlatforms: getAllVendorsNames(state),
    isLoadingListOfAllProductsForOverview: getIsLoadingListOfAllProductsForOverview(state),
    permissions: getPermissions(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfAllProductsForOverview: (params) => dispatch(fetchListOfAllProductsForOverview(params)),
    setIsLoadingListOfAllProductsForOverview: (params) => dispatch(setIsLoadingListOfAllProductsForOverview(params)),
    setListOfAllProductsForOverview: (params) => dispatch(setListOfAllProductsForOverview(params)),
    setCurrentSelectedProduct: (params) => dispatch(setCurrentSelectedProduct(params)),
    setQueryParams: (params) => dispatch(setQueryParams(params)), // Add this line
});

export default connect(mapState, mapDispatchToProps)(ProductsOverview);
