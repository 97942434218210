import { connect } from "react-redux";
import styles from '../styles.module.scss'
import Header from "./general-components/Header";
import TabsRender from "./general-components/TabsRender";
import { CustomButton } from "../../../../shared/ui/button";
import VendorOverviewTab from "./vendor-components/VendorOverviewTab";
import VendorVisualizationTab from "./vendor-components/VendorVisualizationTab";
import { useEffect, useState } from "react";
import NewVendorDataForm from "./vendor-components/NewVendorDataForm";
import ConfigurationSidebar from "./general-components/ConfigurationSidebar";
import DetailsModal from "./general-components/DetailsModal";
import VendorIndepthViewTab from "./vendor-components/VendorIndepthViewTab";
import { CustomDropDown } from "../../../../shared/ui/dropdown";
import NewPlatformDataForm from "./vendor-components/NewPlatformDataForm";

const VendersScreen = ({
  
    ...props
}) => {
    const [isVendorConfigurationSidebarOpen, setIsVendorConfigurationSidebarOpen] = useState(false)

    const [currentDetailsFormToRender, setCurrentDetailsFormToRender] = useState(null)

    const [currentSidebarFormToRender, setCurrentSidebarFormToRender] = useState(null)
    const [currentSidebarFooterToRender, setCurrentSidebarFooterToRender] = useState(null)
    const [sideBarTitle, setSideBarTitle] = useState("")

    const [listOfTabs, setListOfTabs] = useState([]);

    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
    const [detailsModalTitle, setDetailsModalTitle] = useState("Vender details")
    const [detailsModalFooter, setDetailsModalFooter] = useState(null)

 

    useEffect(() => {
        let temp_tabs = [
            {
                label: "Overview",
                screen: <VendorOverviewTab
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setDetailsModalFooter={setDetailsModalFooter}
                    setCurrentSidebarFooterToRender={setCurrentSidebarFooterToRender}
                    setIsVendorConfigurationSidebarOpen={setIsVendorConfigurationSidebarOpen}
                    setCurrentSidebarFormToRender={setCurrentSidebarFormToRender}
                    setSideBarTitle={setSideBarTitle}
                />
            },
            // {
            //     label: "Vendor Pool",
            //     screen: <VendorIndepthViewTab
            //         setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
            //         setIsDetailsModalOpen={setIsDetailsModalOpen}
            //         setDetailsModalTitle={setDetailsModalTitle}
            //         setCurrentSidebarFormToRender={setCurrentSidebarFormToRender}
            //         setSideBarTitle={setSideBarTitle}
            //         setIsVendorConfigurationSidebarOpen={setIsVendorConfigurationSidebarOpen}

            //     />
            // },
            // {
            //     label: "Analytics",
            //     screen: <VendorVisualizationTab />
            // }
        ]
        setListOfTabs(temp_tabs)
    }, [])


    const handleCreateVendor = (e) => { }
    const handleCancelCreateVendor = (e) => {
        setIsVendorConfigurationSidebarOpen(false)
    }
    const handleCloseSidebar = (e) => {
        setIsVendorConfigurationSidebarOpen(false)
    }

    const handleOkayVendorDetailModal = (e) => {
        setIsDetailsModalOpen(false)
    }
    const handleCancelVendorDetailModal = (e) => {
        setIsDetailsModalOpen(false)
    }
    const handleCloseVendorDetailModal = (e) => {
        setIsDetailsModalOpen(false)
    }

    const handleTabOnChange = (e) => { }

    const handleConfigOptionChange = (e) => {
        switch (e) {

            case "add_new_platform":
                setSideBarTitle("Add new platform")
                setCurrentSidebarFormToRender(<NewPlatformDataForm

                />)
                setIsVendorConfigurationSidebarOpen(true)
                break;
            case "add_new_vendor":
                setSideBarTitle("Add new vendor")
                setCurrentSidebarFormToRender(<NewVendorDataForm
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                    setDetailsModalTitle={setDetailsModalTitle}

                    setCurrentSidebarFooterToRender={setCurrentSidebarFooterToRender}
                    setIsVendorConfigurationSidebarOpen={setIsVendorConfigurationSidebarOpen}
                />)
                setIsVendorConfigurationSidebarOpen(true)
                break;
        }
    }

    return <div className={styles.vendors_container}>
        <div className={styles.header_container}>
            <Header title='Vendors' />
            <div className={styles.actions_container}>
            </div>
        </div>
        <div className={styles.body_container}>

            <div className={styles.tabs_container}>
                <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
            </div>
        </div>
        {
            isVendorConfigurationSidebarOpen && <div className={styles.configuration_side_container}>
                <ConfigurationSidebar
                    title={sideBarTitle}
                    handleClose={handleCloseSidebar}
                    handleOkay={handleCreateVendor}
                    handleCancel={handleCancelCreateVendor}
                    okay_button_title='Create'
                    cancel_button_title='Cancel'
                    body={currentSidebarFormToRender}
                    footer={currentSidebarFooterToRender}
                />
            </div>
        }

        <div className={styles.configuration_modal_container}>
            <DetailsModal
                title={detailsModalTitle}
                handleClose={handleCloseVendorDetailModal}
                handleOkay={handleOkayVendorDetailModal}
                handleCancel={handleCancelVendorDetailModal}
                okay_button_title='Okay'
                cancel_button_title='Cancel'
                body={currentDetailsFormToRender}
                isModalOpen={isDetailsModalOpen}
                footer={detailsModalFooter}
            />
        </div>


    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
 });


export default connect(mapState, mapDispatchToProps)(VendersScreen)