import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Table, Switch } from "antd";
import styles from './modal.module.scss';
import { fetchListOfFacialRecognitionsLinkedToChannel, updateFacialRecognitionStatus, updateVendorStatus } from "../../../logic/channels/actions";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import {FaSearch } from "react-icons/fa";
// import { updateVendorChannels } from "../../../../api/VendorAPI";
import debounce from 'lodash.debounce';
import { getIsLoadingListOfFacialRecognitionsLinkedToChannel, getListOfFacialRecognitionsLinkedToChannel } from "../../../logic/channels/selectors";

const ListOfAllFacialRecognitions = ({
    updateFacialRecognitionStatus,
    fetchListOfFacialRecognitionsLinkedToChannel,
    listOfFacialRecognitionsLinkedToChannel,
    is_pagination_enabled = true,
    isLoadingListOfFacialRecognitionsLinkedToChannel,
    record,
    refresh,
    ...props }) => {
    // State to track enabled/disabled vendors
    const [facialRecognitions, setFacialRecognitions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });
    
    const prevSearchTerm = useRef('');

    const debouncedSearch = useCallback(
        debounce((searchTerm, page, record, is_pagination_enabled) => {
            fetchListOfFacialRecognitionsLinkedToChannel({ keyword: searchTerm, page, limit: 10, channel_id: record?.channel_id, is_pagination_enabled });
        }, 1000), // 1-second debounce delay
        []
    );

    useEffect(() => {
     
        if (record) {
            fetchListOfFacialRecognitionsLinkedToChannel({ keyword: "", page: 1, limit: 10, channel_id: record?.channel_id, is_pagination_enabled });
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
        }
    }, [record?.channel_id, is_pagination_enabled,refresh])

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, record, is_pagination_enabled);
    }, [searchTerm, pagination.current, debouncedSearch, record?.vendor_id, is_pagination_enabled]);


    useEffect(() => {
     
        if (listOfFacialRecognitionsLinkedToChannel) {
            const isSearchTermEmpty = searchTerm === '';
            // const allowedVendorIds = record?.vendors?.map(vendor => vendor.vendor_id) || [];

            const uniqueFacialRecognition = (facial_recognitions) => {
                const seen = new Set();
                return facial_recognitions?.filter(face => {
                    const duplicate = seen.has(face.id);
                    seen.add(face.id);
                    return !duplicate;
                });
            };

           

            const allVendors = uniqueFacialRecognition(listOfFacialRecognitionsLinkedToChannel?.formatted_data);

            setFacialRecognitions(allVendors);

            setPagination(prev => ({
                ...prev,
                total: listOfFacialRecognitionsLinkedToChannel.totalRecords,
                pageSize: prev?.current === 1 ? isSearchTermEmpty ? listOfFacialRecognitionsLinkedToChannel.extra_page_limit : listOfFacialRecognitionsLinkedToChannel.totalRecords : isSearchTermEmpty ? 10 : listOfFacialRecognitionsLinkedToChannel.totalRecords,
            }));

        }
    }, [listOfFacialRecognitionsLinkedToChannel, searchTerm, record?.channel_id]);

    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);

    // Handle enabling/disabling of vendors
    const handleToggle = (selectedFacialRecognition) => {

        setFacialRecognitions(prevVendors =>
            prevVendors.map(face =>
                face.id === selectedFacialRecognition?.id
                    ? { ...face, enabled: !face.enabled }
                    : face
            )
        );

        // if (record?.vendors?.some(vendor => vendor.id === selectedFacialRecogntion?.id)) {
        updateFacialRecognitionStatus({
            channel_id: record?.channel_id,
            facial_recognition_id: selectedFacialRecognition?.id,
            enabled: !selectedFacialRecognition.enabled // Correctly toggle the status
        });

    };

    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile_url',
            key: 'profile_url',
            render: (profile_url, record) => {
                const renderProfileImage = (url, name) => {
                    if (url) {
                        return (
                            <img
                                src={url}
                                alt={name}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ''; // Reset the image source if it fails
                                }}
                                style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                            />
                        );
                    } else {
                        const initials = name.split(' ').map(n => n[0]).join('');
                        return (
                            <div style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fff',
                            }}>
                                {initials}
                            </div>
                        );
                    }
                };

                return renderProfileImage(profile_url, record.name);
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                    <Switch
                        checked={record.enabled}
                        onChange={() => handleToggle(record)}
                        checkedChildren="Enabled"
                        unCheckedChildren="Disabled"
                    />
                </div>
            ),
        },
    ];
    // Add serial numbers to vendor data
    const data = facialRecognitions?.map((face, index) => ({
        key: face.facial_id,
        serialNumber: index + 1,
        ...face,
    }));

    const handleAdd = (vendor_record) => {
    }

    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    return (
        <div className={styles.list_of_allowed_channels_container}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total, // Total number of records from API
                    showSizeChanger: false
                }}
                onChange={handleTableChange}
                bordered
                loading={isLoadingListOfFacialRecognitionsLinkedToChannel}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.search_bar}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search i.e  vendor name "
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfFacialRecognitionsLinkedToChannel: getListOfFacialRecognitionsLinkedToChannel(state),
    isLoadingListOfFacialRecognitionsLinkedToChannel: getIsLoadingListOfFacialRecognitionsLinkedToChannel(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateFacialRecognitionStatus: (data) => dispatch(updateFacialRecognitionStatus(data)),
    fetchListOfFacialRecognitionsLinkedToChannel: (data) => dispatch(fetchListOfFacialRecognitionsLinkedToChannel(data)),
});

export default connect(mapState, mapDispatchToProps)(ListOfAllFacialRecognitions);
