import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { Table, Switch } from 'antd';
import styles from '../../styles.module.scss';
import { sortedCountriesGroupedBySubRegion } from '../../../../../shared/utils/regions_with_countries';
import { CustomButton } from '../../../../../shared/ui/button';
import { updateVendorRegions } from '../../../logic/vendor/actions';

const ListOfAllowedRegions = ({
    setDetailsModalFooter,
    setIsDetailsModalOpen,
    updateVendorRegions,
    listSearchParams = null,
    record, ...props }) => {
    const [data, setData] = useState([]);
    const [allowedRegions, setAllowedRegions] = useState([]);
    const [newlyEnabledRegions, setNewlyEnabledRegions] = useState([]);
    const [hasChanges, setHasChanges] = useState(false); // Track unsaved changes
    // Create refs to track the latest values
    const allowedRegionsRef = useRef(allowedRegions);
    const newlyEnabledRegionsRef = useRef(newlyEnabledRegions);

    // Update refs when state changes
    useEffect(() => {
        allowedRegionsRef.current = allowedRegions;
    }, [allowedRegions]);

    useEffect(() => {
        newlyEnabledRegionsRef.current = newlyEnabledRegions;
    }, [newlyEnabledRegions]);

    // Update the footer when `handleUpdate` is updated

    const handleUpdate = useCallback(() => {
        const latestAllowedRegions = allowedRegionsRef.current;
        const latestNewlyEnabledRegions = newlyEnabledRegionsRef.current;
        updateVendorRegions({
            vendor_id: record?.vendor_id,
            newlyEnabledRegions: latestNewlyEnabledRegions,
            allowedRegions: latestAllowedRegions,
            listSearchParams: listSearchParams
        }).then(() => setHasChanges(false));;
    }, [record?.vendor_id, updateVendorRegions]);


    useEffect(() => {
        setDetailsModalFooter(
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                <CustomButton
                    title={"Update"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleUpdate}
                    disabled={!hasChanges} // Disable Update if no changes
                />
                <CustomButton
                    title={"Cancel"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    colorScheme="danger"
                    onClick={handleCancel}
                />
            </div>
        );
    }, [handleUpdate, hasChanges]);

    useEffect(() => {
        const initialAllowedRegions = record?.allowedRegions || [];
        setAllowedRegions(initialAllowedRegions);

        // Create a Map for efficient lookup of enabled status by country_code
        const allowedCountriesMap = new Map(
            initialAllowedRegions.map(region => [region.country_code, region.enabled])
        );

        const updatedData = sortedCountriesGroupedBySubRegion.map(region => ({
            ...region,
            countries: region.countries.map(country => ({
                ...country,
                enabled: allowedCountriesMap.get(country.isoCode) ?? country.enabled // Use the enabled status from the map if available, otherwise default to country.enabled
            }))
        }));

        setData(updatedData);
    }, [record]);


    // Handle switch change
    const handleSwitchChange = (subRegionIndex, countryIndex, checked) => {
        const updatedData = [...data];
        const countryCode = updatedData[subRegionIndex].countries[countryIndex].isoCode;

        // Update local data
        updatedData[subRegionIndex].countries[countryIndex].enabled = checked;
        setData(updatedData);
        setHasChanges(true); // Set hasChanges to true on modification
        // Update allowedRegions state
        setAllowedRegions(prevRegions => {
            const existingRegion = prevRegions.find(region => region.country_code === countryCode);

            if (checked) {
                if (existingRegion) {
                    // If the region already exists, update its enabled status
                    const updatedRegions = prevRegions.map(region =>
                        region.country_code === countryCode
                            ? { ...region, enabled: true }
                            : region
                    );
                    return updatedRegions;
                } else {
                    // If the region is newly enabled and wasn't in the allowedRegions, add it to newlyEnabledRegions
                    const newRegion = { country_code: countryCode, enabled: true };
                    setNewlyEnabledRegions(prevNewlyEnabled => [...prevNewlyEnabled, newRegion]);
                    return prevRegions; // Don't modify allowedRegions directly
                }
            } else {
                if (existingRegion) {
                    // If disabling an existing region, update its status
                    const updatedRegions = prevRegions.map(region =>
                        region.country_code === countryCode
                            ? { ...region, enabled: false }
                            : region
                    );
                    return updatedRegions;
                } else {
                    // If disabling a newly added region, remove it from newlyEnabledRegions
                    setNewlyEnabledRegions(prevNewlyEnabled =>
                        prevNewlyEnabled.filter(region => region.country_code !== countryCode)
                    );
                    return prevRegions; // No changes to allowedRegions needed
                }
            }
        });
    };



    // Define columns for the main table (sub-regions)
    const columns = [
        {
            title: 'Sub-Region',
            dataIndex: 'subRegion',
            key: 'subRegion',
        },
        {
            title: 'Total Countries',
            dataIndex: 'totalCountries',
            key: 'totalCountries',
        },
        {
            title: 'Enabled Countries',
            dataIndex: 'enabledCountries',
            key: 'enabledCountries',
        },
    ];

    // Map the data to table format
    const tableData = data.map((region, index) => {
        const enabledCount = region.countries.filter(country => country.enabled).length;
        return {
            key: index + 1,
            subRegion: region.subRegion,
            totalCountries: region.countries.length,
            enabledCountries: enabledCount,
            countries: region.countries,
        };
    });


    const handleCancel = (e) => {
        setIsDetailsModalOpen(false)
        setHasChanges(false); // Discard changes
    }

    return (
        <div className={styles.list_of_allowed_regions_container} style={{ width: '100%' }}>
            <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                bordered
                expandable={{
                    expandedRowRender: (record, index) => (
                        <Table
                            columns={[
                                { title: 'Country', dataIndex: 'name', key: 'name' },
                                { title: 'ISO Code', dataIndex: 'isoCode', key: 'isoCode' },
                                {
                                    title: 'Enabled',
                                    dataIndex: 'enabled',
                                    key: 'enabled',
                                    render: (text, record, countryIndex) => (
                                        <Switch
                                            checked={record.enabled}
                                            onChange={(checked) => handleSwitchChange(index, countryIndex, checked)}
                                        />
                                    ),
                                },
                            ]}
                            dataSource={record.countries.map((country, countryIndex) => ({
                                key: countryIndex + 1,
                                name: country.name,
                                isoCode: country.isoCode,
                                enabled: country.enabled,
                            }))}
                            pagination={false}
                            style={{ width: '100%' }}
                        />
                    ),
                    rowExpandable: (record) => record.countries && record.countries.length > 0,
                }}
            />
        </div>
    );
};

const mapState = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    updateVendorRegions: (data) => dispatch(updateVendorRegions(data)),
});

export default connect(mapState, mapDispatchToProps)(ListOfAllowedRegions);
