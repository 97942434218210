import React, { useState } from "react";
import { connect } from "react-redux";
import { Table, Checkbox } from 'antd';
import styles from './modal.module.scss';

const VendorRegionConfigurationForm = ({ ...props }) => {
    // State to manage the selected checkboxes for countries and sub-regions
    const [selectedCountryKeys, setSelectedCountryKeys] = useState([]);
    const [selectedSubRegionKeys, setSelectedSubRegionKeys] = useState([]);

    // Sample data for the table
    const regionsData = [
        {
            key: '1',
            country: 'United States',
            sub_regions: [
                { key: '1-1', sub_region_name: 'California' },
                { key: '1-2', sub_region_name: 'Texas' },
                { key: '1-3', sub_region_name: 'New York' },
            ],
        },
        {
            key: '2',
            country: 'Canada',
            sub_regions: [
                { key: '2-1', sub_region_name: 'Ontario' },
                { key: '2-2', sub_region_name: 'Quebec' },
                { key: '2-3', sub_region_name: 'British Columbia' },
            ],
        },
        {
            key: '3',
            country: 'Australia',
            sub_regions: [
                { key: '3-1', sub_region_name: 'New South Wales' },
                { key: '3-2', sub_region_name: 'Victoria' },
                { key: '3-3', sub_region_name: 'Queensland' },
            ],
        },
        // Add more countries and sub-regions as needed
    ];

    // Calculate the number of selected sub-regions for each country
    const getSelectedSubRegionsCount = (subRegions) => {
        return subRegions.filter((sub) => selectedSubRegionKeys.includes(sub.key)).length;
    };

    // Define the columns for the table
    const columns = [
        {
            title: (
                <Checkbox
                    indeterminate={selectedCountryKeys.length > 0 && selectedCountryKeys.length < regionsData.length}
                    onChange={(e) => {
                        if (e.target.checked) {
                            const allKeys = regionsData.map((item) => item.key);
                            setSelectedCountryKeys(allKeys);
                        } else {
                            setSelectedCountryKeys([]);
                        }
                    }}
                />
            ),
            dataIndex: 'checkbox',
            key: 'checkbox',
            render: (text, record) => (
                <Checkbox
                    checked={selectedCountryKeys.includes(record.key)}
                    onChange={(e) => {
                        const newSelectedCountryKeys = e.target.checked
                            ? [...selectedCountryKeys, record.key]
                            : selectedCountryKeys.filter((key) => key !== record.key);
                        setSelectedCountryKeys(newSelectedCountryKeys);
                    }}
                />
            ),
            width: '10%',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            width: '40%',
        },
        {
            title: 'Sub-Regions',
            dataIndex: 'sub_regions_count',
            key: 'sub_regions_count',
            render: (text, record) => (
                <span>{`${getSelectedSubRegionsCount(record.sub_regions)} / ${record.sub_regions.length}`}</span>
            ),
            width: '50%',
        },
    ];

    // Define the columns for the sub-region table
    const subRegionColumns = [
        {
            title: (
                <Checkbox
                    indeterminate={selectedSubRegionKeys.length > 0 && selectedSubRegionKeys.length < regionsData.flatMap(item => item.sub_regions).length}
                    onChange={(e) => {
                        if (e.target.checked) {
                            const allSubRegionKeys = regionsData.flatMap(item => item.sub_regions.map(sub => sub.key));
                            setSelectedSubRegionKeys(allSubRegionKeys);
                        } else {
                            setSelectedSubRegionKeys([]);
                        }
                    }}
                />
            ),
            dataIndex: 'checkbox',
            key: 'checkbox',
            render: (text, subRecord) => (
                <Checkbox
                    checked={selectedSubRegionKeys.includes(subRecord.key)}
                    onChange={(e) => {
                        const newSelectedSubRegionKeys = e.target.checked
                            ? [...selectedSubRegionKeys, subRecord.key]
                            : selectedSubRegionKeys.filter((key) => key !== subRecord.key);
                        setSelectedSubRegionKeys(newSelectedSubRegionKeys);
                    }}
                />
            ),
            width: '10%',
        },
        {
            title: 'Sub-Region',
            dataIndex: 'sub_region_name',
            key: 'sub_region_name',
            width: '90%',
        },
    ];

    return (
        <div className={styles.vendors_region_configuration_form_container} style={{ width: '100%' }}>
            <Table
                columns={columns}
                dataSource={regionsData}
                pagination={{ pageSize: 10,showSizeChanger: false }}  // Pagination set to 10 records per page
                rowKey="key"
                bordered
                expandable={{
                    expandedRowRender: (record) => (
                        <Table
                            columns={subRegionColumns}
                            dataSource={record.sub_regions}
                            pagination={false}  // No pagination for sub-region table
                            rowKey="key"
                            scroll={{ y: 150 }}  // Enable vertical scrolling with a max height of 150px
                            style={{ width: '100%' }}
                        />
                    ),
                    rowExpandable: (record) => record.sub_regions && record.sub_regions.length > 0,
                }}
                style={{ width: '100%' }}
            />
        </div>
    );
};

const mapState = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapState, mapDispatchToProps)(VendorRegionConfigurationForm);
