// Existing selectors
export const getRoute = (state) => state.dashboard?.route;
export const getCurrentLoggedInUser = (state) => state.dashboard?.currentLoggedInUser;

// New selectors for the counts
export const getTotalNumberOfUsers = (state) => state.dashboard?.total_number_of_users;
export const getTotalNumberOfChannels = (state) => state.dashboard?.total_number_of_channels;
export const getTotalNumberOfVendors = (state) => state.dashboard?.total_number_of_vendors;
export const getTotalNumberOfQRCodeScans = (state) => state.dashboard?.total_number_of_qr_code_scans;
export const getTotalNumberOfQRCodeVisits = (state) => state.dashboard?.total_number_of_qr_code_visits;
export const getTotalNumberOfProductsAdvertized = (state) => state.dashboard?.total_number_of_products_advertized;
export const getTotalNumberOfProducts = (state) => state.dashboard?.total_number_of_products;
export const getIsLoadingTotalCountStates = (state) => state.dashboard?.is_loading_total_count_states;
export const getProductVerificationSpeed = (state) => state.dashboard?.product_verification_speed;
export const getTotalScoreCount = (state) => state.dashboard?.total_score_count;
export const getTotalAverageScoreCount = (state) => state.dashboard?.total_average_score;
export const getIsLoadingMatchDetails = (state) => state.dashboard?.is_loading_match;
export const getMatchDetails = (state) => state.dashboard?.match_details;

export const getTotalMatches = (state) => state.dashboard?.total_matches;

export const getIsLoadingTotalUsersCount = (state) => state.dashboard?.is_loading_total_users;
export const getIsLoadingTotalVendorsCount = (state) => state.dashboard?.is_loading_total_vendors;
export const getIsLoadingTotalChannelCount = (state) => state.dashboard?.is_loading_total_channels;
export const getIsLoadingReviewStates = (state) => state.dashboard?.is_loading_review_states;
export const getIsLoadingTotalProducts = (state) => state.dashboard?.is_loading_total_products;
export const getIsLoadingUniqueMatches = (state) => state.dashboard?.is_loading_unique_matches;
export const getIsLoadingTotalMatches = (state) => state.dashboard?.is_loading_total_matches;
export const getIsLoadingVerificationSpeed = (state) => state.dashboard?.is_loading_verification_speed;
export const getIsLoadingQRCodeStates = (state) => state.dashboard?.is_loading_qr_code_states;
export const getIsLoadingTop10Vendors = (state) => state.dashboard?.is_loading_top_10_vendors;



