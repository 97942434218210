

import { VendorPI } from "../../../api";
import { setIsLoadingListOfCatagoriesForVendor, setIsLoadingListOfVendors, setIsLoadingListOfVendorsForExport, setIsLoadingMatchCountDivisionForVendor, setIsLoadingVendorCountDetailsByChannel, setIsUpdatingChannelsForRegions, setIsUpdatingRegions, setListOfCatagoriesForVendor, setListOfVendors, setListOfVendorsForExport, setListOfVendorsNames, setMatchCountDivisionForVendor } from "./slice";
import { setIsLoadingListOfChannels, setListOfChannels } from "../channels/slice";
import toast from "react-hot-toast";
let abortController_fetchListOfAllVendors = null;  // Declare globally within the file
let abortController_fetchListOfChannelsForVendor = null;  // Declare globally within the file
let abortController_fetchListOfCatagoriesForVendor = null;  // Declare globally within the file
let abortController_fetchMatchCountDivisionsForVendor = null;  // Declare globally within the file
let abortController_fetchVendorCountDetailsByChannel = null
let abortController_fetchListOfAllVendorsForExport = null

const fetchVendorCountDetailsByChannel = (data) => (dispatch) => {
    if (abortController_fetchVendorCountDetailsByChannel) {
        abortController_fetchVendorCountDetailsByChannel.abort();
    }

    // Create a new AbortController
    abortController_fetchVendorCountDetailsByChannel = new AbortController();
    const { signal } = abortController_fetchVendorCountDetailsByChannel;

    // Set loading state
    dispatch(setIsLoadingVendorCountDetailsByChannel(true));

    // Return the promise
    return VendorPI.getVendorCountDetailsByChannel(data, signal)
        .then((response) => {
          
            if (response?.status === 'success') {
                // Reset loading state and resolve with data
                dispatch(setIsLoadingVendorCountDetailsByChannel(false));
                return Promise.resolve(response?.data); // Explicit resolve
            }

            // Reset loading state if the status is not 'success'
            dispatch(setIsLoadingVendorCountDetailsByChannel(false));
            return Promise.reject(
                new Error('Failed to fetch review details') // Explicit rejection
            );
        })
        .catch((error) => {
            console.error('getReviewDetails:error', error);

            // Reset loading state
            dispatch(setIsLoadingVendorCountDetailsByChannel(false));

            return Promise.reject(error); // Propagate error to caller
        });
};


const fetchMatchCountDivisionsForVendor = (data) => (dispatch) => {
    if (abortController_fetchMatchCountDivisionsForVendor) {
        abortController_fetchMatchCountDivisionsForVendor.abort();
    }

    // Create a new AbortController
    abortController_fetchMatchCountDivisionsForVendor = new AbortController();
    const { signal } = abortController_fetchMatchCountDivisionsForVendor;

    dispatch(setIsLoadingMatchCountDivisionForVendor(true));

    VendorPI.getMatchCountDetailsForVendor(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {

                    return {
                        ...record
                    }
                });

                dispatch(setMatchCountDivisionForVendor({
                    formatted_data: formatted_data,
                    totalRecords: response.totalRecords,
                    totalPages: response.totalPages,
                    currentPage: response.currentPage,
                }));
            }
            dispatch(setIsLoadingMatchCountDivisionForVendor(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingMatchCountDivisionForVendor(false));
        });
};

const fetchListOfAllVendors = (data) => (dispatch) => {
    if (abortController_fetchListOfAllVendors) {
        abortController_fetchListOfAllVendors.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllVendors = new AbortController();
    const { signal } = abortController_fetchListOfAllVendors;

    dispatch(setIsLoadingListOfVendors(true));

    VendorPI.getAllVendors(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    const parsedRegions = JSON.parse(record.regions || '[]'); // Parse JSON string

                    return {
                        vendor_id: record.id,
                        platform: record.platform,
                        name: record.name,
                        allowedRegions: parsedRegions, // Use parsed regions
                        allowedChannels: record.enabled_channels,
                        logo_url: record.logo_url,
                        enabled: record?.enabled,
                        domain_url: record.domain_url,
                        total_products: record.total_products,
                        enabled_regions: parsedRegions.filter((rec) => rec.enabled).length, // Count enabled regions
                        disabled_regions: parsedRegions.filter((rec) => !rec.enabled).length, // Count disabled regions
                        enabled_channels: record?.enabled_channels,
                        disabled_channels: record?.disabled_channels,
                        status: record?.status,
                        matches_count: record?.matches_count
                    };
                });

                dispatch(setListOfVendors({
                    formatted_data: formatted_data,
                    totalRecords: response.totalRecords,
                    totalPages: response.totalPages,
                    currentPage: response.currentPage,
                }));
            }
            dispatch(setIsLoadingListOfVendors(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingListOfVendors(false));
        });
};


const fetchListOfAllVendorsForExport = (data) => (dispatch) => {
    if (abortController_fetchListOfAllVendorsForExport) {
        abortController_fetchListOfAllVendorsForExport.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfAllVendorsForExport = new AbortController();
    const { signal } = abortController_fetchListOfAllVendorsForExport;

    dispatch(setIsLoadingListOfVendorsForExport(true));

    return VendorPI.exportAllVendors(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {
                    return {
                        ...record
                    };
                });

                dispatch(setListOfVendorsForExport(formatted_data));
            }
            dispatch(setIsLoadingListOfVendorsForExport(false));
            return response; // Return the response to the caller
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingListOfVendorsForExport(false));
            throw error; // Rethrow the error to the caller
        });
};


const fetchListOfCatagoriesForVendor = (data) => (dispatch) => {
    if (abortController_fetchListOfCatagoriesForVendor) {
        abortController_fetchListOfCatagoriesForVendor.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfCatagoriesForVendor = new AbortController();
    const { signal } = abortController_fetchListOfCatagoriesForVendor;
    dispatch(setIsLoadingListOfCatagoriesForVendor(true));

    VendorPI.getAllCatagoriesForVendor(data, signal)
        .then((response) => {
            if (response?.status === 'success') {
                let formatted_data = response?.data?.map((record) => {

                    return {
                        id: record?.id,
                        name: record?.name,
                        tier: record.tier,
                        parent_id: record.parent_id,
                        enabled: record.enabled
                    };
                });

                dispatch(setListOfCatagoriesForVendor({
                    formatted_data: formatted_data,
                    // totalRecords: response.totalRecords,
                    // totalPages: response.totalPages,
                    // currentPage: response.currentPage,
                }));
            }
            dispatch(setIsLoadingListOfCatagoriesForVendor(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingListOfCatagoriesForVendor(false));
        });
};

const fetchListOfChannelsForVendor = (data) => (dispatch) => {
    if (abortController_fetchListOfChannelsForVendor) {
        abortController_fetchListOfChannelsForVendor.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfChannelsForVendor = new AbortController();
    const { signal } = abortController_fetchListOfChannelsForVendor;

    dispatch(setIsLoadingListOfChannels(true))

    VendorPI.getAllChannelsForVendors(data, signal)
        .then((response) => {
            if (response?.status === 'success') {

                let formatted_data = response?.data?.map((record) => {
                    return {
                        channel_id: record.channel_id,
                        channelName: record.channel_name,
                        logo_url: record.logo_url,
                        enabled: record.enabled,
                    }
                })

                dispatch(setListOfChannels({
                    formatted_data: formatted_data,
                    totalRecords: response.totalRecords,
                    totalPages: response.totalPages,
                    currentPage: response.currentPage,

                }))
            }

            dispatch(setIsLoadingListOfChannels(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfChannels(false))

        })
};

const fetchListOfVendorsNames = (data) => (dispatch) => {

    VendorPI.getAllVendorsList(data)
        .then((response) => {

            if (response?.status === 'success') {

                const sortedData = response?.data.sort((a, b) => a.platform.localeCompare(b.platform)); // Sort vendors by name
                dispatch(setListOfVendorsNames(sortedData))
            }

        })
        .catch((error) => {
            console.error(error)

        })
};

const updateVendorRegions = (data) => (dispatch) => {
    dispatch(setIsUpdatingRegions(true));

    const updateVendorRegionsPromise = VendorPI.updateVendorRegions(data);

    // Use toast.promise to handle all stages (loading, success, and error)
    toast.promise(updateVendorRegionsPromise, {
        loading: "Updating regions...",
        success: "Successfully updated regions",
        error: (error) => `Error updating regions: ${error.message}`,
    });

    return updateVendorRegionsPromise
        .then((response) => {
            dispatch(fetchListOfAllVendors(data?.listSearchParams));
            dispatch(setIsUpdatingRegions(false));
            return response;
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsUpdatingRegions(false));
            return Promise.reject(error);
        });
};

const updateVendorChannels = (data) => (dispatch) => {

    const updateVendorChannelsPromise = VendorPI.updateVendorChannels(data);

    toast.promise(updateVendorChannelsPromise, {
        loading: "Updating channels...",
        success: "Successfully updated channels for this vendor",
        error: (error) => `Error updating channels: ${error.message}`,
    });

    return updateVendorChannelsPromise
        .then((response) => {
            dispatch(fetchListOfAllVendors(data?.listSearchParams));
            // dispatch(fetchListOfChannelsForVendor({
            //     vendor_id: data.vendor_id,
            //     page: data?.currentPage,
            //     keyword: data.searchTerm
            // }));

            return response;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject(error);
        });
};

const updateVendorChannelsInBulk = (data) => (dispatch) => {

    const updateVendorChannelsInBulkPromise = VendorPI.updateVendorChannelsInBulk(data);

    toast.promise(updateVendorChannelsInBulkPromise, {
        loading: "Updating channels...",
        success: "Successfully updated channels for this vendor",
        error: (error) => `Error updating channels: ${error.message}`,
    });

    return updateVendorChannelsInBulkPromise
        .then((response) => {

            dispatch(fetchListOfAllVendors(data?.listSearchParams));
            // dispatch(fetchListOfChannelsForVendor({
            //     vendor_id: data.vendor_id,
            //     page: data?.currentPage,
            //     keyword: data.searchTerm
            // }));

            return response;
        })
        .catch((error) => {
            console.error(error);
            return Promise.reject(error);
        });
};

const updateVendorCatagories = (data) => (dispatch) => {
    // dispatch(setIsUpdatingChannelsForRegions(true));

    const updateVendorChannelsPromise = VendorPI.updateCatagoriesForChannel(data);

    toast.promise(updateVendorChannelsPromise, {
        loading: "Updating Categories ...",
        success: "Successfully updated category  status",
        error: (error) => `Error updating Categories : ${error.message}`,
    });

    return updateVendorChannelsPromise
        .then((response) => {
            dispatch(fetchListOfCatagoriesForVendor(data));
            dispatch(fetchListOfChannelsForVendor({ vendor_id: data.vendor_id, page: data?.currentPage }));
            // dispatch(setIsUpdatingChannelsForRegions(false));
            return response;
        })
        .catch((error) => {
            console.error(error);
            // dispatch(setIsUpdatingChannelsForRegions(false));
            return Promise.reject(error);
        });
};



const addNewVendor = (data) => (dispatch) => {
    const addVendorPromise = VendorPI.addNewVendor(data);

    toast.promise(addVendorPromise, {
        loading: "Adding new vendor...",
        success: "Vendor added successfully!",
        error: (error) => `Error adding vendor: ${error.message}`,
    });

    return addVendorPromise
        .then((response) => {
            dispatch(fetchListOfAllVendors());
            return response; // Resolve the promise with the response
        })
        .catch((error) => {
            console.error(error); // Error is handled by toast.promise
            return Promise.reject(error); // Reject the promise
        });
};



const updateVendorDetails = (data, listSearchParams) => (dispatch) => {
    const updatePromise = VendorPI.updateVendorDetails(data);

    toast.promise(updatePromise, {
        loading: "Updating vendor...",
        success: "Vendor updated successfully!",
        error: (error) => `Error updating vendor: ${error.message}`,
    });

    return updatePromise
        .then((response) => {
            dispatch(fetchListOfAllVendors(listSearchParams));
            return response; // Resolve the promise with the response
        })
        .catch((error) => {
            console.error(error); // Error is handled by toast.promise
            return Promise.reject(error); // Reject the promise
        });
};




const disableVendor = (data) => (dispatch) => {
    const disableVendorPromise = VendorPI.disableVendor(data);

    toast.promise(disableVendorPromise, {
        loading: "Disabling vendor...",
        success: "Vendor disabled successfully!",
        error: (error) => `Error disabling vendor: ${error.message}`,
    });

    return disableVendorPromise
        .then((response) => {
            dispatch(fetchListOfAllVendors());
            return response; // Resolve the promise with the response
        })
        .catch((error) => {
            console.error(error); // Error is handled by toast.promise
            return Promise.reject(error); // Reject the promise
        });
};



export {
    fetchListOfAllVendors,
    updateVendorRegions,
    updateVendorChannels,
    updateVendorCatagories,
    addNewVendor,
    updateVendorDetails,
    fetchListOfVendorsNames,
    fetchListOfChannelsForVendor,
    disableVendor,
    fetchListOfCatagoriesForVendor,
    fetchMatchCountDivisionsForVendor,
    fetchVendorCountDetailsByChannel,
    fetchListOfAllVendorsForExport,
    updateVendorChannelsInBulk
}
