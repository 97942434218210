import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Dropdown, Menu } from 'antd'; // Import Dropdown and Menu from antd
import { FaSearch, FaTwitter, FaFacebook, FaInstagram, FaEdit, FaFileExcel, FaImages, FaArrowCircleDown, FaArrowDown, FaPen } from 'react-icons/fa';
import styles from '../../styles.module.scss';
import { CustomTextfield } from '../../../../../shared/ui/text-field';
import { connect } from 'react-redux';
import { CustomButton } from '../../../../../shared/ui/button';
import { fetchListOfPeople } from '../../../logic/people-manager/actions';
import { getIsLoadingListOfPeople, getListOfPeople, getRefreshListOfPeople } from '../../../logic/people-manager/selectors';
import debounce from 'lodash.debounce'; // Import lodash debounce function
import NewPeopleDataForm from './NewPeopleDataForm';
import FacialRecordConfigurationModal from './FacialRecordConfigurationModal';
import FaceProductConfigurationModal from './FaceProductConfigurationModal';
import { setCurrentSelectPeople, setIsLoadingListOfPeople, setListOfPeople } from '../../../logic/people-manager/slice';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin if needed
import localizedFormat from "dayjs/plugin/localizedFormat"; // Import localized format plugin
import { setIsLoadingListOfAllProducts } from '../../../logic/product/slice';
import { getPermissions } from '../../../logic/permissions/selectors';

dayjs.extend(utc)
dayjs.extend(timezone); // Extend with timezone if needed
dayjs.extend(localizedFormat); // Extend with localized format if needed


const localTimeZone = dayjs.tz.guess(); // Get the user's local time zone


const PeopleOverviewTab = ({
    fetchListOfPeople,
    listOfAllPeople,
    isLoadingListOfAllPeople,
    setCurrentSidebarFormToRender,
    setIsPersonConfigurationSidebarOpen,
    setPersonConfigurationSidebarFooter,
    setIsDetailsModalOpen,
    setDetailsModalTitle,
    setCurrentDetailsFormToRender,
    setDetailsModalFooter,
    setCurrentSelectPeople,
    refreshListOfPeople,
    setIsLoadingListOfPeople,
    setListOfPeople,
    setIsLoadingListOfAllProducts,
    permissions,
    ...props
}) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const prevSearchTerm = useRef(''); // Store the previous search term
    const [currentSelectPeopleIndex, setCurrentSelectPeopleIndex] = useState(-1)
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });
    const [searchParams, setSearchParam] = useState(null)
    const [isAllowedToAddPeople, setIsAllowedToAddPeople] = useState(false);
    const [isAllowedToUpdatePeople, setIsAllowedToUpdatePeople] = useState(false);
    const [isAllowedToManageTrainingImages, setIsAllowedToManageTrainingImages] = useState(false);
    const [isAllowedToManageProductAttachment, setIsAllowedToManageProductAttachment] = useState(false);
    useEffect(() => {
        setIsLoadingListOfPeople(true)
        return () => {
            setListOfPeople({
                formatted_data: [],
                totalRecords: 0,
                totalPages: 0,
                currentPage: 1
            })
        }
    }, [])

    useEffect(() => {
        if (permissions) {
            setIsAllowedToAddPeople(permissions?.ADD_PEOPLE?.enabled);
            setIsAllowedToUpdatePeople(permissions?.UPDATE_PEOPLE?.enabled);
            setIsAllowedToManageTrainingImages(permissions?.MANAGE_TRAINING_IMAGES?.enabled);
            setIsAllowedToManageProductAttachment(permissions?.MANAGE_PRODUCT_ATTACHMENT?.enabled);
        }
    }, [permissions]);

    // useEffect(() => {
    //     fetchListOfPeople({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize });
    // }, [refreshListOfPeople])

    // Debounce the search function to avoid excessive requests
    const debouncedSearch = useCallback(
        debounce((searchTerm, page, pageSize) => {
            setSearchParam({ keyword: searchTerm, page, limit: pageSize })
            fetchListOfPeople({ keyword: searchTerm, page, limit: pageSize });
        }, 1000), // 1-second debounce delay
        []
    );

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, pagination.pageSize);
    }, [searchTerm, pagination.current, debouncedSearch]);

    useEffect(() => {
        if (listOfAllPeople) {
            setData(listOfAllPeople.formatted_data);
            if (currentSelectPeopleIndex != -1)
                setCurrentSelectPeople(listOfAllPeople.formatted_data[currentSelectPeopleIndex])
            setPagination(prev => ({
                ...prev,
                total: listOfAllPeople.totalRecords,
            }));
        }
    }, [listOfAllPeople]);

    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);
    // Define the menu for the dropdown
    const menu = (record, index) => (
        <Menu>
            <Menu.Item key="1" onClick={() => handleEditRecord(record, index)} disabled={!isAllowedToUpdatePeople}>
                Edit Details
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleConfigure(record)} disabled={!isAllowedToManageTrainingImages}>
                Edit Images
            </Menu.Item>
            <Menu.Item key="3" onClick={() => handleConfigureMerch(record, index)} disabled={!isAllowedToManageProductAttachment}>
                Edit Merch
            </Menu.Item>
        </Menu>
    );

    // Define table columns
    const columns = [
        {
            title: 'Profile',
            dataIndex: 'profile_url',
            key: 'profile_url',
            render: (profile_url, record) => {
                const renderProfileImage = (url, name) => {
                    if (url) {
                        return (
                            <img
                                src={url}
                                alt={name}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ''; // Reset the image source if it fails
                                }}
                                style={{ width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover' }}
                            />
                        );
                    } else {
                        const initials = name.split(' ').map(n => n[0]).join('');
                        return (
                            <div style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fff',
                            }}>
                                {initials}
                            </div>
                        );
                    }
                };

                return renderProfileImage(profile_url, record.name);
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return record.personal_url ? (
                    <a href={record.personal_url} target="_blank" rel="noopener noreferrer">
                        {text}
                    </a>
                ) : (
                    text
                );
            },
        },

        {
            title: 'Date of Birth',
            dataIndex: 'dob',
            key: 'dob',
            render: (dob) => dob !== null ? dayjs(dob).tz(localTimeZone).format('MM/DD/YYYY') : null, // Display in local time zone
        },
        {
            title: 'Social Links',
            key: 'social_links',
            render: (text, record) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    {record.twitter_url && (
                        <a href={record.twitter_url} target="_blank" rel="noopener noreferrer">
                            <FaTwitter style={{ color: '#1DA1F2' }} />
                        </a>
                    )}
                    {record.facebook_url && (
                        <a href={record.facebook_url} target="_blank" rel="noopener noreferrer">
                            <FaFacebook style={{ color: '#1877F2' }} />
                        </a>
                    )}
                    {record.instagram_url && (
                        <a href={record.instagram_url} target="_blank" rel="noopener noreferrer">
                            <FaInstagram style={{ color: '#C13584' }} />
                        </a>
                    )}
                </div>
            ),
        },

        {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record, index) => (
                // <div style={{display:"flex",justifyContent:"right"}}>
                // <Dropdown overlay={menu(record, index)} trigger={['click']}>
                //     <CustomButton
                //         title="Edit"
                //         width="100%"
                //         font_size="0.8"
                //         unit="rem"
                //         padding="5px 10px 0px 10px"
                //         colorScheme=""
                //         icon={<FaPen/>}
                //     />
                // </Dropdown>
                // </div>
                <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                    <CustomButton
                        title="Edit Details"
                        width="100%"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        onClick={() => handleEditRecord(record, index)}
                        icon={<FaEdit size={15} />}
                        disabled={!isAllowedToUpdatePeople}
                    />
                    <CustomButton
                        title="Edit Images"
                        width="100%"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        colorScheme="secondary"
                        icon={<FaImages size={15} />}
                        onClick={() => handleConfigure(record)}
                        disabled={!isAllowedToManageTrainingImages}
                    />
                    <CustomButton
                        title="Edit Merch"
                        width="100%"
                        font_size="0.8"
                        unit="rem"
                        padding="5px 10px 0px 10px"
                        onClick={() => handleConfigureMerch(record, index)}
                        icon={<FaEdit size={15} />}
                        disabled={!isAllowedToManageProductAttachment}
                    />
                </div>
            ),
        },
    ];

    const handleConfigure = (record) => {
        setDetailsModalTitle('Configure training images for ' + record.name)
        setCurrentDetailsFormToRender(<FacialRecordConfigurationModal record={record} />)
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true)
    };

    const handleConfigureMerch = (record, index) => {
        // setIsLoadingListOfAllProducts(true)
        setCurrentSelectPeople(record)
        setCurrentSelectPeopleIndex(index)
        setDetailsModalTitle('Configuration merchandise for ' + record.name)
        setCurrentDetailsFormToRender(<FaceProductConfigurationModal record={record} />)
        setDetailsModalFooter(false)
        setIsDetailsModalOpen(true)
    };

    const handleEditRecord = (record, index) => {
        setCurrentSelectPeople(record)
        setCurrentSelectPeopleIndex(index)

        setCurrentSidebarFormToRender(<NewPeopleDataForm
            is_this_for_update={true}
            // record={record}
            searchParams={searchParams}
            setPersonConfigurationSidebarFooter={setPersonConfigurationSidebarFooter}
            setIsPersonConfigurationSidebarOpen={setIsPersonConfigurationSidebarOpen}
            setCurrentSidebarFormToRender={setCurrentSidebarFormToRender}
        />)

        setIsPersonConfigurationSidebarOpen(true)
    };

    const handleDeleteRecord = (record) => {
        // Implement delete record logic
    };


    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    return (
        <div className={styles.person_overview_tab_container}>
            <div className={styles.table_top_action_bar}>
                <div className={styles.search_bar}>
                    <CustomTextfield
                        border_radius="10px"
                        icon={<FaSearch size={15} />}
                        colorScheme="primary"
                        place_holder="Search by name..."
                        font_size="0.7"
                        unit="rem"
                        padding="10px"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                {/* Remove the Add new person button */}
            </div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total, // Total number of records from API
                    showSizeChanger: false
                }}
                bordered
                onChange={handleTableChange}
                loading={isLoadingListOfAllPeople}
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfAllPeople: getListOfPeople(state),
    isLoadingListOfAllPeople: getIsLoadingListOfPeople(state),
    refreshListOfPeople: getRefreshListOfPeople(state),
    permissions: getPermissions(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfPeople: (formData) => dispatch(fetchListOfPeople(formData)),
    setCurrentSelectPeople: (formData) => dispatch(setCurrentSelectPeople(formData)),
    setIsLoadingListOfPeople: (data) => dispatch(setIsLoadingListOfPeople(data)),
    setListOfPeople: (data) => dispatch(setListOfPeople(data)),
    setIsLoadingListOfAllProducts: (data) => dispatch(setIsLoadingListOfAllProducts(true))
});

export default connect(mapState, mapDispatchToProps)(PeopleOverviewTab);
