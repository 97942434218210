import styled from 'styled-components';
import { Select } from 'antd';

const { Option } = Select;

const SelectStyleOne = styled(Select)`
  // background-color: ${(props) => props.backgroundColor || '#fff'};
  color: ${(props) => props.color || '#333'};
  font-size: ${(props) => props.fontSize || '16px'};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  border-radius: ${(props) => props.borderRadius || '5px'};
  padding: ${(props) => props.padding || '10px'};
  margin-top: ${(props) => props.marginTop || '0'};
  // border: 1px solid ${(props) => props.borderColor || '#d9d9d9'};
  width: ${(props) => props.width || '100%'};
  
  .ant-select-selector {
    // background-color: ${(props) => props.backgroundColor || '#fff'};
    color: ${(props) => props.color || '#333'};
    border-radius: ${(props) => props.borderRadius || '5px'};
    padding: ${(props) => props.padding || '10px'};
    border-color: ${(props) => props.borderColor || '#d9d9d9'} !important;
    
    &:focus,
    &:hover {
      border-color: ${(props) => props.hoverBorderColor || '#1890ff'} !important;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  @media (max-width: 600px) {
    font-size: ${(props) => props.mobileFontSize || '14px'};
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: ${(props) => props.tabletFontSize || '15px'};
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: ${(props) => props.laptopFontSize || '16px'};
  }

  @media (min-width: 1201px) {
    font-size: ${(props) => props.macbook13FontSize || '17px'};
  }
`;

export { SelectStyleOne, Option };
