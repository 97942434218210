import toast from "react-hot-toast";
import { UsersAPI, ProfileAPI } from "../../../api";
import { 
    setIsLoadingListOfUsers, setListOfUsers, 
    setIsLoadingListOfUserRoles, setListOfUserRoles,
    setProfileDetails, setIsLoadingProfileDetails,
    setBankDetails, setIsLoadingBankDetails 
} from "./slice";

let abortController_fetchListOfUsers = null;
let abortController_fetchListOfUserRoles = null;
let abortController_fetchProfileDetails = null;
let abortController_fetchBankDetails = null;

const fetchListOfUsers = (data) => (dispatch) => {
    if (abortController_fetchListOfUsers) {
        abortController_fetchListOfUsers.abort();
    }

    abortController_fetchListOfUsers = new AbortController();
    const { signal } = abortController_fetchListOfUsers;
    dispatch(setIsLoadingListOfUsers(true));
    UsersAPI.getAllUsers(data, signal)
        .then((response) => {
            dispatch(setListOfUsers(response.data));
            dispatch(setIsLoadingListOfUsers(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingListOfUsers(false));
        });
};

const fetchListOfUserRoles = (data) => (dispatch) => {
    if (abortController_fetchListOfUserRoles) {
        abortController_fetchListOfUserRoles.abort();
    }

    abortController_fetchListOfUserRoles = new AbortController();
    const { signal } = abortController_fetchListOfUserRoles;
    dispatch(setIsLoadingListOfUserRoles(true));
    UsersAPI.getAllUserRoles(data, signal)
        .then((response) => {
            dispatch(setListOfUserRoles(response.data));
            dispatch(setIsLoadingListOfUserRoles(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingListOfUserRoles(false));
        });
};

const updateUserRole = (data) => (dispatch) => {
    UsersAPI.updateUserRole(data)
        .then((response) => {
            toast.success("User role updated successfully");
            dispatch(fetchListOfUsers({})); // Refresh the list of users
        })
        .catch((error) => {
            console.error(error);
            toast.error("Failed to update user role");
        });
};

const fetchProfileDetails = (data) => (dispatch) => {
    if (abortController_fetchProfileDetails) {
        abortController_fetchProfileDetails.abort();
    }

    abortController_fetchProfileDetails = new AbortController();
    const { signal } = abortController_fetchProfileDetails;
    dispatch(setIsLoadingProfileDetails(true));
    ProfileAPI.fetch_basic_profile_details(data, signal)
        .then((response) => {
            dispatch(setProfileDetails(response.data));
            dispatch(setIsLoadingProfileDetails(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingProfileDetails(false));
        });
};

const fetchBankDetails = (data) => (dispatch) => {
    if (abortController_fetchBankDetails) {
        abortController_fetchBankDetails.abort();
    }

    abortController_fetchBankDetails = new AbortController();
    const { signal } = abortController_fetchBankDetails;
    dispatch(setIsLoadingBankDetails(true));
    ProfileAPI.fetch_bank_profile_details(data, signal)
        .then((response) => {
            dispatch(setBankDetails(response.data));
            dispatch(setIsLoadingBankDetails(false));
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingBankDetails(false));
        });
};

const updateBasicDetails = (data) => (dispatch) => {
    dispatch(setIsLoadingProfileDetails(true));
    ProfileAPI.update_basic_details(data)
        .then((response) => {
            toast.success("Basic details updated successfully");
            dispatch(setProfileDetails(response.data)); 
            dispatch(setIsLoadingProfileDetails(false));
        })
        .catch((error) => {
            toast.error("Failed to update basic details");
            dispatch(setIsLoadingProfileDetails(false));
        });
};

const updateBankDetails = (data) => (dispatch) => {
    dispatch(setIsLoadingBankDetails(true));
    ProfileAPI.update_bank_details(data)
        .then((response) => {
            toast.success("Bank details updated successfully");
            dispatch(setBankDetails(response.data));
            dispatch(setIsLoadingBankDetails(false));
        })
        .catch((error) => {
            toast.error("Failed to update bank details");
            dispatch(setIsLoadingBankDetails(false));
        });
};

export {
    fetchListOfUsers,
    fetchListOfUserRoles,
    updateUserRole,
    fetchProfileDetails,
    fetchBankDetails,
    updateBasicDetails,
    updateBankDetails
}