import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import styles from '../../styles.module.scss';
import { FaSearch, FaCog, FaFunnelDollar } from 'react-icons/fa';
import { CustomTextfield } from '../../../../../shared/ui/text-field';
import { CustomButton } from '../../../../../shared/ui/button';
import FaceProductConfigurationModal from './FaceProductConfigurationModal';


import debounce from 'lodash.debounce'; // Import lodash debounce function

import { getAllVendorsNames } from '../../../logic/vendor/selectors';
import { CustomDropDown } from '../../../../../shared/ui/dropdown';
import ListOfAllVendors from './ListOfAllVendors';
import { setIsLoadingListOfDeals, setListOfDeals } from '../../../logic/deals/slice';
import { fetchListOfAllDeals } from '../../../logic/deals/actions';
import { getIsLoadingListOfDeals, getListOfDeals } from '../../../logic/deals/selectors';
import ic_voucher from '../../../../../assets/icons/voucher.png'
import ic_promotion from '../../../../../assets/icons/loudspeaker.png'
import { sortedCountriesGroupedBySubRegionForFilter } from '../../../../../shared/utils/regions_with_countries';

const DealsOverview = ({
    listOfDeals,
    fetchListOfAllDeals,
    setIsDetailsModalOpen,
    setDetailsModalTitle,
    setCurrentDetailsFormToRender,
    setDetailsModalFooter,
    isLoadingListOfDeals,
    setIsLoadingListOfDeals,
    setListOfDeals,
    ...props
}) => {
    const [pagination, setPagination] = useState({
        current: 1, // current page
        pageSize: 10, // number of items per page
    });

    const prevSearchTerm = useRef(''); // Store the previous search term
    const [selectedPlatform, setSelectedPlatform] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [listOfSelectedVendorIds, setListOfSelectedVendorIds] = useState([])
    const [listSearchParams, setListSearchParams] = useState({})
    const [expandedRows, setExpandedRows] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [listOfPlatforms, setListOfPlatforms] = useState([{ value: "default", label: "All platforms" }])
    const [selectedCountry, setSelectedCountry] = useState(['all']);
    const [selectedRegion, setSelectedRegion] = useState('all');


    useEffect(() => {
        setIsLoadingListOfDeals(true)
        return () => {
            setListOfDeals({
                formatted_data: [],
                totalRecords: 0,
                totalPages: 0,
                currentPage: 1
            })
        }
    }, [])

    // Debounce the search function to avoid excessive requests
    const debouncedSearch = useCallback(
        debounce((searchTerm, page, pageSize, selectedPlatform, listOfSelectedVendorIds, selectedType, selectedCountry) => {
            fetchListOfAllDeals({
                keyword: searchTerm,
                page, limit:
                    pageSize,
                platformIds: [],
                // platformIds: selectedPlatform[0] === 'all' ? [] : selectedPlatform,
                vendorIds: listOfSelectedVendorIds,
                types: selectedType == '' ? [] : [selectedType],
                regions: selectedCountry[0] == 'all' ? [] : selectedCountry
            });
        }, 1000), // 1-second debounce delay
        []
    );

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, pagination.pageSize, selectedPlatform, listOfSelectedVendorIds, selectedType, selectedCountry);
    }, [searchTerm, pagination.current, pagination.pageSize, debouncedSearch, listOfSelectedVendorIds]);

    useEffect(() => {
        if (listOfDeals) {
            setData(listOfDeals.formatted_data);
            setPagination(prev => ({
                ...prev,
                total: listOfDeals.totalRecords,
            }));
        }
    }, [listOfDeals]);

    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={record.type === 'voucher' ? ic_voucher : ic_promotion}
                        alt="deal icon"
                        style={{ width: '20px', height: '20px', marginRight: '10px' }}
                    />
                    <a
                        href={record.affiliate_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontWeight: '' }}
                    >
                        {text}
                    </a>
                </div>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => {
                const isExpanded = expandedRows.includes(record.id);
                return (
                    <div>
                        {isExpanded ? text : `${text.slice(0, 50)}...`}
                        {text.length > 50 && (
                            <button
                                onClick={() => handleReadMore(record.id)}
                                style={{
                                    marginLeft: '10px',
                                    color: 'blue',
                                    border: 'none',
                                    background: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                {isExpanded ? 'Read Less' : 'Read More'}
                            </button>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (startDate) => (
                <span>{new Date(startDate).toLocaleDateString()}</span>
            ),
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (endDate) => {
                const isExpired = new Date(endDate) < new Date();
                return (
                    <div style={{ display: 'flex', alignItems: 'center', color: isExpired ? 'red' : 'inherit' }}>
                        <span style={{ textDecoration: isExpired ? 'line-through' : 'none' }}>
                            {new Date(endDate).toLocaleDateString()}
                        </span>
                        {isExpired && (
                            <span style={{ marginLeft: '10px', fontSize: '12px' }}>
                                (Expired)
                            </span>
                        )}
                    </div>
                );
            },
        },
    ];

    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    const handleSelectVendorsForFiler = () => {
        setCurrentDetailsFormToRender(<ListOfAllVendors
            setListOfSelectedVendorIds={setListOfSelectedVendorIds}
            listOfSelectedVendorIds={listOfSelectedVendorIds}
            defaultSelectedCountry={selectedCountry}
            defaultSelectedPlatform={selectedPlatform}
            defaultSelectedRegion={selectedRegion}
        />);
        setDetailsModalTitle("Select vendors for filter");
        setIsDetailsModalOpen(true);
        setDetailsModalFooter(false)
    }

    const handleReadMore = (id) => {
        setExpandedRows((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };

    const handleTypeChange = value => {
        setSelectedType(value);
        setPagination(prev => ({ ...prev, current: 1 })); // Reset to first page
        setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platformIds: [], types: value == '' ? [] : [value], regions: selectedCountry[0] == 'all' ? [] : selectedCountry })
        fetchListOfAllDeals({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platformIds: [], vendorIds: listOfSelectedVendorIds, types: value == '' ? [] : [value], regions: selectedCountry[0] == 'all' ? [] : selectedCountry });
    };

    const handleRegionChange = (region) => {
        setSelectedRegion(region);
        if (region === 'all') {
            // Collect all country codes from all regions
            // const allCountryCodes = sortedCountriesGroupedBySubRegionForFilter.flatMap(r => r.countries.map(country => country.isoCode));
            setSelectedCountry(['all']);
            setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platformIds: [], vendorIds: listOfSelectedVendorIds, types: selectedType == '' ? [] : [selectedType], regions: [] })
            fetchListOfAllDeals({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platformIds: [], vendorIds: listOfSelectedVendorIds, types: selectedType == '' ? [] : [selectedType], regions: [] });

        } else {
            // Find the selected region object
            const selectedRegionObj = sortedCountriesGroupedBySubRegionForFilter.find(r => r.subRegion === region);

            // Update selected countries based on the selected region
            if (selectedRegionObj) {
                const countryCodes = selectedRegionObj.countries.map(country => country.isoCode);
                setSelectedCountry(countryCodes);
                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platformIds: [], vendorIds: listOfSelectedVendorIds, types: selectedType == '' ? [] : [selectedType], regions: countryCodes })
                fetchListOfAllDeals({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platformIds: [], vendorIds: listOfSelectedVendorIds, types: selectedType == '' ? [] : [selectedType], regions: countryCodes });
            } else {
                setSelectedCountry([]); // Reset if no match
                setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platformIds: [], vendorIds: listOfSelectedVendorIds, types: selectedType == '' ? [] : [selectedType], regions: [] })
                fetchListOfAllDeals({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platformIds: [], vendorIds: listOfSelectedVendorIds, types: selectedType == '' ? [] : [selectedType], regions: [] });
            }
        }

        setPagination(prev => ({
            ...prev,
            current: 1
        }));

        // Reset the Country dropdown to "All Countries"
        // setSelectedCountry(['all']);
    };

    return (
        <div className={styles.deal_overview_container}>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total, // Total number of records from API
                    showSizeChanger: false
                }}
                loading={isLoadingListOfDeals}
                onChange={handleTableChange}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.left_side}>
                            <div className={styles.platform_dropdown}>
                                <CustomDropDown
                                    defaultValue=""
                                    options={[
                                        { value: '', label: 'All Types' },
                                        { value: 'promotion', label: 'Promotion' },
                                        { value: 'voucher', label: 'Voucher' },
                                    ]}
                                    onChange={handleTypeChange}
                                />
                            </div>
                            <div className={styles.region_dropdown}>
                                <CustomDropDown
                                    enable_search={true}

                                    defaultValue='all'
                                    options={[
                                        { value: 'all', label: 'All Regions' },
                                        ...sortedCountriesGroupedBySubRegionForFilter.map((region) => ({
                                            value: region?.subRegion,
                                            label: region?.subRegion
                                        }))
                                    ]}
                                    onChange={handleRegionChange}
                                />
                            </div>
                            <div className={styles.country_dropdown}>
                                <CustomDropDown
                                    enable_search={true}
                                    defaultValue='all'

                                    // options={[
                                    //     { value: 'all', label: 'All Countries' },
                                    //     ...(selectedRegion !== 'all'
                                    //         ? sortedCountriesGroupedBySubRegionForFilter
                                    //             .find((region) => region.subRegion === selectedRegion)?.countries
                                    //             .map((country) => ({
                                    //                 value: country.isoCode,
                                    //                 label: country.name,
                                    //             })) || []
                                    //         : sortedCountriesGroupedBySubRegionForFilter.flatMap((region) =>
                                    //             region.countries.map((country) => ({
                                    //                 value: country.isoCode,
                                    //                 label: country.name,
                                    //             }))
                                    //         )),
                                    // ]}
                                    options={[
                                        { value: 'all', label: 'All Countries' },
                                        ...(selectedRegion !== 'all'
                                            ? // If a specific region is selected, filter by that region and map countries
                                            sortedCountriesGroupedBySubRegionForFilter
                                                .find((region) => region.subRegion === selectedRegion)?.countries
                                                .map((country) => ({
                                                    value: country.isoCode,
                                                    label: country.name,
                                                })) || []
                                            : // Otherwise, flatten all regions and sort globally
                                            sortedCountriesGroupedBySubRegionForFilter
                                                .flatMap((region) => region.countries) // Flatten all countries
                                                .sort((a, b) => a.name.localeCompare(b.name)) // Sort globally by name
                                                .map((country) => ({
                                                    value: country.isoCode,
                                                    label: country.name,
                                                }))
                                        ),
                                    ]}
                                    onChange={(value) => {
                                        setPagination(prev => ({
                                            ...prev,
                                            current: 1
                                        }));
                                        if (value === 'all') {
                                            let countries;

                                            if (selectedRegion !== 'all') {
                                                const selectedRegionData = sortedCountriesGroupedBySubRegionForFilter.find(region => region.subRegion === selectedRegion);

                                                countries = selectedRegionData
                                                    ? selectedRegionData.countries.map(country => (country.isoCode))
                                                    : [];
                                            } else {
                                                countries = sortedCountriesGroupedBySubRegionForFilter.flatMap(region =>
                                                    region.countries.map(country => (country.isoCode))
                                                );
                                            }
                                            setSelectedCountry(countries)

                                            setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: countries })
                                            fetchListOfAllDeals({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: countries });

                                        } else {
                                            setSelectedCountry([value])
                                            setListSearchParams({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: [value] })
                                            fetchListOfAllDeals({ keyword: searchTerm, page: pagination.current, limit: pagination.pageSize, platform: selectedPlatform, country_code: [value] });
                                        }
                                    }}
                                />
                            </div>

                            <div className={styles.vendors_filter}>
                                <CustomButton
                                    title={`Filter by vendors (${listOfSelectedVendorIds?.length})`}
                                    width="100%"
                                    font_size="0.7"
                                    unit="rem"
                                    padding="5px 5px 0px 5px"
                                    onClick={() => handleSelectVendorsForFiler()}
                                    icon={<FaFunnelDollar size={15} />}
                                />
                            </div>
                        </div>
                        <div className={styles.right_side}>
                            <div className={styles.search_bar}>
                                <CustomTextfield
                                    border_radius="10px"
                                    icon={<FaSearch size={15} />}
                                    colorScheme="primary"
                                    place_holder="Search name..."
                                    font_size="0.7"
                                    unit="rem"
                                    padding="10px"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                )}
                rowKey="id" // Use 'id' as the unique key for each row
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfDeals: getListOfDeals(state),
    isLoadingListOfDeals: getIsLoadingListOfDeals(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfAllDeals: (params) => dispatch(fetchListOfAllDeals(params)),
    setIsLoadingListOfDeals: (params) => dispatch(setIsLoadingListOfDeals(params)),
    setListOfDeals: (params) => dispatch(setListOfDeals(params))
});

export default connect(mapState, mapDispatchToProps)(DealsOverview);
