
import toast from "react-hot-toast";
import { PeopleAPI } from "../../../api";
import { setIsLoadingListOfPeople, setIsLoadingListOfTrainingImages, setListOfPeople, setListOfTrainingImages } from "./slice";
let abortController_fetchListOfPeople = null;  // Declare globally within the file

const fetchListOfPeople = (data) => (dispatch) => {
    if (abortController_fetchListOfPeople) {
        abortController_fetchListOfPeople.abort();
    }

    // Create a new AbortController
    abortController_fetchListOfPeople = new AbortController();
    const { signal } = abortController_fetchListOfPeople;

    dispatch(setIsLoadingListOfPeople(true))
    PeopleAPI.getAllPeople(data,signal)
        .then((response) => {

            let formatted_data = response?.data?.map((record) => {
                return {
                    id: record.id,
                    name: record.name,
                    dob: record.dob,
                    accomplishments: record.accomplishments,
                    twitter_url: record.twitter_url,
                    instagram_url: record.instagram_url,
                    facebook_url: record.facebook_url,
                    created_date: record.created_date,
                    profile_url: record.image_url,
                    personal_url: record.personal_url,
                }
            })

            dispatch(setListOfPeople({
                formatted_data: formatted_data,
                totalRecords: response.totalRecords,
                totalPages: response.totalPages,
                currentPage: response.currentPage
            }))

            dispatch(setIsLoadingListOfPeople(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfPeople(false))
        })
};


const addPeopleRecord = (data) => (dispatch) => {
    const addPromise = PeopleAPI.addNewPeople(data);

    // Use toast.promise for cleaner toast handling
    toast.promise(addPromise, {
        loading: 'Adding record...',
        success: 'Successfully added the record!',
        error: 'Error while adding the record',
    });

    // Return the promise from the API call
    return addPromise
        .then((response) => {
     
            dispatch(fetchListOfPeople({})); // Dispatch the action to fetch the updated list
            return response; // Return the response for further chaining if needed
        })
        .catch((error) => {
            console.error('addPeopleRecord error:', error);
            return Promise.reject(error); // Reject the promise with the error
        });
};



const updatePeopleRecord = (data,searchParams) => (dispatch) => {
    const updatePromise = PeopleAPI.updatePeopleRec(data);

    // Use toast.promise for cleaner toast handling
    toast.promise(updatePromise, {
        loading: 'Updating record...',
        success: 'Successfully updated the record!',
        error: 'Error while updating the record',
    });

    return updatePromise
        .then((response) => {
          
            // Dispatch any necessary actions if needed
            dispatch(fetchListOfPeople(searchParams))
            return response;
        })
        .catch((error) => {
            console.error('updatePeopleRecord error:', error);
            return Promise.reject(error);
        });
};

const fetchListOfAllTrainingImages = (data) => (dispatch) => {
    dispatch(setIsLoadingListOfTrainingImages(true))
   
    dispatch(setListOfTrainingImages([]))
    PeopleAPI.getAllTrainingImages(data)
        .then((response) => {
         
            let formatted_data = response?.data?.map((record) => {
                return {
                    image_url: record.image_url,
                    title: record.title
                }
            })

            // dispatch(setListOfPeople({
            //     formatted_data: formatted_data,
            //     totalRecords: response.totalRecords,
            //     totalPages: response.totalPages,
            //     currentPage: response.currentPage
            // }))

            dispatch(setListOfTrainingImages(formatted_data))
            dispatch(setIsLoadingListOfTrainingImages(false))

        })
        .catch((error) => {
            console.error(error)
            dispatch(setIsLoadingListOfTrainingImages(false))
        })
};

const deleteFacialTrainingImage = (data) => (dispatch) => {

    PeopleAPI.deleteTrainingImage(data)
        .then((response) => {
          
            toast.success('Successfully deleted...')
            dispatch(fetchListOfAllTrainingImages({ id: data?.id }))
        })
        .catch((error) => {
            console.error(error)
            toast.error('Error while adding people')
        })
};

const configureFacialTrainingImage = (data) => (dispatch) => {
  

    const configurePromise = PeopleAPI.configureTrainingImages(data);

    // Use toast.promise for cleaner toast handling
    toast.promise(configurePromise, {
        loading: 'Uploading image...',
        success: 'Successfully uploaded!',
        error: 'Error while uploading image',
    });

    return configurePromise
        .then((response) => {
           
            dispatch(fetchListOfAllTrainingImages({ id: data?.id }));
            return response;
        })
        .catch((error) => {
            console.error('configureFacialTrainingImage error:', error);
            return Promise.reject(error);
        });
};


export {
    fetchListOfPeople,
    addPeopleRecord,
    updatePeopleRecord,
    fetchListOfAllTrainingImages,
    deleteFacialTrainingImage,
    configureFacialTrainingImage
}