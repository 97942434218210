import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'product',
    initialState: {
        listOfFacialRecognitions: [],
        isLoadingListOfFacialRecognitions: false,
        listOfAllProductsForOverview: [],
        listOfProductsToExport: [],
        isLoadingListOfProductsToExport: false,
        isLoadingListOfAllProducts: false,
        isLoadingListOfAllProductsForOverview: false,
        listOfAllSelectedProducts: [],
        isLoadingListOfAllSelectedProducts: false,
        currentSelectedProduct: null,
        refreshProductForms: false,
        queryParams: {}, // Add this line
    },
    reducers: {
        setListOfFacialRecognitions: (state, action) => {
            state.listOfFacialRecognitions = action.payload;
        },

        setCurrentSelectedProduct: (state, action) => {
            console.log('setCurrentSelectedProduct:', action.payload)
            state.currentSelectedProduct = action.payload;
        },
        setListOfAllProducts: (state, action) => {
            state.listOfAllProducts = action.payload;
        },
        setListOfAllProductsForOverview: (state, action) => {
            state.listOfAllProductsForOverview = action.payload;
        },
        setIsLoadingListOfAllProductsForOverview: (state, action) => {
            state.isLoadingListOfAllProductsForOverview = action.payload;
        },
        setListOfAllSelectedProducts: (state, action) => {
            state.listOfAllSelectedProducts = action.payload;
        },
        setIsLoadingListOfAllProducts: (state, action) => {
            state.isLoadingListOfAllProducts = action.payload;
        },
        setIsLoadingListOfFacialRecognitions: (state, action) => {
            state.isLoadingListOfFacialRecognitions = action.payload;
        },

        setIsLoadingListOfProductsToExport: (state, action) => {
            state.isLoadingListOfProductsToExport = action.payload;
        },
        setListOfProductsToExport: (state, action) => {
            state.listOfProductsToExport = action.payload;
        },

        setRefreshProductForms: (state, action) => {
            state.refreshProductForms = !state.refreshProductForms
        },
        setQueryParams: (state, action) => { // Add this reducer
            state.queryParams = action.payload;
        },

    },
});

export const {
    setListOfFacialRecognitions,
    setListOfAllProducts,
    setListOfAllSelectedProducts,
    setIsLoadingListOfAllProducts,
    setIsLoadingListOfFacialRecognitions,
    setListOfAllProductsForOverview,
    setIsLoadingListOfAllProductsForOverview,
    setCurrentSelectedProduct,
    setIsLoadingListOfProductsToExport,
    setListOfProductsToExport,
    setRefreshProductForms,
    setQueryParams // Add this line
} = appSlice.actions;

export default appSlice.reducer;
