import { connect } from "react-redux";
import styles from '../styles.module.scss'
import Header from "./general-components/Header";
import { CustomButton } from "../../../../shared/ui/button";
import ConfigurationSidebar from "./general-components/ConfigurationSidebar";
import { useEffect, useState } from "react";
import TabsRender from "./general-components/TabsRender";
import ChannelOverviewTab from "./channel-components/ChannelOverviewTab";
import ChannelVisualizationTab from "./channel-components/ChannelVisualizationTab";
import ChannelDataForm from "./channel-components/ChannelDataForm";
import DetailsModal from "./general-components/DetailsModal";
import { fetchListOfAllChannels } from "../../logic/channels/actions";
import { setCurrentChannelRecord, setStopPlayingStream } from "../../logic/channels/slice";
import { getStopPlayingStream } from "../../logic/channels/selectors";
import { getPermissions } from "../../logic/permissions/selectors";

const ChannelScreen = ({
    // fetchListOfAllChannels,
    setStopPlayingStream,
    stop_playing_stream,
    setCurrentChannelRecord,
    permissions,
    ...props }) => {

    const [isChannelConfigurationSidebarOpen, setIsChannelConfigurationSidebarOpen] = useState(false)
    const [channelConfigurationSidebarFooter, setChannelConfigurationSidebarFooter] = useState(null)
    const [currentSidebarFormToRender, setCurrentSidebarFormToRender] = useState(null)
    const [listOfTabs, setListOfTabs] = useState([]);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
    const [detailsModalTitle, setDetailsModalTitle] = useState("Vender details")
    const [currentDetailsFormToRender, setCurrentDetailsFormToRender] = useState(null)
    const [detailsModalFooter, setDetailsModalFooter] = useState(null)
    const [modalWidth, setModalWidth] = useState("60%")

    const [isAllowedToCreateChannel, setIsAllowedToCreateChannel] = useState(false)

    useEffect(() => {
        console.log('permissions?.CREATE_CHANNEL?.enabled', permissions?.CREATE_CHANNEL?.enabled)
        if (permissions) {
            setIsAllowedToCreateChannel(permissions?.CREATE_CHANNEL?.enabled)
        }
    }, [permissions])

    useEffect(() => {
        let temp_tabs = [
            {
                label: "Overview",
                screen: <ChannelOverviewTab
                    setModalWidth={setModalWidth}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setDetailsModalFooter={setDetailsModalFooter}
                    setCurrentSidebarFormToRender={setCurrentSidebarFormToRender}
                    setIsChannelConfigurationSidebarOpen={setIsChannelConfigurationSidebarOpen}
                    setChannelConfigurationSidebarFooter={setChannelConfigurationSidebarFooter}
                />
            },
            // {
            //     label: "Visualization",
            //     screen: <ChannelVisualizationTab />
            // }
        ]
        setListOfTabs(temp_tabs)
    }, [])

    const handleCreateChannel = (e) => { }

    const handleCancelCreateChannel = (e) => {
        setIsChannelConfigurationSidebarOpen(false)
    }

    const handleCloseSidebar = (e) => {
        setIsChannelConfigurationSidebarOpen(false)
    }

    const handleTabOnChange = (e) => { }

    // Modal

    const handleOkayChannelVendorDetailModal = (e) => {
        setIsDetailsModalOpen(false)
    }

    const handleCancelChannelVendorDetailModal = (e) => {
        setIsDetailsModalOpen(false)
    }

    const handleCloseChannelVendorDetailModal = (e) => {
        setIsDetailsModalOpen(false)
        setStopPlayingStream(!stop_playing_stream)
    }

    return <div className={styles.channels_container}>
        <div className={styles.header_container}>
            <Header title='Channels' />
            <div className={styles.actions_container}>
                <CustomButton
                    title="Add new channel"
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    disabled={!isAllowedToCreateChannel} // Change this line
                    onClick={(e) => {
                        setCurrentSidebarFormToRender(<ChannelDataForm
                            setIsChannelConfigurationSidebarOpen={setIsChannelConfigurationSidebarOpen}
                            setChannelConfigurationSidebarFooter={setChannelConfigurationSidebarFooter}
                        />)
                        setIsChannelConfigurationSidebarOpen(true)
                        setCurrentChannelRecord(null)
                    }}
                />
            </div>
        </div>
        <div className={styles.body_container}>
            <div className={styles.tabs_container}>
                <TabsRender list_of_tabs={listOfTabs} handleOnChange={handleTabOnChange} />
            </div>
        </div>
        {
            isChannelConfigurationSidebarOpen && <div className={styles.configuration_side_container}>
                <ConfigurationSidebar
                    title="Configure Channel"
                    handleClose={handleCloseSidebar}
                    handleOkay={handleCreateChannel}
                    handleCancel={handleCancelCreateChannel}
                    okay_button_title='Create channel'
                    cancel_button_title='Cancel'
                    footer={channelConfigurationSidebarFooter}
                    body={currentSidebarFormToRender}
                    setDetailsModalTitle={setDetailsModalTitle}
                    setDetailsModalFooter={setDetailsModalFooter}
                    setIsDetailsModalOpen={setIsDetailsModalOpen}
                    setCurrentDetailsFormToRender={setCurrentDetailsFormToRender}
                />
            </div>
        }
        <div className={styles.configuration_modal_container}>
            <DetailsModal
                width={modalWidth}
                title={detailsModalTitle}
                handleClose={handleCloseChannelVendorDetailModal}
                handleOkay={handleOkayChannelVendorDetailModal}
                handleCancel={handleCancelChannelVendorDetailModal}
                okay_button_title='Okay'
                cancel_button_title='Cancel'
                body={currentDetailsFormToRender}
                isModalOpen={isDetailsModalOpen}
                footer={detailsModalFooter}
            />
        </div>

    </div>
}

const mapState = (state) => ({
    permissions: getPermissions(state),
    stop_playing_stream: getStopPlayingStream(state)
})

const mapDispatchToProps = (dispatch) => ({
    setStopPlayingStream: (data) => dispatch(setStopPlayingStream(data)),
    setCurrentChannelRecord: (data) => dispatch(setCurrentChannelRecord(data)),
});


export default connect(mapState, mapDispatchToProps)(ChannelScreen)