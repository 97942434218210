import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/dashboard-styles/main.css";
import "./assets/dashboard-styles/responsive.css";
import { store } from './store/store';
import { App } from './modules/app';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
    <Toaster
      position="top-right"
      reverseOrder={false}
    />
    {/* <ToastContainer limit={1} position="bottom-right"   autoClose={1000} /> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
