import { BrowserRouter } from "react-router-dom"
import { Route, Routes as ReactRoutes } from 'react-router'
import { AuthenticationPage } from "../auth"
import { RouteAuthenticator } from "./RouteAuthenticator"
import { DashboardPage } from "../dashboard"
import { PageNotAllowed } from "../not-allowed"

export const Routes = () => {
    return <BrowserRouter>
        <ReactRoutes>
            <Route path="/dashboard/:sub_screen" element={<RouteAuthenticator authenticationPath="/auth" path="/dashboard" component={<DashboardPage />} />} />
            <Route path="/" element={<RouteAuthenticator authenticationPath="/auth" path="/" component={<AuthenticationPage />} />} />
            <Route path="/not-allowed" element={<PageNotAllowed />} />
            <Route path="*" element={<RouteAuthenticator authenticationPath="/auth" path="/auth" component={<AuthenticationPage />} />} />
        </ReactRoutes>
    </BrowserRouter>
}