

import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Table, Checkbox, Row, Col } from "antd";
import styles from './modal.module.scss';
import { fetchListOfListOfBlacklistViatorDestinationLinkedToChannel, updateBlacklistViatorDestinationStatus } from "../../../logic/channels/actions";
import { CustomTextfield } from "../../../../../shared/ui/text-field";
import { FaSearch } from "react-icons/fa";
import debounce from 'lodash.debounce';
import { getIsLoadingListOfBlacklistDestinationLinkedToChannel, getListOfBlacklistDestinationLinkedToChannel } from "../../../logic/channels/selectors";

const ListOfAllBlacklistViatorDestination = ({
    updateBlacklistViatorDestinationStatus,
    fetchListOfListOfBlacklistViatorDestinationLinkedToChannel,
    listOfBlackListViatorDestination,
    isLoadingBlackListViatorDestination,
    record,
    refresh,
    ...props
}) => {
    const [blackListViatorDestination, setBlackListViatorDestination] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 60,
    });

    const prevSearchTerm = useRef('');

    const debouncedSearch = useCallback(
        debounce((searchTerm, page, record) => {
            fetchListOfListOfBlacklistViatorDestinationLinkedToChannel({
                keyword: searchTerm, page: page, limit: 60, channel_id: record?.channel_id
            });
        }, 1000),
        []
    );

    useEffect(() => {
        if (record) {
            fetchListOfListOfBlacklistViatorDestinationLinkedToChannel({
                keyword: "", page: 1, limit: 60, channel_id: record?.channel_id
            });
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
        }
    }, [record?.channel_id, refresh]);

    useEffect(() => {
        debouncedSearch(searchTerm, pagination.current, record);
    }, [searchTerm, pagination.current, debouncedSearch, record?.channel_id]);

    useEffect(() => {
        if (listOfBlackListViatorDestination) {
            setBlackListViatorDestination(listOfBlackListViatorDestination?.formatted_data || []);
            setPagination(prev => ({
                ...prev,
                total: listOfBlackListViatorDestination.totalRecords,
                page: listOfBlackListViatorDestination.currentPage,
                pageSize: 60,
            }));
        }
    }, [listOfBlackListViatorDestination]);


    useEffect(() => {
        // Check if the search term has actually changed
        if (prevSearchTerm.current !== searchTerm) {
            setPagination((prev) => ({
                ...prev,
                current: 1,
            }));
            prevSearchTerm.current = searchTerm; // Update ref to new search term
        }
    }, [searchTerm]);
    const handleToggle = (selectedFacialRecognition) => {
        setBlackListViatorDestination(prevDestinations =>
            prevDestinations.map(destination =>
                destination.destination_id === selectedFacialRecognition?.destination_id
                    ? { ...destination, enabled: !destination.enabled }
                    : destination
            )
        );

        updateBlacklistViatorDestinationStatus({
            channel_id: record?.channel_id,
            destination_id: selectedFacialRecognition?.destination_id,
            checked: !selectedFacialRecognition.enabled
        });
    };

    const renderDestinationsInColumns = () => {
        const columns = [[], [], []]; // Initialize 4 columns

        blackListViatorDestination?.forEach((destination, index) => {
            const colIndex = index % 3; // Distribute records across 4 columns
            columns[colIndex].push(destination);
        });

        return (
            <Row gutter={[16, 16]}>
                {columns.map((column, colIndex) => (
                    <Col key={colIndex} span={8}> {/* Each column takes 1/4th of the row */}
                        {column.map((destination) => (
                            <div key={destination.destination_id} style={{ display: "flex", alignItems: "center", marginBottom: "0px" }}>
                                <div>

                                    <Checkbox
                                        disabled={!record.show_viator_tickets}

                                        checked={destination.enabled}
                                        onChange={() => handleToggle(destination)}
                                        style={{ marginRight: '10px' }}
                                    />
                                </div>

                                <div style={{ color: "black" }}>{destination.destination_name}</div>
                            </div>
                        ))}
                    </Col>
                ))}
            </Row>
        );
    };

    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    return (
        <div className={styles.list_of_black_list_destinations_container}>
            <Table
                columns={[{ title: "", dataIndex: "destination_name" }]} // Empty column header
                dataSource={[]} // Table will be populated with custom row rendering
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: false
                }}
                onChange={handleTableChange}
                bordered
                loading={isLoadingBlackListViatorDestination}
                title={() => (
                    <div className={styles.table_top_action_bar}>
                        <div className={styles.search_bar}>
                            <CustomTextfield
                                border_radius="10px"
                                icon={<FaSearch size={15} />}
                                colorScheme="primary"
                                place_holder="Search i.e  Tampere "
                                font_size="0.7"
                                unit="rem"
                                padding="10px"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                )}
                rowClassName={() => styles.custom_row}
                locale={{ emptyText: renderDestinationsInColumns() }}
            />
        </div>
    );
};

const mapState = (state) => ({
    listOfBlackListViatorDestination: getListOfBlacklistDestinationLinkedToChannel(state),
    isLoadingBlackListViatorDestination: getIsLoadingListOfBlacklistDestinationLinkedToChannel(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateBlacklistViatorDestinationStatus: (data) => dispatch(updateBlacklistViatorDestinationStatus(data)),
    fetchListOfListOfBlacklistViatorDestinationLinkedToChannel: (data) => dispatch(fetchListOfListOfBlacklistViatorDestinationLinkedToChannel(data)),
});

export default connect(mapState, mapDispatchToProps)(ListOfAllBlacklistViatorDestination);
