import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Switch } from 'antd'; // Assuming Ant Design's Switch
import styles from './modal.module.scss';
import { CustomButton } from '../../../../../shared/ui/button';
import ic_warning from '../../../../../assets/icons/warning-sign.png';
import { CustomHeading } from '../../../../../shared/ui/heading';
import { getCurrentSelectedVendor } from '../../../logic/vendor/selectors';
import { getCurrentSelectedChannelRecord } from '../../../logic/channels/selectors';
import { updateVendorStatusInBulk } from '../../../logic/channels/actions';
import { getCountryNameByCode } from '../../../../../shared/utils/regions_with_countries';

const WarningBeforeEnablingOrDisablingAllVendors = ({
    record,
    setIsDetailsModalOpen,
    setDetailsModalFooter,
    updateVendorStatusInBulk,
    vendorsListSearchFilter,
    ...props
}) => {
    const [isEnableAction, setIsEnableAction] = useState(true); // State to toggle enable/disable action
    const [isChecked, setIsChecked] = useState(false); // State to control checkbox selection

    // Check if search parameters exist and set the checkbox accordingly
    useEffect(() => {
        if (vendorsListSearchFilter?.platform || vendorsListSearchFilter?.country_code) {
            setIsChecked(true); // Set checkbox to checked if there are any search filters
        }
    }, [vendorsListSearchFilter]);

    // Generate filter summary text
    const getFilterSummary = () => {
        const platforms = vendorsListSearchFilter?.platform || [];
        const countries = vendorsListSearchFilter?.country_code || [];
    
        // Get platform text
        const platformText = platforms.length > 0 ? `Platform: ${platforms.join(', ')}` : '';
    
        // Get country names from codes using getCountryNameByCode
        const countryNames = countries
            .map((isoCode) => getCountryNameByCode(isoCode)) // Get country names using isoCode
            .filter(Boolean) // Remove null values
            .sort() // Sort countries alphabetically
            .join(', ');
    
        const countryText = countryNames ? `Countries: ${countryNames}` : '';
    
        return [platformText, countryText].filter(Boolean).join(' | ');
    };
    const handleOkay = useCallback(() => {
        updateVendorStatusInBulk({
            channel_id: record.channel_id,
            checked: isEnableAction,
            keyword: "",
            platform: vendorsListSearchFilter?.platform ? vendorsListSearchFilter?.platform : [],
            country_code: vendorsListSearchFilter?.country_code ? vendorsListSearchFilter?.country_code : []
        });
        setIsDetailsModalOpen(false);
    }, [record?.vendor_id, isEnableAction]);

    const handleCancel = () => {
        setIsDetailsModalOpen(false);
    };

    useEffect(() => {
        setDetailsModalFooter(
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                <CustomButton
                    title={isEnableAction ? "Yes, enable all vendors" : "Yes, disable all vendors"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    colorScheme={isEnableAction ? "success" : "danger"}
                    padding="5px 10px 0px 10px"
                    onClick={handleOkay}
                />
                <CustomButton
                    title={"Cancel"}
                    width="100%"
                    font_size="0.8"
                    unit="rem"
                    padding="5px 10px 0px 10px"
                    onClick={handleCancel}
                />
            </div>
        );
    }, [handleOkay, isEnableAction]);

    return (
        <div className={styles.delete_vendor_warning_modal}>
            <div className={styles.image_container}>
                <img src={ic_warning} width={'45%'} />
            </div>
            <div className={styles.message_container}>
                <CustomHeading
                    textAlign='center'
                    font_size="1.4"
                    unit="rem"
                    headingText={`Are you sure you want to ${isEnableAction ? "enable" : "disable"} all the vendors for ${record?.channelName}? It's a massive action to take!!`}
                />
                <div style={{ marginTop: "15px", fontSize: "1rem", textAlign: "center" }}>
                    <span>and in the filter you have selected: </span>
                    <span>{getFilterSummary() || "No filters applied"}</span>
                </div>
            </div>
            <div className={styles.switch_container} style={{ marginTop: "15px", textAlign: "center" }}>
                <Switch
                    checked={isEnableAction}
                    onChange={setIsEnableAction}
                    checkedChildren="Enable All"
                    unCheckedChildren="Disable All"
                    style={{ transform: "scale(1.2)" }}
                />
            </div>
        </div>
    );
};

const mapState = (state) => ({
    record: getCurrentSelectedVendor(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateVendorStatusInBulk: (data) => dispatch(updateVendorStatusInBulk(data)),
});

export default connect(mapState, mapDispatchToProps)(WarningBeforeEnablingOrDisablingAllVendors);
