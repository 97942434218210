import React, { useEffect, useState } from "react";
import { Table, Checkbox, Button, Row, Col, Input, notification, Switch } from "antd";
import * as XLSX from "xlsx";
import { connect } from "react-redux";

import { fetchListOfProductsToExport } from "../../../logic/product/actions";
import { getIsLoadingListOfProductsToExport, getListOfProductsToExport } from "../../../logic/product/selectors";
import { CustomButton } from "../../../../../shared/ui/button";

const Export = ({
    listOfProductsForExport,
    isLoadingListOfProductsToExport,
    fetchListOfProductsToExport,
    listSearchParams = null
}) => {
    const [columns, setColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [editableColumns, setEditableColumns] = useState({});
    const [fileName, setFileName] = useState("exported_data.xlsx");
    const [isExporting, setIsExporting] = useState(false);
    const [applyFilters, setApplyFilters] = useState(true);
    const [limit, setLimit] = useState(100);
    const [triggerExport, setTriggerExport] = useState(false);
    const [includeVendorsWithZeroProducts, setIncludeVendorsWithZeroProducts] = useState(false);
    const [includeVendorsWithZeroMatches, setIncludeVendorsWithZeroMatches] = useState(false);
  
    useEffect(() => {
        // Example sample data to simulate columns
        const sampleData = [
            {
                image_link: "https://www.dagboekreizen.nl/upload/content/banner/zuid-amerika/brazilie/brazil banner aras (2).jpg",
                english_title: "Individuele rondreis Brazilië",
                // product_id: 2916929,
                vendor_name: "Dagboekreizen.nl",
                // vendor_id: 1,
                platform: "TradeTracker",
                description: null,
                // alt_title: null,
                affiliate_link: "https://www.dagboekreizen.nl/reizen/?tt=2758_544336_467567_&r=https%3A%2F%2Fwww.dagboekreizen.nl%2Fbrazilie.html"
            },
            // Add more sample data if needed
        ];

        // Generate column options from sample data
        const initialColumns = Object.keys(sampleData[0]).map((key) => ({
            key,
            label: formatColumnName(key.replace(/_/g, " ")),
        }));

        setColumns(initialColumns);
        setSelectedColumns(initialColumns.map((col) => col.key)); // Select all columns by default

        const editable = {};
        initialColumns.forEach((col) => {
            editable[col.key] = col.label; // Initialize editable labels
        });

        setEditableColumns(editable);

        
        return () => {
            setTriggerExport(false)
        }
    }, []);

    useEffect(() => {
        if (triggerExport&&listOfProductsForExport?.length > 0) {
            // Filter data based on selected columns and apply custom column names
            const dataToExport = listOfProductsForExport.map((row) => {
                const filteredRow = {};
                selectedColumns.forEach((col) => {
                    let cellValue = row[col];
                    if (typeof cellValue === 'string' && cellValue.length > 32767) {
                        cellValue = cellValue.substring(0, 32767); // Truncate text to 32767 characters
                    }
                    filteredRow[editableColumns[col] || col] = cellValue;
                });
                return filteredRow;
            });


            // Create Excel file
            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Exported Data");

            // Trigger download with the provided file name
            XLSX.writeFile(workbook, fileName);

            // Set isExporting to false after export is complete
            setIsExporting(false);
            setTriggerExport(false); // Reset the export trigger
        }
    }, [triggerExport,listOfProductsForExport]);

    const handleExport = () => {
        if (selectedColumns.length === 0) {
            alert("Please select at least one column to export.");
            return;
        }
        setIsExporting(true);
        fetchListOfProductsToExport({
            keyword: applyFilters ? listSearchParams.keyword : "",
            platform: applyFilters ? listSearchParams.platform : [],
            vendorIds: applyFilters ? listSearchParams.vendorIds : [],
            limit: limit,
            numberOfRecordsToLoad:limit,
            categories:applyFilters ? listSearchParams.categories : [],
        }).then(() => {
            setIsExporting(false);
            setTriggerExport(true)    
        }).catch((error) => {
            console.error(error)
            setIsExporting(false)
        })
    };

    const handleColumnNameChange = (key, value) => {
        setEditableColumns((prev) => ({ ...prev, [key]: value }));
    };
 
    const formatColumnName = (str) => {
        return str
            .replace(/_/g, " ")  // Replace underscores with spaces
            .replace(/\b\w/g, (match) => match.toUpperCase());  // Capitalize the first letter of each word
    };

    // Generate filter description
    const getFilterDescription = () => {
        if (!listSearchParams) return "";
        const { keyword, platform } = listSearchParams;
        let description = "You have selected: ";
        if (platform && platform.length > 0) {
            description += `Platform(s): ${platform.join(", ")}. `;
        }
        if (keyword) {
            description += `Search keyword: "${keyword}". `;
        }
        return description.trim();
    };

    const handleApplyFiltersChange = (checked) => {
        setApplyFilters(checked);
        if (checked) {
            notification.warning({
                message: "Applying Filters",
                description: "Enabling filters may take more time to process the export.",
                duration: 5,
            });
        }
    };

    useEffect(() => {
        const getFileName = () => {
            if (!listSearchParams) return "exported_data.xlsx";
    
            const { keyword, platform } = listSearchParams;
            let fileName = "exported_data";
    
            // If there are platforms, add them to the filename
            if (platform && platform.length > 0) {
                fileName += `_${platform.join("_")}`;
            }
    
            // If there is a keyword, replace spaces with underscores and add it to the filename
            if (keyword) {
                const formattedKeyword = keyword.replace(/\s+/g, '_'); // Replace spaces with underscores
                fileName += `_${formattedKeyword}`;
            }
    
            return `${fileName}.xlsx`;
        };
    
        setFileName(getFileName());
    }, [listSearchParams]);
    

    return (
        <div>
            <Row gutter={[16, 16]} align="middle" style={{ marginBottom: "16px" }}>
                <Col flex="auto">
                    <Input
                        placeholder="Enter file name (default: exported_data.xlsx)"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value || "exported_data.xlsx")}
                    />
                </Col>
                <Col>
                    {/* <Button type="primary" onClick={handleExport} loading={isExporting}>
                        Export to Excel
                    </Button> */}
                      <CustomButton
                        title="Export to Excel"
                        width="100%"
                        font_size="0.7"
                        unit="rem"
                        padding="5px 5px 5px 5px"
                        colorScheme="primary"
                        border_color="#FAFAFA"
                        background_color="#FAFAFA"
                        is_loading={isExporting}
                        onClick={(e) => handleExport(e)}
                    />
                </Col>
            </Row>

            {/* Apply Filters Switches */}
            <div style={{ marginBottom: "16px" }}>
                <Row gutter={[16, 16]}>
                    {/* First Section: Apply Previous Filters */}
                    {/* <Col span={24}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <label style={{ marginRight: "10px" }}>Apply previous filters:</label>
                            <Switch
                                checked={applyFilters}
                                onChange={handleApplyFiltersChange} 
                            />

                        </div>
                    </Col> */}
                    {/* Filter Description */}
                    {/* {applyFilters && listSearchParams && (
                        <div style={{ marginBottom: "16px", padding: "10px", background: "#f0f0f0", borderRadius: "4px" }}>
                            {getFilterDescription()}
                        </div>
                    )} */}
                    <Col span={24}>
                        <label>Set number of products to export:</label>
                        <Input
                            type="number"
                            value={limit}
                            onChange={(e) => setLimit(Number(e.target.value) || 10)}
                            placeholder="Enter limit"
                            style={{ marginLeft: "10px", width: "100px" }}
                        />
                    </Col>
                </Row>
            </div>



            <div style={{ marginBottom: "16px" }}>
                {/* Column header */}
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div style={{ textAlign: "left", flex: 1 }}>Select the columns to include in your export file from the list below.</div>

                </div>

                {/* Three Column Layout (Flexbox) */}
                <Row gutter={[16, 16]}>
                    {/* Dynamically Render Columns */}
                    {columns.map((col, index) => (
                        <Col key={col.key} span={6}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ textAlign: "left", flex: 1 }}>
                                    {/* Display original column label alongside editable name */}
                                    <div style={{ marginBottom: "5px", fontWeight: "bold" }}>
                                        {formatColumnName(col.key)}
                                    </div>
                                    <Checkbox
                                        checked={selectedColumns.includes(col.key)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setSelectedColumns((prev) =>
                                                isChecked
                                                    ? [...prev, col.key]
                                                    : prev.filter((key) => key !== col.key)
                                            );
                                        }}
                                    />
                                    <Input
                                        value={editableColumns[col.key]}
                                        onChange={(e) => handleColumnNameChange(col.key, e.target.value)}
                                        placeholder="Column Name"
                                        style={{ width: "150px", marginLeft: "10px" }}
                                    />
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>


    );
};

const mapState = (state) => ({
    listOfProductsForExport: getListOfProductsToExport(state),
    isLoadingListOfProductsToExport: getIsLoadingListOfProductsToExport(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchListOfProductsToExport: (data) => dispatch(fetchListOfProductsToExport(data)),
});

export default connect(mapState, mapDispatchToProps)(Export);
