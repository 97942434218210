import { connect } from "react-redux";
import styles from './modal.module.scss'
import { CustomHeading } from "../../../../../shared/ui/heading";
import TabsRender from "../general-components/TabsRender";
import ListOfAllowedChannels from "./ListOfAllowedChannels";
import ListOfAllowedRegions from "./ListOfAllowedRegions";
import { useEffect, useState } from "react";
const VendorDetailsForm = ({
    setDetailsModalFooter,
    setIsDetailsModalOpen,

    record, ...props }) => {
    const [listOfTabs, setListOfTabs] = useState([]);
    useEffect(() => {
        let temp_tabs = [
            {
                label: "Channels",
                screen: <ListOfAllowedChannels is_pagination_enabled={false} setIsDetailsModalOpen={setIsDetailsModalOpen} setDetailsModalFooter={setDetailsModalFooter} record={record} />
            },
            {
                label: "Regions",
                screen: <ListOfAllowedRegions setIsDetailsModalOpen={setIsDetailsModalOpen} setDetailsModalFooter={setDetailsModalFooter} record={record} />
            }
        ]
        setListOfTabs(temp_tabs)
      
    }, [record])

    const handleTabOnChange = (e) => { }

    return <div className={styles.vendors_details_form_container}>
        <div className={styles.row_1}>
            <div className={styles.left_side}>
                <div className={styles.value}>
                    <CustomHeading font_size="0.7" unit="rem" font_weight="bold" headingText={"Platform Name : "} />
                    <CustomHeading font_size="0.7" unit="rem" headingText={record?.platform} />
                </div>
                <div className={styles.value}>
                    <CustomHeading font_size="0.7" unit="rem" font_weight="bold" headingText={"Vendor Name : "} />
                    <CustomHeading font_size="0.7" unit="rem" headingText={record?.name} />

                </div>
                <div className={styles.value}>
                    <CustomHeading font_size="0.7" unit="rem" font_weight="bold" headingText={"Allowed Channels : "} />
                    <CustomHeading font_size="0.7" unit="rem" headingText={`Total : ${record?.allowedChannels?.length} | Enabled : ${record.enabled_channels} | Disabled : ${record.disabled_channels}`} />

                </div>
                <div className={styles.value}>
                    <CustomHeading font_size="0.7" unit="rem" font_weight="bold" headingText={"Allowed Regions : "} />
                    <CustomHeading font_size="0.7" unit="rem" headingText={`Total : ${record?.allowedRegions?.length} | Enabled : ${record?.enabled_regions} | Disabled : ${record?.disabled_regions}`} />

                </div>
                <div className={styles.value}>
                    <CustomHeading font_size="0.7" unit="rem" font_weight="bold" headingText={"Domain URL : "} />
                    <CustomHeading font_size="0.7" unit="rem" headingText={record?.domain_url} />

                </div>
                <div className={styles.value}>
                    <CustomHeading font_size="0.7" unit="rem" font_weight="bold" headingText={"Logo : "} />
                    <img src={record?.logo_url} width={30} height={30}/>
                </div>

            </div>
            <div className={styles.right_side}>

            </div>
        </div>
        {/* <div className={styles.row_2}>
            <TabsRender
                list_of_tabs={listOfTabs}
                handleOnChange={handleTabOnChange}
            />
        </div> */}
    </div>
}

const mapState = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapState, mapDispatchToProps)(VendorDetailsForm)