import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'vendor',
    initialState: {
        listOfVendors: [],
        listOfVendorsNames: [],
        listOfCatagoriesForVendor: null,
        listOfVendorsToExport: [],
        match_count_division_for_vendor: [],
        is_loading_match_count_division_for_vendor: false,
        is_loading_list_of_vendors_for_export: false,
        is_loading_list_of_vendors: false,
        is_updating_regions: false,
        is_updating_channels_for_vendors: false,
        details_modal_opened_for: null,
        is_loading_catagories_for_vendor: false,
        current_select_vendor: null,
        is_loading_vendor_count_details_by_channel: false,
    },
    reducers: {
        setListOfVendors: (state, action) => {
            state.listOfVendors = action.payload;
        },
        setCurrentSelectVendor: (state, action) => {
            state.current_select_vendor = action.payload;
        },
        setListOfCatagoriesForVendor: (state, action) => {
            state.listOfCatagoriesForVendor = action.payload;
        },
        setListOfVendorsNames: (state, action) => {
            state.listOfVendorsNames = action.payload;
        },
        setDetailsModalOpenedFor: (state, action) => {
            state.details_modal_opened_for = action.payload;
        },
        setIsLoadingListOfVendors: (state, action) => {
            state.is_loading_list_of_vendors = action.payload;
        },
        setIsUpdatingRegions: (state, action) => {
            state.is_updating_regions = action.payload;
        },
        setIsUpdatingChannelsForRegions: (state, action) => {
            state.is_updating_channels_for_vendors = action.payload;
        },
        setMatchCountDivisionForVendor: (state, action) => {
            state.match_count_division_for_vendor = action.payload;
        },

        setIsLoadingMatchCountDivisionForVendor: (state, action) => {
            state.is_loading_match_count_division_for_vendor = action.payload;
        },

        setIsLoadingListOfCatagoriesForVendor: (state, action) => {
            state.is_loading_catagories_for_vendor = action.payload;
        },
        setIsLoadingVendorCountDetailsByChannel: (state, action) => {
            state.is_loading_vendor_count_details_by_channel = action.payload;
        },
        setListOfVendorsForExport: (state, action) => {
            state.listOfVendorsToExport = action.payload;
        },
        setIsLoadingListOfVendorsForExport: (state, action) => {
            state.is_loading_list_of_vendors_for_export = action.payload;
        },

    },
});

export const {
    setListOfVendors,
    setDetailsModalOpenedFor,
    setIsLoadingListOfVendors,
    setIsUpdatingChannelsForRegions,
    setIsUpdatingRegions,
    setListOfVendorsNames,
    setListOfCatagoriesForVendor,
    setIsLoadingListOfCatagoriesForVendor,
    setCurrentSelectVendor,
    setMatchCountDivisionForVendor,
    setIsLoadingMatchCountDivisionForVendor,
    setIsLoadingVendorCountDetailsByChannel,
    setListOfVendorsForExport,
    setIsLoadingListOfVendorsForExport,
} = appSlice.actions;

export default appSlice.reducer;
